import useSWR from 'swr';
import { useEffect, useState, useContext } from 'react';
import ProjectContext from '../../store/projectContext';
import { useDebounce, useQueryParams } from '../../lib/hooks';
import { useAppClient } from '../../lib/AppProvider';

export function useDocumentDefaultAction({ projectId }) {
  const client = useAppClient();

  const { data: documents, revalidate } = useSWR(
    projectId && 'default',
    () => client.listDocumentsDefault(projectId),
    { revalidateOnFocus: false }
  );
  const { deleteDocument, update, create } = useDocuments();

  return {
    documents,
    async createDefaultDocument(form) {
      await create(form);
      revalidate();
    },
    async updateDefaultDocument(id, form) {
      await update(id, form);
      revalidate();
    },
    async deleteDefaultDocument(id, form) {
      await deleteDocument(id, form);
      revalidate();
    },
  };
}

export function useDocumentSearch({ projectId }) {
  const client = useAppClient();
  const [query, setQuery] = useQueryParams();

  const [q, onChangeQ] = useState(query.q ?? '');
  const memoQuery = useDebounce(q, 500);

  const cacheKey = [
    ['projectId', projectId],
    ['query', memoQuery],
  ].reduce((memo, [key, value]) => `${memo}${key}=${value}&`, '');

  const { data: documents, revalidate, error } = useSWR(
    projectId && `documents?${cacheKey}`,
    () => client.listDocuments(projectId, { query: memoQuery }),
    { revalidateOnFocus: false }
  );

  useEffect(() => {
    setQuery({ q: memoQuery });
  }, [memoQuery, setQuery]);
  if (error) {
    console.error(error);
  }

  const { projectDispatch } = useContext(ProjectContext);

  return {
    documents: documents ?? [],
    loading: !documents,
    query: q,
    onChangeQuery: onChangeQ,
    revalidate,
    async deleteDocument(id, form) {
      await client.deleteDocument(id, { projectId: form.projectId });
      projectDispatch({ type: 'REMOVE_DOCUMENT', id });
    },
  };
}

export function useDocumentAction() {
  const { projectDispatch } = useContext(ProjectContext);
  const { deleteDocument, update, create } = useDocuments();

  return {
    async deleteDocumentAction(id, form) {
      await deleteDocument(id, form);
      projectDispatch({ type: 'REMOVE_DOCUMENT', id });
    },
    async updateAction(id, form) {
      const document = await update(id, form);

      projectDispatch({
        type: 'UPDATE_DOCUMENT',
        document,
      });
    },
    async createAction(form) {
      const document = await create(form);

      projectDispatch({
        type: 'CREATE_DOCUMENT',
        document,
      });
    },
  };
}

export function useDocuments() {
  const client = useAppClient();

  return {
    async deleteDocument(id, form) {
      await client.deleteDocument(id, { projectId: form.projectId });
      return id;
    },
    async update(id, form) {
      const document = await client.updateDocument(id, {
        documentType: form.documentType,
        projectId: form.projectId,
        name: form.name,
        description: form.description,
        link: form.link,
        status: form.status,
        category: form.category,
        file: form.file,
      });

      return document;
    },
    async create(form) {
      const document = await client.createDocument({
        documentType: form.documentType,
        projectId: form.projectId,
        name: form.name,
        description: form.description,
        link: form.link,
        status: form.status,
        category: form.category,
        file: form.file,
      });
      return document;
    },
  };
}
