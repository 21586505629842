import React from 'react';
import { useStarredProject } from '../../../../useProjectSearch';

export function withProject(Component) {
  function WithProject(props) {
    const { data } = useStarredProject();

    const newProps = {
      starredProjects: data || [],
      ...props,
    };
    return <Component {...newProps} />;
  }

  return WithProject;
}
