export const TypesImageAndVideo = [
  '.jpg',
  '.svg',
  '.png',
  '.tiff',
  '.psd',
  '.gif',
  '.ai',
  '.eps',
  '.indd',
  '.mp4',
  '.avi',
  '.mov',
  '.mkv',
  '.avchd',
  '.flv',
  '.webp',
  '.jpeg',
];

export const typesOfImage = [
  '.jpg',
  '.svg',
  '.png',
  '.tiff',
  '.psd',
  '.gif',
  '.ai',
  '.eps',
  '.indd',
  '.webp',
  '.jpeg',
];

export const TypesImage = [
  '.jpg',
  '.svg',
  '.png',
  '.tiff',
  '.psd',
  '.gif',
  '.ai',
  '.eps',
  '.indd',
  '.webp',
  '.jpeg',
];

export const TypesVideo = ['.mp4', '.avi', '.mov', '.mkv', '.avchd', '.flv'];
