import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Modal } from '../../../../components/Modal';
import { Radio } from '../../../../components/Radio';
import {
  Button,
  OutlineButton,
  // eslint-disable-next-line import/no-useless-path-segments
} from '../../../../features/authentication/components/Button';
import { CloseIcon } from '../../../../components/Icons';

function ConfirmModal({
  className,
  isOpen,
  onClose,
  onConfirm,
  message,
  isRepeat,
  updatedValues,
  initialValues,
}) {
  const [selected, setSelected] = useState(null);

  const isRecurrenceChange = useMemo(() => {
    return (
      updatedValues?.recurrence === 'not_repeat' &&
      initialValues?.recurrence !== updatedValues?.recurrence
    );
    // eslint-disable-next-line
  }, [initialValues?.recurrence, updatedValues?.recurrence]);

  const isDateChange = useMemo(() => {
    return initialValues?.dueDate !== updatedValues?.dueDate;
    // eslint-disable-next-line
  }, [initialValues?.dueDate, updatedValues?.dueDate]);

  const isUpdateRecurrence = useMemo(() => {
    return (
      initialValues?.recurrence !== updatedValues?.recurrence ||
      initialValues?.startAt !== updatedValues?.startAt ||
      initialValues?.endAt !== updatedValues?.endAt
    );
  }, [initialValues, updatedValues]);

  function onCheck(select) {
    setSelected(select);
  }

  function onSubmit() {
    onConfirm(selected, isUpdateRecurrence);
    setSelected(null);
  }

  return (
    <Modal className={className} isOpen={isOpen} onClose={onClose}>
      <div className="close-btn" onClick={onClose}>
        <CloseIcon className="icon-close" />
      </div>
      <div className="container">
        <div className="title">{message}</div>
        {isRepeat &&
          (isDateChange || !isUpdateRecurrence) &&
          !isRecurrenceChange && (
            <Radio
              className="radio"
              checked={selected === 'this'}
              value="this"
              label="This ticket"
              onClick={e => onCheck(e)}
            />
          )}
        {isRepeat && !isDateChange && !isRecurrenceChange && (
          <>
            <Radio
              className="radio"
              checked={selected === 'this_and_following'}
              value="this_and_following"
              label="This and next ticket"
              onClick={e => onCheck(e)}
            />
            <Radio
              className="radio"
              checked={selected === 'all'}
              value="all"
              label="All ticket"
              onClick={e => onCheck(e)}
            />
          </>
        )}
        {isRepeat && isRecurrenceChange && (
          <Radio
            className="radio"
            checked={selected === 'accept'}
            value="accept"
            label="All tickets will be deleted except this ticket."
            onClick={e => onCheck(e)}
          />
        )}
        <div className="btn-action">
          <OutlineButton className="cancel-btn" onClick={onClose}>
            Cancel
          </OutlineButton>
          <Button
            className="confirm-btn"
            onClick={onSubmit}
            disabled={!selected}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
}

const StyledConfirmModal = styled(ConfirmModal)`
  max-width: 420px;
  width: 90vw;
  position: relative;

  > .close {
    display: none;
  }

  .close-btn {
    position: absolute;
    background: #c1c3ca;
    right: 10px;
    top: 10px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 4px;
    cursor: pointer;

    svg {
      color: white;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #c1c3ca;
    }
  }

  .container {
    > .image {
      height: 215px;
      width: fit-content;
      margin-bottom: 30px;
    }

    > .title {
      color: #333;
      font-size: 22px;
      margin-bottom: 20px;
      font-weight: bold;
    }

    > span {
      color: #333;
      font-size: 16px;
      margin-bottom: 20px;
    }

    > .btn-action {
      display: flex;
      margin-top: 40px;
      justify-content: flex-end;

      > .confirm-btn {
        max-width: 120px;
        width: 100%;
        padding: 12px 0;

        &:disabled {
          background: #adadad;
          cursor: not-allowed;
          border: none;
        }
      }

      > .cancel-btn {
        border: none;
        color: #c1c3ca;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
`;

export { StyledConfirmModal as ConfirmModal };
