import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import '@reach/accordion/styles.css';
import { titleCase } from '../../../components/TextTransform';
import { useDocumentDefaultAction } from '../../documents/useDocumentSearch';
import { BaseFilter } from '../../../components/SideBarFilter/BaseFilter';
import { ExclamationIcon } from '../../../components/Icons';

const DocumentListItem = styled(
  React.forwardRef(function DocumentListItem(
    { className, documentType, document, color, ...props },
    ref
  ) {
    return (
      <div
        className={`${className} ${document ? '' : 'default'}`}
        {...props}
        ref={ref}
      >
        {document && (
          <a
            href={document.fileUrl}
            download
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="col document-name default-document-available">
              <div className="col document-type-title">
                {titleCase(documentType)}
              </div>
              {document?.name}
            </div>
            <div className="file-type">
              {document?.fileUrl?.includes('.pdf') ? 'PDF' : 'IMG'}
            </div>
          </a>
        )}
        {!document && (
          <div className="col document-name default-document">
            <ExclamationIcon color="#f75519" />
            {titleCase(documentType)}
          </div>
        )}
      </div>
    );
  })
)`
  align-items: center;
  display: flex;

  border-radius: 6px;
  height: 50px;
  padding: 0 15px;
  margin: 10px 0px;

  color: #999;
  font-size: 12px;
  font-weight: bold;

  background-color: #f5f8f8;

  &.default {
    background-color: rgba(247, 85, 25, 0.05);
  }

  > .default-document {
    font-size: 12px;
    font-weight: normal;
    color: #f75519;

    > svg {
      margin-right: 5px;
    }
  }

  > a {
    text-decoration: none;
    display: flex;
    flex-direction: revert;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    > .file-type {
      color: #22c759;
      right: 5px;
      top: 30%;
      width: 40px;
      height: 20px;
      padding: 5px 10px;
      border-radius: 10px;
      background-color: #dff0e2;
    }

    > .col {
      display: flex;
      align-items: center;

      &.default-document-available {
        position: relative;
        display: block;
        font-size: 12px;
        color: #3a3a3a;

        > .document-type-title {
          font-size: 10px;
          margin-bottom: 5px;
          color: #9fa3ae;
        }
      }
    }
  }
`;

function DocumentPreview({ className, projectId, color, isLoading }) {
  const { documents } = useDocumentDefaultAction({ projectId });
  const defaultDocumentName = [
    'master_service_agreement',
    'scope_of_work',
    'requirement_document',
    'test_case',
    'user_manual',
  ];

  const navigate = useNavigate();

  return (
    <BaseFilter
      className={className}
      title="Documents"
      noSearch
      isLoading={isLoading}
    >
      <div className="specific">Specific Documents</div>

      {defaultDocumentName.map(name => (
        <DocumentListItem
          key={name}
          className={className}
          documentType={name}
          document={documents?.find(document => document.documentType === name)}
          color={color}
        />
      ))}

      <div className="document-menu" onClick={() => navigate(`./documents`)}>
        <h5 className="title">All documents</h5>
      </div>
    </BaseFilter>
  );
}

const StyledDocumentPreview = styled(DocumentPreview)`
  .specific {
    font-size: 12px;
    font-weight: bold;
    color: #9fa3ae;
  }

  .document-menu {
    cursor: pointer;
    padding: 20px;

    .title {
      font-size: 14px;
      color: #22c759;
      font-weight: normal;
      text-decoration: underline;
      text-align: center;
    }
  }
`;

const MemoDocumentPreview = React.memo(StyledDocumentPreview);

export { MemoDocumentPreview as DocumentPreview };
