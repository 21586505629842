import React from 'react';
import { AppLayout } from '../../components/AppLayout';
import { withAuthenticatedGuard } from '../authentication/hocs/withAuthenticatedGuard';
import { AccountLayout } from './AccountLayout';
import { SettingAccountPage } from './pages/SettingAccountPage';
import { ChangePasswordPage } from './pages/ChangePasswordPage';
import { CurrentCompanyProvider } from '../projects/useCurrentCompany';
import GlobalStateProvider from '../../store/GlobalStateProvider';

const AuthenticatedAppLayout = withAuthenticatedGuard(AppLayout);

export const AccountRoutes = {
  path: '/settings',
  element: (
    <GlobalStateProvider>
      <CurrentCompanyProvider>
        <AuthenticatedAppLayout>
          <AccountLayout />
        </AuthenticatedAppLayout>
      </CurrentCompanyProvider>
    </GlobalStateProvider>
  ),
  children: [
    { path: 'account', element: <SettingAccountPage /> },
    { path: 'password', element: <ChangePasswordPage /> },
  ],
};
