import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';

function Checkbox({ className, checked, ...props }) {
  return (
    <span className={className}>
      <input type="checkbox" checked={checked} {...props} />
      <div className="fake-input">
        <div className={`mark ${checked ? 'checked' : ''}`} />
      </div>
    </span>
  );
}

const StyledCheckbox = styled(Checkbox)`
  position: relative;

  > input {
    width: 20px;
    height: 20px;
    opacity: 0;
    cursor: pointer;
  }

  > .fake-input {
    color: white;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 6px;
    border: solid 1px #dddddd;
    background-color: #ffffff;
    visibility: initial;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    .mark {
      width: 12px;
      height: 12px;
      border-radius: 2px;

      &.checked {
        background-color: ${theme('colors.primary')};
      }
    }
  }
`;

export { StyledCheckbox as Checkbox };
