import React from 'react';
import { theme } from 'styled-tools';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import { AccountContent } from '../../components/AccountContent';
import { LoadingIndicator } from '../../../../components/LoadingIndicator';
import { TextField } from '../../../authentication/components/TextField';
import { FormikSingleImageUploader as SingleImageUploader } from '../../../settings/pages/SettingCompanyPage/SingleImageUploader';

const validationSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
});

function SettingAccountPage({
  className,
  loading,
  initialValues,
  initialAvatar,
  onSubmit,
  onReset,
}) {
  if (loading) return <LoadingIndicator />;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ setFieldValue }) => (
        <Form noValidate>
          <AccountContent
            className={className}
            title="Account Settings"
            onReset={onReset}
          >
            <div className="contents">
              <SingleImageUploader
                className="image-uploader"
                name="avatarFile"
                previewUrl={initialAvatar}
                setFieldValue={setFieldValue}
              />
              <div className="field-group">
                <label className="title-account">First name</label>
                <label className="title-account">Last name</label>
                <TextField
                  id="firstName"
                  name="firstName"
                  className="textfield-account"
                />
                <TextField id="lastName" name="lastName" />
                <label className="title-account">Nickname</label>
                <label className="title-account">Email</label>
                <TextField id="nickName" name="nickName" />
                <TextField id="email" name="email" disabled />
              </div>
            </div>
          </AccountContent>
        </Form>
      )}
    </Formik>
  );
}

const StyledSettingAccountPage = styled(SettingAccountPage)`
  > .contents {
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-gap: 20px;
    padding: 20px;

    > .image-uploader {
      width: 200px;
      height: 200px;
    }

    > .field-group {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 0 20px;
      align-self: flex-start;

      > .title-account {
        margin-bottom: 10px;
        color: #c1c3ca;
        font-size: 12px;
      }

      > .textfield-account {
        margin-bottom: 20px;
      }

      > ${TextField} {
        > .input-group {
          > input:disabled {
            background-color: #f4f5f8;
            color: #9fa3ae;
          }
        }
      }
    }
  }

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    > .contents {
      grid-template-columns: auto;

      > .field-group {
        grid-template-columns: auto;
      }
    }
  }
`;

export { StyledSettingAccountPage as SettingAccountPage };
