import styled, { css } from 'styled-components';
import { theme } from 'styled-tools';

export const LoadingIndicator = styled.div.attrs(props => ({
  color: props.color || null,
}))`
  ${props =>
    props.fullscreen &&
    css`
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;

      z-index: 9999;
    `}

  ${props =>
    props.size !== 'sm' &&
    css`
      height: 100%;
    `}

  z-index: 100;
  display: grid;
  place-items: center;

  border-radius: 6px;
  width: 100%;

  background: #ffffff77;

  &:after {
    content: ' ';
    display: block;
    width: ${props => (props.size === 'sm' ? 16 : 64)}px;
    height: ${props => (props.size === 'sm' ? 16 : 64)}px;
    margin: 8px auto;
    border-radius: 50%;
    border: ${props => (props.size === 'sm' ? 2 : 6)}px solid
      ${props =>
        props.color ? theme(`colors.${props.color}`) : theme('colors.primary')};
    border-color: ${props =>
        props.color ? theme(`colors.${props.color}`) : theme('colors.primary')}
      transparent
      ${props =>
        props.color ? theme(`colors.${props.color}`) : theme('colors.primary')}
      transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
