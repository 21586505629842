import React from 'react';
import hoist from 'hoist-non-react-statics';
import useSWR from 'swr';
import { useAppClient } from '../../../../lib/AppProvider';

export function withEpicAndSprintPage(Component) {
  function WithEpicAndSprintPage(props) {
    const projectId = 7; // mock project id
    const page = 1;
    const per_page = 5;

    const { data, createComment } = useActivityLogsAndComments({
      id: projectId,
      page,
      per_page,
    });

    const handleCreateComment = ({ comment }, { resetForm }) => {
      createComment({ pid: projectId, comment });
      resetForm();
    };

    const pageProps = {
      projectId,
      activityLogsAndComments: data,
      handleCreateComment,
    };

    return <Component {...props} {...pageProps} />;
  }
  hoist(WithEpicAndSprintPage, Component);

  WithEpicAndSprintPage.displayName = `WithEpicAndSprintPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithEpicAndSprintPage;
}

function useActivityLogsAndComments({ id, page, limit }) {
  const client = useAppClient();

  const { data } = useSWR(
    `/use_cases/${id}/activity_log_comments?page=${page}`,
    () => client.listActivityLogsAndComments({ id, page, per_page: limit }),
    { revalidateOnFocus: false }
  );

  const createComment = ({ pid, comment }) =>
    client.createComment({ pid, comment });

  return {
    loading: !data,
    data: data?.data ?? [],
    createComment,
  };
}
