import React from 'react';
import styled from 'styled-components';
import { theme, ifProp } from 'styled-tools';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryParams } from '../../../lib/hooks';
import { CalendarIcon, KanBanIcon, ListIcon } from '../../../components/Icons';

export const ToggleButton = styled.button`
  outline: none;
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: ${ifProp('active', theme('colors.primary'), 'transparent')};
  color: ${ifProp('active', 'white', theme('colors.primary'))};
`;

export const ToggleFrame = styled.div`
  display: flex;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid ${theme('colors.primary')};
`;

export const ToggleViewButton = () => {
  const [queryParams, setQueryParams] = useQueryParams();
  const { companyId } = useParams();
  const navigate = useNavigate();
  const status = queryParams.status || 'kanban';

  return (
    <ToggleFrame>
      <ToggleButton
        active={status === 'kanban'}
        onClick={() => setQueryParams({ status: 'kanban' })}
      >
        <KanBanIcon />
      </ToggleButton>
      <ToggleButton
        active={status === 'list'}
        onClick={() => setQueryParams({ status: 'list' })}
      >
        <ListIcon />
      </ToggleButton>
      <ToggleButton
        active={status === 'calendar'}
        onClick={() => navigate(`/company/${companyId}/?tab=ticket`)}
      >
        <CalendarIcon />
      </ToggleButton>
    </ToggleFrame>
  );
};
