import React, { useState } from 'react';
import hoist from 'hoist-non-react-statics';
import { mutate } from 'swr';
import { useNavigate } from 'react-router-dom';

import { useCurrentCompany } from '../../useCurrentCompany';
import { successToast } from '../../../../lib/toast';
import { useCompanyAction } from '../../useCompanyList';
import { useAppClient } from '../../../../lib/AppProvider';

export function withCompanySettingModal(Component) {
  function WithCompanySettingModal({
    projectId,
    onClose,
    reFetchTickets,
    ...props
  }) {
    const client = useAppClient();
    const { data: company } = useCurrentCompany();
    const { create } = useCompanyAction();
    const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
    const navigate = useNavigate();

    const initialValues = {
      name: '',
      logoFile: null,
      description: '',
      logoUrl: '',
      members: [],
    };

    const pageProps = {
      companyName: company?.name,
      projectId,
      initialValues,
      async onSubmit({ name, logoFile: logo, description, members }) {
        await create({
          name,
          logo,
          description,
          members,
        }).then(async res => {
          await mutate('companies');
          await client.selectCompany(res.id);
          await mutate('starred-projects');
          await mutate('projects');
          localStorage.setItem('currentCompanyId', res.id);
          navigate(`/company/${res.id}`, { replace: true });
        });
        successToast({ message: 'The Company has been created.' });
      },
      onClose,
      isShowDeleteModal,
      onOpenDeleteModal() {
        setIsShowDeleteModal(true);
      },
      onCloseDeleteModal() {
        setIsShowDeleteModal(false);
      },
    };

    return <Component {...props} {...pageProps} />;
  }

  hoist(WithCompanySettingModal, Component);

  WithCompanySettingModal.displayName = `WithCompanySettingModal(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithCompanySettingModal;
}
