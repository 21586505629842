import styled from 'styled-components';
import { ifProp, switchProp, theme } from 'styled-tools';
import { Select } from './SelectView';

const StyledSelectField = styled(Select)`
  margin-bottom: 15px;

  > .label {
    display: block;
    font-size: 12px;

    margin-bottom: 8px;

    color: #c1c3ca;
  }

  > .input-group {
    position: relative;
    color: #3a3a3a;

    .popover {
      display: block;
    }

    > .field {
      border-radius: 6px;
      border: 1px solid ${ifProp('error', theme('colors.error'), '#dce0ea')};
      width: 100%;
      padding: 10px ${ifProp('hasSuffix', '50px', '10px')} 10px
        ${ifProp('hasPrefix', '50px', '10px')};
      text-align: left;

      font-size: ${switchProp('size', { sm: '12px' }, 'inherit')};
      background: #ffffff;
      box-shadow: ${ifProp(
        'noShadow',
        'none',
        '0 10px 20px 0 rgba(0, 128, 42, 0.08)'
      )};
      white-space: nowrap;
      text-overflow: ellipsis;

      &:focus,
      &:active {
        outline-color: ${theme('colors.primary')};
      }

      &:focus + .popover {
        display: block;
      }

      > .placeholder {
        color: #9fa3ae;
      }
    }

    .field.disabled {
      background: #efefef;
      cursor: not-allowed;
    }

    > select {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;

      &:disabled {
        cursor: not-allowed;
        border-color: unset;
      }
    }

    > select:active + .field,
    > select:focus + .field {
      border-color: ${theme('colors.primary')};
    }

    svg {
      width: 10px;
      height: 10px;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%) rotateZ(180deg);
      color: ${theme('colors.secondary')};
    }
  }

  > .error-msg {
    color: red;
    font-size: 14px;
    margin: 5px 0 0;
  }
`;

export { StyledSelectField as SelectField };
