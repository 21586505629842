import React, { useState, useContext } from 'react';
import hoist from 'hoist-non-react-statics';
import { useNavigate } from 'react-router-dom';
import ProjectContext from '../../../../store/projectContext';
import { useCurrentCompany } from '../../useCurrentCompany';
import { useProject, useProjectAction } from '../../useProjectSearch';
import { transformMember } from '../../useProfile';
import { colorOptions } from '../../../../components/ColorPalette/ColorPalette';
import { successToast } from '../../../../lib/toast';
import { useQueryParams } from '../../../../lib/hooks';
import { ToolingsClient } from '../../../../clients/ToolingsClient';

export function withProjectSettingModal(Component) {
  function WithProjectSettingModal({
    projectId,
    onClose,
    reFetchTickets,
    reloadAllProjects,
    ...props
  }) {
    const { projectState, projectDispatch } = useContext(ProjectContext);
    const { data: company } = useCurrentCompany();
    const { data: project, update } = useProject(projectId);
    const projectMembers = { data: projectState?.members };
    const { create, deleteProject } = useProjectAction();
    const isEdit = Boolean(projectId);
    const navigate = useNavigate();
    const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
    const [, setQueryParams] = useQueryParams();

    const initialValues = {
      name: project?.name ?? '',
      logoFile: null,
      description: project?.description ?? '',
      color: project?.color ?? colorOptions[0],
      logoUrl: project?.logoUrl,
      members: projectMembers?.data ?? [],
      hasImageDelete: false,
    };

    const pageProps = {
      loading: isEdit && !project,
      companyName: company?.name,
      projectId,
      selectedProject: project,
      initialValues,
      async onSubmit({
        name,
        logoFile: logo,
        description,
        color,
        members,
        hasImageDelete,
      }) {
        if (isEdit) {
          await update({
            id: projectId,
            name,
            logo,
            description,
            color,
            members,
            hasImageDelete,
          });

          if (reFetchTickets) {
            reFetchTickets();
          }
          const client = new ToolingsClient();
          const memberResponse = await client.listProjectMember({ projectId });

          projectDispatch({
            type: 'UPDATE_MEMBERS',
            data: memberResponse.data.map(transformMember),
          });

          successToast({ message: 'The project has been updated.' });
          onClose();
          return;
        }

        await create({ name, logo, description, color, members });
        successToast({ message: 'The project has been created.' });
        onClose();
        if (reloadAllProjects) {
          reloadAllProjects();
        }
      },
      async onDelete() {
        await deleteProject(projectId);
        navigate('/');
      },
      onClose,
      isShowDeleteModal,
      onOpenDeleteModal() {
        setIsShowDeleteModal(true);
      },
      onCloseDeleteModal() {
        setIsShowDeleteModal(false);
      },
      setQueryParams,
    };

    return <Component {...props} {...pageProps} />;
  }

  hoist(WithProjectSettingModal, Component);

  WithProjectSettingModal.displayName = `withProjectSettingModal(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithProjectSettingModal;
}
