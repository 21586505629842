import React from 'react';
import { colorOptions } from '../../../../components/ColorPalette/ColorPalette';
import { MobileProjectEditor } from '../MobileProjectEditor';

export function MobileProjectCreatePage({
  className,
  initialValues = {
    name: '',
    description: '',
    logoFile: null,
    color: colorOptions[0],
    logoUrl: null,
  },
  onSubmit,
  companyName,
  onCancel,
}) {
  return (
    <section className={className}>
      <MobileProjectEditor
        initialValues={initialValues}
        onSubmit={onSubmit}
        companyName={companyName}
        onCancel={onCancel}
      />
    </section>
  );
}
