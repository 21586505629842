import React, { useEffect, useState } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import hoist from 'hoist-non-react-statics';
import { useQuery } from 'react-query';
import { useAppClient } from '../../../../lib/AppProvider';
import { useCurrentCompany } from '../../useCurrentCompany';
import { useDebounce, useQueryParams } from '../../../../lib/hooks';

export function withProjectListPage(Component) {
  function WithProjectListPage(props) {
    const [searchParams] = useSearchParams();
    const [queryParams, setQueryParams] = useQueryParams();
    const [query, setQuery] = useState(queryParams.q ?? '');
    const currentPage = +queryParams.page || 1;
    const limit = 15;
    const { data: company } = useCurrentCompany();
    const memoQuery = useDebounce(query, 1000);
    const tab = searchParams.get('tab') ?? 'project';

    const {
      projects,
      pagination,
      loading,
      projectCount,
      ticketCount,
      revalidate: reloadAllProjects,
    } = useSearchProjects({
      query: tab === 'project' ? queryParams.q : null,
      limit,
      page: currentPage,
      tab,
    });

    const onCloseSettingProjectModal = () => {
      setQueryParams({ modal: null });
    };

    useEffect(() => {
      setQueryParams({ q: memoQuery });
    }, [memoQuery, setQueryParams]);

    useEffect(() => {
      setQuery('');
    }, [queryParams.tab]);

    function onArchive() {
      refetchData();
    }

    function onDuplicate() {
      refetchData();
    }

    function refetchData() {
      reloadAllProjects();
    }

    const pageProps = {
      projects,
      loading,
      reloadAllProjects,
      totalItems: pagination?.total,
      currentPage,
      pageSize: limit,
      query,
      company,
      onChangeQuery: setQuery,
      isSettingProjectModal: Boolean(queryParams.modal === 'create-project'),
      isSettingCompanyModal: Boolean(queryParams.modal === 'create-company'),
      onCloseSettingProjectModal,
      onArchive,
      onDuplicate,
      refetchData,
      projectCount,
      ticketCount,
      setQueryParams,
      tab,
    };

    return <Component {...props} {...pageProps} />;
  }

  hoist(WithProjectListPage, Component);

  WithProjectListPage.displayName = `withProjectListPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithProjectListPage;
}

function useSearchProjects({ query, page, limit }) {
  const client = useAppClient();
  const [pagination, setPagination] = useState(null);
  const { companyId } = useParams();

  const { data, refetch: revalidate, isLoading } = useQuery(
    [page, query, companyId],
    () => client.listProjects({ page, per_page: limit, query }),
    {
      refetchOnWindowFocus: false,
      enabled: !!companyId,
    }
  );

  useEffect(() => {
    if (!data) {
      return;
    }

    setPagination(data?.meta.pagination);
  }, [data]);
  return {
    loading: isLoading,
    projects: data?.data ?? [],
    pagination,
    revalidate,
    projectCount: data?.meta.project_count ?? 0,
    ticketCount: data?.meta.ticket_count ?? 0,
  };
}
