import React, { useCallback, useState, useContext } from 'react';
import hoist from 'hoist-non-react-statics';
import GlobalContext from '../../store/globalContext';
import { useMemberSearch } from '../../features/settings/useMemberSearch';

export function withMemberManagement(Component) {
  function WithMemberManagement({ projectId, onChange, value, ...props }) {
    const [search, setSearch] = useState('');
    const { data: memberSearch = [] } = useMemberSearch();
    const { globalState } = useContext(GlobalContext);
    const { profile } = globalState;

    const onSearch = useCallback(
      e => {
        setSearch(e.target.value);
      },
      [setSearch]
    );

    const pageProps = {
      members:
        memberSearch?.data?.filter(member => member.id !== profile?.id) ?? [],
      value: [{ ...profile, me: true }].concat(
        value?.filter(member => member.id !== profile?.id) ?? []
      ),
      onChange(updated) {
        onChange(updated.filter(({ me }) => !me));
      },
      onSearch,
      search,
      loading: !profile || !memberSearch,
    };

    return <Component {...props} {...pageProps} />;
  }

  hoist(WithMemberManagement, Component);

  WithMemberManagement.displayName = `WithMemberManagement(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithMemberManagement;
}
