import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import pick from 'ramda/es/pick';
import { Link, useParams, useLocation } from 'react-router-dom';

import { useCompanyList } from '../features/projects/useCompanyList';
import { transformMember } from '../features/projects/useProfile';
import { Avatar } from './Avatar';
import { AddNewCompanyIcon, SettingIcon } from './Icons';
import { IconButton } from '../features/authentication/components/Button';
import { withAppLayout, useAppLayout } from './withAppLayout';
import { MobileNav } from './MobileNav';
import { Notification } from './Notification';
import { useQueryParams } from '../lib/hooks';
import { MobileHeaderLayout } from './MobileHeaderLayout';
import GlobalContext from '../store/globalContext';
import { ToolingsClient } from '../clients/ToolingsClient';

const AddNewCompany = () => {
  return (
    <div className="new-company-badge">
      <div
        style={{
          border: '1px dashed #FFF',
          width: '36px',
          height: '36px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '6px',
        }}
      >
        <AddNewCompanyIcon color="#FFF" size={20} />
      </div>
    </div>
  );
};

function AppLayout({ children, className }) {
  const { data: companies = [] } = useCompanyList();
  const { pathname } = useLocation();
  const { companyId } = useParams();
  const { toggleSidebar } = useAppLayout();
  const [, setQueryParams] = useQueryParams();
  const { globalState, globalDispatch } = useContext(GlobalContext);

  const data = globalState.profile;

  useEffect(() => {
    async function fetchProfile() {
      if (!globalState.profile) {
        const client = new ToolingsClient();
        const profileResponse = await client.getProfile().then(transformMember);
        globalDispatch({ type: 'ADD_PROFILE', data: profileResponse });
      }
    }
    fetchProfile();
  }, [globalState, globalDispatch]);

  return (
    <section className={className}>
      <MobileNav />
      <MobileHeaderLayout />
      <aside className="sidebar">
        <button className="btn-hamburger" type="button" onClick={toggleSidebar}>
          <div />
          <div />
          <div />
        </button>
        <div className="company-list">
          {companies.map(company => {
            if (company.id !== companyId) {
              return (
                <a
                  key={company.id}
                  href={`/company/${company.id}`}
                  className="company-btn"
                >
                  <Avatar
                    className="avatar"
                    name={company.name}
                    initial={company.initial}
                    avatarUrl={company.logoUrl}
                    noTooltip
                    isSquare
                  />
                </a>
              );
            }

            return (
              <Link
                key={company.id}
                to={`/company/${company.id}`}
                className="company-btn active"
              >
                <Avatar
                  className="avatar"
                  name={company.name}
                  initial={company.initial}
                  avatarUrl={company.logoUrl}
                  noTooltip
                  isSquare
                />
              </Link>
            );
          })}
          <div onClick={() => setQueryParams({ modal: 'create-company' })}>
            <AddNewCompany />
          </div>
        </div>
        <div className="footer">
          {companyId && (
            <IconButton
              as={Link}
              to="./settings/general"
              className="setting-icon"
            >
              <SettingIcon />
            </IconButton>
          )}
          <Notification />
          {data && (
            <div className={pathname.startsWith('/settings') ? 'active' : ''}>
              <a
                href="/settings/account"
                className="avatar"
                style={{ textDecoration: 'none' }}
              >
                <Avatar
                  {...pick(['avatarUrl', 'name', 'initial'], data)}
                  noTooltip
                />
              </a>
            </div>
          )}
        </div>
      </aside>
      <div className="main-content">{children}</div>
    </section>
  );
}

const StyledAppLayout = styled(AppLayout)`
  min-height: 100vh;

  background: ${theme('colors.background')};
  padding-left: 50px;

  .new-company-badge {
    cursor: pointer;
    line-height: 50px;
    margin-top: 10px;
    > div {
      width: fit-content;
      margin: 0 auto;
    }
  }

  .btn-hamburger {
    border: 0;
    background: none;
    padding: 15px 12px;

    cursor: pointer;

    > div {
      height: 4px;
      background: ${theme('colors.primary')};
    }

    > div:not(:last-child) {
      margin-bottom: 4px;
    }

    > div:nth-child(1) {
      width: 26px;
    }

    > div:nth-child(2) {
      width: 20px;
    }

    > div:nth-child(3) {
      width: 23px;
    }
  }

  .company-btn {
    display: block;
    padding: 5px;
    text-decoration: none;

    &.active {
      background: ${theme('colors.primary')};
    }

    > .avatar {
      > .image {
        display: block;

        width: 40px;
        height: 40px;

        line-height: 40px;
      }
    }
  }

  > ${MobileNav} {
    display: none;
  }

  > .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 50px;
    z-index: 100;

    display: flex;
    flex-direction: column;

    background: #333;

    > .footer {
      margin-top: auto;

      > .setting-icon {
        color: white;
        background: transparent;
        border: none;
        padding: 16px 0 10px;
        width: 100%;
        position: relative;

        > svg {
          width: 100%;
          height: 24px;
        }
      }

      > div {
        padding: 8px 10px;

        &.active {
          background: ${theme('colors.primary')};
        }
      }
    }
  }

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    padding-left: 0;

    > ${MobileNav} {
      display: block;
    }

    > .sidebar {
      display: none;
    }
  }
`;

const ConnectedAppLayout = withAppLayout(StyledAppLayout);

export { ConnectedAppLayout as AppLayout };
