import React, { useState, useContext } from 'react';
import hoist from 'hoist-non-react-statics';
import { useLocation } from 'react-router-dom';
import GlobalContext from '../../store/globalContext';

export function withMobileNav(Component) {
  function WithMobileNav(props) {
    const [showSideBar, setShowSideBar] = useState(false);
    const { globalState } = useContext(GlobalContext);
    const data = globalState.profile;
    const { pathname } = useLocation();

    function toggleSidebar() {
      setShowSideBar(!showSideBar);
    }

    const pageProps = {
      showSideBar,
      toggleSidebar,
      data,
      pathname,
    };

    return <Component {...props} {...pageProps} />;
  }

  hoist(WithMobileNav, Component);

  WithMobileNav.displayName = `WithMobileNav(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithMobileNav;
}
