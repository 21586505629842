import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import { TypesVideo, TypesImage } from './ImageTypes';

export const FullscreenImagePreview = React.memo(
  styled(function FullscreenImagePreview({
    isOpen,
    onClose,
    images,
    initialIndex,
    typesVideo = TypesVideo,
    typesImage = TypesImage,
  }) {
    const [mainSlider, setMainSlider] = useState(null);
    const [previewSlider, setPreviewSlider] = useState(null);

    useEffect(() => {
      if (!mainSlider || !previewSlider || initialIndex === null) {
        return;
      }

      mainSlider.slickGoTo(initialIndex);
    }, [mainSlider, previewSlider, initialIndex]);

    return (
      <DialogOverlay
        style={{ background: 'rgba(0,0,0,0.95)' }}
        isOpen={isOpen}
        onDismiss={onClose}
      >
        <Content aria-label="Image Gallery">
          <button className="close" type="button" onClick={onClose}>
            Close <span className="close-icon">X</span>
          </button>
          <StyledSlider
            ref={ref => setMainSlider(ref)}
            infinite={false}
            asNavFor={previewSlider}
            initialSlide={initialIndex}
          >
            {images.map(image => (
              <div className="image-container" key={image.url}>
                {typesImage.some(el => image.url.includes(el)) && (
                  <img className="main-image" src={image.url} alt="" />
                )}
                {typesVideo.some(el => image.url.includes(el)) && (
                  <video className="main-image" controls autoPlay loop muted>
                    <source src={image.url} type="video/mp4" />
                  </video>
                )}
              </div>
            ))}
          </StyledSlider>
          <StyledSlider
            compact
            focusOnSelect
            ref={ref => setPreviewSlider(ref)}
            infinite={false}
            asNavFor={mainSlider}
            variableWidth
            swipe={false}
            initialSlide={initialIndex}
          >
            {images.map(image => (
              <div className="image-container" key={image.url}>
                {typesImage.some(el => image.url.includes(el)) && (
                  <img className="image-list" src={image.url} alt="" />
                )}
                {typesVideo.some(el => image.url.includes(el)) && (
                  <video className="image-list" autoPlay muted>
                    <source src={image.url} type="video/mp4" />
                  </video>
                )}
              </div>
            ))}
          </StyledSlider>
        </Content>
      </DialogOverlay>
    );
  })``
);

const Content = styled(DialogContent)`
  margin: 0;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 0 40px;
  width: 100vw;
  height: 100vh;

  background: none;

  > .close {
    position: absolute;
    top: 10px;
    right: 10px;

    border: 0;

    font-size: 12px;

    color: white;
    background: none;
    font-weight: bold;

    z-index: 9999;
  }
`;

const StyledSlider = styled(Slider)`
  height: ${props => (!props.compact ? 'calc(100vh - 120px)' : '100px')};

  > .slick-list,
  > .slick-list > .slick-track,
  > .slick-list > .slick-track > .slick-slide,
  > .slick-list > .slick-track > .slick-slide > div,
  .image-container {
    height: 100%;
  }

  .image-container > img {
    margin: ${props => (props.compact ? '0 10px' : '0 auto')};
  }

  .main-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .image-list {
    width: 100px;
    height: 100%;
    object-fit: cover;
  }
`;
