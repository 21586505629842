import React, { useState } from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { Link, useLocation, useSearchParams } from 'react-router-dom';

import { Button } from '../../../authentication/components/Button';
import { TextFieldView as TextField } from '../../../authentication/components/TextField';
import { DocumentTitle } from '../../../../components/DocumentTitle';

import '@reach/tabs/styles.css';
import { MemberTable } from './MemberTable';
import { InviteNewUserModal } from '../../components/InviteNewUserModal';
import { PermissionGuard } from '../../../authentication/hocs/withPermission';

function MemberListPage({
  className,
  members,
  totalItems,
  numberOfMembers,
  numberOfInvited,
  onRemove,
  onChangeRole,
  loading,
  query,
  onChangeQuery,
  reload,
  onSortBy,
}) {
  const [searchParams] = useSearchParams();
  const status = searchParams.get('status') ?? 'all';
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <section className={className}>
      <DocumentTitle title="Members" />
      <h3 className="title">
        Members
        <PermissionGuard allow="member:write">
          <Button
            className="button"
            type="submit"
            onClick={() => setIsOpen(true)}
          >
            Invite
          </Button>
        </PermissionGuard>
      </h3>
      <div className="tabs">
        <div className="tab-list">
          <Link
            to={`/${pathname}`}
            className={['tab', status === 'all' ? 'active' : '']
              .join(' ')
              .trim()}
          >
            All Members ({numberOfMembers})
          </Link>
          <Link
            to={`/${pathname}?status=invited`}
            className={['tab', status === 'invited' ? 'active' : '']
              .join(' ')
              .trim()}
          >
            Invited ({numberOfInvited})
          </Link>
          <TextField
            className="search"
            type="search"
            placeholder="Search member by name or email"
            value={query}
            onChange={e => onChangeQuery(e.target.value)}
          />
        </div>
        <MemberTable
          members={members}
          totalItems={totalItems}
          onRemove={onRemove}
          onChangeRole={onChangeRole}
          loading={loading}
          onSortBy={onSortBy}
        />
      </div>
      <InviteNewUserModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        reload={reload}
      />
    </section>
  );
}

const StyledMemberListPage = styled(MemberListPage)`
  padding: 15px 30px 30px;

  > .title {
    display: flex;
    align-items: center;

    margin-bottom: 30px;
  }

  > .title > ${Button} {
    margin-left: auto;
  }

  > .tabs {
    > .tab-list {
      display: flex;
      background: none;
      border-bottom: 1px solid #ddd;

      > .tab {
        border-radius: 12px 12px 0 0;
        border-bottom: 0;
        padding: 12px 30px;

        color: #999;
        font-weight: bold;
        text-decoration: none;
      }

      > .tab.active {
        color: white;
        background: ${theme('colors.primary')};
      }

      > .search {
        float: right;
        position: relative;
        top: -10px;

        margin-left: auto;
        margin-bottom: 0;
        width: 400px;
      }
    }
  }

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    > .tabs {
      > .tab-list {
        flex-wrap: wrap-reverse;

        > .search {
          position: static;
          float: none;
          width: 100%;
          top: 0;
          margin-bottom: 12px;
        }
      }
    }
  }
`;

export { StyledMemberListPage as MemberListPage };
