import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Menu, MenuButton } from '@reach/menu-button';
import { ifProp } from 'styled-tools';
import {
  MenuItem,
  MenuList,
} from '../../features/tickets/pages/AllTicketPage/Subtask';
import { IconButton } from '../../features/authentication/components/Button';
import { EllipseVerticalIcon } from '../Icons';

import { Indicator } from './Indicator';
import { BaseFilter } from './BaseFilter';

import { EpicCreateModal } from './EpicCreateModal';
import { DeleteModal } from './DeleteModal';

function EpicFilter({
  className,
  data = [],
  selectedIds = [],
  query,
  memoQuery,
  onSelect,
  onSearch,
  onSelectAll,
  projectName,
  isAddEpicModal,
  onAddEpicModal,
  onCloseEpicModal,
  onSubmit,
  isDeleteEpicModal,
  onOpenDeleteEpicModal,
  onCloseDeleteEpicModal,
  onDelete,
  selectedEpic,
  epicMode,
  onEditEpicModal,
  handleKeyPress,
  projectColor,
  isLoading,
}) {
  const options = useMemo(
    () =>
      data
        .map((item, i) => {
          const isChecked = selectedIds.includes(item.id);

          return {
            rank: item.name?.charCodeAt(0) + isChecked ? 100 : 0,
            onSelect(e) {
              e.stopPropagation();

              onSelect(item.id);
            },
            isChecked,
            id: item.id,
            name: item.name,
            color: item.color || projectColor,
            percentage: i * 15,
            isShow:
              isChecked ||
              !memoQuery ||
              item.name
                ?.toLowerCase()
                .startsWith(memoQuery.toLocaleLowerCase()),
          };
        })
        .sort((x, y) => y.rank - x.rank),
    [data, selectedIds, memoQuery, onSelect, projectColor]
  );

  return (
    <BaseFilter
      className={className}
      title="Epics"
      query={query}
      onSearch={onSearch}
      onSelectAll={onSelectAll}
      onAdd={onAddEpicModal}
      addPermission="project-epic:write"
      noSearch={options.length === 0}
      isLoading={isLoading}
    >
      <>
        {options.length > 0
          ? options.map(item => (
              <Menu key={item.id}>
                <Item show={item.isShow}>
                  <Indicator checked={item.isChecked} onClick={item.onSelect} />
                  <div className="progress" onClick={item.onSelect}>
                    <h5 className="name">{item.name}</h5>
                    <div className="bar">
                      <div
                        className="completed"
                        style={{
                          width: `${item.percentage}%`,
                          maxWidth: '100%',
                        }}
                      />
                    </div>
                  </div>
                  <MenuButton as={IconButton} className="menu">
                    <EllipseVerticalIcon />
                  </MenuButton>
                  <MenuList>
                    <MenuItem primary onSelect={onEditEpicModal(item)}>
                      Edit
                    </MenuItem>
                    <MenuItem onSelect={onOpenDeleteEpicModal(item)}>
                      Delete
                    </MenuItem>
                  </MenuList>
                </Item>
              </Menu>
            ))
          : null}
        <EpicCreateModal
          isOpen={isAddEpicModal}
          onClose={onCloseEpicModal}
          onSubmit={onSubmit}
          epicMode={epicMode}
          selectedEpic={selectedEpic}
          projectName={projectName}
          projectColor={projectColor}
          handleKeyPress={handleKeyPress}
        />
        <DeleteModal
          isOpen={isDeleteEpicModal}
          onClose={onCloseDeleteEpicModal}
          onDelete={onDelete}
          deleteText={`Delete Epic "${selectedEpic?.name}" ?`}
        />
      </>
    </BaseFilter>
  );
}

const Item = styled.div`
  display: ${ifProp('show', 'flex', 'none')};
  align-items: center;
  justify-content: space-between;

  padding: 10px 0;
  cursor: pointer;

  > .progress {
    margin-left: 10px;

    flex-grow: 1;
    max-width: 172.95px;
  }

  > .progress > .name {
    overflow: hidden;

    flex-grow: 1;
    flex-shrink: 1;

    margin-bottom: 5px;
    padding: 0 10px 0 0;

    color: #999;
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  > .progress > .bar {
    border-radius: 5px;
    height: 4px;
    width: 100%;

    background: #ddd;

    > .completed {
      border-radius: 5px;
      height: 4px;
      background: #999;
    }
  }
`;

const StyledEpicFilter = styled(EpicFilter)`
  .filter-list {
    padding-bottom: 10px;
  }
`;

const MemoEpicFilter = React.memo(StyledEpicFilter);

export { MemoEpicFilter as EpicFilter };
