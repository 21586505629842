import React from 'react';
import styled from 'styled-components';
import { ifProp, theme } from 'styled-tools';
import { CheckIcon } from '../Icons';

export const Indicator = styled.button.attrs(() => ({
  type: 'button',
  children: <CheckIcon size={16} />,
}))`
  border-radius: 6px;
  border: 2px solid ${ifProp('checked', theme('colors.primary'), '#ddd')};
  width: 24px;
  height: 24px;
  padding: 1px;

  color: white;
  text-align: center;

  background: ${ifProp('checked', theme('colors.primary'), 'white')};
  cursor: pointer;
`;
