import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { theme } from 'styled-tools';
import {
  Button,
  OutlineButton,
} from '../../../authentication/components/Button';
import { TextField } from '../../../authentication/components/TextField';
import { FormikSingleImageUploader as SingleImageUploader } from '../../../settings/pages/SettingCompanyPage/SingleImageUploader';
import { EditableText } from '../../../settings/pages/SettingCompanyPage/EditableText';
import { ColorPalette } from '../../../../components/ColorPalette';
import { MemberManagement } from '../../../../components/MemberManagement';

function MobileProjectEditor({
  className,
  initialValues,
  onSubmit,
  companyName,
  onCancel,
  isEdit,
}) {
  return (
    <section className={className}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, isSubmitting, setFieldValue, submitForm }) => (
          <div className="project-cerate-mobile">
            <div className="header">
              <div className="company-wrapper">
                <div>
                  Company :<span className="company-name">{companyName}</span>
                </div>
                <div className="color-palette">
                  <ColorPalette name="color" />
                </div>
              </div>
            </div>
            <div className="contents">
              <div className="logo-uploader">
                <SingleImageUploader
                  className="image-uploader"
                  name="logoFile"
                  previewUrl={values.logoUrl}
                />
              </div>
              <EditableText name="name" placeholder="Enter project name" />

              <div className="field-group">
                <TextField
                  style={{ height: '180px' }}
                  id="description"
                  name="description"
                  label="Description and purpose"
                  rows={8}
                  multiline
                  oldLabel
                />
              </div>
            </div>

            <MemberManagement
              className="members-content"
              value={values.members}
              onChange={members => setFieldValue('members', members)}
            />
            <div className="footer">
              <div className="actions">
                <OutlineButton
                  className={`${isEdit ? 'delete' : ''} button cancel`}
                  type="button"
                  onClick={onCancel}
                >
                  {isEdit ? 'Delete' : 'Cancel'}
                </OutlineButton>
                <Button
                  className="button"
                  type="submit"
                  onClick={() => submitForm()}
                  disabled={isSubmitting || !values.name}
                >
                  {isEdit ? 'Save' : 'Create'}
                </Button>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </section>
  );
}

const StyledMobileProjectEditor = styled(MobileProjectEditor)`
  position: relative;

  .project-cerate-mobile {
    .header {
      padding-top: 80px;
      padding-bottom: 15px;
      margin-bottom: 10px;

      font-size: 14px;
      font-weight: normal;

      background: white;

      .company-wrapper {
        margin: 0 15px;
      }

      .company-name {
        color: ${theme('colors.primary')};
        margin-left: 5px;
      }

      .color-palette > div {
        display: flex;
        flex-flow: row;
        align-items: center;

        > span {
          padding-right: 5px;
        }

        .radio-color-group {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .contents {
      padding: 0 15px;
      background: white;

      .logo-uploader {
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        justify-content: center;

        .image-uploader {
          width: 120px;
          height: 120px;

          .placeholder {
            svg {
              width: 24px;
              height: 24px;
            }

            h4 {
              font-size: 14px;
            }
          }
        }
      }

      .field-group {
        padding-top: 20px;
        padding-bottom: 10px;
      }
    }

    .members-content {
      background: white;
      margin-bottom: 20px;

      .selection-area {
        padding: 0 15px;
      }
    }

    .footer {
      padding-top: 20px;
      background: white;
      padding-bottom: 80px;

      .actions {
        margin: 0 7.5px;
        display: flex;
        justify-content: space-between;

        .delete {
          border-color: #f75519;
          color: #f75519;

          &:hover {
            background: #f75519;
            color: white;
          }
        }

        button {
          width: 100%;
          margin: 0 7.5px;
        }
      }
    }
  }
`;

export { StyledMobileProjectEditor as MobileProjectEditor };
