import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useField } from 'formik';
import { theme } from 'styled-tools';

import { ClearButton } from '../../../authentication/components/Button';
import { FullscreenImagePreview } from './FullscreenImagePreview';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { filehash } from '../../../../lib/utils';
import { CancelFileIcon, PreviewFileIcon } from '../../../../components/Icons';
import { TypesImageAndVideo, TypesVideo, TypesImage } from './ImageTypes';
import Xls from '../../../../assets/images/xls.svg';

const PictureListWrapper = styled.div`
  position: relative;

  .video {
    object-fit: cover;
    object-position: center;
    border-radius: 6px;
    width: 130px;
    height: 110px;
  }

  .etc {
    border-radius: 6px;
    width: 130px;
    height: 110px;
    background: #9fa3ae;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    > a {
      height: max-content;
      font-weight: bold;
      color: white;
    }
  }

  .image,
  .xlsx {
    border-radius: 6px;
    width: 130px;
    height: 110px;
  }

  .xlsx {
    background: #9fa3ae;
  }

  .pdf {
    border-radius: 6px;
    width: 130px;
    height: 90px;
    object-fit: cover;
    object-position: center;
  }
  .xls {
    width: 130px;
    height: 110px;
    padding: 10px;
  }

  .namefiles {
    color: green;
    width: 100%;
    height: 100%;
  }

  > input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
  }

  > .items {
    width: 100%;

    display: flex;
    flex-flow: wrap;

    border-radius: 6px;
    padding: 10px;

    background: #f5f8f8;

    ::-webkit-scrollbar {
      height: 2px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    > .preview,
    > .upload-button,
    .upload-button-mobile {
      overflow: hidden;
      flex: 0 0 130px;
      margin: 10px;
      cursor: pointer;
    }

    > .preview > .image,
    > .upload-button,
    .upload-button-mobile {
      border-radius: 6px;
      width: 130px;
      height: 110px;
    }

    > .preview {
      position: relative;

      border: 0;

      text-align: left;
      background: transparent;

      > .image {
        display: block;
      }

      > .guide {
        opacity: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        border-radius: 0 0 6px 6px;

        color: white;
        font-size: 12px;
        text-align: center;
        line-height: 22px;

        background: ${theme('colors.primary')};
        transition: opacity 0.2s;
      }

      &:hover > .guide {
        opacity: 1;
      }

      > .remove {
        position: absolute;
        top: 5px;
        right: 5px;
        padding: 0;

        color: white;
        font-size: 12px;
        font-weight: bold;
        line-height: 1.7;
        text-decoration: underline;

        &:hover {
          background: unset;
        }
      }
    }

    > .upload-button,
    > .upload-button-mobile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      color: ${theme('colors.primary')};
      background: white;

      z-index: 1;
      cursor: pointer;

      > .text {
        display: block;

        margin-top: 15px;
        font-size: 12px;
        font-weight: bold;
      }
    }

    &::after {
      content: ' ';
      display: block;
      padding: 10px;
    }

    .upload-button-mobile {
      display: none;
    }

    @media (max-width: ${theme('breakpoints.mobile')}px) {
      padding: unset;
      background: white;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;

      .upload-button-mobile {
        border: solid 1px ${theme('colors.primary')};
        display: flex !important;
      }

      .upload-button {
        display: none;
      }
    }

    @media (max-width: ${theme('breakpoints.tablet')}px) {
      .upload-button {
        display: none;
      }
    }
  }
`;

export const FileAllList = React.memo(
  React.forwardRef(function FileAllList(
    { id = 'PictureList', accept, singleUpload = false, name, ...props },
    ref
  ) {
    const [initialIndex, setInitialIndex] = useState(null);
    const isOpen = initialIndex !== null;
    const [input, { error }, helpers] = useField(name);
    const inputHashList = useMemo(
      () => input.value?.map(({ hash }) => hash) ?? [],
      [input.value]
    );
    const localRef = useRef(null);
    const uploadInput = ref ?? localRef;

    const visibleFiles = useMemo(
      () => input.value?.filter(({ removed }) => !removed) ?? [],
      [input.value]
    );

    function onClose() {
      setInitialIndex(null);
    }

    function contains(target, pattern) {
      let status = false;
      for (let i = 0; i < pattern.length; i += 1) {
        if (target.includes(pattern[i])) {
          status = true;
        }
      }
      return status;
    }

    function handleChange(e) {
      const newValue = Array.from(e.target.files)
        .map(file => {
          return {
            file,
            url: URL.createObjectURL(file),
            hash: filehash(file),
          };
        })
        .filter(({ hash }) => {
          return !inputHashList.includes(hash);
        });
      const changingValue = singleUpload
        ? newValue
        : input.value.concat(newValue);
      helpers.setValue(changingValue);

      uploadInput.current.value = '';
    }

    return (
      <>
        <PictureListWrapper {...props}>
          <input
            type="file"
            id={id}
            multiple
            accept={accept}
            onChange={handleChange}
            ref={uploadInput}
          />
          <div className="items">
            <label className="upload-button-mobile" htmlFor={id}>
              <PreviewFileIcon className="icon" size={35} />
              <span className="text">Upload File</span>
            </label>
            {visibleFiles.map(
              (
                {
                  url,
                  hash,
                  file,
                  image_type = TypesImageAndVideo,
                  typesVideo = TypesVideo,
                  typesImage = TypesImage,
                },
                index
              ) => (
                <div className="preview" key={index}>
                  {contains(hash, image_type) && (
                    <div key={hash} onClick={() => setInitialIndex(index)}>
                      {typesImage.some(el => hash.includes(el)) && (
                        <img className="image" src={url} alt="" />
                      )}
                      {typesVideo.some(el => hash.includes(el)) && (
                        <video className="video" autoPlay muted>
                          <source src={url} type="video/mp4" />
                        </video>
                      )}
                    </div>
                  )}

                  {!contains(hash, image_type) &&
                    !hash.includes('.pdf') &&
                    !hash.includes('.xlsx') && (
                      <div className="etc">
                        <a href={url} className="namefiles" download>
                          {
                            hash
                              .split('/')
                              .pop()
                              .split(',')[0]
                              .split('?')[0]
                              .split('#')[0]
                              .replace(/%20/g, ' ')
                              .split('.')[1]
                          }
                        </a>
                      </div>
                    )}
                  {hash.includes('.pdf') && (
                    <div>
                      <a href={url} className="namefiles" download>
                        <div>
                          {hash
                            .split('/')
                            .pop()
                            .split('?')[0]
                            .split('#')[0]
                            .slice(0, 15)
                            .replace(/%20/g, ' ')}
                          <embed
                            src={url}
                            className="pdf"
                            type="application/pdf"
                          />
                        </div>
                      </a>
                    </div>
                  )}
                  {hash.includes('.xlsx') && (
                    <div className="xlsx">
                      <a href={url} className="namefiles" download>
                        <img className="xls" src={Xls} alt="" />
                      </a>
                    </div>
                  )}

                  <ClearButton
                    className="remove"
                    onClick={e => {
                      e.stopPropagation();

                      if (file) {
                        helpers.setValue(
                          input.value.filter(v => hash !== v.hash)
                        );
                        return;
                      }

                      if (singleUpload) {
                        helpers.setValue([]);
                      } else {
                        helpers.setValue(
                          input.value.map(v =>
                            hash === v.hash ? { ...v, removed: true } : v
                          )
                        );
                      }
                    }}
                  >
                    <CancelFileIcon />
                  </ClearButton>
                  <div className="guide">Download File</div>
                </div>
              )
            )}
            <label className="upload-button" htmlFor={id}>
              <PreviewFileIcon className="icon" size={35} />
              <span className="text">Upload File</span>
            </label>
          </div>
          <FullscreenImagePreview
            isOpen={isOpen}
            onClose={onClose}
            images={visibleFiles}
            initialIndex={initialIndex}
          />
        </PictureListWrapper>
        {error && <div className="error-message">{error}</div>}
      </>
    );
  })
);
