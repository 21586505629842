import styled from 'styled-components';
import { prop, ifProp, theme } from 'styled-tools';
import { Icon } from '../../../components/Icons';

export const LinkButton = styled.button`
  border-radius: 6px;
  border: 0;
  padding: 0;

  color: ${ifProp('primary', theme('colors.primary'), '#999')};
  text-align: center;

  background: transparent;
`;

export const ClearButton = styled.div`
  border-radius: 6px;
  border: 0;
  padding: 10px 30px;

  color: #ddd;
  text-align: center;

  background: transparent;

  &:hover {
    background: #fafafa;
  }
`;

export const IconButton = styled.button`
  border-radius: 6px;
  border: 0;
  padding: 0;
  width: 30px;
  height: 30px;

  color: #ddd;
  text-align: center;
  line-height: 1;

  background: transparent;

  &:hover {
    background: #fafafa;
  }
`;

export const Button = styled.button`
  border-radius: 6px;
  border: 1px solid ${prop('color', theme('colors.primary'))};
  padding: 10px 30px;

  color: white;
  font-weight: bold;
  text-decoration: none;

  background: ${prop('color', theme('colors.primary'))};
  box-shadow: 0 10px 20px 0 rgba(0, 128, 42, 0.08);

  cursor: pointer;

  &:hover {
    border-color: ${prop('hoverColor', '#1fa94e')};
    background: ${prop('hoverColor', '#1fa94e')};
  }

  > ${Icon}:first-child {
    margin-right: 6px;
  }

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    font-size: 14px;
    padding: 14px 30px;
  }
`;

export const OutlineButton = styled(Button)`
  border: 1px solid ${prop('color', theme('colors.primary'))};

  color: ${prop('color', theme('colors.primary'))};

  background: transparent;
  box-shadow: none;

  &:hover {
    color: white;
    background: ${prop('color', theme('colors.primary'))};
  }
`;
