import { DateTime } from 'luxon';

export function filehash(file) {
  if (!file) return null;

  return [file.name, file.lastModified, file.size].join(',');
}

export function listToMapBy(key, list) {
  return list.reduce((memo, item) => ({ ...memo, [item.id]: item }), {});
}

export function formatDateTimeUtc(date, format) {
  const d = DateTime.fromISO(new Date(date).toISOString(), { zone: 'utc' });

  return d.toFormat(format || 'yyyy-LL-dd');
}

export function formatDateTime(date, format) {
  const d = DateTime.fromISO(new Date(date).toISOString());

  return d.toFormat(format || 'yyyy-LL-dd');
}

export function formatDate(date, format = 'yyyy-MM-dd HH:mm', hours = 7) {
  const d = new Date(date);
  const dt = d.setHours(d.getHours() - hours);

  return formatDateTime(dt, format);
}

export function getDate(date) {
  const {
    hour,
    minute,
    day,
    monthLong,
    daysInMonth,
    weekdayLong,
    weekday,
    month,
    year,
  } = DateTime.fromJSDate(new Date(date));
  const weekLastMonth = Math.floor(+daysInMonth / 7);
  const week = Math.floor(day / 7);
  const prefixes = ['first', 'second', 'third', 'fourth', 'fifth'];

  if (prefixes[weekLastMonth] === 'fifth') {
    prefixes[4] = 'last';
  }

  if (prefixes[weekLastMonth] === 'fourth') {
    prefixes[3] = 'last';
  }

  return {
    day,
    hour,
    minute,
    monthLong,
    weekdayLong,
    weekday,
    week: week + 1,
    weekLong: prefixes[week],
    month,
    date: `${year}-${month}-${day}`,
  };
}

export function formatDateString(date, option = { day: 'numeric' }) {
  return new Date(date).toLocaleDateString('en-US', option);
}

export function getTime(time) {
  const { date, hour, minute } = getDate(time);

  return new Date(date).setHours(hour, minute, 0, 0);
}

export function getWeekday(recurrence, dueDate, recurrenceDate) {
  const dateNow = new Date(Date.now());

  switch (recurrence) {
    case 'daily':
      return '[0, 1, 2, 3, 4, 5, 6]';
    case 'weekly':
      return `[${
        recurrenceDate?.weekday || dueDate?.weekday || dateNow?.weekday
      }]`;
    case 'every_weekday':
      return '[1, 2, 3, 4, 5]';
    default:
      break;
  }
}

export function compareTwoArrayOfObjects(obj1, obj2) {
  return (
    obj1.length === obj2.length &&
    obj1.every(element1 =>
      obj2.some(element2 =>
        Object.keys(element1).every(key => element1[key] === element2[key])
      )
    )
  );
}
