import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { theme } from 'styled-tools';
import { Draggable } from 'react-beautiful-dnd';
import {
  Button,
  ClearButton,
} from '../../features/authentication/components/Button';
import { PlusIcon } from '../Icons';
import { TextField } from '../../features/authentication/components/TextField';
import { useOnClickOutside } from '../../lib/hooks';

const validationSchema = yup.object({
  listName: yup.string().required('List name is required'),
});

const initialValues = {
  listName: '',
};

export const AddListCard = React.memo(
  styled(function AddListCard({ className, onAdd, index, ...props }) {
    const [isAdding, setIsAdding] = useState(false);
    const inputRef = useRef(null);
    const cardRef = useRef(null);

    useEffect(() => {
      if (!isAdding) {
        return;
      }

      requestAnimationFrame(() => {
        inputRef.current.focus();
      });
    }, [isAdding]);

    useOnClickOutside(cardRef, () => {
      setIsAdding(false);
    });

    async function handleCreateList({ listName }, { resetForm }) {
      resetForm();
      await onAdd(listName);
      setIsAdding(false);
    }

    return (
      <Draggable draggableId="add-column" index={index} isDragDisabled>
        {p => (
          <div
            className={className}
            ref={p.innerRef}
            {...p.draggableProps}
            {...p.dragHandleProps}
            {...props}
          >
            {!isAdding ? (
              <Button className="button" onClick={() => setIsAdding(true)}>
                <PlusIcon size={12} />
                Add List
              </Button>
            ) : (
              <div className="card" ref={cardRef}>
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleCreateList}
                  validationSchema={validationSchema}
                >
                  {() => (
                    <Form>
                      <TextField
                        label="New List Name"
                        ref={inputRef}
                        id="listName"
                        name="listName"
                      />
                      <div className="actions">
                        <ClearButton
                          className="cancel"
                          type="button"
                          onClick={() => setIsAdding(false)}
                        >
                          Cancel
                        </ClearButton>
                        <Button className="add" type="submit">
                          Add list
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            )}
          </div>
        )}
      </Draggable>
    );
  })`
    width: 310px;
    flex: 0 0 310px;
    padding: 0 15px;

    > .button {
      border: 0;
      display: block;
      width: 100%;

      color: ${theme('colors.primary')};

      background: white;
    }

    > .card {
      border-radius: 6px;
      padding: 20px;
      background: white;

      > .actions {
        text-align: right;
      }

      > .actions > .add,
      > .actions > .cancel {
        font-size: 12px;
        padding: 6px 18px;
      }
    }
  `
);
