import React, { useMemo, useContext } from 'react';
import hoist from 'hoist-non-react-statics';
import ProjectContext from '../../store/projectContext';
import GlobalContext from '../../store/globalContext';
import { useFilter } from './useFilter';

export function withMemberFilter(Component) {
  function WithMemberFilter({ projectId, ...props }) {
    const { projectState } = useContext(ProjectContext);
    const { globalState } = useContext(GlobalContext);
    const data = { data: projectState.members };
    const { profile } = globalState;

    const members = useMemo(() => {
      return (
        data?.data?.map(member => ({
          ...member,
          name: `${member.name} ${
            profile?.id === member.id ? '(Me)' : ''
          }`.trim(),
          isSelf: profile?.id === member.id,
        })) ?? []
      );
    }, [profile, data]);

    const filter = useFilter({ key: 'member', data: members });

    const pageProps = {
      ...props,
      ...filter,
      members: members ?? [],
    };

    return <Component {...props} {...pageProps} />;
  }

  hoist(WithMemberFilter, Component);

  WithMemberFilter.displayName = `withMemberFilter(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithMemberFilter;
}
