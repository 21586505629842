import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

import BaseDatePicker from 'react-datepicker';
import { theme, ifProp, switchProp } from 'styled-tools';
import { DateTime } from 'luxon';
import { useField } from 'formik';

import { ArrowUpIcon, DatepickerIcon } from './Icons';

import 'react-datepicker/dist/react-datepicker.css';

export const DatePicker = React.memo(
  styled(function DatePicker({
    className,
    label,
    value,
    onChange,
    showTimeSelect,
    dateFormat = 'dd MMMM yyyy',
    hiddenArrowIcon,
    showDateIcon,
    name,
    minDate,
    maxDate,
    isClearable,
    ...props
  }) {
    const selected = value?.toJSDate();
    const datePickerFormat = showTimeSelect ? 'dd MMMM yyyy HH:mm' : dateFormat;
    const [, { error }] = useField(name);

    function handleChange(date) {
      if (date !== null) {
        onChange(DateTime.fromJSDate(date));
      } else {
        onChange(undefined);
      }
    }

    return (
      <div className={className}>
        {label && (
          <label htmlFor={props.id} className="label">
            {label}
          </label>
        )}
        <div className="input-group">
          <BaseDatePicker
            className={classnames({
              field: true,
              'has-error': name && !!error,
            })}
            selected={selected}
            onChange={handleChange}
            dateFormat={datePickerFormat}
            showTimeSelect={showTimeSelect}
            minDate={minDate ?? null}
            maxDate={maxDate ?? null}
            isClearable={!props.disabled && isClearable}
            {...props}
          />
          {showDateIcon && <DatepickerIcon className="icon" />}
          {!props.disabled && !hiddenArrowIcon && <ArrowUpIcon />}
        </div>
      </div>
    );
  })`
    > .label {
      display: block;
      font-size: 12px;

      margin-bottom: 8px;

      color: #c1c3ca;
    }

    > .input-group {
      position: relative;

      .popover {
        display: block;
      }

      .react-datepicker {
        display: flex;
      }

      .react-datepicker-wrapper {
        width: 100%;
      }

      .field {
        border-radius: 6px;
        border: 1px solid ${ifProp('error', theme('colors.error'), '#dce0ea')};
        width: 100%;
        padding: 10px ${ifProp('hasSuffix', '50px', '10px')} 10px
          ${ifProp('hasPrefix', '50px', '10px')};

        font-size: ${switchProp('size', { sm: '14px' }, 'inherit')};
        text-align: left;

        background: #ffffff;

        &:focus,
        &:active {
          outline-color: ${theme('colors.primary')};
        }

        &:focus + .popover {
          display: block;
        }

        > .placeholder {
          color: #c1c3ca;
        }

        &:disabled {
          background: #efefef;
        }

        &:disabled {
          background: #efefef;
        }
      }

      .field.has-error {
        border-color: ${theme('colors.error')};
      }

      > select {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }

      > select:active + .field,
      > select:focus + .field {
        border-color: ${theme('colors.primary')};
      }

      svg {
        width: 14px;
        height: 14px;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: ${ifProp(
          'showDateIcon',
          'translateY(-50%)',
          'translateY(-50%) rotateZ(180deg)'
        )};
        color: ${theme('colors.primary')};
      }
    }

    .react-datepicker__close-icon {
      right: 35px;

      ::after {
        background: ${theme('colors.primary')};
      }

      &:hover {
        ::after {
          background: black;
        }
      }
    }
  `
);
