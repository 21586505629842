import React, { useState } from 'react';
import hoist from 'hoist-non-react-statics';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthentication } from '../../useAuthentication';

export function withResetPasswordPage(Component) {
  function WithResetPasswordPage(props) {
    const { resetPassword } = useAuthentication();
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get('t');
    const id = searchParams.get('id');

    async function onResetPassword({ password: newPassword }) {
      try {
        setError(null);
        await resetPassword({ id, token, newPassword });
        searchParams.set(
          'reset-password',
          'You have reset the password. Please sign in to Vantage Point.'
        );
        const url = searchParams.get('redirectTo');
        navigate(url ?? `/auth/sign-in?${searchParams.toString()}`, {
          replace: !!url,
        });
      } catch (err) {
        if (!err.isAxiosError) {
          throw err;
        }
        setError(err.response?.data?.message);
      }
    }

    const pageProps = {
      error,
      onResetPassword,
      ...props,
    };

    return <Component {...pageProps} />;
  }

  hoist(WithResetPasswordPage, Component);

  WithResetPasswordPage.displayName = `withResetPasswordPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithResetPasswordPage;
}
