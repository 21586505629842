import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import hoist from 'hoist-non-react-statics';
import { DateTime } from 'luxon';

import { useCurrentCompany } from '../../useCurrentCompany';
import { useProject } from '../../useProjectSearch';
import { successToast, errorToast } from '../../../../lib/toast';
import { useProjectInfoAction } from '../../useSetupProject';

export function withProjectSetupPage(Component) {
  function WithProjectSetupPage(props) {
    const { id } = useParams();
    const { data: project } = useProject(id);
    const { data: company } = useCurrentCompany();
    const [isEdit, setIsEdit] = useState(false);

    const { informations, revalidate, create, update } = useProjectInfoAction({
      projectId: id,
    });

    const defaultResource = [
      {
        id: null,
        position: '',
        number: 0,
      },
    ];

    const defaultContact = [
      {
        id: null,
        name: '',
        role: 'project manager',
        email: '',
        telephone: '',
      },
    ];

    const initialValues = useMemo(
      () => ({
        clientName: informations?.client ?? '',
        tin: informations?.tin ?? '',
        companyAddress: informations?.companyAddress ?? '',
        startDate: informations?.startDate
          ? DateTime.fromFormat(informations.startDate, 'yyyy-MM-dd')
          : null,
        endDate: informations?.endDate
          ? DateTime.fromFormat(informations.endDate, 'yyyy-MM-dd')
          : null,
        totalTime: informations?.totalBudgetTime ?? 0,
        contacts: informations?.useCaseContracts ?? defaultContact,
        resources: informations?.useCaseResources ?? defaultResource,
      }),
      [informations, defaultContact, defaultResource]
    );

    async function updateInfo(form) {
      const response = informations
        ? await update(id, form)
        : await create(id, form);
      if (response?.errors) {
        errorToast({
          message: response?.errors?.map(err => err.detail)?.join(','),
        });
      } else {
        await revalidate();
        successToast({ message: 'Project info has been updated.' });
      }
    }

    async function handleSubmit(params) {
      await updateInfo(params);
      setIsEdit(false);
    }

    function handleChangeEdit(flag) {
      setIsEdit(flag);
    }

    const pageProps = {
      informations,
      project,
      companyName: company?.name,
      handleSubmit,
      handleChangeEdit,
      isEdit,
      defaultResource,
      defaultContact,
      initialValues,
    };

    return <Component {...props} {...pageProps} />;
  }

  hoist(WithProjectSetupPage, Component);

  WithProjectSetupPage.displayName = `withProjectSetupPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithProjectSetupPage;
}
