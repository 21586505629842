import React, { useState } from 'react';
import styled from 'styled-components';
import { prop, theme } from 'styled-tools';
import { DateTime } from 'luxon';
import { titleCase } from '../../../components/TextTransform';
import { DeleteModal } from '../../../components/SideBarFilter/DeleteModal';
import {
  TrashIcon,
  Pencil,
  EllipseVerticalIcon,
  MinimalArrowDownIcon,
} from '../../../components/Icons';
import { IconButton } from '../../authentication/components/Button';
import { ExpenseEditor } from './ExpenseEditor';

// move this to Badge.js

export const Badge = styled.div`
  display: inline-block;
  border-radius: 999px;
  border: 0;
  color: white;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.7;

  background: ${prop('color', theme('colors.primary'))};
`;

const ExpenseListItemHeader = styled.div`
  display: grid;
  grid-template-columns: 90px 90px 90px 1fr 80px 163px 20px;
  gap: 30px;
  align-items: stretch;
  padding: 16px 20px 16px 20px;
  text-align: left;
  color: #9fa3ae;
  font-size: 12px;

  > .col {
    display: flex;
    align-items: center;

    &.amount {
      justify-content: end;
    }

    &.date {
      cursor: pointer;
    }
  }

  > .summary {
    padding-left: 0;

    > ${Badge} {
      margin-right: 10px;
    }
  }
`;

const ExpenseListItem = styled(
  React.forwardRef(function ExpenseListItem(
    {
      expense,
      color,
      onDeleteExpense,
      setSelectExpenseId,
      setEditExpenseId,
      lastItem,
      isOpenMenu,
      onCloseMenu,
      ...props
    },
    ref
  ) {
    const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);

    function onOpenDeleteModal() {
      setIsShowDeleteModal(true);
    }

    function onCloseDeleteModal() {
      setIsShowDeleteModal(false);
    }

    return (
      <>
        <div {...props} ref={ref}>
          <div className="col date">
            {expense?.date && (
              <div className="due-date">
                {DateTime.fromISO(expense.date).toFormat('d MMM yyyy')}
              </div>
            )}
          </div>
          <div className="col project">
            {expense.companyExpenseProject?.name ?? '-'}
          </div>
          <div className="col type">
            {expense.companyExpenseType?.name ?? '-'}
          </div>
          <div className="col description">{expense.description ?? '-'}</div>
          <div className="col amount">
            {expense.amount ? Intl.NumberFormat().format(expense.amount) : '-'}
          </div>
          <div className="col team">
            {expense.team ? titleCase(expense.team) : '-'}
          </div>

          <div className="col menu">
            <IconButton
              className="menu-icon"
              onClick={() => setSelectExpenseId(expense.id)}
            >
              <EllipseVerticalIcon color={isOpenMenu ? color : null} />
            </IconButton>
            {isOpenMenu && (
              <div className={`expense-menu ${lastItem ? 'last-item' : ''}`}>
                <div
                  onClick={() => {
                    setEditExpenseId(expense.id);
                    setSelectExpenseId();
                  }}
                >
                  <Pencil color="#3a3a3a" />
                  <span style={{ marginLeft: '8px', color: '#3a3a3a' }}>
                    Edit
                  </span>
                </div>
                <div onClick={onOpenDeleteModal}>
                  <TrashIcon color="#3a3a3a" />
                  <span style={{ marginLeft: '8px', color: '#3a3a3a' }}>
                    Delete
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <DeleteModal
          isOpen={isShowDeleteModal}
          onClose={onCloseDeleteModal}
          onDelete={() => onDeleteExpense(expense.id)}
          deleteText="Delete Expense?"
          description="Do you really want to delete this expense? This process cannot be undone."
        />
      </>
    );
  })
)`
  width: 100%;
  display: grid;
  grid-template-columns: 100px 90px 90px 1fr 80px 163px 20px;
  gap: 30px;
  align-items: stretch;
  padding: 16px 20px 16px 20px;
  color: #3a3a3a;
  font-size: 14px;
  background: #f5f8f8;
  box-shadow: ${theme('shadow')};

  &.odd-row {
    background: white;
  }

  > .col {
    overflow: auto;
    white-space: nowrap;
    display: flex;
    align-items: center;
    &.default-expense-available {
      display: block;

      > .expense-type-title {
        color: black;
      }
    }

    &.amount {
      justify-content: end;
    }

    &.default-expense {
      font-size: 16px;
      color: black;
    }

    &.menu {
      overflow: visible;
      position: relative;
    }
    > .expense-menu {
      position: absolute;
      width: 100px;
      height: 80px;
      padding: 10px;
      background-color: white;
      border-radius: 5px;
      z-index: 10;
      top: 100%;
      right: 50%;

      &.last-item {
        top: -120%;
        right: 70%;
      }

      > div {
        padding: 5px;
        display: flex;
        cursor: pointer;

        > svg {
          width: 15px;
        }
        > span {
          margin-left: 3px;
          margin-top: 3px;
        }
      }
    }
  }

  > .name {
    color: #333;
    font-size: 14px;
    font-weight: normal;
  }

  > .menu {
    margin-left: auto;
  }
`;

export const ExpenseListBoard = React.memo(
  styled(function ExpenseListBoard({
    className,
    color,
    companyId,
    sortDate,
    onSort,
    onDeleteExpense,
    expenses = [],
    editExpense,
    setEditExpense,
    reloadExpenses,
  }) {
    const [editExpenseId, setEditExpenseId] = useState();
    const [selectExpenseId, setSelectExpenseId] = useState();

    function showExpenseOptions(id) {
      const expenseId = id !== selectExpenseId ? id : null;
      setSelectExpenseId(expenseId);
    }

    return (
      <div className={className}>
        <div className="table-wrapper">
          <div className="table">
            <ExpenseListItemHeader>
              <div
                className="col date"
                onClick={() => onSort(sortDate === 'desc' ? 'asc' : null)}
              >
                <div>Date</div>
                <MinimalArrowDownIcon
                  transform={sortDate === 'desc' ? 'scale(1, -1)' : ''}
                />
              </div>
              <div className="col project">Project</div>
              <div className="col type">Type</div>
              <div className="col description">Description</div>
              <div className="col amount">Amount</div>
              <div className="col team">Team</div>
              <div className="col menu" />
            </ExpenseListItemHeader>
            {editExpense && (
              <ExpenseEditor
                className="odd-row"
                color={color}
                companyId={companyId}
                setEditExpense={setEditExpense}
                reloadExpenses={reloadExpenses}
              />
            )}
            {expenses.map((expense, index) =>
              expense.id === editExpenseId ? (
                <ExpenseEditor
                  className={index % 2 !== 0 ? 'odd-row' : ''}
                  key={expense.id}
                  expense={expense}
                  companyId={companyId}
                  color={color}
                  reloadExpenses={reloadExpenses}
                  setEditExpenseId={setEditExpenseId}
                />
              ) : (
                <ExpenseListItem
                  className={index % 2 !== 0 ? 'odd-row' : ''}
                  key={expense.id}
                  expense={expense}
                  color={color}
                  lastItem={index + 1 === expenses.length}
                  isOpenMenu={selectExpenseId === expense.id}
                  onCloseMenu={() => setSelectExpenseId()}
                  setSelectExpenseId={showExpenseOptions}
                  setEditExpenseId={setEditExpenseId}
                  onDeleteExpense={onDeleteExpense}
                />
              )
            )}
          </div>
        </div>
      </div>
    );
  })`
    > .title {
      font-size: 20px;
    }

    > .table-wrapper {
      width: 100%;
      overflow: auto;

      > .table {
        min-width: max-content;
        min-height: 100vh;
      }
    }

    @media (max-width: ${theme('breakpoints.mobile')}px) {
      > .table-wrapper {
        width: max-content;
      }
    }
  `
);
