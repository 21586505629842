import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Menu, MenuButton } from '@reach/menu-button';
import {
  MenuItem,
  MenuList,
} from '../../features/tickets/pages/AllTicketPage/Subtask';
import { IconButton } from '../../features/authentication/components/Button';
import { EllipseVerticalIcon } from '../Icons';

import '@reach/accordion/styles.css';
import { Indicator } from './Indicator';
import { BaseFilter } from './BaseFilter';

import { SprintCreateModal } from './SprintCreateModal';
import { DeleteModal } from './DeleteModal';

function SprintFilter({
  className,
  data = [],
  selectedIds = [],
  query,
  memoQuery,
  onSelect,
  onSearch,
  onSelectAll,
  projectName,
  isAddSprintModal,
  onAddSprintModal,
  onCloseSprintModal,
  onSubmit,
  isDeleteSprintModal,
  onOpenDeleteSprintModal,
  onCloseDeleteSprintModal,
  onDelete,
  selectedSprint,
  sprintMode,
  onEditSprintModal,
  projectColor,
  isLoading,
}) {
  const options = useMemo(
    () =>
      data
        .map(item => {
          const isChecked = selectedIds.includes(item.id);

          return {
            rank: item.name?.charCodeAt(0) + isChecked ? 100 : 0,
            onSelect(e) {
              e.stopPropagation();
              onSelect(item.id);
            },
            isChecked,
            id: item.id,
            name: item.name,
            goal: item.goal,
            startDate: item.range.start,
            endDate: item.range.end,
            dateRange: `${item.range.start.toFormat(
              'dd MMM'
            )} - ${item.range.end.toFormat('dd MMM')}`,
            isShow:
              isChecked ||
              !memoQuery ||
              item.name
                ?.toLowerCase()
                .startsWith(memoQuery.toLocaleLowerCase()),
            color: item.color || projectColor,
          };
        })
        .sort((x, y) => y.rank - x.rank),
    [data, selectedIds, memoQuery, onSelect, projectColor]
  );

  return (
    <BaseFilter
      className={className}
      title="Sprints"
      query={query}
      onSearch={onSearch}
      onSelectAll={onSelectAll}
      onAdd={onAddSprintModal}
      addPermission="project-sprint:write"
      noSearch={options.length === 0}
      isLoading={isLoading}
    >
      <>
        {options.map(item => (
          <Menu key={item.id}>
            <Item key={item.id} style={{ display: !item.isShow && 'none' }}>
              <Indicator checked={item.isChecked} onClick={item.onSelect} />
              <div className="name" onClick={item.onSelect}>
                {item.name}
                <br />
                <small className="range">{item.dateRange}</small>
              </div>
              <MenuButton as={IconButton} className="menu">
                <EllipseVerticalIcon />
              </MenuButton>
              <MenuList>
                <MenuItem primary onSelect={onEditSprintModal(item)}>
                  Edit
                </MenuItem>
                <MenuItem onSelect={onOpenDeleteSprintModal(item)}>
                  Delete
                </MenuItem>
              </MenuList>
            </Item>
          </Menu>
        ))}
        <SprintCreateModal
          isOpen={isAddSprintModal}
          onClose={onCloseSprintModal}
          onSubmit={onSubmit}
          sprintMode={sprintMode}
          selectedSprint={selectedSprint}
          projectName={projectName}
          projectColor={projectColor}
        />
        <DeleteModal
          isOpen={isDeleteSprintModal}
          onClose={onCloseDeleteSprintModal}
          onDelete={onDelete}
          deleteText={`Delete Sprint "${selectedSprint?.name}" ?`}
        />
      </>
    </BaseFilter>
  );
}

const Item = styled.div`
  display: flex;

  padding: 10px 0;
  cursor: pointer;

  > .name {
    overflow: hidden;

    flex-grow: 1;
    flex-shrink: 1;

    margin-bottom: 5px;
    padding: 0 10px 0 0;

    color: #999;
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;

    > .range {
      font-weight: normal;
    }
  }

  ${Indicator} {
    margin-right: 10px;
  }
`;

const StyledSprintFilter = styled(SprintFilter)`
  .filter-list {
    padding-bottom: 10px;
  }
`;

const MemoSprintFilter = React.memo(StyledSprintFilter);

export { MemoSprintFilter as SprintFilter };
