import React from 'react';
import hoist from 'hoist-non-react-statics';

export function withMobileProjectEditor(Component) {
  function WithMobileProjectEditor({ ...props }) {
    return <Component {...props} />;
  }

  hoist(WithMobileProjectEditor, Component);

  WithMobileProjectEditor.displayName = `WithMobileProjectEditor(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithMobileProjectEditor;
}
