import React, { useState } from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';

import {
  EllipseVerticalIcon,
  DuplicateIcon,
  TrashIcon,
  LinkHorizontalIcon,
} from '../../../components/Icons';
import { useOnClickOutside } from '../../../lib/hooks';

export const SelectAction = styled(function SelectAction({
  className,
  projectId,
  ticketId,
  onCopyLink,
  onDuplicate,
  onDelete,
}) {
  const actionRef = React.useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useOnClickOutside(actionRef, () => {
    setIsOpen(false);
  });

  return (
    <section className={className} ref={actionRef}>
      <div className="menu">
        <div
          className="menu-icon"
          onClick={e => {
            e.stopPropagation();
            setIsOpen(true);
          }}
        >
          <EllipseVerticalIcon />
        </div>
        {isOpen && (
          <div className="menu-list">
            <div
              className="menu-item"
              onClick={e => {
                e.stopPropagation();
                setIsOpen(false);
                onCopyLink();
              }}
            >
              <LinkHorizontalIcon size={16} />
              Copy link
            </div>
            <div
              className="menu-item"
              onClick={e => {
                e.stopPropagation();
                setIsOpen(false);
                onDuplicate(ticketId, projectId);
              }}
            >
              <DuplicateIcon size={16} />
              Duplicate
            </div>
            <div
              className="menu-item"
              onClick={e => {
                e.stopPropagation();
                setIsOpen(false);
                onDelete();
              }}
            >
              <TrashIcon size={16} />
              Delete
            </div>
          </div>
        )}
      </div>
    </section>
  );
})`
  .menu {
    position: relative;
    font-size: 14px;
    .menu-icon {
      color: #ddd;
      cursor: pointer;
      transform: rotate(90deg);
    }

    .menu-list {
      width: 150px;
      border: 0;
      min-width: 80px;
      border-radius: 6px;
      padding: 6px 0;
      position: absolute;
      background: white;
      z-index: 1;

      box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.15);

      @media (max-width: ${theme('breakpoints.mobile')}px) {
        right: 0;
      }
    }

    .menu-item {
      padding: 10px 15px;
      font-weight: normal;
      cursor: pointer;
      color: ${theme('colors.black')};

      svg {
        margin-right: 10px;
      }

      &:hover {
        color: ${theme('colors.black')};
        background: #efefef;
      }
    }
  }
`;
