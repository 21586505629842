import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DocumentTitle } from '../../../../components/DocumentTitle';
import { Button } from '../../../authentication/components/Button';
import { PlusIcon, ArrowLeftIcon } from '../../../../components/Icons';
import { ProjectSettingModal } from '../../components/ProjectSettingModal';
import { useAppLayout } from '../../../../components/withAppLayout';
import { ProjectCard } from '../../components/ProjectCard';
import { CompanySidebar } from '../../../../components/CompanySidebar';
import { TextFieldView } from '../../../authentication/components/TextField';
import { CompanySettingModal } from '../../components/CompanySettingModal';
import { Project } from './components/Project';
import { Ticket } from './components/Ticket';
import '@reach/tabs/styles.css';

function ProjectListPage({
  className,
  projects,
  reloadAllProjects,
  loading,
  currentPage,
  totalItems,
  pageSize,
  query,
  onChangeQuery,
  company,
  isSettingProjectModal,
  isSettingCompanyModal,
  onCloseSettingProjectModal,
  onArchive,
  onDuplicate,
  refetchData,
  projectCount,
  ticketCount,
  setQueryParams,
  tab,
}) {
  const { isShowSidebar: isShowSidebarDesktop } = useAppLayout();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const content = document.querySelector('.main-content');
  const contentWidth = content && content.getBoundingClientRect();

  const projectStyle =
    projects.length > 0 && tab === 'project'
      ? { paddingBottom: '160px' }
      : tab === 'ticket' && contentWidth?.width < 1023
      ? { paddingBottom: '20px' }
      : { paddingBottom: 0 };

  return (
    <section className={className} style={projectStyle}>
      <DocumentTitle title="Projects" />
      <CompanySidebar className={className} company={company} tab={tab} />
      <div
        className={`content ${
          isShowSidebarDesktop ? 'show-sidebar-desktop' : ''
        }`}
      >
        <div className="header">
          <div className="title">
            <div onClick={() => navigate(-1)}>
              <ArrowLeftIcon />
            </div>
            <span>Projects</span>
          </div>
          <TextFieldView
            type="search"
            value={query}
            placeholder="Search by name"
            onChange={e => onChangeQuery(e.target.value)}
          />
          <Button
            className="button"
            type="button"
            onClick={() => setQueryParams({ modal: 'create-project' })}
          >
            New Project
          </Button>
        </div>
        <div className="tabs">
          <div className="tab-list">
            <Link
              to={`/${pathname}`}
              className={['tab project', tab === 'project' ? 'active' : '']
                .join(' ')
                .trim()}
            >
              All project ({projectCount || 0})
            </Link>
            <Link
              to={`/${pathname}?tab=ticket`}
              className={['tab ticket', tab === 'ticket' ? 'active' : '']
                .join(' ')
                .trim()}
            >
              All ticket ({ticketCount || 0})
            </Link>
          </div>
        </div>
        {tab === 'project' && (
          <Project
            reloadAllProjects={reloadAllProjects}
            loading={loading}
            currentPage={currentPage}
            totalItems={totalItems}
            pageSize={pageSize}
            projects={projects}
          />
        )}
        {tab === 'ticket' && (
          <Ticket
            className="ticket"
            onArchive={onArchive}
            onDuplicate={onDuplicate}
            refetchData={refetchData}
          />
        )}
      </div>
      {isSettingProjectModal && (
        <ProjectSettingModal
          isOpen={isSettingProjectModal}
          onClose={onCloseSettingProjectModal}
          reloadAllProjects={reloadAllProjects}
        />
      )}
      {isSettingCompanyModal && (
        <CompanySettingModal
          isOpen={isSettingCompanyModal}
          onClose={onCloseSettingProjectModal}
        />
      )}
      {projects.length > 0 && tab === 'project' && (
        <div className="new-project-btn-mobile">
          <Button
            className="button"
            type="button"
            onClick={() => navigate(`/company/${company?.id}/project/create`)}
          >
            <PlusIcon />
            New Project
          </Button>
        </div>
      )}
    </section>
  );
}

const StyledProjectListPage = styled(ProjectListPage)`
  > .content {
    display: flex;
    flex-direction: column;
    width: calc(100vw - 50px);

    flex-grow: 1;
    transition: all 0.2s ease-in-out;

    &.width-100 {
      width: 100%;
      overflow: auto;
    }

    @media (max-width: ${theme('breakpoints.mobile')}px) {
      padding-left: 20px !important;
    }

    .header > div {
      margin-bottom: unset;
    }

    > .header {
      position: sticky;
      top: 0;
      z-index: 10;
      background: white;
      padding: 20px;
      display: flex;
      align-items: center;
      width: 100%;

      .title {
        font-size: 20px;
        font-weight: bold;
      }
    }

    &.show-sidebar-desktop {
      padding-left: ${theme('sidebarWidth')}px;

      > .header {
        width: calc(100vw - 340px);
      }
    }

    > .tabs {
      padding: 20px;

      > .tab-list {
        display: flex;
        background: none;
        border-bottom: 1px solid #ddd;

        > .tab {
          border: solid 1px #1ccc56;
          border-bottom: 0;
          padding: 12px 30px;

          color: ${theme('colors.primary')};
          font-weight: bold;
          text-decoration: none;
        }

        > .tab.project {
          border-top-left-radius: 6px;
        }
        > .tab.ticket {
          border-top-right-radius: 6px;
        }

        > .tab.active {
          color: white;
          background: ${theme('colors.primary')};
        }
      }
    }

    @media (max-width: ${theme('breakpoints.mobile')}px) {
      > .tabs {
        > .tab-list {
          flex-wrap: nowrap;
        }
      }
    }

    @media (max-width: ${theme('breakpoints.mobile')}px) {
      > .tabs {
        font-size: 14px;
        font-weight: bold;
        padding: unset;
        width: 100%;
        margin-bottom: 25px;

        > .tab-list {
          flex-wrap: nowrap;

          a {
            width: 100%;
            text-align: center;
          }
        }
      }
    }

    > .header {
      display: grid;
      grid-template-areas: 'title search button';
      grid-template-columns: max-content auto auto;
      grid-column-gap: 20px;

      > .title {
        grid-area: title;
        align-items: center;
        display: flex;

        > div {
          cursor: pointer;
        }

        svg {
          height: 30px;
          width: 30px;
        }

        span {
          border-left: 1px solid #dce0ea;
          padding-left: 15px;
          margin-left: 15px;
          height: 38px;
          align-items: center;
          display: flex;
        }
      }

      > ${TextFieldView} {
        grid-area: search;
        width: 300px;
      }

      > ${Button} {
        grid-area: button;
        height: 42px;
        margin-left: auto;
      }
    }

    > .title {
      font-size: 20px;
      margin-top: 20px;
      padding: 0px 20px;
    }

    > .grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 20px;
      width: 100%;
      margin: 20px auto;
      padding: 0px 20px;

      > ${ProjectCard}:hover {
        cursor: pointer;
      }
    }

    @media (max-width: ${theme('breakpoints.mobile')}px) {
      .grid {
        padding: unset;
      }
    }

    > .pagination {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .new-project-btn-mobile {
    display: none;
  }

  &.loading {
    height: 100vh;
    margin-top: 20px;
  }

  @media (max-width: ${theme('breakpoints.tablet')}px) {
    > .content {
      > .header {
        grid-template-areas:
          'title button'
          'search search';
        grid-template-columns: auto auto;
        grid-row-gap: 10px;
        grid-column-gap: 0;
      }

      > .grid {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    padding-top: 60px;

    .no-project .no-projects {
      height: calc(100vh - 14rem);
    }

    &.no-project {
      padding-bottom: 0;
    }

    > .content {
      padding: 20px;
      width: 100%;

      > .header {
        display: none;
      }

      > .header {
        grid-template-areas:
          'title'
          'button'
          'search';
        grid-template-columns: auto;

        > ${TextFieldView} {
          width: 100%;
        }

        > ${Button} {
          margin-left: unset;
        }
      }

      > .grid {
        grid-template-columns: auto;
      }
    }
    > .new-project-btn-mobile {
      display: flex;
      position: fixed;
      bottom: 60px;
      left: 0;
      width: 100%;
      height: 86px;
      background: white;
      align-items: center;
      justify-content: center;
      padding: 20px 15px;

      > .button {
        width: 100%;
        height: 46px;
      }
    }
  }

  .loading-project {
    margin-top: 10%;
    background: transparent;
  }

  .no-projects {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 60px);
    padding: 0 15px;
    color: #9fa3ae;

    > .label {
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 10px;
    }

    > .description {
      font-size: 0.857rem;
      margin-bottom: 10px;
    }

    > div {
      text-align: center;
    }

    > ${Button} {
      height: 46px;
      font-size: 0.875rem;
    }
  }

  .calendar {
    position: relative;
  }
`;

export { StyledProjectListPage as ProjectListPage };
