import React from 'react';
import hoist from 'hoist-non-react-statics';
import { useProjectSearch } from '../../features/projects/useProjectSearch';
import { useFilter } from './useFilter';
import { useQueryParams } from '../../lib/hooks';

export function withProjectFilter(Component) {
  function WithProjectFilter(props) {
    const { data: projects, revalidate } = useProjectSearch();
    const filter = useFilter({ key: 'project', data: projects });
    const [queryParams, setQueryParams] = useQueryParams();

    const onCloseSettingProjectModal = () => {
      setQueryParams({ selectedProjectId: null, modal: null });
      revalidate();
    };

    const pageProps = {
      ...props,
      ...filter,
      projects: projects ?? [],
      onCloseSettingProjectModal,
      isSettingProjectModal:
        Boolean(queryParams.selectedProjectId) || Boolean(queryParams.modal),
      isSettingCompanyModal: Boolean(queryParams.modal === 'create-company'),
      projectMemberList: [],
      loading: Boolean(projects),
      selectedProjectId: queryParams.selectedProjectId,
      onEdit(selectedProjectId) {
        setQueryParams({ selectedProjectId });
      },
      onAdd() {
        setQueryParams({ modal: 'create-project' });
      },
    };

    return <Component {...pageProps} />;
  }

  hoist(WithProjectFilter, Component);

  WithProjectFilter.displayName = `withProjectFilter(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithProjectFilter;
}
