import React from 'react';
import useProjectState from './useProjectState';
import ProjectContext from './projectContext';

const ProjectStateProvider = ({ children }) => {
  return (
    <ProjectContext.Provider value={useProjectState()}>
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectStateProvider;
