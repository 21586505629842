import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { Button, OutlineButton } from '../../authentication/components/Button';

function AccountContent({ className, children, title, onReset }) {
  return (
    <div className={className}>
      <div className="header">
        <div className="title">{title}</div>
        <div className="button-group">
          <OutlineButton
            className="button cancel"
            type="reset"
            onClick={onReset}
          >
            Cancel
          </OutlineButton>
          <Button className="button" type="submit">
            Save
          </Button>
        </div>
      </div>
      {children}
    </div>
  );
}

const StyledAccountContent = styled(AccountContent)`
  > .header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
    background-color: #fff;

    > .title {
      font-size: 20px;
      font-weight: bold;
    }

    > .button-group {
      column-gap: 10px;
      display: flex;

      .button {
        width: 120px;
      }

      .cancel {
        border: solid 1px #c2c3ca;
        color: #c2c3ca;
      }

      .cancel:hover {
        background-color: #c2c3ca;
        color: white;
      }
    }
  }

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    > .header {
      grid-template-areas:
        'breadcrumb breadcrumb'
        'title button';

      > .title {
        font-size: 16px;
        margin: auto 0;
      }
    }
  }
`;

export { StyledAccountContent as AccountContent };
