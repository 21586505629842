export const rules = {
  admin: [
    'company:read',
    'company:write',
    'member:read',
    'member:write',
    'project:read',
    'project:write',
    'project-member:read',
    'project-member:write',
    'project-epic:read',
    'project-epic:write',
    'project-sprint:read',
    'project-sprint:write',
    'project-tag:read',
    'project-tag:write',
  ],
  member: [
    'company:read',
    'member:read',
    'project:read',
    'project:write',
    'project-member:read',
    'project-member:write',
    'project-epic:read',
    'project-epic:write',
    'project-tag:read',
    'project-tag:write',
  ],
  guest: [
    'company:read',
    'member:read',
    'project:read',
    'project-member:read',
    'project-epic:read',
    'project-epic:write',
    'project-tag:read',
    'project-tag:write',
  ],
  super_admin: [
    'company:read',
    'company:write',
    'company-expense:read',
    'company-expense:write',
    'company-employee-salary:read',
    'company-employee-salary:write',
    'member-super-admin:read',
    'member-super-admin:write',
    'member:read',
    'member:write',
    'project:read',
    'project:write',
    'project-member:read',
    'project-member:write',
    'project-epic:read',
    'project-epic:write',
    'project-sprint:read',
    'project-sprint:write',
    'project-tag:read',
    'project-tag:write',
  ],
};
