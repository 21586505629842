import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { theme } from 'styled-tools';
import {
  IconButton,
  Button,
  OutlineButton,
} from '../../../authentication/components/Button';
import { Breadcrumbs } from '../../../../components/Breadcrumbs';
import { TrashIcon } from '../../../../components/Icons';
import { DatePicker } from '../../../../components/DatePicker';
import { LoadingIndicator } from '../../../../components/LoadingIndicator';
import {
  TextField,
  TextFieldView,
} from '../../../authentication/components/TextField';

function ProjectSetupPage({
  className,
  informations,
  project,
  loading,
  companyName,
  handleSubmit,
  isEdit,
  defaultResource,
  defaultContact,
  initialValues,
  handleChangeEdit,
}) {
  const navigate = useNavigate();

  return (
    <section className={className}>
      <div className="content-bar">
        <div className="header">
          <div className="bar" onClick={() => navigate('./../')}>
            <Breadcrumbs data={[companyName, project?.name ?? '']} />
          </div>
        </div>
        <div className="header">
          <h3>Project Information</h3>
        </div>
      </div>
      <div className="content">
        <div className="project-form">
          {isEdit ? (
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              {({ values, isSubmitting, setFieldValue, submitForm }) => {
                return (
                  <div className="form">
                    <div className="submit editor">
                      <span>Setup Project</span>
                      <div>
                        <OutlineButton
                          className="button cancel"
                          onClick={() => handleChangeEdit(false)}
                          disabled={isSubmitting}
                        >
                          Cancel
                        </OutlineButton>
                        <Button
                          type="submit"
                          onClick={submitForm}
                          disabled={isSubmitting}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                    <div className="detail">
                      <div className="group">
                        <div className="title">Information</div>
                        <div className="section client-name">
                          <TextField
                            name="clientName"
                            label="Client/Product Owner"
                            placeholder="Name Client/Product owner"
                            oldLabel
                          />
                          <TextField
                            name="tin"
                            label="Taxpayer Identification Number (TIN)"
                            placeholder="TIN NO."
                            oldLabel
                          />
                          <div className="address">
                            <TextField
                              name="companyAddress"
                              label="Company Registration address"
                              placeholder="Address, City, County and Postcode"
                              multiline
                              rows={12}
                              style={{ height: '160px' }}
                              oldLabel
                            />
                          </div>
                          <div className="timeline">
                            <div className="title">Timeline</div>
                          </div>
                          <div className="section">
                            <DatePicker
                              className="datepicker"
                              label="Start Date"
                              value={values.startDate}
                              onChange={startDate =>
                                setFieldValue('startDate', startDate)
                              }
                              size="sm"
                              isClearable
                              hiddenArrowIcon
                              showDateIcon
                              placeholderText="dd/mm/yyyy"
                            />
                            <DatePicker
                              className="datepicker"
                              label="End Date"
                              value={values.endDate}
                              onChange={endDate =>
                                setFieldValue('endDate', endDate)
                              }
                              size="sm"
                              isClearable
                              hiddenArrowIcon
                              showDateIcon
                              placeholderText="dd/mm/yyyy"
                            />
                          </div>
                          <TextField
                            name="totalTime"
                            label="Total budget (Time)"
                            suffixMsg="Hours"
                            oldLabel
                          />
                        </div>
                      </div>
                      <div className="group">
                        <div className="label">Resource Planning</div>
                        <div className="section">
                          {values.resources.map(
                            (resource, index) =>
                              !resource.destroy && (
                                <div className="flex-row" key={index}>
                                  <TextField
                                    className="resource-name"
                                    size="sm"
                                    name={`resources[${index}].position`}
                                    label="Position"
                                    value={resource.position}
                                    placeholder="Name Position"
                                    oldLabel
                                  />
                                  <div className="resource-amount">
                                    <TextField
                                      size="sm"
                                      type="number"
                                      name={`resources[${index}].number`}
                                      label="Amount"
                                      value={resource.number}
                                      placeholder="0"
                                      min="0"
                                      oldLabel
                                    />
                                    {index !== 0 && (
                                      <div>
                                        <IconButton
                                          onClick={() => {
                                            if (resource.id) {
                                              const { resources } = values;
                                              resources[index] = {
                                                ...resources[index],
                                                destroy: true,
                                              };
                                              setFieldValue('resources', [
                                                ...resources,
                                              ]);
                                            } else {
                                              setFieldValue(
                                                'resources',
                                                values.resources.filter(
                                                  (_, i) => i !== index
                                                )
                                              );
                                            }
                                          }}
                                        >
                                          <TrashIcon color="#3a3a3a" />
                                        </IconButton>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )
                          )}
                        </div>
                        <div
                          className="add-resource"
                          onClick={() =>
                            setFieldValue('resources', [
                              ...values.resources,
                              ...defaultResource,
                            ])
                          }
                        >
                          Add Position
                        </div>
                      </div>
                      <div className="group contact">
                        <div className="label contact">Contact Person</div>
                        {values.contacts.map(
                          (contact, index) =>
                            !contact.destroy && (
                              <div
                                className="section contact-content"
                                key={index}
                              >
                                <TextField
                                  name={`contacts[${index}].name`}
                                  label="Name"
                                  placeholder="Name Contact Person"
                                  oldLabel
                                  value={contact.name}
                                />
                                <TextField
                                  name={`contacts[${index}].role`}
                                  label="Role"
                                  placeholder="Enter Role"
                                  oldLabel
                                  value={contact.role}
                                />
                                <TextField
                                  name={`contacts[${index}].email`}
                                  label="Email"
                                  placeholder="email@example.com"
                                  oldLabel
                                  value={contact.email}
                                />
                                <div className="telephone">
                                  <TextField
                                    name={`contacts[${index}].telephone`}
                                    placeholder="+66 81 234 5678"
                                    label="Tel"
                                    oldLabel
                                    value={contact.telephone}
                                  />
                                  {index !== 0 && (
                                    <div>
                                      <IconButton
                                        onClick={() => {
                                          if (contact.id) {
                                            const { contacts } = values;
                                            contacts[index] = {
                                              ...contacts[index],
                                              destroy: true,
                                            };
                                            setFieldValue('contacts', [
                                              ...contacts,
                                            ]);
                                          } else {
                                            setFieldValue(
                                              'contacts',
                                              values.contacts.filter(
                                                (_, i) => i !== index
                                              )
                                            );
                                          }
                                        }}
                                      >
                                        <TrashIcon color="#3a3a3a" />
                                      </IconButton>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )
                        )}
                        <div
                          className="add-resource"
                          onClick={() =>
                            setFieldValue('contacts', [
                              ...values.contacts,
                              ...defaultContact,
                            ])
                          }
                        >
                          Add Contact Person
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }}
            </Formik>
          ) : (
            <div className="form">
              <div className="submit">
                <span>Setup Project</span>
                <Button onClick={() => handleChangeEdit(true)}>Edit</Button>
              </div>
              <div className="detail-form">
                <div className="info">
                  <div className="sub-title">Information</div>
                  <div className="row">
                    <div className="column">
                      <div>Client/Product owner</div>
                      <div>{informations?.client || '-'}</div>
                    </div>
                    <div className="column">
                      <div>Taxpayer Identification Number (TIN)</div>
                      <div>{informations?.tin || '-'}</div>
                    </div>
                    <div className="column">
                      <div>Company Registration Address</div>
                      <div>{informations?.companyAddress || '-'}</div>
                    </div>
                  </div>
                </div>
                <div className="timeline">
                  <div className="sub-title">Timeline</div>
                  <div className="row">
                    <div className="column">
                      <div>Start - End date</div>
                      <div>
                        {informations?.startDate ?? '/'} -{' '}
                        {informations?.endDate ?? '/'}
                      </div>
                    </div>
                    <div className="column">
                      <div>Total budget (Time)</div>
                      <div>
                        {informations?.totalBudgetTime
                          ? `${informations.totalBudgetTime} Hours`
                          : '-'}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="resource">
                  <div className="planning">
                    <div className="sub-title">Resource Planning</div>
                    {informations?.useCaseResources.length ? (
                      informations?.useCaseResources.map((resource, index) => (
                        <div className="row" key={index}>
                          <div className="column">
                            <div>Position</div>
                            <div>{resource.position}</div>
                          </div>
                          <div className="column">
                            <div>Amount</div>
                            <div>{resource.number}</div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="empty">-</div>
                    )}
                  </div>
                  <div className="contact-person">
                    <div className="sub-title">Contact Person</div>
                    {informations?.useCaseContracts ? (
                      informations?.useCaseContracts.map((contact, index) => (
                        <div className="row" key={index}>
                          <div className="column">
                            <div>Name</div>
                            <div>{contact.name}</div>
                          </div>
                          <div className="column">
                            <div>Role</div>
                            <div>{contact.role}</div>
                          </div>
                          <div className="column">
                            <div>Email</div>
                            <div>{contact.email}</div>
                          </div>
                          <div className="column">
                            <div>Tel</div>
                            <div>{contact.telephone}</div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="empty">-</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="content">
        {loading && (
          <LoadingIndicator
            className={`${className} loading`}
            color="primary"
          />
        )}
      </div>
    </section>
  );
}

const StyledProjectSetupPage = styled(ProjectSetupPage)`
  > .content-bar {
    background: white;
    padding: 20px;

    > .header:nth-child(1) {
      display: grid;
      grid-template-areas: 'title search button';
      grid-template-columns: max-content auto auto;
      grid-column-gap: 20px;
      margin-left: -8px;
      font-size: 14px;

      > .bar {
        cursor: pointer;
      }

      > h1 {
        grid-area: title;
      }

      > ${TextFieldView} {
        grid-area: search;
        width: 300px;
      }

      > ${Button} {
        grid-area: button;
        height: 42px;
        margin-left: auto;
      }
    }

    > .header:nth-child(2) {
      display: flex;
      font-size: 20px;
      margin-top: 10px;

      > h3 {
        font-size: 20px;
        margin-top: 3px;
        margin-right: 8px;
      }
    }
  }

  > .content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;

    > .project-form {
      padding: 20px;
      border-radius: 5px;
      background-color: white;

      .submit {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        span {
          font-size: 20px;
          font-weight: bold;
        }

        button {
          width: 120px;
        }

        button:not(:last-child) {
          margin-right: 10px;
        }
      }

      .detail-form {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .empty {
          margin-top: 20px;
        }

        .sub-title {
          font-size: 16px;
          font-weight: bold;
        }

        .info {
          border-bottom: solid 1px #dce0ea;
          margin-bottom: 30px;
        }

        .timeline {
          border-bottom: solid 1px #dce0ea;
          margin-bottom: 30px;

          .row {
            grid-template-columns: 250px 1fr;

            @media (max-width: ${theme('breakpoints.mobile')}px) {
              grid-template-columns: 1fr;
              row-gap: 12px;
            }
          }
        }

        .resource {
          display: grid;
          grid-template-columns: 520px 1fr;
          column-gap: 20px;

          .planning {
            border-right: solid 1px #dce0ea;

            .row {
              width: 70%;
              border-bottom: solid 1px #dce0ea;
            }
          }

          .contact-person .row {
            grid-template-columns: repeat(4, 1fr);
            border-bottom: solid 1px #dce0ea;

            @media (max-width: ${theme('breakpoints.mobile')}px) {
              grid-template-columns: 1fr;
            }
          }

          @media (max-width: ${theme('breakpoints.mobile')}px) {
            grid-template-columns: 1fr;
          }
        }

        .row {
          display: grid;
          grid-template-columns: 200px 300px 1fr;
          column-gap: 20px;
          padding-top: 20px;
          padding-bottom: 20px;

          .column {
            div:nth-child(1) {
              font-size: 12px;
              color: #c1c3ca;
            }
            div:nth-child(2) {
              font-size: 14px;
              color: #3a3a3a;
              margin-top: 10px;
            }
          }

          @media (max-width: ${theme('breakpoints.mobile')}px) {
            row-gap: 12px;
            grid-template-columns: 1fr;
          }
        }
      }

      > .form {
        .submit.editor {
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            font-size: 20px;
            font-weight: bold;
          }

          .cancel {
            border: unset;
            color: #c2c3ca;
          }

          .cancel:hover {
            background-color: #c2c3ca;
            color: white;
          }
        }
      }

      > .form > .detail {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .group.contact {
          width: 100%;
          margin-top: 20px;

          .label.contact {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 10px;
            color: #3a3a3a;
          }

          .contact-content:nth-child(2) {
            margin-top: 20px;

            .telephone {
              width: 100%;
              grid-template-columns: 1fr;

              > div {
                flex-direction: column;
              }
            }
          }

          .contact-content {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            column-gap: 10px;

            .telephone {
              width: 100%;
              display: grid;
              grid-template-columns: 1fr 50px;
              align-items: center;

              div:last-child {
                display: flex;
                justify-content: center;
              }
            }
          }
        }

        > .group {
          width: 100%;

          .title {
            font-size: 16px;
            padding-bottom: 5px;
            font-weight: bold;
            color: #3a3a3a;
          }

          .add-resource {
            height: 40px;
            background-color: rgba(34, 199, 89, 0.05);
            border-radius: 6px;
            font-size: 14px;
            font-weight: bold;
            color: #22c759;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-top: 10px;
          }

          > .client-name {
            padding-top: 23px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 10px;
            row-gap: 10px;

            > .timeline {
              grid-column: span 2;
            }

            > .address {
              grid-row: span 3 / span 3;
            }

            > .section {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              column-gap: 10px;
            }
          }

          > .label {
            font-size: 16px;
            padding-top: 10px;
            padding-bottom: 5px;
            font-weight: bold;
            color: #3a3a3a;
          }

          > .section > .col {
            padding: 8px 0;

            &:last-child {
              margin-bottom: 10px;
            }
          }
          > .section > .flex-row:not(:first-child) {
            > .resource-amount {
              width: 100%;
              display: grid;
              grid-template-columns: 1fr 50px;
              align-items: center;

              div:last-child {
                display: flex;
                justify-content: center;
              }
            }
          }

          > .section > .flex-row {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 10px;
            padding-top: 3px;
            margin-top: 20px;

            &.date-form {
              margin-bottom: 20px;
              position: relative;
              z-index: 2;
            }

            > .resource-name {
              width: 100%;
            }

            > .resource-amount {
              width: 100%;
            }
          }
        }
      }
    }

    > .actions {
      display: flex;
      justify-content: space-between;

      > .field {
        width: 40%;
      }
      > .button {
        height: 40px;
      }
    }

    > .title {
      margin-top: 20px;
    }

    > .grid {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 20px;
      width: 100%;
      margin: 20px auto;
    }

    > .pagination {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .new-project-btn-mobile {
    display: none;
  }

  &.loading {
    height: 100vh;
    margin-top: 20px;
  }

  @media (max-width: ${theme('breakpoints.tablet')}px) {
    > .content {
      > .header {
        grid-template-areas:
          'title button'
          'search search';
        grid-template-columns: auto auto;
        grid-row-gap: 10px;
        grid-column-gap: 0;
      }

      > .grid {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    padding-bottom: 160px;
    padding-top: 60px;

    &.no-project {
      padding-bottom: 0;
    }

    > .content {
      padding: 20px;
      width: 100%;

      > .actions {
        > .field {
          width: 50%;
        }
      }

      > .header {
        grid-template-areas:
          'title'
          'button'
          'search';
        grid-template-columns: auto;

        > ${TextFieldView} {
          width: 100%;
        }

        > ${Button} {
          margin-left: unset;
        }
      }

      > .grid {
        grid-template-columns: auto;
      }
    }
    > .new-project-btn-mobile {
      display: flex;
      position: fixed;
      bottom: 60px;
      left: 0;
      width: 100%;
      height: 86px;
      background: white;
      align-items: center;
      justify-content: center;
      padding: 20px 15px;

      > .button {
        width: 100%;
        height: 46px;
      }
    }
  }

  .no-projects {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 60px);
    padding: 0 15px;
    color: #9fa3ae;

    > .label {
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 10px;
    }

    > .description {
      font-size: 0.857rem;
      margin-bottom: 10px;
    }

    > div {
      text-align: center;
    }

    > ${Button} {
      height: 46px;
      font-size: 0.875rem;
    }
  }
`;

export { StyledProjectSetupPage as ProjectSetupPage };
