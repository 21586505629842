import useSWR from 'swr';
import { useEffect, useState } from 'react';
import { useDebounce, useQueryParams } from '../../lib/hooks';
import { useAppClient } from '../../lib/AppProvider';

export function useSearchExpenses(
  companyId,
  { page, endDate, startDate, expenseTypeId, expenseProjectId, sortDate, team }
) {
  const client = useAppClient();
  const [pagination, setPagination] = useState(null);
  const [queryParams, setQueryParams] = useQueryParams();
  const [q, onChangeQ] = useState(queryParams.q ?? '');
  const { destroy } = useExpensesAction(companyId);
  const memoQuery = useDebounce(q, 500);

  const cacheKey = [
    ['page', page],
    ['query', memoQuery],
    ['endDate', endDate],
    ['startDate', startDate],
    ['expenseTypeId', expenseTypeId],
    ['expenseProjectId', expenseProjectId],
    ['sortDate', sortDate],
    ['team', team],
  ].reduce((memo, [key, value]) => `${memo}${key}=${value}&`, '');

  const { data, revalidate } = useSWR(
    companyId && `expenses?${cacheKey}`,
    () =>
      client.getCompanyExpenses(companyId, {
        page,
        query: memoQuery,
        end_date: endDate,
        start_date: startDate,
        expense_type_id: expenseTypeId,
        expense_project_id: expenseProjectId,
        sort_date: sortDate,
        team,
      }),
    { revalidateOnFocus: false }
  );

  useEffect(() => {
    if (!data) {
      return;
    }
    setPagination(data?.meta?.pagination);
  }, [data]);

  useEffect(() => {
    setQueryParams({ q: memoQuery });
  }, [memoQuery, setQueryParams]);

  return {
    loading: !data,
    totalExpense: data?.meta?.total_amount ?? '0',
    expenses: data?.data ?? [],
    pagination,
    revalidate,
    onChangeQuery: onChangeQ,
    query: q,
    async destroy(id) {
      await destroy(id);
      revalidate();
    },
  };
}

export function useExpensesAction(companyId) {
  const client = useAppClient();

  return {
    async create({
      date,
      description,
      team,
      amount,
      companyExpenseTypeId,
      companyExpenseProjectId,
    }) {
      return client.createCompanyExpense(companyId, {
        date,
        description,
        team,
        amount,
        companyExpenseTypeId,
        companyExpenseProjectId,
      });
    },
    async update(
      id,
      {
        date,
        description,
        team,
        amount,
        companyExpenseTypeId,
        companyExpenseProjectId,
      }
    ) {
      return client.updateCompanyExpense(companyId, id, {
        date,
        description,
        team,
        amount,
        companyExpenseTypeId,
        companyExpenseProjectId,
      });
    },
    async destroy(id) {
      return client.deleteCompanyExpense(companyId, id);
    },
  };
}

export function useSearchExpenseTypes(companyId) {
  const client = useAppClient();

  const { data, revalidate } = useSWR(
    companyId && `companies/${companyId}/expense_types`,
    () => client.getCompanyExpenseTypes(companyId),
    { revalidateOnFocus: false }
  );

  return {
    expenseTypes: data ?? [],
    revalidate,
    async createType({ name }) {
      return client.createCompanyExpenseType(companyId, { name });
    },
    async updateType(id, { name }) {
      return client.updateCompanyExpenseType(companyId, id, { name });
    },
    async destroyType(id) {
      return client.deleteCompanyExpenseType(companyId, id);
    },
  };
}

export function useSearchExpenseProjects(companyId) {
  const client = useAppClient();

  const { data, revalidate } = useSWR(
    companyId && `companies/${companyId}/expense_projects`,
    () => client.getCompanyExpenseProjects(companyId),
    { revalidateOnFocus: false }
  );

  return {
    expenseProjects: data ?? [],
    revalidate,
    async createProject({ name }) {
      return client.createCompanyExpenseProject(companyId, { name });
    },
    async updateProject(id, { name }) {
      return client.updateCompanyExpenseProject(companyId, id, { name });
    },
    async destroyProject(id) {
      return client.deleteCompanyExpenseProject(companyId, id);
    },
  };
}
