import React from 'react';
import hoist from 'hoist-non-react-statics';

export function withSideBarFilter(Component) {
  function WithSideBarFilter(props) {
    return <Component {...props} />;
  }

  hoist(WithSideBarFilter, Component);

  WithSideBarFilter.displayName = `withSideBarFilter(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithSideBarFilter;
}
