import React, { createContext, useContext, useEffect, useState } from 'react';
import { cache } from 'swr';

import { useParams, useNavigate } from 'react-router-dom';

import { useCompanyList } from './useCompanyList';
import { useAuthentication } from '../authentication/useAuthentication';
import { useAppClient } from '../../lib/AppProvider';

/**
 *
 * @type {React.Context<{
 *   data: *
 *   update(form: *): Promise<void>
 * }>}
 */
const CurrentCompanyContext = createContext({ data: null, update() {} });

export function CurrentCompanyProvider({ children }) {
  const client = useAppClient();
  const { companyId = localStorage.getItem('currentCompanyId') } = useParams();
  const { isAuthenticated } = useAuthentication();
  const { data, mutate, revalidate, isValidating: loading } = useCompanyList();
  const [selectedId, setSelectedId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedId) {
      return () => {};
    }

    if (companyId === selectedId) {
      return () => {};
    }

    const timeout = setTimeout(() => {
      cache.clear();
      // setSelectedId(null);
    }, 500);

    return () => clearTimeout(timeout);
  }, [companyId, selectedId]);

  useEffect(() => {
    if (!isAuthenticated || selectedId || !data) {
      return;
    }

    const cacheCompanyId = localStorage.getItem('currentCompanyId');

    const findCompany = data.find(({ id }) => id === companyId);
    const selectedCompany = findCompany ?? data[0];

    if (companyId === selectedId) {
      return;
    }

    if (selectedCompany) {
      if (cacheCompanyId !== selectedCompany.id) {
        localStorage.setItem('currentCompanyId', selectedCompany.id);
      }

      if (cacheCompanyId === selectedCompany.id) {
        if (selectedId !== selectedCompany.id) {
          setSelectedId(selectedCompany.id);
          if (!findCompany) {
            navigate(`/company/${selectedCompany.id}`);
          }
        }
        return;
      }

      client.selectCompany(selectedCompany.id).then(() => {
        if (cacheCompanyId !== selectedCompany.id)
          localStorage.setItem('currentCompanyId', selectedCompany.id);
        if (selectedId !== selectedCompany.id)
          setSelectedId(selectedCompany.id);
      });
    }
  }, [companyId, isAuthenticated, data, selectedId, client, navigate]);

  const value = {
    data: selectedId ? data?.find(item => selectedId === item.id) : null,
    async update({ name, description, logoFile, hasImageDelete = false }) {
      const result = data?.map(item =>
        item.id === selectedId ? { ...item, name, description } : item
      );

      await mutate(result, false);
      await client.updateCompany(selectedId, {
        name,
        description,
        logoFile,
        hasImageDelete,
      });

      return revalidate();
    },
    loading,
  };

  return (
    <CurrentCompanyContext.Provider value={value}>
      {children}
    </CurrentCompanyContext.Provider>
  );
}

export function useCurrentCompany() {
  return useContext(CurrentCompanyContext);
}
