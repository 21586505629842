import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';
import 'react-toastify/dist/ReactToastify.min.css';
import './index.css';
import '@reach/dialog/styles.css';
import './styles/modal.css';

import TagManager from 'react-gtm-module';
import * as serviceWorker from './serviceWorker';
import { Root } from './Root';
import { ToolingsClient } from './clients/ToolingsClient';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER,
};

TagManager.initialize(tagManagerArgs);

Sentry.init({
  dsn:
    'https://19aa1506b7b04362918a7a39a359a0e8@o429130.ingest.sentry.io/5396556',
  environment: 'local',
});

const client = new ToolingsClient();

document.addEventListener('DOMContentLoaded', function () {
  ReactDOM.render(
    <React.StrictMode>
      <Root client={client} />
    </React.StrictMode>,
    document.getElementById('root')
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
