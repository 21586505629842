import React, { useState, useEffect, useMemo } from 'react';
import { getDate, formatDateTime } from '../../../../lib/utils';

export function withRepeatTicket(Component) {
  function WithRepeatTicket({
    values,
    setFieldValue,
    initialValues,
    isEdit,
    ...props
  }) {
    const [selectedEnd, setSelectedEnd] = useState('never');
    const [selectedWeekly, setSelectedWeekly] = useState([]);
    const [selectedMonthly, setSelectedMonthly] = useState('day');
    const [recurrenceOptions, setRecurrenceOptions] = useState(null);
    const [date, setDate] = useState(null);

    const weekOptions = [
      { label: 'Sunday', value: 'sunday' },
      { label: 'Monday', value: 'monday' },
      { label: 'Tuesday', value: 'tuesday' },
      { label: 'Wednesday', value: 'wednesday' },
      { label: 'Thursday', value: 'thursday' },
      { label: 'Friday', value: 'friday' },
      { label: 'Saturday', value: 'saturday' },
    ];
    const repeatTypeOptions = [
      { label: 'Days', value: 'days' },
      { label: 'Week', value: 'week' },
      { label: 'Months', value: 'months' },
      { label: 'Years', value: 'years' },
    ];

    const isDueDateChange = useMemo(() => {
      return (
        initialValues.recurrence !== 'not_repeat' &&
        values?.recurrence !== 'not_repeat' &&
        (!values.dueDate ||
          formatDateTime(initialValues?.dueDate) !==
            formatDateTime(values?.dueDate))
      );
    }, [
      initialValues.dueDate,
      values.dueDate,
      initialValues.recurrence,
      values.recurrence,
    ]);

    const disabled = useMemo(
      () =>
        (!isEdit && values.recurrence !== 'not_repeat' && !!values.dueDate) ||
        (isEdit && isDueDateChange),
      [isEdit, values.recurrence, isDueDateChange, values.dueDate]
    );

    useEffect(() => {
      const dateNow = new Date(Date.now());
      if (dateNow) {
        setDate(getDate(dateNow));
      }

      if (values.repeatOn) {
        setSelectedWeekly(values.repeatOn);
      }
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (!isEdit && values.recurrence !== 'not_repeat') {
        setFieldValue('dueDate', undefined);
      }
      // eslint-disable-next-line
    }, [values.recurrence, isEdit]);

    useEffect(() => {
      if (values.dueDate) {
        setDate(getDate(values.dueDate));
      }
    }, [values.dueDate]);

    useEffect(() => {
      if (date) {
        setRecurrenceOptions([
          { label: 'Does not repeat', value: 'not_repeat' },
          { label: 'Daily', value: 'daily' },
          {
            label: `Weekly on ${
              values.startAt?.weekdayLong || date.weekdayLong
            }`,
            value: 'weekly',
          },
          {
            label: 'Every weekday (Monday to Friday)',
            value: 'every_weekday',
          },
        ]);
      }
    }, [date, values.startAt]);

    function onCheckEnd(selected) {
      setSelectedEnd(selected);
    }

    function onSelectWeekly(selected) {
      if (selectedWeekly.includes(selected)) {
        setSelectedWeekly(selectedWeekly.filter(weekly => weekly !== selected));
      } else {
        setSelectedWeekly([...selectedWeekly, selected]);
      }
    }

    function onCheckMonthly(selected) {
      setSelectedWeekly(null);
      if (selected === 'day') {
        onChangeField('monthlyOnDay', values.monthlyOnDay || date.date);
      } else {
        onChangeField('monthlyOnWeek', values.monthlyOnWeek || date.week);
        onSelectWeekly(date.weekdayLong.toLowerCase());
      }
      setSelectedMonthly(selected);
    }

    function onChangeField(key, value) {
      setFieldValue(key, value);
    }

    const newProps = {
      values,
      date,
      selectedEnd,
      selectedWeekly,
      selectedMonthly,
      repeatTypeOptions,
      recurrenceOptions,
      weekOptions,
      onCheckMonthly,
      onSelectWeekly,
      onCheckEnd,
      onChangeField,
      disabled,
      ...props,
    };

    return <Component {...newProps} />;
  }

  return WithRepeatTicket;
}
