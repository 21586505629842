import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { theme } from 'styled-tools';

import { Button } from '../../../authentication/components/Button';
import { TextField } from '../../../authentication/components/TextField';
import { EditableText } from './EditableText';
import { FormikSingleImageUploader as SingleImageUploader } from './SingleImageUploader';
import { DocumentTitle } from '../../../../components/DocumentTitle';
import { DeleteModal } from '../../../../components/SideBarFilter/DeleteModal';
import { TrashIcon } from '../../../../components/Icons';

const validationSchema = yup.object({
  name: yup.string().required('Company name is required'),
});

function SettingCompanyPage({
  className,
  initialValues = {
    name: '',
    description: '',
    logoFile: null,
  },
  onSubmit,
  previewUrl,
  disabled,
  deleteCompany,
}) {
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  function onOpenDeleteModal() {
    setIsShowDeleteModal(true);
  }

  function onCloseDeleteModal() {
    setIsShowDeleteModal(false);
  }

  return (
    <>
      <DocumentTitle title="General Setting" />
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ setFieldValue }) => (
          <Form className={className} noValidate>
            <h3 className="title">
              General Settings
              {!disabled && (
                <Button className="button" type="submit">
                  Save
                </Button>
              )}
            </h3>
            <div className="content">
              <div className="uploader">
                <SingleImageUploader
                  disabled={disabled}
                  name="logoFile"
                  previewUrl={previewUrl}
                  setFieldValue={setFieldValue}
                />
              </div>
              <div className="field-group">
                <EditableText
                  name="name"
                  placeholder="Enter Company Name"
                  disabled={disabled}
                />
                <TextField
                  id="description"
                  name="description"
                  label="Description and purpose"
                  rows={8}
                  multiline
                  disabled={disabled}
                />
              </div>
              {!disabled && (
                <span
                  type="button"
                  className="delete"
                  onClick={onOpenDeleteModal}
                >
                  <TrashIcon /> Delete Company
                </span>
              )}
            </div>
          </Form>
        )}
      </Formik>
      <DeleteModal
        isOpen={isShowDeleteModal}
        onClose={onCloseDeleteModal}
        onDelete={deleteCompany}
        deleteText="Delete this company?"
      />
    </>
  );
}

const StyledSettingCompanyPage = styled(SettingCompanyPage)`
  padding: 15px 30px 30px;

  > .title {
    display: flex;
    align-items: center;

    margin-bottom: 20px;
  }

  > .title > ${Button} {
    margin-left: auto;
  }

  > .content {
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-gap: 30px;
  }

  > .content > .field-group {
    > ${EditableText} {
      margin-bottom: 16px;
    }
  }

  .logo-uploader {
    width: 250px;
    height: 250px;
    background: white;
  }

   .uploader .remove {
      color: #9fa3ae;     
  }

  @media (max-width: ${theme('breakpoints.tablet')}px) {
    > .content {
      grid-template-columns: auto;

      > .uploader {
        width: 250px;
      }
    }
  }
  
  ${TrashIcon} {
    margin-right: 8px;
  }
  .delete {
    color: red;
    cursor: pointer;
`;

export { StyledSettingCompanyPage as SettingCompanyPage };
