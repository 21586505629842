import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useField } from 'formik';
import { theme } from 'styled-tools';

import { ClearButton } from '../../../authentication/components/Button';
import { FullscreenImagePreview } from './FullscreenImagePreview';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { filehash } from '../../../../lib/utils';
import { CloseIcon, ImageIcon, PlusIcon } from '../../../../components/Icons';
import { TypesImageAndVideo, TypesVideo, TypesImage } from './ImageTypes';

const PictureListWrapper = styled.div`
  position: relative;

  .video {
    object-fit: cover;
    object-position: center;
    border-radius: 6px;
    width: 130px;
    height: 110px;
  }

  > input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
  }

  > .items {
    overflow-x: auto;
    width: 100%;

    display: flex;
    flex-flow: nowrap;

    border-radius: 6px;
    padding: 10px;

    background: #f5f8f8;

    ::-webkit-scrollbar {
      height: 2px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    > .preview,
    > .upload-button {
      overflow: hidden;
      flex: 0 0 130px;

      margin-right: 20px;
      cursor: pointer;
    }

    > .preview > .image,
    > .upload-button {
      border-radius: 6px;
      width: 130px;
      height: 110px;
    }

    > .preview {
      position: relative;

      border: 0;

      text-align: right;
      background: transparent;

      > .image {
        display: block;
      }

      &:hover > .guide {
        opacity: 1;
      }

      > .guide {
        opacity: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        border-radius: 0 0 6px 6px;

        color: white;
        font-size: 12px;
        text-align: center;
        line-height: 22px;

        background: ${theme('colors.primary')};
        transition: opacity 0.2s;
      }

      > .remove {
        position: absolute;
        top: 5px;
        right: 5px;

        padding: 0;

        color: rgba(255, 255, 255, 0.7);
        font-size: 12px;
        font-weight: bold;
        line-height: 1.7;
        text-decoration: underline;

        &:hover {
          background: rgba(0, 0, 0, 0.5);
        }
      }
    }

    > .upload-button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      color: ${theme('colors.primary')};
      background: white;

      z-index: 1;
      cursor: pointer;

      > .text {
        display: block;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
      }
    }

    &::after {
      content: ' ';
      display: block;
      padding: 10px;
    }
  }
`;

export const PictureList = React.memo(
  React.forwardRef(function PictureList(
    { id = 'pictureList', name, ...props },
    ref
  ) {
    const [input, , helpers] = useField(name);
    const [initialIndex, setInitialIndex] = useState(null);
    const isOpen = initialIndex !== null;
    const inputHashList = useMemo(
      () => input.value?.map(({ hash }) => hash) ?? [],
      [input.value]
    );
    const localRef = useRef(null);
    const uploadInput = ref ?? localRef;
    const visibleImages = useMemo(
      () => input.value?.filter(({ removed }) => !removed) ?? [],
      [input.value]
    );
    if (props.display !== 'mix') {
      let i = 0;
      while (i < visibleImages.length) {
        if (JSON.stringify(visibleImages[i]).includes('file')) {
          if (!contains(visibleImages[i].file.name, TypesImageAndVideo)) {
            visibleImages.splice(i, 1);
          } else {
            i += 1;
          }
        } else if (!contains(visibleImages[i].url, TypesImageAndVideo)) {
          visibleImages.splice(i, 1);
        } else {
          i += 1;
        }
      }
    }

    function contains(target, pattern) {
      let status = false;
      for (let i = 0; i < pattern.length; i += 1) {
        if (target.includes(pattern[i])) {
          status = true;
        }
      }
      return status;
    }

    function onClose() {
      setInitialIndex(null);
    }

    function handleChange(e) {
      helpers.setValue(
        input.value.concat(
          Array.from(e.target.files)
            .map(file => {
              return {
                file,
                url: URL.createObjectURL(file),
                hash: filehash(file),
              };
            })
            .filter(({ hash }) => {
              return !inputHashList.includes(hash);
            })
        )
      );

      uploadInput.current.value = '';
    }

    return (
      <PictureListWrapper {...props}>
        <input
          type="file"
          id={id}
          multiple
          onChange={handleChange}
          accept="image/*,video/*"
          ref={uploadInput}
        />
        <div className="items">
          {visibleImages.map(
            (
              {
                url,
                hash,
                file,
                typesVideo = TypesVideo,
                typesImage = TypesImage,
              },
              index
            ) => (
              <div
                className="preview"
                key={hash}
                onClick={() => setInitialIndex(index)}
              >
                {typesImage.some(el => hash.includes(el)) && (
                  <img className="image" src={url} alt="" />
                )}
                {typesVideo.some(el => hash.includes(el)) && (
                  <video className="video" autoPlay muted>
                    <source src={url} type="video/mp4" />
                  </video>
                )}

                <ClearButton
                  className="remove"
                  onClick={e => {
                    e.stopPropagation();

                    if (file) {
                      helpers.setValue(
                        input.value.filter(v => hash !== v.hash)
                      );
                      return;
                    }

                    helpers.setValue(
                      input.value.map(v =>
                        hash === v.hash ? { ...v, removed: true } : v
                      )
                    );
                  }}
                >
                  <CloseIcon />
                </ClearButton>
                <div className="guide">View Picture</div>
              </div>
            )
          )}
          <label className="upload-button" htmlFor={id}>
            <ImageIcon className="icon" size={35} />
            <span className="text">
              <PlusIcon size={10} />
              Upload Image
              <br />
              and Video
            </span>
          </label>
        </div>
        <FullscreenImagePreview
          isOpen={isOpen}
          onClose={onClose}
          images={visibleImages}
          initialIndex={initialIndex}
        />
      </PictureListWrapper>
    );
  })
);

export const PictureListView = React.memo(function PictureListView({
  images = [],
  ...props
}) {
  const [initialIndex, setInitialIndex] = useState(null);
  const isOpen = initialIndex !== null;

  function onClose() {
    setInitialIndex(null);
  }

  return (
    <PictureListWrapper {...props}>
      <div className="items">
        {images.map(
          (
            { hash, filename, url, id, image_type = TypesImageAndVideo },
            index
          ) => (
            <div
              className="preview"
              key={`picture-view-${id}`}
              onClick={() => {
                return (
                  image_type.some(el => filename.includes(el)) &&
                  setInitialIndex(index)
                );
              }}
            >
              {image_type.some(el => filename.includes(el)) && (
                <img className="image" src={url} alt="" />
              )}
              {!image_type.some(el => filename.includes(el)) &&
                !filename.includes('.pdf') && (
                  <a href={url} className="namefiles">
                    {filename
                      .split('/')
                      .pop()
                      .split('?')[0]
                      .split('#')[0]
                      .replace(/%20/g, ' ')}{' '}
                  </a>
                )}
              {filename.includes('.pdf') && (
                <div>
                  <a href={url} className="namefiles" download>
                    {hash &&
                      hash
                        .split('/')
                        .pop()
                        .split('?')[0]
                        .split('#')[0]
                        .slice(0, 26)
                        .replace(/%20/g, ' ')}{' '}
                  </a>
                  <embed src={url} className="pdf" type="application/pdf" />
                </div>
              )}
              {image_type.some(el => filename.includes(el)) && (
                <div className="guide">View Picture</div>
              )}
              {!image_type.some(el => filename.includes(el)) && (
                <div className="guide">Download File</div>
              )}
            </div>
          )
        )}
      </div>

      <FullscreenImagePreview
        isOpen={isOpen}
        onClose={onClose}
        images={images}
        initialIndex={initialIndex}
      />
    </PictureListWrapper>
  );
});
