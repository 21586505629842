import React from 'react';
import styled from 'styled-components';

function ConfirmationPage({ className }) {
  return <section className={className} />;
}

const StyledConfirmationPage = styled(ConfirmationPage)``;

export { StyledConfirmationPage as ConfirmationPage };
