import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import hoist from 'hoist-non-react-statics';
import { useAppClient } from '../../../../lib/AppProvider';

export function withConfirmationPage(Component) {
  function WithConfirmationPage(props) {
    const client = useAppClient();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get('t');
    const id = searchParams.get('id');
    const url = searchParams.get('redirectTo');

    useEffect(() => {
      async function confirmEmail() {
        try {
          await client.confirmEmail({ token, id });
          navigate(
            url ??
              '/auth/sign-in?success=You have confirmed the email. Please sign in to Toolings.',
            { replace: !!url }
          );
        } catch (err) {
          if (!err.isAxiosError) {
            throw err;
          }
          navigate(url ?? '/auth/sign-in?error=Unauthorized action.', {
            replace: !!url,
          });
        }
      }
      confirmEmail();
    }, [token, id, client, url, navigate]);

    return <Component {...props} />;
  }

  hoist(withConfirmationPage, Component);

  WithConfirmationPage.displayName = `withConfirmationPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithConfirmationPage;
}
