import React, { useMemo } from 'react';
import { useField } from 'formik';
import { filehash } from '../../../../lib/utils';

export const DropFileArea = ({ dragging, onDragLeave, setDragging }) => {
  const [input, , helpers] = useField('images');
  const inputHashList = useMemo(
    () => input.value?.map(({ hash }) => hash) ?? [],
    [input.value]
  );

  function onDrop(e) {
    const files = [...e.dataTransfer.items].map(item => item.getAsFile());
    helpers.setValue(
      input.value.concat(
        Array.from(files)
          .map(file => {
            return {
              file,
              url: URL.createObjectURL(file),
              hash: filehash(file),
            };
          })
          .filter(({ hash }) => {
            return !inputHashList.includes(hash);
          })
      )
    );
    setDragging(false);
  }

  return (
    <div
      className={`on-drag-enter ${dragging && 'dragging'}`}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <div className="area-drop">Drop files here !</div>
    </div>
  );
};
