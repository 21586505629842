import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { theme } from 'styled-tools';
import { DateTime } from 'luxon';
import { SaveIcon, CloseFillIcon } from '../../../components/Icons';
import { IconButton } from '../../authentication/components/Button';
import {
  useSalariesAction,
  useSearchRemainingSalaryMembers,
} from '../useCompanySalary';
import { TextField } from '../../authentication/components/TextField';
import { successToast } from '../../../lib/toast';
import { SelectField as Select } from '../../../components/Select/SelectField';
import { DatePicker } from '../../../components/DatePicker';

export const SalaryEditor = React.memo(
  styled(function SalaryEditor({
    salary,
    companyId,
    color,
    setEditSalary,
    setEditSalaryId,
    reloadSalaries,
    ...props
  }) {
    const { members } = useSearchRemainingSalaryMembers(companyId);
    let selectableUsers = members.map(member => ({
      label: `${member.fullName}${
        member.nickName ? `(${member.nickName})` : ''
      }`,
      value: member.id,
    }));
    if (salary) {
      const salaryUser = salary?.user;
      selectableUsers = [
        {
          label: `${salaryUser?.fullName}${
            salaryUser?.nickName ? `(${salaryUser.nickName})` : ''
          }`,
          value: salaryUser.id,
        },
        ...selectableUsers,
      ];
    }
    const positionOptions = [
      { label: 'Project Manager', value: 'project_manager' },
      { label: 'UX/UI Designer', value: 'ux/ui_designer' },
      { label: 'Business Development', value: 'business_development' },
      { label: 'Marketing Officer', value: 'marketing_officer' },
      { label: 'Operation Officer', value: 'operation_officer' },
      { label: 'Legal Officer', value: 'legal_officer' },
      { label: 'HR Officer', value: 'hr_officer' },
      { label: 'Finance Officer', value: 'finance_officer' },
      { label: 'Frontend Developer', value: 'frontend_developer' },
      { label: 'Full Stack Developer', value: 'full_stack_developer' },
      { label: 'Mobile Developer', value: 'mobile_developer' },
      { label: 'Quality Assurance', value: 'quality_assurance' },
      { label: 'DevOps', value: 'devops' },
      {
        label: 'Machine Learning Engineer',
        value: 'machine_learning_engineer',
      },
      { label: 'Solution Architecture', value: 'solution_architecture' },
    ];
    const levelOptions = [
      { label: 'Team Lead', value: 'team_lead' },
      { label: 'Senior', value: 'senior' },
      { label: 'Middle', value: 'middle' },
      { label: 'Junior', value: 'junior' },
    ];

    const initialValues = useMemo(
      () => ({
        startDate: salary?.startDate
          ? DateTime.fromFormat(salary.startDate, 'yyyy-MM-dd').setZone('utc+7')
          : null,
        position:
          positionOptions.find(option => option.label === salary?.position)
            ?.value ?? null,
        level:
          levelOptions.find(level => level.label === salary?.level)?.value ??
          null,
        salary: salary?.salary ?? 0,
        userId: salary?.user?.id ?? null,
      }),
      [salary, positionOptions, levelOptions]
    );

    const { create, update } = useSalariesAction(companyId);
    async function handleSubmit(params) {
      if (salary) {
        await update(salary.id, {
          ...params,
        });
        successToast({ message: 'Employee Salary has been updated.' });
        setEditSalaryId(null);
        reloadSalaries();
        return;
      }

      await create(params);
      successToast({ message: 'Employee Salary has been created.' });
      setEditSalary(false);
      reloadSalaries();
    }

    return (
      <div {...props}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting, setFieldValue, submitForm }) => {
            return (
              <>
                <div className="col account">
                  <Select
                    className="select"
                    options={selectableUsers}
                    value={values.userId}
                    placeholder="Toolings Account"
                    onChange={e => setFieldValue('userId', e.value)}
                  />
                </div>
                <div className="col position">
                  <Select
                    className="select"
                    options={positionOptions}
                    value={values.position}
                    placeholder="Position"
                    onChange={e => setFieldValue('position', e.value)}
                  />
                </div>
                <div className="col level">
                  <Select
                    className="select"
                    options={levelOptions}
                    value={values.level}
                    placeholder="Level"
                    onChange={e => setFieldValue('level', e.value)}
                  />
                </div>
                <div className="col date">
                  <DatePicker
                    className="date-picker"
                    value={values.startDate}
                    onChange={date => setFieldValue('startDate', date)}
                    isClearable
                    hiddenArrowIcon
                    showDateIcon
                  />
                </div>
                <div className="col salary">
                  <TextField
                    type="number"
                    className="no-margin"
                    id="salary"
                    name="salary"
                  />
                </div>
                <div className="col menu">
                  <IconButton
                    type="submit"
                    onClick={submitForm}
                    disabled={
                      isSubmitting ||
                      !values.userId ||
                      !values.position ||
                      !values.level ||
                      !values.startDate ||
                      !values.salary
                    }
                  >
                    <SaveIcon color={color} />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      salary ? setEditSalaryId(null) : setEditSalary(false)
                    }
                  >
                    <CloseFillIcon color="#9fa3ae" />
                  </IconButton>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    );
  })`
    display: grid;
    grid-template-columns: 1fr 180px 100px 140px 120px 60px;
    gap: 20px;

    padding: 15px 10px;

    color: #999;
    font-size: 12px;
    font-weight: bold;

    background: #f5f8f8;
    box-shadow: ${theme('shadow')};

    &.odd-row {
      background: white;
    }

    .col.salary {
      .no-margin input {
        font-size: 14px;
        text-align: right;
        padding-right: 0;
      }
    }

    .col.date .date-picker {
      font-weight: normal;
      font-size: 14px;
    }

    .col .select .input-group .field {
      font-size: 14px;

      .placeholder {
        font-weight: normal;
      }
    }

    > .col {
      display: flex;
      align-items: center;

      &.default-salary-available {
        display: block;

        > .salary-type-title {
          color: black;
        }
      }

      > ${Select} {
        width: 100%;
        margin: 0px;
        font-weight: normal;
      }

      &.default-salary {
        font-size: 16px;
        color: black;
      }
    }

    > .name {
      color: #333;
      font-size: 14px;
      font-weight: normal;
    }

    > .menu {
      display: flex;
      justify-content: space-between;

      > .preview-link {
        cursor: pointer;
      }
    }
  `
);
