import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { theme } from 'styled-tools';
import {
  Icon,
  UserIcon,
  LockIcon,
  SignOutIcon,
} from '../../../components/Icons';
import { SideBarFilter } from '../../../components/SideBarFilter';
import { useAuthentication } from '../../authentication/useAuthentication';

function AccountSideBar({ className }) {
  const { signOut } = useAuthentication();

  return (
    <SideBarFilter className={className}>
      <div className="menu">
        <NavLink className="item" to="./account">
          <UserIcon /> Account Settings
        </NavLink>
        <NavLink className="item" to="./password">
          <LockIcon /> Change Password
        </NavLink>
        <div className="item" onClick={signOut}>
          <SignOutIcon /> Log out
        </div>
      </div>
    </SideBarFilter>
  );
}

const StyledAccountSideBar = styled(AccountSideBar)`
  overflow-y: auto;

  position: sticky;
  top: 0;

  border-right: 1px solid #eee;
  height: 100vh;

  background: white;

  > .header {
    padding: 0 20px;

    > div {
      margin-bottom: 0;
    }

    > .title {
      border-bottom: 2px dashed #eee;
      padding: 20px 0;
    }
  }

  > .menu {
    padding-top: 0 !important;
    padding: 20px;

    > .item:not(:last-child) {
      border-bottom: dashed 1px #c1c3ca;
    }

    > .item {
      display: block;
      padding: 20px 0;

      color: #999;
      font-size: 14px;
      font-weight: bold;
      text-decoration: none;
      cursor: pointer;

      &.active {
        color: #333;

        > ${Icon} {
          color: ${theme('colors.primary')};
        }
      }

      > ${Icon} {
        margin-right: 5px;
      }
    }
  }

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    display: none;
  }
`;

const MemoAccountSideBar = React.memo(StyledAccountSideBar);

export { MemoAccountSideBar as AccountSideBar };
