import React, { useState, useCallback } from 'react';
import hoist from 'hoist-non-react-statics';

export function withSelectCustom(Component) {
  function WithSelectCustom({ onSubmit, ...props }) {
    const [isShow, setIsShow] = useState(false);

    const handleOpen = useCallback(() => {
      setIsShow(true);
    }, [setIsShow]);

    const handleClose = useCallback(() => {
      setIsShow(false);
    }, [setIsShow]);

    const handleSubmit = useCallback(
      epicItem => () => {
        if (onSubmit) {
          onSubmit(epicItem);
        }
      },
      [onSubmit]
    );

    const pageProps = {
      handleOpen,
      handleClose,
      isShow,
      handleSubmit,
    };

    return <Component {...props} {...pageProps} />;
  }
  hoist(WithSelectCustom, Component);

  WithSelectCustom.displayName = `WithSelectCustom(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithSelectCustom;
}
