import React from 'react';
import styled from 'styled-components';

function ColorRadio({ className, value, ...props }) {
  return (
    <div className={className}>
      <div className="input-group">
        <label>
          <input type="radio" {...props} value={value} />
          <span style={{ backgroundColor: value }}>
            <img
              src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/check-icn.svg"
              alt="Checked Icon"
            />
          </span>
        </label>
      </div>
    </div>
  );
}

const StyledColorRadio = styled(ColorRadio)`
  > .title {
    margin: 0 14px 0 0 !important;
    font-size: 14px;
  }

  .input-group {
    div {
      display: inline-block;
    }

    label {
      color: #333;
      font-family: Arial, sans-serif;
      font-size: 14px;

      input[type='radio'] {
        display: none;

        &:checked + span img {
          opacity: 1;
        }
      }

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        margin: 0 4px 0 0;
        vertical-align: middle;
        cursor: pointer;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center;
        text-align: center;
        line-height: 44px;

        img {
          opacity: 0;
          transition: all 0.3s ease;
        }
      }
    }
  }
`;

export { StyledColorRadio as ColorRadio };
