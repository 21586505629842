import React from 'react';
import styled from 'styled-components';
import { EpicAndSprint } from '../../components/EpicAndSprint';

function EpicAndSprintPage({ className, ...props }) {
  return (
    <section className={className}>
      <EpicAndSprint {...props} />
    </section>
  );
}

const StyledEpicAndSprintPage = styled(EpicAndSprintPage)``;

export { StyledEpicAndSprintPage as EpicAndSprintPage };
