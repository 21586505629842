import React, { createContext, useContext, useState } from 'react';
import hoist from 'hoist-non-react-statics';

const AppLayoutContext = createContext();

export function useAppLayout() {
  return useContext(AppLayoutContext);
}

export function withAppLayout(Component) {
  function WithAppLayout(props) {
    const [isShowSidebar, setIsShowSidebar] = useState(
      localStorage.getItem('isShowSidebar') !== 'false'
    );

    function toggleSidebar() {
      setIsShowSidebar(isShow => {
        localStorage.setItem('isShowSidebar', !isShow);
        return !isShow;
      });
    }

    const value = { isShowSidebar, toggleSidebar };

    return (
      <AppLayoutContext.Provider value={value}>
        <Component {...props} />
      </AppLayoutContext.Provider>
    );
  }

  hoist(WithAppLayout, Component);

  WithAppLayout.displayName = `withAppLayout(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithAppLayout;
}
