import React, { useEffect, useState, useContext, useCallback } from 'react';
import { DateTime } from 'luxon';
import hoist from 'hoist-non-react-statics';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { transformMember } from 'features/projects/useProfile';
import { useAppClient } from 'lib/AppProvider';
import ProjectContext from '../../../../store/projectContext';
import { useTicketSearch } from '../../useTicketSearch';
import { useQueryParams } from '../../../../lib/hooks';
import { useProject } from '../../useProjectSearch';
import { useCurrentCompany } from '../../useCurrentCompany';
import { successToast } from '../../../../lib/toast';

// TODO: encapsulate ticket mamnipulate feature in component
export function withProjectDetailPage(Component) {
  function WithProjectDetailPage(props) {
    const { id } = useParams();
    const client = useAppClient();
    const [queryParams, setQueryParams] = useQueryParams();
    const [isSettingProjectModal, setIsSettingProjectModal] = useState(false);
    const { data: project } = useProject(id);
    const company = useCurrentCompany();
    const { projectState, projectDispatch } = useContext(ProjectContext);

    const { isLoading } = useQuery(
      [id, 'get-option-filter'],
      async () => {
        const memberResponse = await client.listProjectMember({
          projectId: id,
        });
        const tagResponse = await client.listProjectTag(id);
        const projects = await client.getProject(id);
        const now = DateTime.local().startOf('day');
        const epics =
          projects?.epics.map(epic => ({
            id: epic.id,
            name: epic.name,
            color: epic.color,
          })) ?? [];
        const sprints = projects?.sprints.map(sprint => ({
          id: sprint.id,
          name: sprint.name,
          goal: sprint.goal,
          color: sprint.color,
          range: {
            start: DateTime.fromFormat(sprint.startDate, 'yyyy-MM-dd'),
            end: DateTime.fromFormat(sprint.endDate, 'yyyy-MM-dd'),
          },
          onSprint:
            DateTime.fromISO(sprint.startDate).startOf('day') <= now &&
            DateTime.fromISO(sprint.endDate).startOf('day') >= now,
        }));

        if (!queryParams.sprintIds && sprints[0]) {
          setQueryParams({ sprintIds: sprints[0].id });
        }
        projectDispatch({
          type: 'SELECT_PROJECT',
          id,
          membersData: memberResponse.data.map(transformMember),
          tagsData: tagResponse.data,
          epicData: epics,
          sprintData: sprints,
        });
      },
      { refetchOnWindowFocus: false, enabled: !!id }
    );

    const onOpenSettingProjectModal = () => {
      setIsSettingProjectModal(true);
    };

    const onCloseSettingProjectModal = () => {
      setIsSettingProjectModal(false);
    };

    const memberIds = queryParams.memberIds?.split(',');
    const tagIds = queryParams.tagIds?.split(',');
    const sprintIds = queryParams.sprintIds?.split(',');
    const epicIds = queryParams.epicIds?.split(',');

    const {
      tickets,
      loading,
      query,
      onChangeQuery,
      updateStatus,
      updateColumn,
      revalidate,
      duplicate,
      archive,
      createColumn,
      archiveColumn,
      onSelectedTicket,
      onCloseTicket,
      onAddTicketWithColumn,
      onAddTicket,
    } = useTicketSearch({
      projectId: id,
      memberIds,
      tagIds,
      sprintIds,
      epicIds,
      canFetch: !isLoading,
    });

    useEffect(() => {
      if (!loading) {
        projectDispatch({ type: 'LIST_TICKETS', tickets });
      }
    }, [loading, tickets, projectDispatch]);

    const allTickets = projectState.tickets;

    const onArchive = useCallback(
      (ticketId, deleteType, projectId) => {
        archive(ticketId, deleteType, projectId);
        onCloseTicketModal();
        successToast({ message: 'The ticket has been archived.' });
      },
      // eslint-disable-next-line
      [archive]
    );

    const onDuplicate = useCallback(
      (ticketId, projectId) => {
        duplicate(ticketId, projectId);
        onCloseTicketModal();
        successToast({ message: 'The ticket has been duplicated.' });
      },
      // eslint-disable-next-line
      [duplicate]
    );

    const onSelectTicket = useCallback(
      (selectedTicketId, selectedIdEachGroup) => {
        onSelectedTicket({ selectedTicketId, selectedIdEachGroup });
      },
      // eslint-disable-next-line
      [onSelectedTicket]
    );

    const onCloseTicketModal = useCallback(() => {
      onCloseTicket();
      // eslint-disable-next-line
    }, [onCloseTicket]);

    const onUpdateColumn = useCallback((column, form) => {
      return updateColumn(column.id, {
        ...form,
        name: form.name ?? column.name,
        order: form.order ?? column.order,
        projectId: id,
      });
      // eslint-disable-next-line
    }, []);

    const onUpdateStatus = useCallback((ticket, status, order, newData) => {
      return updateStatus(ticket.id, { status, order }, newData);
      // eslint-disable-next-line
    }, []);

    const onAddColumn = useCallback(async name => {
      await createColumn({ projectId: id, name });
      successToast({ message: 'The column has been created.' });
      // eslint-disable-next-line
    }, []);

    const onArchiveColumn = useCallback(async columnId => {
      await archiveColumn(columnId, { projectId: id });
      successToast({ message: 'The column has been archived.' });
      // eslint-disable-next-line
    }, []);

    const onAddTicketToColumn = useCallback(
      columnId => {
        onAddTicket();
        onAddTicketWithColumn(columnId);
      },
      [onAddTicketWithColumn, onAddTicket]
    );

    const handleOpenCreateTicket = useCallback(() => {
      onAddTicket();
    }, [onAddTicket]);

    const pageProps = {
      project: project ?? {},
      tickets: allTickets,
      loading,
      query,
      onChangeQuery,
      onOpenSettingProjectModal,
      onCloseSettingProjectModal,
      isSettingProjectModal,
      companyName: company.data?.name ?? '',
      reFetchTickets: revalidate,
      onSelectTicket,
      isOpenTicketModal:
        !!queryParams.selectedTicketId || queryParams.modal === 'create-ticket',
      onCloseTicketModal,
      selectedTicketId: queryParams.selectedTicketId,
      selectedIdEachGroup: queryParams.selectedIdEachGroup,
      handleOpenCreateTicket,
      onUpdateColumn,
      onUpdateStatus,
      onDuplicate,
      onArchive,
      onAddColumn,
      onArchiveColumn,
      onAddTicketToColumn,
      queryParams,
      optionLoading: isLoading,
    };

    return <Component {...props} {...pageProps} />;
  }

  hoist(WithProjectDetailPage, Component);

  WithProjectDetailPage.displayName = `withProjectDetailPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithProjectDetailPage;
}
