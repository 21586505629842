import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';

import { CheckIcon } from '../Icons';

function Checkbox({
  label,
  disabled,
  className,
  classNameLabel,
  type = 'checkbox',
  ...props
}) {
  const checked = props.checked ?? props.defaultChecked;

  return (
    <label className={className}>
      <div className={`checkbox ${checked ? 'check' : ''}`}>
        <input type={type} disabled={disabled} {...props} />
        {checked && <CheckIcon className="icon-check" />}
      </div>

      {label && <div className={`label-check ${classNameLabel}`}>{label}</div>}
    </label>
  );
}

const StyledCheckbox = styled(Checkbox)`
  display: flex;
  align-items: center;

  .check {
    background-color: ${theme('colors.primary')} !important;
  }

  .checkbox {
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: solid 1px #dce0ea;
    background-color: #ffffff;

    input {
      height: 100%;
      width: 100%;
      opacity: 0;
    }

    .icon-check {
      pointer-events: none;
      position: absolute;
      color: white;
      width: 16px;
      height: 16px;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }
  }

  .label-check {
    margin-left: 10px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100px;
  }
`;

export { StyledCheckbox as Checkbox };
