import React, { useState, useCallback } from 'react';
import hoist from 'hoist-non-react-statics';
import { DateTime } from 'luxon';
import { useProjectSprint } from '../../../components/SideBarFilter/withSprintFilter';

export function withSprintSelect(Component) {
  function WithSprintSelect(props) {
    const { data, create } = useProjectSprint({ projectId: props.projectId });
    const [state, setState] = useState({
      startDate: DateTime.utc(),
      endDate: DateTime.utc(),
    });
    const [show, setShow] = useState(false);

    const { startDate, endDate } = state;

    const handleStartDateChange = useCallback(
      date => setState({ ...state, startDate: date }),
      [state]
    );

    const handleEndDateChange = useCallback(
      date => setState({ ...state, endDate: date }),
      [state]
    );

    const onSubmit = useCallback(
      async ({ sprintName, sprintGoal }, { resetForm }) => {
        await create({
          name: sprintName,
          goal: sprintGoal,
          range: {
            start: startDate,
            end: endDate,
          },
        });
        resetForm();
      },
      [startDate, endDate, create]
    );

    const onShow = useCallback(() => setShow(true), []);
    const onHide = useCallback(() => setShow(false), []);

    const pageProps = {
      data,
      handleStartDateChange,
      handleEndDateChange,
      startDate,
      endDate,
      onSubmit,
      onShow,
      onHide,
      show,
      ...props,
    };

    return <Component {...props} {...pageProps} />;
  }
  hoist(WithSprintSelect, Component);

  WithSprintSelect.displayName = `WithSprintSelect(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithSprintSelect;
}
