import React, { useContext, createContext, useReducer, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { cache } from 'swr';
import { useAppClient } from '../../lib/AppProvider';

/**
 *
 * @type {React.Context<{
 *   signIn(params: { email: string, password: string }): Promise<*>,
 *   signOut(): Promise<*>,
 *   resetPassword(params: { id: string, token: string, newPassword: string}): Promise<*>,
 *   forgotPassword(params: { email: string }): Promise<*>,
 *   isAuthenticated: boolean
 * }>}
 */
const AuthenticationContext = createContext({
  isAuthenticated: false,
  user: null,
});

export function AuthenticationProvider({
  children,
  isAuthenticated: initIsAuthenticated,
}) {
  const client = useAppClient();
  const [{ isAuthenticated, user }, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'INIT': {
          return { ...state };
        }
        case 'INIT_AUTHENTICATED': {
          return {
            ...state,
            isAuthenticated: !!action.user,
            user: action.user,
          };
        }
        case 'SIGN_IN': {
          return { ...state, isAuthenticated: true, user: action.user };
        }
        case 'SIGN_OUT': {
          return { ...state, isAuthenticated: false, user: null };
        }
        default: {
          return state;
        }
      }
    },
    { isAuthenticated: initIsAuthenticated }
  );

  async function signIn(params) {
    localStorage.removeItem('currentCompanyId');
    const response = await client.login(params);
    const { email, id, fullName } = response;

    dispatch({ type: 'SIGN_IN', user: { email, id, fullName } });
  }

  async function register({
    email,
    firstName: first_name,
    lastName: last_name,
    password,
    companyName: company_name,
  }) {
    await client.register({
      email,
      first_name,
      last_name,
      password,
      company_name,
    });
  }

  async function signOut() {
    await client.logout();
    cache.clear();
    dispatch({ type: 'SIGN_OUT' });
  }

  async function forgotPassword(params) {
    await client.forgotPassword(params);
  }

  async function resetPassword(params) {
    await client.resetPassword(params);
  }

  const value = {
    isAuthenticated,
    register,
    signIn,
    signOut,
    forgotPassword,
    resetPassword,
  };

  useEffect(() => {
    dispatch({
      type: 'INIT_AUTHENTICATED',
      user: JSON.parse(localStorage.getItem('usr')),
    });
  }, []);

  useEffect(() => {
    if (user) {
      localStorage.setItem('usr', JSON.stringify(user));
      Sentry.setUser({ id: user.id, email: user.email });
      return;
    }

    localStorage.removeItem('usr');
    Sentry.setUser(null);
  }, [user]);

  useEffect(() => {
    client.onAuthStateChange(token => {
      if (!token) {
        cache.clear();
        dispatch({ type: 'SIGN_OUT' });
      }
    });
  }, [client]);

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
}

export function useAuthentication() {
  return useContext(AuthenticationContext);
}
