import useSWR from 'swr';
import { useAppClient } from '../../lib/AppProvider';

export function useProjectInfoAction({ projectId }) {
  const client = useAppClient();

  const { data: informations, revalidate } = useSWR(
    projectId && `informations`,
    () => client.getProjectInfo(projectId),
    { revalidateOnFocus: false }
  );

  return {
    informations: informations ?? null,
    revalidate,
    async create(id, form) {
      await client.createProjectInfo(id, form);
      revalidate();
    },
    async update(id, form) {
      await client.updateProjectInfo(id, form);
      revalidate();
    },
  };
}
