import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import hoist from 'hoist-non-react-statics';
import { useAppClient } from '../../../../lib/AppProvider';
import { successToast } from '../../../../lib/toast';

export function withChangePasswordPage(Component) {
  function WithChangePasswordPage(props) {
    const navigate = useNavigate();
    const client = useAppClient();
    const [error, setError] = useState(null);

    async function onSubmit(form, { resetForm }) {
      client
        .changePassword(form)
        .then(() => {
          successToast({ message: 'The password has been updated.' });
          setError(null);
          resetForm();
        })
        .catch(err => {
          if (!err.isAxiosError) {
            throw err;
          }
          setError(err.response?.data?.message || 'Something went wrong.');
        });
    }

    function onReset() {
      navigate(-1);
    }

    const pageProps = {
      ...props,
      error,
      onSubmit,
      onReset,
    };

    return <Component {...pageProps} />;
  }

  hoist(WithChangePasswordPage, Component);

  WithChangePasswordPage.displayName = `withChangePasswordPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithChangePasswordPage;
}
