import React from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import * as Sentry from '@sentry/react';

import { ToastContainer } from 'react-toastify';
import { App } from './App';
import { theme } from './theme';
import { AuthenticationProvider } from './features/authentication/useAuthentication';
import { AppProvider } from './lib/AppProvider';
import { InternalError } from './components/InternalError';

export function Root({ client }) {
  return (
    <Sentry.ErrorBoundary fallback={InternalError}>
      <AppProvider client={client}>
        <HelmetProvider>
          <BrowserRouter>
            <AuthenticationProvider isAuthenticated={!!client.accessToken}>
              <ThemeProvider theme={theme}>
                <>
                  <App />
                  <ToastContainer
                    position="bottom-right"
                    autoClose={2000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                    pauseOnHover
                  />
                </>
              </ThemeProvider>
            </AuthenticationProvider>
          </BrowserRouter>
        </HelmetProvider>
      </AppProvider>
    </Sentry.ErrorBoundary>
  );
}
