import React, { useState } from 'react';
import hoist from 'hoist-non-react-statics';
import { useNavigate } from 'react-router-dom';
import { useStarredProject } from '../../useProjectSearch';

export function withProjectCard(Component) {
  function WithProjectCard({ project, reloadAllProjects, ...props }) {
    const navigate = useNavigate();
    const {
      createStarredProject,
      deleteStarredProject,
      revalidate,
    } = useStarredProject(project.id);
    const [isFetching, setIsFetching] = useState(false);

    const members = (project.users ?? []).map(user => ({
      id: user.id,
      name: user.fullName,
      avatarUrl: user.avatarUrl,
      initial: `${user.firstName.substr(0, 1)}${user.lastName.substr(0, 1)}`,
    }));

    const pageProps = {
      project,
      members,
      async handleStarredProject(event) {
        try {
          event.stopPropagation();
          if (isFetching) return;
          setIsFetching(true);
          await createStarredProject(project.id);
          revalidate();
          reloadAllProjects();
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        } finally {
          setIsFetching(false);
        }
      },
      async handleUnStarredProject(event) {
        try {
          event.stopPropagation();
          if (isFetching) return;
          setIsFetching(true);
          await deleteStarredProject(project.id);
          revalidate();
          reloadAllProjects();
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        } finally {
          setIsFetching(false);
        }
      },
      handleCardClicked() {
        navigate(`./projects/${project.id}`);
      },
    };

    return <Component {...props} {...pageProps} />;
  }

  hoist(WithProjectCard, Component);

  WithProjectCard.displayName = `WithProjectCard(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithProjectCard;
}
