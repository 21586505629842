import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import hoist from 'hoist-non-react-statics';
import ProjectContext from '../../../../store/projectContext';
import { useCurrentCompany } from '../../../projects/useCurrentCompany';
import { useProject } from '../../../projects/useProjectSearch';
import { useQueryParams } from '../../../../lib/hooks';
import { successToast } from '../../../../lib/toast';
import {
  useDocumentSearch,
  useDocumentDefaultAction,
} from '../../useDocumentSearch';

export function withDocumentListPage(Component) {
  function WithDocumentListPage(props) {
    const { id } = useParams();
    const [queryParams, setQueryParams] = useQueryParams();
    const { data: project } = useProject(id);
    const { data: company } = useCurrentCompany();
    const { projectState, projectDispatch } = useContext(ProjectContext);
    const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
    const [documentId, setDocumentId] = useState(null);

    const {
      documents,
      loading,
      query,
      onChangeQuery,
      revalidate,
      deleteDocument,
    } = useDocumentSearch({
      projectId: id,
    });

    useEffect(() => {
      if (!loading) {
        projectDispatch({ type: 'LIST_DOCUMENTS', documents });
      }
    }, [loading, documents, projectDispatch]);

    const {
      documents: defaultDocuments,
      deleteDefaultDocument,
    } = useDocumentDefaultAction({
      projectId: id,
    });

    const allDocuments = projectState.documents ?? [];

    async function onDeleteDocument() {
      if (documentId) {
        setIsShowDeleteModal(false);
        if (queryParams.deleteDefault === 'true') {
          await deleteDefaultDocument(documentId, { projectId: id });
        } else {
          await deleteDocument(documentId, { projectId: id });
        }

        setQueryParams({
          deleteDefault: null,
        });
        successToast({ message: 'The document has been deleted.' });
      }
    }

    function onOpenDeleteModal(docId, isDefault = false) {
      setIsShowDeleteModal(true);
      setQueryParams({
        deleteDefault: isDefault,
      });
      setDocumentId(docId);
    }

    function onCloseDeleteModal() {
      setQueryParams({
        deleteDefault: null,
      });
      setIsShowDeleteModal(false);
    }

    const pageProps = {
      documents: allDocuments,
      defaultDocuments,
      project,
      loading,
      query,
      onChangeQuery,
      reFetchDocuments: revalidate,
      isOpenDocumentModal: queryParams.modal === 'create-document',
      onSelectDocument(selectedDocumentId) {
        setQueryParams({ selectedDocumentId });
      },
      async onCloseDocumentModal() {
        setQueryParams({
          selectedDocumentType: null,
          selectedDocumentId: null,
          modal: null,
        });
      },
      onDeleteDocument,
      onCloseDeleteModal,
      onOpenDeleteModal,
      selectedDocumentId: queryParams.selectedDocumentId,
      companyName: company?.name,
      selectedDocumentType: queryParams.selectedDocumentType,
      isShowDeleteModal,
    };

    return <Component {...props} {...pageProps} />;
  }

  hoist(WithDocumentListPage, Component);

  WithDocumentListPage.displayName = `withDocumentListPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithDocumentListPage;
}
