import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { theme, ifProp } from 'styled-tools';
import * as clipboard from 'clipboard-polyfill/text';
import { Draggable } from 'react-beautiful-dnd';
import hoistNonReactStatics from 'hoist-non-react-statics/src';
import {
  Menu,
  MenuButton,
  MenuItem as ReachMenuItem,
  MenuList as ReachMenuList,
} from '@reach/menu-button';
import '@reach/menu-button/styles.css';

import {
  WorkIcon,
  ClockIcon,
  FlagIcon,
  PaperClipIcon,
  Icon,
  SubtaskIcon,
  EllipseVerticalIcon,
} from '../Icons';
import { AvatarList } from '../AvatarList';
import { IconButton } from '../../features/authentication/components/Button';
import { successToast } from '../../lib/toast';
import { transformTicketListData } from '../../features/projects/useTicketSearch';
import { DeleteTicketModal } from '../../features/tickets/pages/AllTicketPage/DeleteTicketModal';
import { useAppClient } from '../../lib/AppProvider';
import ProjectContext from '../../store/projectContext';

export const MenuList = styled(ReachMenuList)`
  border: 0;
  min-width: 80px;
  border-radius: 6px;
  padding: 6px 0;

  box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.15);
`;

export const MenuItem = styled(ReachMenuItem).attrs(() => ({
  primary: null,
}))`
  padding: 5px 10px;

  color: ${ifProp('primary', theme('colors.primary'), theme('colors.black'))};

  &:hover {
    color: ${ifProp('primary', theme('colors.primary'), theme('colors.black'))};
    background: #efefef;
  }
`;

const Ticket = React.forwardRef(function Ticket(
  {
    name,
    dueDate,
    color,
    hasSubtask,
    fileCount,
    sprints,
    assignees,
    epic = '',
    tags,
    idEachGroup,
    mainImageUrl,
    hasRecurrence,
    id,
    projectId,
    userEstimate,
    storySprints,
    order,
    userCount,
    ...props
  },
  ref
) {
  const showFooter =
    dueDate || !!userEstimate || assignees.length || fileCount > 0;
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const { duplicate, archive } = useTicketAction();
  const totalHours = Math.floor(userEstimate / 60);
  const totalMinutes = userEstimate % 60;

  function onOpenDeleteModal() {
    setIsShowDeleteModal(true);
  }

  function onCloseDeleteModal() {
    setIsShowDeleteModal(false);
  }

  function onDeleteTicket(deleteType) {
    setIsShowDeleteModal(false);
    onArchive(id, deleteType);
  }

  function copyLink() {
    clipboard.writeText(
      `${window.location.href}&selectedIdEachGroup=${idEachGroup}&selectedTicketId=${id}`
    );
    successToast({
      message: 'The ticket link has been successfully copied.',
    });
  }

  function onArchive(ticketId, deleteType) {
    archive(ticketId, { projectId, deleteType });
    successToast({ message: 'The ticket has been archived.' });
  }

  function onDuplicate(ticketId) {
    duplicate(ticketId, { projectId });
    successToast({ message: 'The ticket has been duplicated.' });
  }

  return (
    <>
      <div {...props} ref={ref}>
        <div className="container">
          <div className="header">
            <strong>#{idEachGroup}</strong>
            <Menu>
              <div onClick={e => e.stopPropagation()}>
                <MenuButton as={IconButton} className="menu">
                  <EllipseVerticalIcon />
                </MenuButton>
              </div>
              <MenuList>
                <MenuItem onSelect={() => {}}>
                  <div
                    onClick={e => {
                      e.stopPropagation();
                      copyLink();
                    }}
                  >
                    Copy link
                  </div>
                </MenuItem>
                <MenuItem onSelect={() => {}}>
                  <div
                    onClick={e => {
                      e.stopPropagation();
                      onDuplicate(id);
                    }}
                  >
                    Duplicate
                  </div>
                </MenuItem>
                <MenuItem onSelect={() => {}}>
                  <div
                    onClick={e => {
                      e.stopPropagation();
                      onOpenDeleteModal();
                    }}
                  >
                    Delete
                  </div>
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
          <div className="sub-header">
            {!!epic && (
              <div className="epic">
                <WorkIcon color={color} />
                {epic}
              </div>
            )}
            {storySprints?.length > 0 && (
              <div className="sprints">
                {storySprints.map((sprint, index) => (
                  <div key={index} className="sprint">
                    <FlagIcon color={sprint.color || color} />
                    {sprint.name}
                  </div>
                ))}
              </div>
            )}
          </div>

          <p className="name">
            {name} {hasSubtask && <SubtaskIcon className="subtask" size={28} />}
          </p>
          {tags.length > 0 && !tags.includes(null) && (
            <div className="tags">
              {tags.map((tag, index) => (
                <div
                  key={index}
                  className="tag"
                  style={{ background: tag.color ? tag.color : '#000' }}
                >
                  {tag.name}
                </div>
              ))}
            </div>
          )}
          {showFooter ? (
            <div className="footer">
              <div>
                <ClockIcon color={color} />
                <div className="due-date">
                  {dueDate ? dueDate.toFormat('d MMM yyyy') : '-'}
                </div>
                <div className="point">
                  {!!totalHours || !!totalMinutes ? (
                    <>
                      {totalHours.toLocaleString()} hr(s){' '}
                      {totalMinutes.toLocaleString()} min(s)
                    </>
                  ) : (
                    '-'
                  )}
                </div>
                {fileCount > 0 && (
                  <div className="file">
                    <PaperClipIcon color={color} size={13} />
                    {fileCount}
                  </div>
                )}
              </div>
              <AvatarList
                className="assignees"
                data={assignees}
                userCount={userCount}
                compact
                hidePlaceholder
              />
            </div>
          ) : null}
        </div>
      </div>
      <DeleteTicketModal
        isOpen={isShowDeleteModal}
        isDeleteRepeat={hasRecurrence}
        onClose={onCloseDeleteModal}
        onDelete={onDeleteTicket}
        deleteText="Delete this ticket?"
      />
    </>
  );
});

const StyledTicket = styled(Ticket)`
  margin-bottom: 10px;

  border-radius: 6px;

  background-color: #ffffff;

  box-shadow: 0 10px 20px 0 rgba(0, 128, 42, 0.08);

  .epic,
  .sprint,
  .file,
  .time-tracking,
  .due-date,
  .point {
    display: inline-block;

    color: #999;
    font-size: 12px;
    line-height: 20px;
    font-weight: bold;
  }

  overflow: hidden;
  .middle {
    margin: auto;
    width: 50%;
    padding: 5px;
  }

  .xls {
    width: 100%;
    height: 180px;
  }

  .box {
    width: 100%;
    height: 100%;
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    word-wrap: break-word;
  }

  .image-cover {
    width: 100%;
    height: 180px;
  }

  > .container {
    padding: 20px;

    > .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: unset !important;
    }

    > .sub-header {
      display: flex;
      column-gap: 5px;
    }

    > .name {
      color: #000;

      &:not(:first-child) {
        margin-top: 20px;
        margin-bottom: 20px;
      }

      > strong {
        color: ${theme('colors.primary')};
      }
    }

    .epic {
      margin-top: 0;
    }

    .sprints {
      display: flex;
      align-items: center;
      margin-top: 0;

      > .subtask {
        margin-left: auto;
        margin-right: 0;
      }
    }

    .tags {
      display: flex;
      align-items: center;
      margin-top: 10px;
      flex-wrap: wrap;

      > .tag {
        background: #999;
        color: white;
        border-radius: 9px;
        margin-right: 4px;
        margin-bottom: 4px;
        padding: 2px 8px;
        font-size: 12px;
        line-height: 20px;
        font-weight: bold;
      }
    }

    .footer {
      display: flex;
      align-items: flex-end;
      margin-top: 10px;

      > .assignees {
        margin-left: auto;
      }
    }

    .point:not(:first-child),
    .file:not(:first-child) {
      border-left: 2px solid #eee;
      margin-left: 8px;
      padding-left: 8px;
    }

    .time-tracking {
      display: block;

      > .time-tracking-today {
        margin-left: 10px;
        cursor: pointer;

        &.not-start {
          color: #ddd;
        }

        &.starting {
          color: ${theme('colors.error')};
        }

        &.pause {
          color: ${theme('colors.warning')};
        }

        &:hover {
          filter: brightness(85%);
        }
      }
    }

    ${Icon} {
      margin-right: 2px;
    }

    .assignees {
      display: flex;

      > .add {
        border: 2px solid #ccc;
        color: #ccc;
        border-radius: 50%;
        padding: 0;
        width: 32px;
        height: 32px;

        background: white;

        > ${Icon} {
          margin-right: 0;
        }
      }
    }
  }
`;

const MemoTicket = React.memo(StyledTicket);

function DraggableTicket({ index, ...props }) {
  return (
    <Draggable draggableId={`ticket-${props.id}`} index={index}>
      {p => (
        <MemoTicket
          {...p.draggableProps}
          {...p.dragHandleProps}
          ref={p.innerRef}
          {...props}
        />
      )}
    </Draggable>
  );
}

hoistNonReactStatics(DraggableTicket, StyledTicket);

export { MemoTicket as Ticket, DraggableTicket };

function useTicketAction() {
  const client = useAppClient();
  const { projectDispatch } = useContext(ProjectContext);

  return {
    async duplicate(id, { projectId }) {
      let ticket = await client.duplicateTicket(id, { projectId });
      ticket = {
        ...ticket,
        epic: ticket?.epic?.name,
        sprints: (ticket?.sprints ?? []).map(sprint => sprint.name),
      };
      projectDispatch({
        type: 'CREATE_TICKET',
        ticket: transformTicketListData(ticket),
      });
    },
    async archive(id, { projectId, deleteType }) {
      await client.archiveTicket(id, { projectId, deleteType });
      projectDispatch({ type: 'REMOVE_TICKET', id });
    },
  };
}
