import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { Form } from 'formik';
import { SelectField as Select } from '../../../../components/Select';
// import { Radio } from '../../../../components/Radio';
// import { TextField } from '../../../authentication/components/TextField';
// import { DatePicker } from '../../../../components/DatePicker';

function RepeatTicket({
  className,
  recurrenceOptions,
  disabled,
  // values,
  // weekOptions,
  // repeatTypeOptions,
  // onCheckEnd,
  // selectedEnd,
  // selectedWeekly,
  // onSelectWeekly,
  // selectedMonthly,
  // onCheckMonthly,
  // onChangeField,
  // date,
}) {
  return (
    <Form className={className}>
      <div className="section row-date">
        {recurrenceOptions && (
          <Select
            noShadow
            className="select-repeat"
            name="recurrence"
            options={recurrenceOptions}
            label="Recurrence"
            size="sm"
            disabled={disabled}
          />
        )}
        {/* {values.recurrence === 'custom' && (
          <>
            <TextField
              name="repeat"
              label="Repeat every"
              size="sm"
              min="0"
              type="number"
              onBlur={e => onChangeField('repeat', Math.max(0, e.target.value))}
              oldLabel
            />
            <Select
              name="repeatType"
              className="date-type"
              options={repeatTypeOptions}
              label=""
              size="sm"
            />
          </>
        )} */}
      </div>
      {/* {values.recurrence === 'custom' && (
        <div className="section">
          <div className="custom-repeat">
            <div>
              Custom repeat every <span>“{values?.repeatType}”</span>
            </div>
            <div className="row">
              {values.repeatType === 'months' && (
                <div>
                  <div>Monthly on</div>
                  <div className="column-list">
                    <Radio
                      className="radio"
                      checked={selectedMonthly === 'day'}
                      value="day"
                      label={`Monthly on day ${date?.day}`}
                      onClick={e => onCheckMonthly(e)}
                    />
                    <Radio
                      className="radio"
                      checked={selectedMonthly === 'week'}
                      value="week"
                      label={`Monthly on the ${date?.weekLong} ${date?.weekdayLong}`}
                      onClick={e => onCheckMonthly(e)}
                    />
                  </div>
                </div>
              )}
              {values.repeatType === 'week' && (
                <div className="week">
                  <div>Repeat on</div>
                  <div className="card-list">
                    {weekOptions.map(({ label, value }) => (
                      <div
                        className={`card ${
                          selectedWeekly.includes(value) ? 'active' : ''
                        }`}
                        key={value}
                        onClick={() => onSelectWeekly(value)}
                      >
                        {label}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div>
                <div>Ends</div>
                <div className="column-list">
                  <div>
                    <Radio
                      className="radio"
                      checked={selectedEnd === 'never'}
                      value="never"
                      label="Never"
                      onClick={e => onCheckEnd(e)}
                    />
                  </div>
                  <div className="custom-group">
                    <Radio
                      className="radio"
                      checked={selectedEnd === 'on'}
                      value="on"
                      label="On"
                      onClick={e => onCheckEnd(e)}
                    />
                    <DatePicker
                      value={values.endAt}
                      className="date-picker"
                      onChange={current => onChangeField('endAt', current)}
                      isClearable
                      hiddenArrowIcon
                      showDateIcon
                      disabled={!(selectedEnd === 'on')}
                    />
                  </div>
                  <div className="custom-group">
                    <Radio
                      className="radio"
                      checked={selectedEnd === 'after'}
                      value="after"
                      label="After"
                      onClick={e => onCheckEnd(e)}
                    />
                    <TextField
                      className="occurrence"
                      size="sm"
                      type="number"
                      name="endOccurrences"
                      min="0"
                      max="60"
                      suffixMsg="Occurrences"
                      onBlur={e =>
                        onChangeField(
                          'endOccurrences',
                          Math.max(0, e.target.value)
                        )
                      }
                      disabled={!(selectedEnd === 'after')}
                      oldLabel
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )} */}
    </Form>
  );
}

const StyledRepeatTicket = styled(RepeatTicket)`
  .section {
    margin-bottom: 20px;

    > .title {
      display: block;

      margin-bottom: 8px;

      color: #c1c3ca;
      font-size: 12px;
      font-weight: normal;
    }

    .field.selected {
      padding-right: 32px;

      > div {
        overflow: hidden;
      }
    }
  }

  .row-date {
    display: grid;
    grid-template-columns: 280px repeat(2, 1fr);
    grid-gap: 10px;

    .date-type {
      margin-top: 20px;
    }

    > .group {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;

      .date-picker {
        width: 100%;
        position: absolute;
      }

      > div {
        position: absolute;
        right: 0;
      }

      .icon-close {
        cursor: pointer;
        background-color: ${theme('colors.primary')};
        color: #fff;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        padding: 6px;
        font-size: 12px;
        line-height: 1;
        text-align: center;
        display: table-cell;
        vertical-align: middle;
        margin-top: 18px;
        margin-right: 2rem;

        &:hover {
          ::after {
            background: black;
          }
        }
      }

      @media (max-width: ${theme('breakpoints.tablet')}px) {
        margin-top: 10px;
        margin-bottom: 20px;
      }
    }

    @media (max-width: ${theme('breakpoints.tablet')}px) {
      grid-template-columns: 100%;
      grid-gap: 20px;
    }

    @media (max-width: ${theme('breakpoints.mobile')}px) {
      > .flag-fields {
        display: block;
      }
    }
  }
`;

export { StyledRepeatTicket as RepeatTicket };
