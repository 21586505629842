import React, { useState, useContext } from 'react';
import hoist from 'hoist-non-react-statics';
import { useNavigate, useParams } from 'react-router-dom';
import ProjectContext from '../../../../store/projectContext';
import { colorOptions } from '../../../../components/ColorPalette/ColorPalette';

import { useCurrentCompany } from '../../useCurrentCompany';
import { useProjectAction, useProject } from '../../useProjectSearch';
import { successToast } from '../../../../lib/toast';
import { ToolingsClient } from '../../../../clients/ToolingsClient';
import { transformMember } from '../../useProfile';

export function withMobileProjectsEditPage(Component) {
  function WithMobileProjectsEditPage({ reFetchTickets, ...props }) {
    const { id: projectId } = useParams();
    const navigate = useNavigate();
    const { projectState, projectDispatch } = useContext(ProjectContext);
    const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
    const { deleteProject } = useProjectAction();
    const { data: company } = useCurrentCompany();
    const { data: project, update } = useProject(projectId);
    const projectMembers = { data: projectState?.members };

    const initialValues = {
      name: project?.name ?? '',
      logoFile: null,
      description: project?.description ?? '',
      color: project?.color ?? colorOptions[0],
      logoUrl: project?.logoUrl,
      members: projectMembers?.data ?? [],
    };
    async function onDelete() {
      await deleteProject(projectId);

      navigate(-1);
      window.location.reload();
    }

    if (!project) return null;
    const pageProps = {
      initialValues,
      isShowDeleteModal,
      companyName: company?.name,
      project,
      onOpenDeleteModal() {
        setIsShowDeleteModal(true);
      },
      onCloseDeleteModal() {
        setIsShowDeleteModal(false);
      },
      async onSubmit({ name, logoFile: logo, description, color, members }) {
        await update({
          id: projectId,
          name,
          logo,
          description,
          color,
          members,
        });
        if (reFetchTickets) {
          reFetchTickets();
        }
        const client = new ToolingsClient();
        const memberResponse = await client.listProjectMember({ projectId });

        projectDispatch({
          type: 'UPDATE_MEMBERS',
          data: memberResponse.data.map(transformMember),
        });

        successToast({ message: 'The project has been updated.' });
        navigate(-1);
      },
      onDelete,
      ...props,
    };
    return <Component {...pageProps} />;
  }
  hoist(WithMobileProjectsEditPage, Component);

  WithMobileProjectsEditPage.displayName = `WithMobileProjectsEditPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithMobileProjectsEditPage;
}
