import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { AccountContent } from '../../components/AccountContent';
import { PasswordField } from '../../../authentication/components/PasswordField';

const initialValues = {
  oldPassword: '',
  newPassword: '',
  newPasswordConfirmation: '',
};

const validationSchema = yup.object({
  oldPassword: yup.string().required('Old password is required.'),
  newPassword: yup
    .string()
    .matches(
      '^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$',
      'Password should contain at least one character, one number, minimum length 8 character.'
    )
    .required('New password is required.'),
  newPasswordConfirmation: yup
    .string()
    .oneOf([yup.ref('newPassword')], 'Confirm password should be matched.')
    .required('Please confirm your password'),
});

function ChangePasswordPage({ className, onSubmit, error, onReset }) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {() => (
        <Form noValidate>
          <AccountContent
            className={className}
            title="Change Password"
            onReset={onReset}
          >
            <div className="contents">
              <div className="title-password">Old password</div>
              <PasswordField
                id="oldPassword"
                name="oldPassword"
                placeholder="Old Password"
              />
              <div className="title-password">New password</div>
              <PasswordField
                id="newPassword"
                name="newPassword"
                placeholder="New Password"
              />
              <div className="title-password">Confirm new password</div>
              <PasswordField
                id="newPasswordConfirmation"
                name="newPasswordConfirmation"
                placeholder="Confirm New Password"
              />
              {error && <div className="error">{error}</div>}
            </div>
          </AccountContent>
        </Form>
      )}
    </Formik>
  );
}

const StyledChangePasswordPage = styled(ChangePasswordPage)`
  > .contents {
    max-width: 360px;
    padding: 20px;

    #oldPassword,
    #newPassword,
    #newPassword {
      margin-bottom: 20px;
    }

    > .old-password {
      margin-bottom: 30px;
    }

    > .textfield-password {
      margin-top: 10px;
    }

    > .title-password {
      margin-bottom: 10px;
      color: #c1c3ca;
      font-size: 12px;
    }

    > .error {
      margin-top: 30px;
      color: ${theme('colors.error')};
    }
  }
`;

export { StyledChangePasswordPage as ChangePasswordPage };
