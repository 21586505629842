import styled from 'styled-components';
import { Accordion } from '@reach/accordion';

import '@reach/accordion/styles.css';
import { IconButton } from '../../features/authentication/components/Button';

export const Header = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap

  border-bottom: 2px dashed #eee;
  padding: 15px 20px;

  font-size: 18px;
  font-weight: bold;
  line-height: 30px;

  > .avatar {
    height: 32px;
    margin-right: 10px;
  }

  > ${IconButton} {
    font-size: 28px;
  }
`;

export const BlockHeader = styled.div`
  display: block;

  border-bottom: 2px dashed #eee;
  padding: 15px 20px;

  font-size: 18px;
  font-weight: bold;
  line-height: 30px;
`;

export const SideBarFilter = styled.div`
  [data-state='collapsed'] {
    .toggle {
      transform: rotateZ(180deg);
    }
  }
`;

export const FilterGroup = styled(Accordion).attrs(() => ({
  multiple: true,
  collapsible: true,
  defaultIndex: [0, 1, 2, 3, 4],
}))``;
