import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import * as yup from 'yup';
import { Formik } from 'formik';
import { PermissionGuard } from 'features/authentication/hocs/withPermission';
import { SelectCustom } from './SelectCustom';
import { withSprintSelect } from './withSprintSelect';
import { TextField } from '../../authentication/components/TextField';
import { Button } from '../../authentication/components/Button';
import { DatePicker } from '../../../components/DatePicker';

const initialValues = {
  sprintName: '',
  sprintGoal: '',
};

const validationSchema = yup.object({
  sprintName: yup.string().required('Sprint name is required'),
  sprintGoal: yup.string(),
});

function SprintSelect({
  className,
  handleStartDateChange,
  handleEndDateChange,
  startDate,
  endDate,
  data = [],
  onSubmit,
  value,
  onChange,
  onShow,
  onHide,
  show,
}) {
  return (
    <SelectCustom
      label="Sprints"
      placeHolder="Select Sprint"
      handleSelect={e => {
        onChange(
          value.includes(e.id)
            ? value.filter(v => v !== e.id)
            : value.concat(e.id)
        );
      }}
      selectedItem={value?.map(v => data.find(d => d.id === v)).filter(Boolean)}
      handleDelete={e => onChange(value.filter(d => d !== e.id))}
      data={data}
    >
      <div className={className}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ submitForm }) => (
            <>
              {show ? (
                <div className="form">
                  <TextField
                    id="sprintName"
                    name="sprintName"
                    label="Sprint Name"
                  />
                  <TextField
                    id="sprintGoal"
                    name="sprintGoal"
                    label="Sprint Goal"
                    multiline
                  />
                  <div className="date-picker">
                    <DatePicker
                      className="start-date-picker"
                      label="Start Date"
                      value={startDate}
                      onChange={handleStartDateChange}
                    />
                    <DatePicker
                      className="end-date-picker"
                      label="End Date"
                      value={endDate}
                      onChange={handleEndDateChange}
                    />
                  </div>
                  <footer className="footer">
                    <span className="cancel-btn" onClick={onHide}>
                      Cancel
                    </span>
                    <Button className="add-btn" onClick={submitForm}>
                      + Add
                    </Button>
                  </footer>
                </div>
              ) : (
                <PermissionGuard allow="project-sprint:write">
                  <span className="add-sprint-btn" onClick={onShow}>
                    + Add new sprint
                  </span>
                </PermissionGuard>
              )}
            </>
          )}
        </Formik>
      </div>
    </SelectCustom>
  );
}

const StyledSprintSelect = styled(SprintSelect)`
  .form {
    .date-picker {
      margin-bottom: 16px;
      display: flex;
      flex-direction: column;

      > .start-date-picker {
        margin-bottom: 16px;
      }

      > .end-date-picker {
        margin-bottom: 16px;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .cancel-btn {
      color: ${theme('colors.primary')};
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .add-sprint-btn {
    color: ${theme('colors.primary')};
    cursor: pointer;
  }
`;

const ConnectSprintSelect = withSprintSelect(StyledSprintSelect);

export { ConnectSprintSelect as SprintSelect };
