import React from 'react';
import styled from 'styled-components';

import BaseDatePicker from 'react-datepicker';
import { theme, ifProp, switchProp } from 'styled-tools';
import { DateTime } from 'luxon';

import { ArrowUpIcon, DatepickerIcon } from './Icons';

import 'react-datepicker/dist/react-datepicker.css';

export const DatePickerFilter = React.memo(
  styled(function DatePickerFilter({
    className,
    placeholder,
    label,
    value,
    onChange,
    showTimeSelect,
    dateFormat = 'dd MMM yyyy',
    hiddenArrowIcon,
    showDateIcon,
    name,
    isClearable,
    ...props
  }) {
    const selected = value?.toJSDate();
    const datePickerFormat = showTimeSelect ? 'dd MMMM yyyy HH:mm' : dateFormat;

    function handleChange(date) {
      if (date !== null) {
        onChange(DateTime.fromJSDate(date));
      } else {
        onChange(undefined);
      }
    }

    return (
      <div className={className}>
        {label && (
          <label htmlFor={props.id} className="label">
            {label}
          </label>
        )}
        <div className="input-group">
          <BaseDatePicker
            selected={selected}
            placeholderText={placeholder}
            onChange={handleChange}
            dateFormat={datePickerFormat}
            showTimeSelect={showTimeSelect}
            isClearable={!props.disabled && isClearable}
            showYearDropdown
            popperPlacement="bottom"
            popperModifiers={{
              flip: {
                behavior: ['bottom'], // don't allow it to flip to be above
              },
              preventOverflow: {
                enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
              },
              hide: {
                enabled: false, // turn off since needs preventOverflow to be enabled
              },
            }}
            {...props}
          />
          {showDateIcon && <DatepickerIcon className="icon" />}
          {!props.disabled && !hiddenArrowIcon && <ArrowUpIcon />}
        </div>
      </div>
    );
  })`
    > .label {
      display: block;
      font-size: 12px;

      margin-bottom: 8px;

      color: #c1c3ca;
    }

    > .input-group {
      position: relative;

      .react-datepicker-popper {
        z-index: 20;
      }
      .popover {
        display: block;
      }

      .react-datepicker {
        display: flex;
      }

      .react-datepicker-wrapper {
        width: 100%;
      }

      input {
        border-radius: 6px;
        border: 1px solid ${ifProp('error', theme('colors.error'), '#dce0ea')};
        width: 260px;
        padding: 10px ${ifProp('hasSuffix', '50px', '10px')} 10px
          ${ifProp('hasPrefix', '50px', '10px')};

        font-size: ${switchProp('size', { sm: '14px' }, 'inherit')};
        text-align: left;

        background: #ffffff;

        &:focus,
        &:active {
          outline-color: ${theme('colors.primary')};
        }

        &:focus + .popover {
          display: block;
        }

        > .placeholder {
          color: #c1c3ca;
        }

        &:disabled {
          background: #efefef;
        }

        &:disabled {
          background: #efefef;
        }
      }

      .field.has-error {
        border-color: ${theme('colors.error')};
      }

      > select {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }

      > select:active + .field,
      > select:focus + .field {
        border-color: ${theme('colors.primary')};
      }

      svg {
        width: 14px;
        height: 14px;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: ${ifProp(
          'showDateIcon',
          'translateY(-50%)',
          'translateY(-50%) rotateZ(180deg)'
        )};
        color: ${theme('colors.primary')};
      }
    }

    .react-datepicker__close-icon {
      right: 25px;

      ::after {
        background: ${theme('colors.primary')};
      }

      &:hover {
        ::after {
          background: black;
        }
      }
    }
  `
);
