import React from 'react';
import { Outlet } from 'react-router-dom';
import { SignInPage } from './pages/SignInPage';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage';
import { ResetPasswordPage } from './pages/ResetPasswordPage';
import { RegistrationPage } from './pages/RegistrationPage';
import { AcceptInvitationPage } from './pages/AcceptInvitationPage';
import { ConfirmationPage } from './pages/ConfirmationPage';
import { withUnauthenticationGuard } from './hocs/withUnauthenticationGuard';

const Auth = () => <Outlet />;
const AuthenticationWithUnAuthGuard = withUnauthenticationGuard(Auth);

export const AuthenticationRoutes = {
  path: 'auth',
  element: <AuthenticationWithUnAuthGuard />,
  children: [
    { path: 'sign-in', element: <SignInPage /> },
    { path: 'forgot-password', element: <ForgotPasswordPage /> },
    { path: 'reset-password', element: <ResetPasswordPage /> },
    { path: 'registration', element: <RegistrationPage /> },
    { path: 'accept_invitation', element: <AcceptInvitationPage /> },
    { path: 'confirmation', element: <ConfirmationPage /> },
  ],
};
