import React, { useMemo } from 'react';
import styled from 'styled-components';
// import { EditIcon } from '../Icons';
import { Avatar } from '../Avatar';

import '@reach/accordion/styles.css';
import { Indicator } from './Indicator';
import { BaseFilter } from './BaseFilter';

function MemberFilter({
  className,
  members,
  selectedIds,
  query,
  memoQuery,
  onSelect,
  onSearch,
  onSelectAll,
  isLoading,
}) {
  const memberOptions = useMemo(
    () =>
      members
        .map(member => {
          const isChecked = selectedIds.includes(member.id);

          return {
            rank:
              member.name.charCodeAt(0) +
              (isChecked ? 100 : 0) +
              (member.isSelf ? 50 : 0),
            onSelect(e) {
              e.stopPropagation();

              onSelect(member.id);
            },
            avatar: {
              initial: member.initial,
              name: member.name,
              avatarUrl: member.avatarUrl,
            },
            isChecked,
            id: member.id,
            name: member.name,
            nickName: member.nickName,
            isShow:
              isChecked ||
              !memoQuery ||
              member.name
                .toLowerCase()
                .includes(memoQuery.toLocaleLowerCase()) ||
              member.nickName
                .toLowerCase()
                .includes(memoQuery.toLocaleLowerCase()),
          };
        })
        .sort((x, y) => y.rank - x.rank),
    [members, selectedIds, memoQuery, onSelect]
  );

  return (
    <BaseFilter
      className={className}
      title="Members"
      query={query}
      onSearch={onSearch}
      onSelectAll={onSelectAll}
      isLoading={isLoading}
    >
      {memberOptions.map(member => (
        <Item
          key={member.id}
          style={{ display: !member.isShow && 'none' }}
          onClick={member.onSelect}
        >
          <Avatar {...member.avatar} />
          <h5 className="name">
            {member.nickName && `[${member.nickName}]`} {member.name}
          </h5>
          {/* <div className="actions">
            <button type="button" className="btn">
              <EditIcon />
            </button>
          </div> */}
          <Indicator checked={member.isChecked} onClick={member.onSelect} />
        </Item>
      ))}
    </BaseFilter>
  );
}

const Item = styled.div`
  display: flex;
  align-items: center;

  padding: 10px 0;
  cursor: pointer;

  > .name {
    overflow: hidden;

    flex-grow: 1;
    flex-shrink: 1;

    padding: 0 10px;

    white-space: nowrap;
    text-overflow: ellipsis;
  }

  > .actions {
    display: none;
    flex-grow: 1;

    margin-left: auto;
    text-align: right;

    white-space: nowrap;

    > .btn {
      margin: 0 1px;

      border: 0;
      color: #ddd;

      background: transparent;

      cursor: pointer;

      &:hover {
        color: #aaa;
      }
    }
  }

  &:hover > .actions {
    display: block;
  }

  ${Indicator} {
    flex: 0 0 24px;
  }
`;

const StyledMemberFilter = styled(MemberFilter)`
  .filter-list {
    padding-bottom: 10px;
  }
`;

const MemoMemberFilter = React.memo(StyledMemberFilter);

export { MemoMemberFilter as MemberFilter };
