import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import hoist from 'hoist-non-react-statics';
import { successToast } from '../../../../lib/toast';
import { useProfile } from '../../../projects/useProfile';

export function withSettingAccountPage(Component) {
  function WithSettingAccountPage(props) {
    const navigate = useNavigate();
    const { data: profile, update } = useProfile();
    async function onSubmit(form) {
      await update(form);
      successToast({ message: 'The account has been updated.' });
    }

    const { initialAvatar, ...initialValues } = useMemo(
      () => ({
        firstName: profile?.firstName ?? '',
        lastName: profile?.lastName ?? '',
        nickName: profile?.nickName ?? '',
        email: profile?.email ?? '',
        initialAvatar: profile?.avatarUrl,
        hasImageDelete: false,
      }),
      [profile]
    );

    function onReset() {
      navigate(-1);
    }

    const pageProps = {
      ...props,
      loading: !profile,
      initialValues,
      initialAvatar,
      onSubmit,
      onReset,
    };

    return <Component {...pageProps} />;
  }

  hoist(WithSettingAccountPage, Component);

  WithSettingAccountPage.displayName = `withSettingAccountPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithSettingAccountPage;
}
