import React from 'react';
import styled from 'styled-components';
import { Avatar } from '../../../../components/Avatar';
import { CompanySettingModal } from '../../../projects/components/CompanySettingModal';

export const CompaniesListPage = styled(
  ({
    className,
    companies = [],
    isSettingCompanyModal,
    onCloseSettingProjectModal,
  }) => {
    return (
      <div className={className}>
        <div className="wrapper-companies-list">
          {companies.map(company => (
            <a
              key={company.id}
              className="company-item"
              href={`/company/${company.id}?mobileNav=true`}
            >
              <Avatar
                className="rectangle-avatar"
                name={company.name}
                initial={company.initial}
                avatarUrl={company.logoUrl}
                noTooltip
              />
              <div className="company-name">{company.name}</div>
            </a>
          ))}
        </div>
        <CompanySettingModal
          isOpen={isSettingCompanyModal}
          onClose={onCloseSettingProjectModal}
        />
      </div>
    );
  }
)`
  padding-top: 60px;

  > .wrapper-companies-list {
    padding: 20px 15px;

    > .company-item {
      height: 66px;
      background: white;
      width: 100%;
      border-radius: 6px;
      box-shadow: 0 5px 10px 0 rgba(58, 58, 58, 0.05);
      display: flex;
      align-items: center;
      padding: 15px;
      margin-bottom: 10px;
      text-decoration: none;
      color: black;

      > .rectangle-avatar {
        margin-right: 10px;

        > .image {
          width: 36px;
          height: 36px;
          border-radius: 6px !important;
        }
      }

      > .company-name {
        font-size: 1rem;
        font-weight: bold;
        text-decoration: none;
      }
    }
  }
`;
