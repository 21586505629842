import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { Link } from 'react-router-dom';

import { Logo } from '../../../../components/Logo';
import { HomeIcon, EmailIcon, AlertIcon } from '../../../../components/Icons';
import { TextField } from '../../components/TextField';
import { Button } from '../../components/Button';
import { AuthenticationLayout } from '../../components/AuthenticationLayout';

const initialValues = {
  password: '',
  confirmedPassword: '',
};

const validationSchema = yup.object({
  password: yup
    .string()
    .matches(
      '^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$',
      'Password should contain at least one character, one number, minimum length 8 character.'
    )
    .required('Password is required'),
  confirmedPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Confirm password should be matched.')
    .required('Please confirm your password'),
});

function ResetPasswordPage({ className, onResetPassword, error }) {
  return (
    <AuthenticationLayout className={className}>
      <div className="container">
        <header className="header">
          <Logo />
        </header>
        <div className="form">
          <h4 className="title">Reset Password</h4>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onResetPassword}
          >
            {() => (
              <Form className="sign-in-form">
                <TextField
                  PrefixIcon={EmailIcon}
                  SuffixIcon={AlertIcon}
                  id="password"
                  type="password"
                  name="password"
                  label="Your New Password"
                />
                <TextField
                  PrefixIcon={EmailIcon}
                  SuffixIcon={AlertIcon}
                  id="confirm-password"
                  type="password"
                  name="confirmedPassword"
                  placeholder="Confirm Your New Passoword"
                />
                <Button className="submit" type="submit">
                  Save
                </Button>
              </Form>
            )}
          </Formik>
        </div>
        <footer className="footer">
          <Link className="link" to="/auth/registration">
            <HomeIcon />
            Register
          </Link>
          <Link className="link" to="/auth/sign-in">
            Login
          </Link>
        </footer>
        {error && <div className="error">{error}</div>}
      </div>
    </AuthenticationLayout>
  );
}

const StyledResetPasswordPage = styled(ResetPasswordPage)`
  > .container {
    > .form {
      display: flex;
      flex-direction: column;

      > .title {
        font-size: 18px;
        margin: 0 0 10px;
      }

      > .description {
        font-size: 12px;
      }

      > .sign-in-form {
        display: flex;
        flex-direction: column;
        margin: 12px 0;

        > .submit {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    > .error {
      padding: 30px 0;

      color: ${theme('colors.error')};
      text-align: center;
      font-weight: bold;
    }
  }

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    > .container > .form {
      padding: 0 15px;
    }
  }
`;

export { StyledResetPasswordPage as ResetPasswordPage };
