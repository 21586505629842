import styled from 'styled-components';
import { switchProp, theme, ifProp } from 'styled-tools';
import { Select } from './SelectView';

const StyledSelect = styled(Select)`
  > .input-group {
    position: relative;

    > .field {
      border-radius: 6px;
      border: 1px solid ${ifProp('error', theme('colors.error'), '#dce0ea')};
      width: 100%;
      padding: 10px 40px 10px 15px;

      color: #333;
      font-size: ${switchProp('size', { sm: '12px' }, 'inherit')};

      background: white;

      &.error {
        border-color: red;
      }

      > .placeholder {
        color: #9fa3ae;
      }
    }

    .field.disabled {
      background: #efefef;
      cursor: not-allowed;
    }

    > select {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    > select:active + .field,
    > select:focus + .field {
      border-color: ${theme('colors.primary')};
    }

    svg {
      width: 10px;
      height: 10px;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%) rotateZ(180deg);
      color: ${theme('colors.secondary')};
    }
  }

  > .error-msg {
    color: red;
    font-size: 14px;
    margin: 5px 0 0;
  }
`;

export { StyledSelect as Select };
