import React from 'react';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import { theme } from 'styled-tools';

import { SettingSideBar } from './components/SettingSideBar';
import { useCurrentCompany } from '../projects/useCurrentCompany';

function SettingLayout({ className }) {
  const { data } = useCurrentCompany();

  return (
    <div className={className}>
      <SettingSideBar title={data?.name ?? ''} />
      <Outlet />
    </div>
  );
}

const StyledSettingLayout = styled(SettingLayout)`
  display: grid;
  grid-template-columns: 290px 1fr;

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    grid-template-columns: auto;
  }
`;

const MemoSettingLayout = React.memo(StyledSettingLayout);

export { MemoSettingLayout as SettingLayout };
