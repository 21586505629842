import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import Portal from '@reach/portal';
import { useTooltip, TooltipPopup } from '@reach/tooltip';
import strToColor from 'string-to-color';

import '@reach/tooltip/styles.css';
import { prop, switchProp } from 'styled-tools';

const centered = (triggerRect, tooltipRect) => {
  const triggerCenter = triggerRect.left + triggerRect.width / 2;
  const left = triggerCenter - tooltipRect.width / 2;
  const maxLeft = window.innerWidth - tooltipRect.width - 2;
  return {
    left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
    top: triggerRect.top - tooltipRect.height - 8,
  };
};

const Avatar = styled(function Avatar({
  avatarUrl,
  name,
  className,
  initial,
  noTooltip,
  isSquare,
  ...props
}) {
  const [trigger, tooltip] = useTooltip();
  const [error, setError] = useState(false);
  const { isVisible, triggerRect } = tooltip;
  const nameToColor = name
    ?.match(/^[^(]+/g)[0]
    .split(']')
    .slice(-1)[0]
    .trim();
  const style = useMemo(() => ({ background: strToColor(nameToColor) }), [
    nameToColor,
  ]);
  const isShowInitial = error || !avatarUrl;

  return (
    <>
      <div className={className} {...trigger} {...props}>
        {!isShowInitial ? (
          <img
            className="image"
            alt={name}
            width={isSquare ? '36' : '32'}
            height={isSquare ? '36' : '32'}
            src={avatarUrl}
            onError={() => setError(true)}
            draggable={false}
            style={
              isSquare
                ? { borderRadius: '6px', border: 'solid 1px #dce0' }
                : { borderRadius: '32px' }
            }
          />
        ) : (
          <div className="image initial" style={style}>
            {initial}
          </div>
        )}
      </div>
      {!noTooltip && isVisible && (
        // The Triangle. We position it relative to the trigger, not the popup
        // so that collisions don't have a triangle pointing off to nowhere.
        // Using a Portal may seem a little extreme, but we can keep the
        // positioning logic simpler here instead of needing to consider
        // the popup's position relative to the trigger and collisions
        <Portal>
          <div
            style={{
              position: 'absolute',
              left: triggerRect && triggerRect.left - 6 + triggerRect.width / 2,
              top: triggerRect && triggerRect.top - window.scrollY - 8,
              width: 0,
              height: 0,
              borderLeft: '6px solid transparent',
              borderRight: '6px solid transparent',
              borderTop: '6px solid black',
              zIndex: 3,
            }}
          />
        </Portal>
      )}
      {!noTooltip && (
        <TooltipPopup
          {...tooltip}
          label={name}
          aria-label={name}
          style={{
            background: 'black',
            color: 'white',
            border: 'none',
            borderRadius: '3px',
            padding: '0.5em 1em',
            zIndex: 10000,
          }}
          position={centered}
        />
      )}
    </>
  );
})`
  > .image {
    ${switchProp(
      prop('size'),
      {
        lg: css`
          border-radius: 52px;
          width: 52px;
          height: 52px;

          &.initial {
            font-size: 18px;
            line-height: 52px;
          }
        `,
      },
      css`
        border-radius: 32px;
        width: 32px;
        height: 32px;

        &.initial {
          font-size: 12px;
          line-height: 32px;
        }
      `
    )}

    &.initial {
      color: white;
      font-weight: bold;
      text-align: center;

      background: white;
      user-select: none;
    }
  }
`;

const MemoAvatar = React.memo(Avatar);

export { MemoAvatar as Avatar };
