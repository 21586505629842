import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { theme } from 'styled-tools';
import { DateTime } from 'luxon';
import { SaveIcon, CloseFillIcon } from '../../../components/Icons';
import { IconButton } from '../../authentication/components/Button';
import { useExpensesAction } from '../useCompanyExpense';
import { TextField } from '../../authentication/components/TextField';
import { successToast, errorToast } from '../../../lib/toast';
import { SelectField as Select } from '../../../components/Select/SelectField';
import { DatePicker } from '../../../components/DatePicker';
import { TypeSelect } from './TypeSelect';
import { ProjectSelect } from './ProjectSelect';

export const ExpenseEditor = React.memo(
  styled(function ExpenseEditor({
    expense,
    companyId,
    color,
    setEditExpense,
    setEditExpenseId,
    reloadExpenses,
    ...props
  }) {
    const teamOptions = [
      { label: 'All Team', value: 'all_team' },
      { label: 'Business Development', value: 'business_development' },
      { label: 'Project Management', value: 'project_management' },
      { label: 'Finance', value: 'finance' },
      { label: 'HR', value: 'hr' },
      { label: 'Legal', value: 'legal' },
      { label: 'Marketing', value: 'marketing' },
      { label: 'Development', value: 'development' },
      { label: 'UX/UI Designer', value: 'ux/ui_designer' },
      { label: 'Admin', value: 'admin' },
    ];

    const initialValues = useMemo(
      () => ({
        date: expense?.date
          ? DateTime.fromFormat(expense.date, 'yyyy-MM-dd').setZone('utc+7')
          : null,
        description: expense?.description ?? '',
        team:
          teamOptions.find(option => option.label === expense?.team)?.value ??
          teamOptions[0].value,
        amount: expense?.amount ?? '',
        companyExpenseProjectId: expense?.companyExpenseProject?.id ?? '',
        companyExpenseTypeId: expense?.companyExpenseType?.id ?? '',
        name: expense?.name ?? '',
      }),
      [expense, teamOptions]
    );

    const { create, update } = useExpensesAction(companyId);
    async function handleSubmit(params) {
      if (expense) {
        const response = await update(expense.id, {
          ...params,
        });
        if (response?.errors) {
          errorToast({
            message:
              response?.errors?.detail ??
              response?.errors?.map(err => err.detail)?.join(','),
          });
        } else {
          successToast({ message: 'Expense has been updated.' });
          setEditExpenseId(null);
          reloadExpenses();
        }
        return;
      }

      const response = await create(params);
      if (response?.errors) {
        errorToast({
          message:
            response?.errors?.detail ??
            response?.errors?.map(err => err.detail)?.join(','),
        });
      } else {
        successToast({ message: 'Expense has been created.' });
        setEditExpense(false);
        reloadExpenses();
      }
    }

    return (
      <div {...props}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting, setFieldValue, submitForm }) => {
            return (
              <>
                <div
                  className="col date"
                  style={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <DatePicker
                    value={values.date}
                    onChange={date => setFieldValue('date', date)}
                    isClearable
                    showDateIcon
                    hiddenArrowIcon
                  />
                </div>

                <div
                  className="col project"
                  style={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  <ProjectSelect
                    value={values.companyExpenseProjectId}
                    companyId={companyId}
                    onChange={id =>
                      setFieldValue('companyExpenseProjectId', id)
                    }
                  />
                </div>
                <div
                  className="col type"
                  style={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  <TypeSelect
                    value={values.companyExpenseTypeId}
                    companyId={companyId}
                    onChange={id => setFieldValue('companyExpenseTypeId', id)}
                  />
                </div>
                <div className="col description">
                  <TextField
                    className="no-margin"
                    id="description"
                    name="description"
                  />
                </div>
                <div
                  className="col amount"
                  style={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  <TextField
                    type="number"
                    className="no-margin"
                    id="amount"
                    name="amount"
                  />
                </div>
                <div
                  className="col team"
                  style={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  <Select
                    options={teamOptions}
                    value={values.team}
                    noShadow
                    onChange={e => setFieldValue('team', e.value)}
                  />
                </div>
                <div style={{ display: 'flex', gap: '6px' }}>
                  <IconButton
                    type="submit"
                    onClick={submitForm}
                    disabled={isSubmitting}
                  >
                    <SaveIcon color={color} />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      expense ? setEditExpenseId(null) : setEditExpense(false)
                    }
                  >
                    <CloseFillIcon size={20} color="#9fa3ae" />
                  </IconButton>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    );
  })`
    display: grid;
    grid-template-columns: 110px 110px 110px 0.9fr 100px 173px 30px;
    align-items: stretch;
    gap: 5px;
    padding: 16px 0 0 10px;
    color: #3a3a3a;
    font-size: 14px;
    background: #f5f8f8;
    box-shadow: ${theme('shadow')};

    &.odd-row {
      background: white;
    }

    &.default-expense {
      font-size: 16px;
      color: black;
    }

    > .tags {
      > .btn {
        border: 0;
        border-radius: 6px;
        padding: 0;
        width: 24px;
        height: 24px;

        color: white;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        line-height: 24px;

        background: #e6e6e6;
      }
    }

    > .menu {
      display: flex;
      align-items: center;
      > .preview-link {
        cursor: pointer;
      }
    }
  `
);
