import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { theme } from 'styled-tools';
import { Icon, SettingIcon, UserIcon } from '../../../components/Icons';
import { Header, SideBarFilter } from '../../../components/SideBarFilter';

function SettingSideBar({ className, title }) {
  return (
    <SideBarFilter className={className}>
      <Header>{title}</Header>
      <div className="menu">
        <NavLink className="item" to="./general">
          <SettingIcon /> General Settings
        </NavLink>
        <NavLink className="item" to="./members">
          <UserIcon /> Members
        </NavLink>
      </div>
    </SideBarFilter>
  );
}

const StyledSettingSideBar = styled(SettingSideBar)`
  overflow-y: auto;

  position: sticky;
  top: 0;

  border-right: 1px solid #eee;
  height: 100vh;

  background: white;

  > .header {
    padding: 0 20px;

    > .title {
      border-bottom: 2px dashed #eee;
      padding: 20px 0;
    }
  }

  > .menu {
    margin-top: 15px;

    > .item {
      display: block;
      padding: 15px 20px;

      color: #999;
      font-size: 14px;
      font-weight: bold;
      text-decoration: none;

      &.active {
        color: #333;

        > ${Icon} {
          color: ${theme('colors.primary')};
        }
      }

      > ${Icon} {
        margin-right: 8px;
      }
    }
  }

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    display: none;
  }
`;

const MemoSettingSideBar = React.memo(StyledSettingSideBar);

export { MemoSettingSideBar as SettingSideBar };
