import React from 'react';
import styled from 'styled-components';

export const Tooltip = styled(({ className, text, children }) => {
  return (
    <div className={className}>
      <div className="tooltip">
        {children}
        <span className="tooltip-text">{text}</span>
      </div>
    </div>
  );
})`
  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltip-text {
    visibility: hidden;
    background-color: black;
    color: #fff;
    width: 80px;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    font-size: 12px;
    font-weight: bold;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -45px;
  }

  .tooltip:hover .tooltip-text {
    visibility: visible;
  }

  .tooltip-text::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #000 transparent transparent transparent;
  }
`;
