import React, { useState, useContext, useEffect } from 'react';
import useSWR from 'swr';
import hoist from 'hoist-non-react-statics';
import { useParams } from 'react-router-dom';
import { useQueryParams } from '../../lib/hooks';
import { ToolingsClient } from '../../clients/ToolingsClient';
import GlobalContext from '../../store/globalContext';

export function withSearchSelect(Component) {
  function WithSearchSelect({ onChange, ...props }) {
    const [queryParams] = useQueryParams();
    const [search, setSearch] = useState('');
    const [isFlag, setIsFlag] = useState(false);
    const [name, setName] = useState('');
    const { companyId } = useParams();

    const { data: members, profileId } = useMemberOptions({
      companyId,
      query: search,
    });

    useEffect(() => {
      if (!isFlag) {
        setName(getNameMember(queryParams.memberId || profileId));
      }
      // eslint-disable-next-line
    }, [[members, profileId, isFlag]]);

    function handleChange(value) {
      setSearch('');
      setIsFlag(false);

      if (onChange) {
        onChange(value);
      }
    }

    function getNameMember(id) {
      const option = members.find(opt => opt.id === id);
      return option?.name || '';
    }

    function handleSearch(query) {
      setSearch(query);
      setIsFlag(true);

      if (query === '') {
        onChange(null);
      }
    }

    const pageProps = {
      isFlag,
      options: members,
      search,
      handleSearch,
      onChange: handleChange,
      memberId: queryParams.memberId,
      profileId,
      name,
      ...props,
    };

    return <Component {...pageProps} />;
  }

  hoist(WithSearchSelect, Component);

  WithSearchSelect.displayName = `withSearchSelect(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithSearchSelect;
}

function useMemberOptions({ companyId, status = 'active', query }) {
  const client = new ToolingsClient();
  const { globalState } = useContext(GlobalContext);
  const { profile } = globalState;

  const { data } = useSWR(
    ['members', query],
    () => client.listCompanyMembers({ companyId, status, query }),
    { revalidateOnFocus: false }
  );

  return {
    data: transformMemberOptions(data?.data, profile) || [],
    profileId: profile?.id,
  };
}

function transformMemberOptions(members, profile) {
  const me = (members || []).find(member => member?.id === profile?.id);

  const memberList = (members || [])
    .filter(member => member?.id !== profile?.id)
    .map(item => ({
      name: `${item?.nickName ? `[${item?.nickName}]` : ''} ${item?.fullName}`,
      ...item,
    }));

  if (me) {
    memberList.unshift({ id: me?.id, name: `${me?.fullName} (me)` });
  }
  return memberList || [];
}
