import React from 'react';
import hoist from 'hoist-non-react-statics';
import { useCurrentCompany } from '../../projects/useCurrentCompany';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { useCompanyList } from '../../projects/useCompanyList';

export function withCurrentCompanyGuard(Component) {
  function WithCurrentCompanyGuard(props) {
    const { data: companies, isValidating } = useCompanyList();
    const { data: company } = useCurrentCompany();

    if (!company && !(companies?.length === 0 && !isValidating)) {
      return <LoadingIndicator fullscreen />;
    }

    return <Component {...props} />;
  }

  hoist(WithCurrentCompanyGuard, Component);

  WithCurrentCompanyGuard.type = 'AUTH_GUARD';
  WithCurrentCompanyGuard.displayName = `withCurrentCompanyGuard(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithCurrentCompanyGuard;
}
