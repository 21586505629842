import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import { Logo } from '../../../../components/Logo';
import { AuthenticationLayout } from '../../components/AuthenticationLayout';
import { TextField } from '../../components/TextField';
import { Button } from '../../components/Button';
import { DocumentTitle } from '../../../../components/DocumentTitle';
import { FlashAlert } from '../../../../components/FlashAlert';

const initialValues = {
  email: '',
  password: '',
};

const validationSchema = yup.object({
  email: yup.string().required('Email is required.').email('Email is invalid.'),
  password: yup.string().required('Password is required.'),
});

/**
 *
 * @param {string} [className]
 * @param {*} onSignIn
 * @param {string} [error]
 * @returns {JSX.Element}
 * @constructor
 */
function SignInPage({
  className,
  onSignIn,
  error,
  successAlert,
  errorAlert,
  resetPasswordAlert,
}) {
  const alertText = successAlert ?? errorAlert ?? resetPasswordAlert;
  const alertType = successAlert || resetPasswordAlert ? 'success' : 'error';

  return (
    <AuthenticationLayout className={className}>
      <DocumentTitle title="Log in to Toolings" showCredit={false} />
      {alertText && <FlashAlert type={alertType} message={alertText} />}
      <div className="container">
        <header className="header">
          <Logo />
        </header>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSignIn}
        >
          {() => (
            <Form className="sign-in-form" noValidate>
              <h4 className="form-title">Login</h4>
              <TextField id="email" type="email" name="email" label="Email" />
              <TextField
                className="input"
                id="password"
                name="password"
                type="password"
                label="Password"
              />
              <Button className="submit" type="submit">
                Login
              </Button>
            </Form>
          )}
        </Formik>
        <footer className="footer">
          <Link className="link" to="/auth/registration">
            Register
          </Link>
          <Link className="link gray" to="/auth/forgot-password">
            Forgot password?
          </Link>
        </footer>
        {error && <div className="error">{error}</div>}
      </div>
    </AuthenticationLayout>
  );
}

const StyledSignInPage = styled(SignInPage)`
  > .container > .sign-in-form {
    margin-bottom: 16px;
  }

  > .container > .footer > .link {
    &.gray {
      color: #c1c3ca;
    }
  }

  .submit {
    display: block;
    width: 100%;
  }

  .error {
    padding: 30px 0;

    color: ${theme('colors.error')};
    text-align: center;
    font-weight: bold;
  }

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    > .container {
      > .sign-in-form {
        padding: 0 15px;
      }
    }
  }
`;

export { StyledSignInPage as SignInPage };
