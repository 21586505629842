import React from 'react';
import styled from 'styled-components';
import { prop, theme, ifProp } from 'styled-tools';
import { AlarmIcon, FlagIcon } from './Icons';
import { AvatarList } from './AvatarList';

// move this to Badge.js
export const Badge = styled.div`
  padding: 2px 8px;

  border-radius: 8px;
  border: 0;

  color: white;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.7;

  background: ${prop('color', theme('colors.primary'))};
`;

const ListItemHeader = styled.div`
  display: grid;
  grid-template-columns: ${ifProp(
    'showStatus',
    '1fr 120px 230px 170px 120px 100px 50px',
    '1fr 170px 170px 120px 100px 50px'
  )};
  align-items: stretch;
  padding: 20px 0;

  color: #767676;
  font-size: 12px;

  > .col {
    display: flex;
    align-items: center;

    padding: 0 20px;
  }

  > .summary {
    padding-left: 0;

    > ${Badge} {
      margin-right: 10px;
    }
  }
`;

const ListItem = styled(
  React.forwardRef(function ListItem(
    { ticket, color, showStatus, onSelectTicket, ...props },
    ref
  ) {
    return (
      <div
        {...props}
        ref={ref}
        onClick={() => onSelectTicket(ticket.id, ticket.idEachGroup)}
      >
        <div className="col name">{ticket.name}</div>
        {showStatus && <div className="col status">{ticket.statusname}</div>}
        <div className="col date-point">
          {ticket.dueDate && (
            <div className="due-date">
              <AlarmIcon color={color} />
              {ticket.dueDate.toFormat('d MMM')}
            </div>
          )}
          {(!!ticket.point || !!ticket.pointMinute) && (
            <div className="point">
              {ticket.point.toLocaleString()} hr(s){' '}
              {ticket.pointMinute.toLocaleString()} min(s)
            </div>
          )}
        </div>
        <div className="col sprints">
          {ticket.sprints?.slice(0, 1).map(sprint => (
            <div key={sprint.id} className="sprint">
              <FlagIcon color={color} />
              {sprint.name}
            </div>
          ))}
          {ticket.sprints?.length > 1 && (
            <div className="more" style={{ background: color }}>
              +{(ticket.sprints?.length - 1).toLocaleString()}
            </div>
          )}
        </div>
        <div className="col tags">
          {ticket.tags?.map(tag => (
            <Badge key={tag?.id} color={tag?.color}>
              {tag?.name}
            </Badge>
          ))}
        </div>
        <div className="col assignees">
          <AvatarList data={ticket.assignees} hidePlaceholder />
        </div>
      </div>
    );
  })
)`
  display: grid;
  grid-template-columns: ${ifProp(
    'showStatus',
    '1fr 120px 230px 170px 120px 100px 50px',
    '1fr 170px 170px 120px 100px 50px'
  )};
  align-items: stretch;

  border-radius: 6px;
  padding: 20px 0;

  color: #999;
  font-size: 12px;
  font-weight: bold;

  background: white;
  box-shadow: ${theme('shadow')};
  cursor: pointer;

  > .col {
    display: flex;
    align-items: center;

    padding: 0 20px;

    &:not(:last-child) {
      border-right: 1px solid #ddd;
    }
  }

  > .name {
    color: #333;
    font-size: 14px;
    font-weight: normal;
  }

  > .date-point {
    font-weight: bold;

    > .point {
      margin-left: 10px;

      border-left: 1px solid #ddd;
      padding-left: 10px;
    }
  }

  > .sprints {
    > .more {
      flex: 0 0 20px;

      margin-top: -2px;
      margin-left: 5px;

      border-radius: 10px;
      width: 20px;
      height: 20px;

      color: white;
      text-align: center;
      line-height: 20px;
    }
  }

  > .tags {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 6px;
  }

  > .col.assignees {
    justify-content: flex-end;
    border-right: 0;
  }

  > .menu {
    padding: 0 10px;
  }
`;

export const ListBoard = React.memo(
  styled(function ListBoard({
    className,
    color,
    showStatus = true,
    tickets,
    title,
    onSelectTicket,
  }) {
    const numberOfPoints = tickets.reduce(
      (sum, { point, pointMinute }) => sum + point * 60 + pointMinute,
      0
    );
    const pointMinute = numberOfPoints % 60;
    const pointHour = Math.trunc(numberOfPoints / 60);

    return (
      <div className={className}>
        <h4 className="title">{title}</h4>
        <div className="table-wrapper">
          <div className="table">
            <ListItemHeader showStatus={showStatus}>
              <div className="col summary">
                <Badge>{tickets.length.toLocaleString()} tickets</Badge>
                <Badge>
                  {pointHour} hr(s) {pointMinute} min(s)
                </Badge>
              </div>
              {showStatus && <div className="col status">Status</div>}
              <div className="col due-date">Due date</div>
              <div className="col sprint">Sprint</div>
              <div className="col tag">Tag</div>
              <div className="col assignees">Member</div>
              <div className="col menu" />
            </ListItemHeader>
            {tickets.map(ticket => (
              <ListItem
                key={ticket.id}
                ticket={ticket}
                color={color}
                showStatus={showStatus}
                onSelectTicket={onSelectTicket}
              />
            ))}
          </div>
        </div>
      </div>
    );
  })`
    > .title {
      font-size: 20px;
    }

    > .table-wrapper {
      width: 100%;
      overflow: auto;

      > .table {
        min-width: 850px;

        ${ListItem} {
          margin-bottom: 10px;
        }
      }
    }
  `
);
