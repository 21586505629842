import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import pick from 'ramda/src/pick';

import { SettingIcon, Board, MagnifyingGlass } from '../Icons';
import { SideDrawer } from './SideDrawer';
import { Notification } from '../Notification';
import { Avatar } from '../Avatar';

function MobileNav({ className, showSideBar, toggleSidebar, data, pathname }) {
  return (
    <div className={className}>
      <nav>
        <div>
          <a href="/companies">
            <Board />
            Board
          </a>
        </div>
        <div>
          <SettingIcon />
          Setting
        </div>
        <div>
          <MagnifyingGlass />
          Search
        </div>
        <div>
          <Notification />
          Notification
        </div>
        <div>
          {data && (
            <div className={pathname.startsWith('/settings') ? 'active' : ''}>
              <a href="/settings/account" className="avatar">
                <Avatar
                  className="image-avatar"
                  {...pick(['avatarUrl', 'name', 'initial'], data)}
                  noTooltip
                />
              </a>
            </div>
          )}
          Account
        </div>
        {/* <button className="btn-hamburger" type="button" onClick={toggleSidebar}> */}
        {/*  <div /> */}
        {/*  <div /> */}
        {/*  <div /> */}
        {/* </button> */}

        {/* <div className="footer"> */}
        {/*  <Notification /> */}
        {/* </div> */}
      </nav>
      <SideDrawer showSideBar={showSideBar} toggleSidebar={toggleSidebar} />
    </div>
  );
}

const StyledMobileNav = styled(MobileNav)`
  > nav {
    display: flex;
    width: 100%;
    height: 60px;
    background: white;
    position: fixed;
    bottom: 0;
    z-index: 10;
    border-top: 1px solid #eaecf2;
    align-items: center;
    justify-content: space-between;
    padding: 12px 15px;

    > div,
    a {
      text-decoration: none;
      color: black;
      font-size: 0.625rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    > .btn-hamburger {
      border: 0;
      background: none;
      padding: 15px 12px;

      cursor: pointer;

      > div {
        height: 4px;
        background: ${theme('colors.primary')};
      }

      > div:not(:last-child) {
        margin-bottom: 4px;
      }

      > div:nth-child(1) {
        width: 26px;
      }

      > div:nth-child(2) {
        width: 20px;
      }

      > div:nth-child(3) {
        width: 23px;
      }
    }

    > .footer {
      margin-left: auto;
      display: flex;
      align-items: center;
    }

    .image-avatar {
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
`;

export { StyledMobileNav as MobileNav };
