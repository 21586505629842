import React, {
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import { ifProp, theme } from 'styled-tools';
import { Menu } from '@reach/menu-button';
import GlobalContext from 'store/globalContext';
import { useProjectListOption } from 'features/projects/pages/ProjectListPage/components/Ticket/hook';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from '@reach/accordion';
import {
  useProjectColumnOptions,
  useTicketSidebarSearch,
} from 'features/projects/useTicketSearch';
import { Draggable } from '@fullcalendar/interaction';
import { DateTime } from 'luxon';
import { useQueryCache } from 'react-query';
import { CalendarTicket } from './KanbanBoard/CalendarTicket';
import { Indicator } from './SideBarFilter/Indicator';
import { LoadingIndicator } from './LoadingIndicator';
import { ArrowUpIcon } from './Icons';

function CompanyTicketSidebar({ className }) {
  const { globalState } = useContext(GlobalContext);
  const queryCache = useQueryCache();
  const [projectIndexId, setProjectIndexId] = useState(null);
  const [allTickets, setAllTickets] = useState([]);
  const allTicketsRef = useRef(allTickets);
  const [page, setPage] = useState(1);
  const [selectedIds, setSelectedIds] = useState([]);
  const [showFilter, setShowFilter] = useState(true);
  const { profile } = globalState;
  const { projectList } = useProjectListOption({
    userId: profile?.id,
  });
  const isLoading = false;

  const data = useProjectColumnOptions({
    projectId:
      projectList && projectList.length > 0
        ? projectList[projectIndexId]?.id
        : null,
  });

  const { maxPage, tickets, loading } = useTicketSidebarSearch({
    storyGroupIds: selectedIds,
    projectId:
      projectList && projectList.length > 0
        ? projectList[projectIndexId]?.id
        : null,
    memberIds: [profile?.id],
    canFetch: !isLoading,
    page,
  });

  const options = useMemo(() => {
    return (
      data.map(item => {
        const isChecked = selectedIds.includes(item.value);
        return {
          onSelect(e) {
            e.stopPropagation();
            setAllTickets([]);
            setPage(1);
            if (selectedIds.includes(item.value)) {
              setSelectedIds(selectedIds.filter(id => id !== item.value));
            } else {
              setSelectedIds([...selectedIds, item.value]);
            }
          },
          isChecked,
          id: item.value,
          name: item.label,
          isShow: true,
        };
      }) || []
    );
  }, [data, selectedIds]);

  const updateAfterDrag = useCallback(value => {
    const {
      id,
      date,
      estimateTime,
      estimateTimeMinute,
      hasSubtask,
      allDay,
    } = value.detail;
    const currentAllTicket = [...allTicketsRef.current];
    const ticketIndex = currentAllTicket.findIndex(
      ticket => ticket.id.toString() === id
    );
    if (ticketIndex >= 0) {
      currentAllTicket[ticketIndex] = {
        ...currentAllTicket[ticketIndex],
        dueDate: DateTime.fromISO(date),
      };

      if (!hasSubtask) {
        if (allDay) {
          currentAllTicket[ticketIndex].point = 8;
          currentAllTicket[ticketIndex].pointMinute = 0;
          currentAllTicket[ticketIndex].userEstimate = 480;
        } else {
          currentAllTicket[ticketIndex].point = estimateTime;
          currentAllTicket[ticketIndex].pointMinute = estimateTimeMinute;
          currentAllTicket[ticketIndex].userEstimate =
            estimateTime * 60 + estimateTimeMinute;
        }
      }

      setAllTickets([...currentAllTicket]);
    }
  }, []);

  const resetSidebarToDefault = useCallback(() => {
    setShowFilter(true);
    setAllTickets([]);
    setPage(1);
    queryCache.invalidateQueries(({ queryKey }) => {
      return queryKey.join('/').startsWith(`ticket-sidebar-search/1`);
    });
  }, [queryCache]);

  useEffect(() => {
    allTicketsRef.current = allTickets;
  }, [allTickets]);

  useEffect(() => {
    document.addEventListener('updateTicketAfterDrag', updateAfterDrag);
    document.addEventListener('resetSidebarToDefault', resetSidebarToDefault);
    return () => {
      document.removeEventListener('updateTicketAfterDrag', updateAfterDrag);
      document.removeEventListener(
        'resetSidebarToDefault',
        resetSidebarToDefault
      );
    };
  }, [resetSidebarToDefault, updateAfterDrag]);

  useEffect(() => {
    if (!loading && projectIndexId >= 0) {
      if (tickets && tickets.length > 0) {
        setAllTickets(prevTickets => [...prevTickets, ...tickets]);
      }
    }
  }, [tickets, loading, page, projectIndexId]);

  const draggableInstance = useRef(null);

  useEffect(() => {
    // Check if Draggable is already instantiated
    if (
      projectIndexId >= 0 &&
      allTickets.length > 0 &&
      !draggableInstance.current
    ) {
      const containerEl = document.querySelector(`#events-${projectIndexId}`);

      if (containerEl) {
        // Create Draggable only once
        draggableInstance.current = new Draggable(containerEl, {
          itemSelector: '.event',
        });
      }
    }

    // Cleanup function to remove Draggable instance on unmount
    return () => {
      if (draggableInstance.current) {
        draggableInstance.current.destroy(); // Destroy Draggable instance to prevent memory leaks
        draggableInstance.current = null;
      }
    };
  }, [projectIndexId, allTickets.length]);

  const loadMore = () => {
    setPage(oldPage => oldPage + 1);
  };

  return (
    <Accordion
      collapsible
      onChange={value => {
        setShowFilter(true);
        setAllTickets([]);
        setPage(1);
        setSelectedIds([]);
        setProjectIndexId(value === projectIndexId ? null : value);
      }}
    >
      {(projectList ?? []).map((project, index) => (
        <AccordionItem className={className} key={index}>
          <div className="header">
            <h5
              className={classnames('title', {
                active: index === projectIndexId,
              })}
            >
              {project.name}
            </h5>
            <p
              className={classnames('count', {
                hidden: index === projectIndexId,
              })}
            >
              {project.ticketCount} Tickets
            </p>
            <div className="action">
              <AccordionButton className="toggle">
                <ArrowUpIcon
                  color={
                    index === projectIndexId
                      ? theme('colors.primary')
                      : '#3a3a3a'
                  }
                />
              </AccordionButton>
            </div>
          </div>

          <AccordionPanel>
            <div className="filter-header">
              <p>
                Swimlane ({selectedIds.length}/{options.length})
              </p>
              <div
                className="hide"
                onClick={() => {
                  setShowFilter(!showFilter);
                }}
              >
                {showFilter ? 'Hide' : 'Show'}
              </div>
            </div>
            {showFilter && options && options.length > 0
              ? options.map((item, i) => (
                  <Menu key={i}>
                    <Item show={item.isShow}>
                      <Indicator
                        checked={item.isChecked}
                        onClick={item.onSelect}
                      />
                      <div className="progress" onClick={item.onSelect}>
                        <h5 className="name">{item.name}</h5>
                      </div>
                    </Item>
                  </Menu>
                ))
              : null}

            <div id={`events-${index}`}>
              {allTickets?.map((ticket, ticketIndex) => (
                <CalendarTicket
                  className="event"
                  onClick={() => {}}
                  key={`ticket-${ticket.id}`}
                  index={ticketIndex}
                  color={ticket?.epicColor || project?.color || '#22c759'}
                  projectId={project?.id}
                  {...ticket}
                />
              ))}
            </div>
            {loading && <LoadingIndicator />}

            {!loading && allTickets.length <= 0 && (
              <div className="not-found">Owned Tickets not found</div>
            )}

            <div
              onClick={loadMore}
              className={classnames('load-more', {
                hidden: page >= maxPage,
              })}
            >
              Load More
            </div>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
}

const Item = styled.div`
  display: ${ifProp('show', 'flex', 'none')};
  align-items: center;

  padding: 10px 0;
  cursor: pointer;

  > .progress {
    margin-left: 10px;

    flex-grow: 1;
    max-width: 172.95px;
  }

  > .progress > .name {
    overflow: hidden;

    flex-grow: 1;
    flex-shrink: 1;

    padding: 0 10px 0 0;

    color: #3a3a3a;
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  > .progress > .bar {
    border-radius: 5px;
    height: 4px;
    width: 100%;

    background: #ddd;

    > .completed {
      border-radius: 5px;
      height: 4px;
      background: #999;
    }
  }
`;

const StyledCompanyTicketSidebar = styled(CompanyTicketSidebar)`
  padding: 10px 20px;

  .not-found {
    color: #9fa3ae;
    text-align: center;
    padding: 10px;
  }

  .load-more {
    text-align: center;
    padding: 10px;
    text-decoration: underline;
    cursor: pointer;
  }

  .hidden {
    display: none;
  }

  &::after {
    content: ' ';

    position: relative;
    bottom: -8px;

    display: block;

    border-bottom: 2px dashed #eee;
  }

  .form {
    padding: 10px 0;

    .all {
      font-size: 0.75rem;
      color: #9fa3ae;
      text-decoration: underline;
      margin-top: 10px;
      cursor: pointer;
    }
  }

  .filter-header {
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin-top: 5px;

    > * {
      font-size: 12px;
      color: #9fa3ae;
    }

    > .hide {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  > .header {
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin-right: -8px;

    > * {
      font-size: 14px;
    }

    > .count {
      font-size: 12px;
      margin-left: auto;
      color: #9fa3ae;
      white-space: nowrap;
    }

    > .active {
      color: ${theme('colors.primary')};
    }

    button {
      border: 0;

      background: transparent;

      cursor: pointer;
    }

    .action {
      .add {
        color: #ddd;
      }

      .add,
      .toggle {
        padding: 5px 8px;
      }

      .toggle {
        color: ${theme('colors.primary')};
      }
    }
  }

  [data-state='collapsed'] {
    .toggle {
      transform: rotateZ(180deg);
    }
  }

  .filter-list {
    padding-bottom: 10px;
  }

  .wrapper-empty-items {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 0.75rem;
    margin: 20px 0;

    > div:first-child {
      color: #9fa3ae;
    }

    > div:nth-child(2) {
      color: ${theme('colors.primary')};
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export { StyledCompanyTicketSidebar as CompanyTicketSidebar };
