import React from 'react';
import hoist from 'hoist-non-react-statics';
import { useCompanyList } from '../../../projects/useCompanyList';
import { useQueryParams } from '../../../../lib/hooks';

export function withCompaniesList(Component) {
  function WithCompaniesList(props) {
    const { data: companies } = useCompanyList();
    const [queryParams, setQueryParams] = useQueryParams();

    const onCloseSettingProjectModal = () => {
      setQueryParams({ modal: null });
    };

    const pageProps = {
      companies,
      isSettingCompanyModal: Boolean(queryParams.modal === 'create-company'),
      onCloseSettingProjectModal,
    };

    return <Component {...props} {...pageProps} />;
  }

  hoist(WithCompaniesList, Component);

  WithCompaniesList.displayName = `withCompaniesList(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithCompaniesList;
}
