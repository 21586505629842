import styled from 'styled-components';
import React, { useState } from 'react';
import { useField } from 'formik';
import { ifProp, theme } from 'styled-tools';
import { CheckIcon, EditIcon } from '../../../../components/Icons';
import { TextField } from '../../../authentication/components/TextField';

const Text = styled.h1.attrs(() => ({ placeholder: null }))`
  color: ${ifProp('placeholder', '#999', '#333')};
  font-weight: ${ifProp('placeholder', 'normal', 'bold')};
  word-break: break-word;
  color: #c1c3ca;
  font-weight: normal;
  @media (max-width: ${theme('breakpoints.mobile')}px) {
    font-size: 20px;
  }
`;

export const EditableText = styled(function EditableText({
  className,
  name,
  disabled,
  placeholder,
}) {
  const [isEdit, setIsEdit] = useState(false);
  const [{ value }, { error }] = useField(name);

  return (
    <div className={className}>
      {disabled || !isEdit ? (
        <Text
          className={`text ${error && 'error'}`}
          placeholder={!value}
          onClick={() => !disabled && setIsEdit(true)}
        >
          {value || placeholder}
          {!disabled && (
            <button
              type="button"
              className="btn"
              onClick={() => setIsEdit(true)}
            >
              <EditIcon />
            </button>
          )}
          {error && <div className="error">name is a required field</div>}
        </Text>
      ) : (
        <div className="editor">
          <TextField name={name} autoFocus onBlur={() => setIsEdit(false)} />
          {!error && (
            <button
              type="button"
              className="btn"
              onClick={() => setIsEdit(false)}
            >
              <CheckIcon />
            </button>
          )}
        </div>
      )}
    </div>
  );
})`
  .btn {
    margin-left: 10px;

    border: 0;

    color: #ddd;

    background: none;
  }

  ${TextField} {
    flex: 1;
    margin-bottom: 0;
  }

  > .editor {
    display: flex;
    align-items: center;

    > .btn {
      color: ${theme('colors.primary')};
    }
  }
`;
