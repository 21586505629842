import useSWR from 'swr';
import { useEffect, useState } from 'react';
import { useDebounce, useQueryParams } from '../../lib/hooks';
import { useAppClient } from '../../lib/AppProvider';

export function useSearchSalaries(companyId) {
  const client = useAppClient();
  const [queryParams, setQueryParams] = useQueryParams();
  const [q, onChangeQ] = useState(queryParams.q ?? '');
  const { destroy } = useSalariesAction(companyId);
  const memoQuery = useDebounce(q, 500);
  const cacheKey = [['query', memoQuery]].reduce(
    (memo, [key, value]) => `${memo}${key}=${value}&`,
    ''
  );

  const { data, revalidate } = useSWR(
    companyId && `salaries?${cacheKey}`,
    () =>
      client.getCompanySalaries(companyId, {
        query: memoQuery,
      }),
    { revalidateOnFocus: false }
  );

  useEffect(() => {
    setQueryParams({ q: memoQuery });
  }, [memoQuery, setQueryParams]);

  return {
    loading: !data,
    salaries: data ?? [],
    revalidate,
    onChangeQuery: onChangeQ,
    query: q,
    async destroy(id) {
      await destroy(id);
      revalidate();
    },
  };
}

export function useSearchRemainingSalaryMembers(companyId) {
  const client = useAppClient();
  const { data, revalidate } = useSWR(
    [companyId, 'salaries'],
    () => client.getCompanyEmployeesWithNoSalaryData(companyId),
    { revalidateOnFocus: false }
  );

  return {
    members: data ?? [],
    revalidate,
  };
}

export function useSalariesAction(companyId) {
  const client = useAppClient();

  return {
    async create({ startDate, position, level, salary, userId }) {
      await client.createCompanySalary(companyId, {
        startDate,
        position,
        level,
        salary,
        userId,
      });
    },
    async update(id, { startDate, position, level, salary, userId }) {
      await client.updateCompanySalary(companyId, id, {
        startDate,
        position,
        level,
        salary,
        userId,
      });
    },
    async destroy(id) {
      await client.deleteCompanySalary(companyId, id);
    },
  };
}
