import React, { useCallback, useState } from 'react';
import hoist from 'hoist-non-react-statics';
import { useProjectEpic } from '../../../components/SideBarFilter/withEpicFilter';
import { successToast } from '../../../lib/toast';

export function withEpicSelect(Component) {
  function WithEpicSelect(props) {
    const [show, setShow] = useState(false);
    const { data, create } = useProjectEpic({ projectId: props.projectId });

    const onSubmit = useCallback(
      async ({ epicName }, { resetForm }) => {
        await create({ name: epicName });
        resetForm();

        successToast({ message: 'The epic has been created.' });
      },
      [create]
    );

    const onShow = useCallback(() => setShow(true), []);
    const onHide = useCallback(() => setShow(false), []);

    const pageProps = {
      onSubmit,
      data,
      onShow,
      onHide,
      show,
      ...props,
    };

    return <Component {...props} {...pageProps} />;
  }
  hoist(WithEpicSelect, Component);

  WithEpicSelect.displayName = `WithEpicSelect(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithEpicSelect;
}
