import React, { useMemo } from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { useNavigate } from 'react-router-dom';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from '@reach/accordion';

import {
  ArrowUpIcon,
  WorkIcon,
  SettingIcon,
  UserIcon,
  LockIcon,
  SignOutIcon,
} from '../Icons';
import { Avatar } from '../Avatar';
import { SelectField } from '../Select/SelectField';
import { useCurrentCompany } from '../../features/projects/useCurrentCompany';
import { useCompanyList } from '../../features/projects/useCompanyList';
import { useAuthentication } from '../../features/authentication/useAuthentication';

function SideDrawer({ className, showSideBar, toggleSidebar }) {
  const { data: currentCompany } = useCurrentCompany();

  const { data: companyList } = useCompanyList();
  const { signOut } = useAuthentication();

  const navigate = useNavigate();

  const options = useMemo(() => {
    return (
      companyList?.map(company => ({
        label: company.name,
        value: company.id,
      })) || []
    );
  }, [companyList]);

  function handleToggleSidebar() {
    document.querySelector('body').style.overflow = showSideBar
      ? 'unset'
      : 'hidden';
    document.querySelector('#root').style.overflow = showSideBar
      ? 'unset'
      : 'hidden';
    toggleSidebar();
  }

  function setCurrentCompany(company) {
    window.location.href = `/company/${company.value}`;
  }

  function onSelectMenu(path) {
    handleToggleSidebar();
    navigate(path);
  }

  return (
    <div
      className={`${className} ${showSideBar ? 'show' : ''}`}
      onClick={handleToggleSidebar}
    >
      <div className="sidebar" onClick={e => e.stopPropagation()}>
        <div>
          <div className="company-detail">
            <Avatar
              className="avatar"
              name={currentCompany?.name}
              initial={currentCompany?.initial}
              avatarUrl={currentCompany?.logoUrl}
              noTooltip
            />
            <SelectField
              value={currentCompany?.id}
              options={options}
              onChange={setCurrentCompany}
            />
          </div>
          <div className="menu-list">
            <button
              type="button"
              className="menu-item"
              onClick={() => onSelectMenu(`/company/${currentCompany?.id}`)}
            >
              <WorkIcon className="icon-left" /> Projects
            </button>
            <Accordion collapsible>
              <AccordionItem>
                <AccordionButton className="menu-item">
                  Company Settings
                  <ArrowUpIcon className="icon-right" />
                </AccordionButton>
                <AccordionPanel>
                  <button
                    type="button"
                    className="menu-item"
                    onClick={() =>
                      onSelectMenu(
                        `/company/${currentCompany?.id}/settings/general`
                      )
                    }
                  >
                    <SettingIcon className="icon-left" /> General Settings
                  </button>
                  <button
                    type="button"
                    className="menu-item"
                    onClick={() =>
                      onSelectMenu(
                        `/company/${currentCompany?.id}/settings/members`
                      )
                    }
                  >
                    <UserIcon className="icon-left" /> Members
                  </button>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <div className="profile-group">
              <button
                type="button"
                className="menu-item"
                onClick={() => onSelectMenu('/settings/account')}
              >
                <UserIcon className="icon-left" /> Account Settings
              </button>
              <button
                type="button"
                className="menu-item"
                onClick={() => onSelectMenu('/settings/password')}
              >
                <LockIcon className="icon-left" /> Change Password
              </button>
              <button type="button" className="menu-item" onClick={signOut}>
                <SignOutIcon className="icon-left" /> Log out
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const StyledSideDrawer = styled(SideDrawer)`
  background-color: #00000080;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 5;
  opacity: 0;
  transition: opacity 0.4s ease-in-out 0.2s;
  height: 100vh;

  &.show {
    opacity: 1;
    transition: opacity 0.4s ease-in-out;
    pointer-events: unset;

    > .sidebar {
      width: 100%;
    }
  }

  > .sidebar {
    position: static;
    width: 0;
    max-width: 300px;
    height: 100%;

    background-color: white;

    transition: width 0.4s ease-in-out;
    overflow-y: scroll;

    > div {
      max-width: 100vw;
      width: 300px;
      height: 100%;
      overflow: hidden;
    }
  }

  > .sidebar > div > .company-detail {
    padding: 20px;
    background-color: ${theme('colors.primary')};
    display: flex;
    align-items: center;
    height: 80px;

    > .avatar > .image {
      width: 60px;
      height: 60px;

      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
    }

    > ${SelectField} {
      margin: 0 0 0 10px;
      flex: 1;

      > .input-group {
        > .field {
          color: white;
          border-color: white;
          background: ${theme('colors.primary')};
        }
        > svg {
          color: white;
        }
      }
    }
  }

  > .sidebar > div > .menu-list {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    padding: 20px 0;
    max-height: calc(100vh - 80px);
    height: 100%;
    overflow-y: auto;

    > .profile-group {
      font-size: 14px;
      margin-top: auto;

      > .menu-item:first-child {
        border-top: 1px solid #cccccc;
      }
    }

    .menu-item {
      display: flex;
      width: 100%;
      border: unset;
      background: white;
      outline: none;
      font-weight: 600;
      padding: 12px 20px;

      &[aria-expanded='false'] {
        > svg {
          transform: rotateZ(180deg);
        }
      }

      > .icon-left {
        margin-right: 10px;
      }

      > .icon-right {
        margin-left: auto;
      }
    }

    div[data-reach-accordion-panel] {
      > button {
        padding: 12px 20px 12px 40px;
      }
    }
  }
`;

export { StyledSideDrawer as SideDrawer };
