import React from 'react';
import styled from 'styled-components';
import { EpicSelect } from './EpicSelect';
import { SprintSelect } from './SprintSelect';
import { ActivityLogs } from './ActivityLogs';

function EpicAndSprint({
  className,
  projectId,
  activityLogsAndComments,
  handleCreateComment,
}) {
  return (
    <div className={className}>
      <EpicSelect epicId={projectId} />
      <SprintSelect sprintId={projectId} />
      <ActivityLogs
        data={activityLogsAndComments}
        handleCreateComment={handleCreateComment}
      />
    </div>
  );
}

const StyledEpicAndSprint = styled(EpicAndSprint)`
  width: 400px;
  background: #f5f8f8;
  border-radius: 6px;
  margin: 0 auto;
`;

export { StyledEpicAndSprint as EpicAndSprint };
