import React, { useEffect } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { ifProp, prop, theme } from 'styled-tools';

/**
 *
 * @param totalItems {number}
 * @param currentPage {number}
 * @param pageSize {number}
 * @param maxPages {number}
 * @returns {{totalItems: number, startPage: number, startIndex: number, pages: number[], endIndex: number, totalPages: number, pageSize: number, endPage: number, currentPage: number}}
 */
export function usePagination({
  totalItems,
  currentPage: initCurrentPage,
  pageSize,
  maxPages,
}) {
  const totalPages = Math.ceil(totalItems / pageSize);
  let currentPage = initCurrentPage;

  // ensure current page isn't out of range
  if (currentPage < 1) {
    currentPage = 1;
  } else if (currentPage > totalPages) {
    currentPage = totalPages;
  }

  let startPage;
  let endPage;
  if (totalPages <= maxPages) {
    // total pages less than max so show all pages
    startPage = 1;
    endPage = totalPages;
  } else {
    // total pages more than max so calculate start and end pages
    const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
    const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
    if (currentPage <= maxPagesBeforeCurrentPage) {
      // current page near the start
      startPage = 1;
      endPage = maxPages;
    } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
      // current page near the end
      startPage = totalPages - maxPages + 1;
      endPage = totalPages;
    } else {
      // current page somewhere in the middle
      startPage = currentPage - maxPagesBeforeCurrentPage;
      endPage = currentPage + maxPagesAfterCurrentPage;
    }
  }

  // calculate start and end item indexes
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

  // create an array of pages to ng-repeat in the pager control
  const pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
    i => startPage + i
  );

  // return object with all pager properties required by the view
  return {
    totalItems,
    currentPage,
    pageSize,
    totalPages,
    startPage,
    endPage,
    startIndex,
    endIndex,
    pages,
  };
}

const Step = styled(Link)`
  display: inline-block;
  margin: 0 ${prop('right', 0)} 0 ${prop('left', 0)};

  border-radius: ${ifProp('active', 0, '6px')};
  padding: 6px 12px;

  color: #999;
  text-decoration: none;
  font-weight: bold;

  pointer-events: ${ifProp('disabled', 'none', 'auto')};

  &:hover {
    background: ${ifProp('active', 'transparent', '#eee')};
  }
`;

const Page = styled(({ active: _, ...props }) => <Link {...props} />)`
  display: inline-block;
  margin: 0 4px;

  border-radius: ${ifProp('active', 0, '6px')};
  border-bottom: 2px solid transparent;
  border-color: ${ifProp('active', theme('colors.primary'), 'transparent')};
  padding: 6px 12px;

  color: ${ifProp('active', theme('colors.primary'), '#999')};
  text-decoration: none;
  font-weight: bold;

  pointer-events: ${ifProp('active', 'none', 'auto')};
  cursor: ${ifProp('active', 'default', 'pointer')};

  &:hover {
    background: ${ifProp('active', 'transparent', '#eee')};
  }
`;

export const Pagination = React.memo(
  styled(function Pagination({
    className,
    currentPage = 1,
    totalItems,
    maxPages,
    pageSize = 10,
  }) {
    const { pages, totalPages } = usePagination({
      totalItems,
      maxPages,
      currentPage,
      pageSize,
    });
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();

    searchParams.set('page', Math.max(1, currentPage - 1));
    const backUrl = `/${pathname}?${searchParams.toString()}`;

    searchParams.set('page', Math.min(totalPages, currentPage + 1));
    const nextUrl = `/${pathname}?${searchParams.toString()}`;

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [currentPage]);

    if (Number.isNaN(totalItems) || totalItems === 0) {
      return null;
    }

    return (
      <div className={className}>
        <Step right="10px" to={backUrl} disabled={currentPage === 1}>
          Back
        </Step>
        {pages.map(page => {
          searchParams.set('page', page);

          const to = `/${pathname}?${searchParams.toString()}`;
          const active = currentPage === page;

          return (
            <Page key={`${page}${active}`} to={to} active={active}>
              {page}
            </Page>
          );
        })}
        <Step left="10px" to={nextUrl} disabled={currentPage === totalPages}>
          Next
        </Step>
      </div>
    );
  })``
);
