import useSWR from 'swr';
import { useMemo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import GlobalContext from '../../store/globalContext';
import ProjectContext from '../../store/projectContext';
import { useAppClient } from '../../lib/AppProvider';
import { transformMember } from './useProfile';
import { useCurrentCompany } from './useCurrentCompany';

export function useProjectSearch() {
  const client = useAppClient();
  const { create, update } = useProjectAction();

  const result = useSWR(
    'projects',
    () =>
      client.listProjects().then(projects => {
        return projects.data.map(transformProject);
      }),
    { revalidateOnFocus: false }
  );

  return {
    ...result,
    async create({
      name,
      logo,
      description,
      color,
      selectedMembers: members = [],
    }) {
      const project = await create({
        name,
        logo,
        description,
        color,
        members,
      });

      await result.mutate(result.data.concat(project));
    },
    async update({
      id,
      name,
      logo,
      description,
      color,
      selectedMembers: members = [],
      hasImageDelete = false,
    }) {
      const project = await update(id, {
        name,
        logo,
        description,
        color,
        members,
        hasImageDelete,
      });

      await result.mutate(result.data.map(p => (p.id === id ? project : p)));
    },
  };
}

export function useProject(id) {
  const navigate = useNavigate();
  const client = useAppClient();
  const { update } = useProjectAction();
  const { projectState } = useContext(ProjectContext);
  const { globalState } = useContext(GlobalContext);
  const { profile } = globalState;
  const { data: company } = useCurrentCompany();

  const result = useSWR(
    id && `projects/${id}`,
    () =>
      client
        .getProject(id)
        .then(transformProject)
        .catch(() => navigate(`/company/${company.id}`, { replace: true })),
    { revalidateOnFocus: false }
  );

  return {
    ...result,
    async update({
      name,
      logo,
      description,
      color,
      members,
      hasImageDelete = false,
    }) {
      await result.mutate(
        data => ({ ...data, name, description, color, hasImageDelete }),
        false
      );
      const projectMembers = { data: projectState?.members };

      const newMembers = members
        .filter(m => m.id !== profile.id)
        .filter(m => !projectMembers.data.some(pMem => pMem.id === m.id));

      const removeMembers = projectMembers.data
        .filter(m => m.id !== profile.id)
        .filter(m => !members.some(m2 => m.id === m2.id));

      const project = await update(id, {
        name,
        logo,
        description,
        color,
        newMembers,
        removeMembers,
        hasImageDelete,
      });

      await result.mutate(project);
    },
  };
}

export function useProjectAction() {
  const client = useAppClient();
  const { globalState } = useContext(GlobalContext);
  const { profile } = globalState;

  return {
    async create({ name, logo, description, color, members = [] }) {
      const project = await client.createProject({
        name,
        logo,
        description,
        color,
        creatorId: profile?.id,
        add_users: members.map(member => member.email),
      });

      return transformProject(project);
    },
    async update(
      id,
      {
        name,
        logo,
        description,
        color,
        newMembers,
        removeMembers,
        hasImageDelete,
      }
    ) {
      const project = await client
        .updateProject({
          id,
          name,
          logo,
          description,
          color,
          add_users: newMembers.map(member => member.email),
          remove_users: removeMembers.map(member => +member.id),
          hasImageDelete,
        })
        .then(transformProject);

      return project;
    },
    async deleteProject(projectId) {
      await client.deleteProject(projectId);
    },
  };
}

export function useProjectMember(id) {
  const client = useAppClient();
  const result = useSWR(
    id && ['project', 'members', id],
    () => client.listProjectMember({ projectId: id }),
    { revalidateOnFocus: false }
  );

  return {
    ...result,
    data: useMemo(
      () => ({
        ...result.data,
        data: result.data?.data.map(transformMember),
      }),
      [result.data]
    ),
  };
}

export function useStarredProject(projectId) {
  const client = useAppClient();
  const {
    createStarredProject,
    deleteStarredProject,
  } = useStarredProjectAction();

  const result = useSWR(
    'starred-projects',
    () =>
      client.listStarredProjects(projectId).then(projects => {
        return projects.data.map(transformProject);
      }),
    { revalidateOnFocus: false }
  );

  return {
    ...result,
    async createStarredProject() {
      await createStarredProject(projectId);
    },
    async deleteStarredProject() {
      await deleteStarredProject(projectId);
    },
  };
}

export function useStarredProjectAction() {
  const client = useAppClient();

  return {
    async createStarredProject(projectId) {
      await client.createStarredProject(projectId);
    },
    async deleteStarredProject(projectId) {
      await client.deleteStarredProject(projectId);
    },
  };
}

function transformProject(project) {
  return {
    id: project.id,
    name: project.name,
    logoUrl: project.logoUrl,
    color: project.color,
    description: project.description,
  };
}
