import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';
import { Menu, MenuButton } from '@reach/menu-button';
import { DroppableTicketList } from './TicketList';
import { EllipseVerticalIcon } from '../Icons';
import { IconButton } from '../../features/authentication/components/Button';
import { MenuItem, MenuPopover } from '../MenuButton';
import { useOnClickOutside } from '../../lib/hooks';

const Column = React.forwardRef(function Column(
  {
    project,
    title,
    numberOfTickets,
    totalEstimateMinutes,
    data,
    onSelectTicket,
    listId,
    droppableId,
    onAddTicket,
    onUpdateName,
    onArchive,
    ...props
  },
  ref
) {
  const [isEditName, setIsEditName] = useState(false);
  const inputRef = useRef(null);
  const totalHours = Math.floor(totalEstimateMinutes / 60);
  const totalMinutes = totalEstimateMinutes % 60;

  useOnClickOutside(inputRef, handleUpdateName);

  function onEditName() {
    setIsEditName(true);
    requestAnimationFrame(() => {
      inputRef.current.focus();
    });
  }

  function handleUpdateName() {
    onUpdateName(inputRef?.current.value);
    setIsEditName(false);
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      handleUpdateName();
    }
  }

  return (
    <div {...props} ref={ref}>
      <div className="header">
        <div className="detail">
          <h3 className="title">
            {!isEditName ? (
              <span onClick={onEditName} className="span">
                {title}
              </span>
            ) : (
              <input
                className="input"
                defaultValue={title}
                ref={inputRef}
                onKeyPress={handleKeyPress}
              />
            )}
            <div className="pill-group">
              <span className="pill">{numberOfTickets} tickets</span>
              <span className="pill">
                {totalHours} hr(s) {totalMinutes} min(s)
              </span>
            </div>
          </h3>
        </div>
        <Menu>
          <MenuButton as={IconButton} className="menu">
            <EllipseVerticalIcon />
          </MenuButton>
          <MenuPopover width={120}>
            <MenuItem onSelect={onEditName}>Edit name</MenuItem>
            <MenuItem onSelect={onArchive}>Delete this list</MenuItem>
          </MenuPopover>
        </Menu>
      </div>
      <DroppableTicketList
        className="list"
        onSelectTicket={onSelectTicket}
        data={data}
        project={project}
        listId={droppableId}
        onAddTicket={onAddTicket}
      />
    </div>
  );
});

const StyledColumn = styled(Column)``;

const MemoColumn = React.memo(StyledColumn);

const StyledDraggableColumn = styled(function DraggableColumn({
  index,
  ...props
}) {
  return (
    <Draggable draggableId={props.droppableId} index={index}>
      {p => (
        <MemoColumn
          {...p.draggableProps}
          {...p.dragHandleProps}
          ref={p.innerRef}
          {...props}
        />
      )}
    </Draggable>
  );
})`
  flex: 0 0 310px;

  width: 310px;
  padding: 0 15px;

  > .header {
    display: flex;
    align-items: flex-start;
    padding: 10px 0;

    > .detail > .title {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }

    > .detail > .title > .span {
      margin-right: 10px;
    }

    > .detail > .title > .input {
      width: 120px;
      font-size: 14px;
      margin-right: 10px;
    }

    > .detail > .title > .pill-group > .pill {
      border-radius: 7px;
      padding: 2px 8px;
      font-weight: bold;
      color: white;
      font-size: 12px;

      background: #333;

      white-space: nowrap;
    }

    > .detail > .title > .pill-group > .pill + .pill {
      margin-left: 5px;
    }

    > .detail > .time-tracking {
      font-size: 12px;
      font-weight: bold;
      margin-top: 10px;
    }

    > .menu {
      margin-left: auto;
      margin-right: -15px;
      color: #999;
    }
  }

  > .list {
    overflow-y: auto;

    margin: 0 -15px;

    padding: 5px 15px 60px;
    height: 100%;
  }
`;

export { MemoColumn as Column, StyledDraggableColumn as DraggableColumn };
