import React from 'react';
import { Helmet } from 'react-helmet-async';

export function DocumentTitle({ title, showCredit = true }) {
  const documentTitle = title
    ? showCredit
      ? `${title} - Toolings`
      : title
    : 'Toolings';

  return (
    <Helmet>
      <title>{documentTitle}</title>
    </Helmet>
  );
}
