import useSWR from 'swr';
import { useAppClient } from '../../lib/AppProvider';

export function useCompanyList() {
  const client = useAppClient();

  return useSWR(
    'companies',
    () =>
      client.getProfile().then(member => {
        return member.companies.map(company => ({
          id: company.id,
          name: company.name,
          logoUrl: company.logoUrl,
          initial: company.name.substr(0, 2).toUpperCase(),
          description: company.description,
        }));
      }),
    { revalidateOnFocus: false }
  );
}
export function useCompanyAction() {
  const client = useAppClient();

  return {
    async create({ name, logo, description, members = [] }) {
      const response = await client.createCompany({
        name,
        logo,
        description,
        add_users: members.map(member => member.email),
      });
      return { ...response };
    },
  };
}
