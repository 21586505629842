import React, { useCallback } from 'react';
import hoist from 'hoist-non-react-statics';
import { useMemberSearch } from '../../useMemberSearch';
import { useDebounce, useQueryParams } from '../../../../lib/hooks';
import { successToast } from '../../../../lib/toast';

export function withMemberListPage(Component) {
  function WithMemberListPage(props) {
    const [queryParams, setQueryParams] = useQueryParams();
    const memoQuery = useDebounce(queryParams.query, 500);
    const { data, kick, changeRole, revalidate } = useMemberSearch({
      status: queryParams.status,
      query: memoQuery,
      limit: 10,
      page: parseInt(queryParams.page, 10) || 1,
      order: queryParams.order,
      sort: queryParams.sort,
    });

    const onSortBy = useCallback(
      (order, sort) => {
        setQueryParams({ order, sort });
      },
      [setQueryParams]
    );

    const pageProps = {
      ...props,
      loading: !data,
      members: data?.data ?? [],
      totalItems: data?.meta.pagination.total,
      numberOfMembers: data?.meta.active ?? 0,
      numberOfInvited: data?.meta.invited ?? 0,
      reload: revalidate,
      async onRemove(id) {
        if (!window.confirm('Remove user')) {
          return;
        }

        await kick(id);

        successToast({ message: 'The user has been removed.' });
      },
      async onChangeRole(id, roleId) {
        await changeRole(id, roleId);

        successToast({ message: 'The role has been changed.' });
      },
      query: queryParams.query ?? '',
      onChangeQuery(query) {
        setQueryParams({ query, page: 1 });
      },
      onSortBy,
    };

    return <Component {...pageProps} />;
  }

  hoist(WithMemberListPage, Component);

  WithMemberListPage.displayName = `withMemberListPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithMemberListPage;
}
