import React, { useEffect, useRef, useState, useContext } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { ifProp, prop, theme, withProp } from 'styled-tools';
import { Menu, MenuButton, MenuPopover } from '@reach/menu-button';
import { Field, Formik, Form } from 'formik';
import { PermissionGuard } from 'features/authentication/hocs/withPermission';
import ProjectContext from '../../../../store/projectContext';
import {
  ArrowUpIcon,
  CloseIcon,
  EditIcon,
  Icon,
  TrashIcon,
} from '../../../../components/Icons';
import {
  IconButton,
  LinkButton,
  Button,
} from '../../../authentication/components/Button';
import {
  TextFieldView,
  TextField,
} from '../../../authentication/components/TextField';
import { useAppClient } from '../../../../lib/AppProvider';

const initialValues = {
  tagName: '',
};

const validationSchema = yup.object({
  tagName: yup.string().required('Tag name is required'),
});

const TagEditor = styled(function TagEditor({
  className,
  name,
  onSubmit,
  onCancel,
}) {
  return (
    <Formik initialValues={{ name }} onSubmit={onSubmit}>
      {() => {
        return (
          <Form className={className} noValidate>
            <Field className="input" name="name" autoFocus />
            <div className="actions">
              <LinkButton type="button" onClick={onCancel}>
                Cancel
              </LinkButton>
              <LinkButton type="submit" primary>
                Save
              </LinkButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 10px;

  > .input {
    border-radius: 6px;
    border: 1px solid #ddd;
    padding: 0 10px;

    font-size: 12px;
    line-height: 1.7;
  }

  > .actions > ${LinkButton} {
    font-size: 12px;
    font-weight: bold;
    text-decoration: underline;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

const Tag = styled.div`
  display: inline-block;

  border-radius: 9px;

  padding: 2px 8px;
  font-size: 12px;
  line-height: 20px;
  font-weight: bold;

  color: ${ifProp('selected', '#fff', '#666')};
  background: ${ifProp(
    'selected',
    props => (props.color ? props.color : '#333'),
    '#ddd'
  )};
`;

const TagItem = styled(function TagItem({
  className,
  name,
  selected,
  onSelect,
  onRemove,
  onSubmit,
  color,
}) {
  const [isEditing, setIsEditing] = useState(false);

  if (isEditing) {
    return (
      <TagEditor
        name={name}
        onSubmit={values => {
          setIsEditing(false);
          onSubmit(values);
        }}
        onCancel={() => setIsEditing(false)}
      />
    );
  }

  return (
    <div className={className}>
      <Tag selected={selected} onClick={onSelect} color={color}>
        {name}
      </Tag>
      <div className="actions">
        {selected ? (
          <IconButton onClick={onRemove}>
            <CloseIcon size={16} />
          </IconButton>
        ) : (
          <>
            <IconButton onClick={() => setIsEditing(true)}>
              <EditIcon size={16} />
            </IconButton>
            <IconButton onClick={onRemove}>
              <TrashIcon size={16} />
            </IconButton>
          </>
        )}
      </div>
    </div>
  );
})`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 10px;

  > .actions > ${IconButton} {
    width: 24px;
    height: 24px;

    > ${Icon} {
      color: #ccc;
    }
  }
`;

const Popover = styled(MenuPopover)`
  position: relative;
  margin-top: 10px;

  border-radius: 6px;
  border: 1px solid #ddd;
  padding: 20px;
  width: ${ifProp(
    'width',
    withProp(prop('width'), w => `${w}px`),
    'auto'
  )};

  background: white;
  box-shadow: ${theme('shadow')};

  > hr {
    margin: 15px 0;

    border: 0 solid #ddd;
    border-top-width: 1px;
  }

  > .list {
    max-height: 110px;
    overflow-y: auto;
  }

  > .list > .placeholder {
    color: #999;
    font-size: 12px;
    text-align: center;
  }

  .selectable-list {
    ${Tag} {
      cursor: pointer;
    }
  }
`;

export const TagSelect = React.memo(styled(function TagSelect({
  className,
  onChange,
  value = [],
  projectId,
}) {
  const dom = useRef(null);
  const [width, setWidth] = useState(null);
  const [tags, setTags] = useState([]);
  const [show, setShow] = useState(false);
  const [query, setQuery] = useState('');
  const client = useAppClient();
  const { projectState, projectDispatch } = useContext(ProjectContext);

  const { tags: data } = projectState;
  const selectedById = value
    .map(({ id }) => id)
    .reduce((memo, id) => ({ ...memo, [id]: true }), {});

  const allTags = tags;
  const selectedTags = value;
  const selectableTags = allTags.filter(
    ({ id, name }) =>
      !selectedById[id] && name.toUpperCase().startsWith(query.toUpperCase())
  );

  async function handleCreate(input) {
    const tag = await client.createProjectTag(projectId, {
      name: input.tagName,
    });
    projectDispatch({ type: 'CREATE_TAG', data: tag });
    setQuery('');
    onChange(value.concat(tag));
  }

  async function updateTag(id, { name }) {
    await client.updateProjectTag(id, { name, projectId });
    projectDispatch({ type: 'UPDATE_TAG', tagId: id, tag: { name } });
  }

  async function removeTag(id) {
    await client.removeProjectTag(id, { projectId });
    projectDispatch({ type: 'REMOVE_TAG', tagId: id });
  }

  useEffect(() => {
    setWidth(dom.current.offsetWidth);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data) {
      setTags(data ?? []);
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <Menu>
      <div className={className}>
        <div className="label">Tags</div>
        <MenuButton className="field-group" ref={dom}>
          {value.length === 0 && <div className="placeholder">Select Tag</div>}
          <div className="tag-list">
            {selectedTags.map(({ id, name, color }) => (
              <Tag selected key={id} color={color}>
                {name}
              </Tag>
            ))}
          </div>
          <ArrowUpIcon />
        </MenuButton>
      </div>
      <Popover width={width}>
        <TextFieldView
          label="Search tag"
          value={query}
          onChange={e => setQuery(e.target.value)}
        />
        <hr />
        <div className="list selected-list">
          {value.length === 0 && (
            <div className="placeholder">No tag selected.</div>
          )}
          {selectedTags.map(({ id, name, color }) => (
            <TagItem
              selected
              key={id}
              name={name}
              onRemove={() => {
                onChange(value.filter(item => item.id !== id));
              }}
              color={color}
            />
          ))}
        </div>
        <hr />
        <div className="list selectable-list">
          {selectableTags.length === 0 && (
            <div className="placeholder">No tag found.</div>
          )}
          {selectableTags.map(({ id, name, color }) => (
            <TagItem
              key={id}
              name={name}
              color={color}
              onSubmit={form => updateTag(id, form)}
              onSelect={() => {
                onChange(value.concat({ id, name, color }));
              }}
              onRemove={() => removeTag(id)}
            />
          ))}
        </div>
        <hr />

        <div className={className}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={e => handleCreate(e)}
          >
            {({ submitForm }) => (
              <>
                {show ? (
                  <div className="form">
                    <TextField id="tagName" name="tagName" label="New Tag" />
                    <footer className="footer">
                      <span
                        className="cancel-btn"
                        onClick={() => setShow(false)}
                      >
                        Cancel
                      </span>
                      <Button
                        type="submit"
                        className="add-btn"
                        onClick={submitForm}
                      >
                        + Add
                      </Button>
                    </footer>
                  </div>
                ) : (
                  <PermissionGuard allow="project-tag:write">
                    <span className="add-tag-btn" onClick={() => setShow(true)}>
                      + Add new tag
                    </span>
                  </PermissionGuard>
                )}
              </>
            )}
          </Formik>
        </div>
      </Popover>
    </Menu>
  );
})`
  margin: 0;

  border: 0;
  padding: 0;
  width: 100%;

  background: transparent;

  .add-tag-btn {
    color: ${theme('colors.primary')};
    cursor: pointer;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .cancel-btn {
      color: ${theme('colors.primary')};
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }

  > .label {
    margin-bottom: 5px;

    color: #9fa3ae;
    font-size: 12px;
    line-height: 1.7;
  }

  > .field-group {
    text-align: left;

    position: relative;

    border-radius: 6px;
    border: 1px solid ${ifProp('error', theme('colors.error'), '#c1c3ca')};
    width: 100%;
    padding: 10px 60px 10px ${ifProp('hasPrefix', '50px', '10px')};

    background: #ffffff;

    > .placeholder {
      color: #9fa3ae;
    }

    > .tag-list {
      overflow-x: auto;
      margin-bottom: -10px;
      padding-bottom: 10px;

      white-space: nowrap;
    }

    > .tag-list > ${Tag} {
      margin-right: 10px;
    }

    > ${ArrowUpIcon} {
      width: 10px;
      height: 10px;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%) rotateZ(180deg);
      color: ${theme('colors.secondary')};
    }

    &:focus,
    &:active {
      outline-color: ${theme('colors.primary')};
    }
  }
`);
