import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { Modal } from './Modal';
import {
  CloseIcon,
  FolderV2Icon,
  DollarCircleIcon,
  UserCircleIcon,
} from './Icons';
import {
  Button,
  IconButton,
  OutlineButton,
} from '../features/authentication/components/Button';
import { Avatar } from './Avatar';
import { usePermissionGuard } from '../features/authentication/hocs/withPermission';

function MobileMenuModal({ className, isOpen, onClose, company }) {
  const navigate = useNavigate();
  const location = useLocation();
  const allowSuperAdmin = usePermissionGuard('member-super-admin:write');
  return (
    <Modal
      className={className}
      isOpen={isOpen}
      onClose={onClose}
      hideCloseButton
    >
      <div className="header">
        <div className="title">
          <IconButton onClick={onClose}>
            <CloseIcon size={16} />
          </IconButton>

          <h1>{company?.name ?? '-'}</h1>
        </div>
        <Avatar
          className="rectangle-avatar"
          name={company?.name}
          initial={company?.initial}
          avatarUrl={company?.logoUrl}
          noTooltip
        />
      </div>
      <div
        className="menu"
        onClick={() => {
          onClose();
          navigate(`/company/${company.id}`);
        }}
      >
        <FolderV2Icon
          color={
            !location.pathname.includes('expenses') &&
            !location.pathname.includes('salaries')
              ? '#22c759'
              : '#9fa3ae'
          }
        />
        <h5
          className="title"
          style={{
            color:
              !location.pathname.includes('expenses') &&
              !location.pathname.includes('salaries')
                ? '#22c759'
                : '#9fa3ae',
          }}
        >
          Project
        </h5>
      </div>
      {allowSuperAdmin && (
        <div
          className="menu"
          onClick={() => {
            onClose();
            navigate(`/company/${company.id}/expenses`);
          }}
        >
          <DollarCircleIcon
            color={
              location.pathname.includes('expenses') ? '#22c759' : '#9fa3ae'
            }
          />
          <h5
            className="title"
            style={{
              color: location.pathname.includes('expenses')
                ? '#22c759'
                : '#9fa3ae',
            }}
          >
            Company Expense
          </h5>
        </div>
      )}
      {allowSuperAdmin && (
        <div
          className="menu"
          onClick={() => {
            onClose();
            navigate(`/company/${company.id}/salaries`);
          }}
        >
          <UserCircleIcon
            color={
              location.pathname.includes('salaries') ? '#22c759' : '#9fa3ae'
            }
          />
          <h5
            className="title"
            style={{
              color: location.pathname.includes('salaries')
                ? '#22c759'
                : '#9fa3ae',
            }}
          >
            Employee
          </h5>
        </div>
      )}
    </Modal>
  );
}

const StyledMobileMenuModal = styled(MobileMenuModal)`
  width: 100vw;
  margin: 0;
  border-radius: 0;
  height: 100%;
  padding: 0;
  background-color: #f5f8f8;

  .header {
    width: 100%;
    padding: 15px;
    height: 60px;
    background-color: white;
    margin-bottom: 20px;
    align-items: center;
    display: flex;
    justify-content: space-between;

    > .rectangle-avatar > .image {
      width: 36px;
      height: 36px;
      border-radius: 6px;
    }

    > .title {
      display: flex;
      align-items: center;

      > ${IconButton} {
        color: #000;
      }
      > h1 {
        padding-left: 5px;
        font-size: 16px;
      }
    }

    > h4 {
      > span {
        color: ${theme('colors.primary')};
        font-weight: normal;
      }
    }
  }

  .menu {
    margin: 0 15px 10px 15px;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    background-color: white;
    height: 66px;
    border-radius: 6px;
    box-shadow: 0 5px 10px 0 rgba(58, 58, 58, 0.05);

    > .title {
      padding-left: 5px;
    }
  }

  .content {
    margin-bottom: 20px;
  }

  .label {
    font-size: 12px;
    color: #999999;
  }

  .radio-color-group {
    margin: 0;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    ${Button} {
      margin-left: 12px;
    }

    ${OutlineButton}.red-outline {
      border: 1px solid #e01b32;
      color: #e01b32;

      &:hover {
        background-color: #e01b32;
        color: white;
      }
    }
  }
`;

export { StyledMobileMenuModal as MobileMenuModal };
