import React, { useState } from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import {
  OutlineButton,
  // eslint-disable-next-line import/no-useless-path-segments
} from '../../../../features/authentication/components/Button';
import { Modal } from '../../../../components/Modal';
import { Radio } from '../../../../components/Radio';

function DeleteTicketModal({
  className,
  isDeleteRepeat,
  isOpen,
  onClose,
  onDelete,
  deleteText,
}) {
  const [selected, setSelected] = useState('this');

  function onCheck(select) {
    setSelected(select);
  }

  return (
    <Modal
      className={className}
      isOpen={isOpen}
      onClose={onClose}
      hideCloseButton
    >
      <div className="container">
        <div className="title">{deleteText}</div>
        {isDeleteRepeat && (
          <>
            <Radio
              className="radio"
              checked={selected === 'this'}
              value="this"
              label="This ticket"
              onClick={e => onCheck(e)}
            />
            <Radio
              className="radio"
              checked={selected === 'this_and_following'}
              value="this_and_following"
              label="This and next ticket"
              onClick={e => onCheck(e)}
            />
            <Radio
              className="radio"
              checked={selected === 'all'}
              value="all"
              label="All ticket"
              onClick={e => onCheck(e)}
            />
          </>
        )}

        <div className="btn-action">
          <OutlineButton className="cancel-btn" onClick={onClose}>
            Cancel
          </OutlineButton>
          <OutlineButton
            className="delete-btn"
            onClick={() => onDelete(selected)}
          >
            Yes, Delete
          </OutlineButton>
        </div>
      </div>
    </Modal>
  );
}

const StyledDeleteTicketModal = styled(DeleteTicketModal)`
  max-width: 420px;
  width: 90vw;
  position: relative;

  > .close {
    display: none;
  }

  .close-btn {
    position: absolute;
    background-color: #999;
    color: white;
    right: 10px;
    top: 10px;
    padding: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 0;
  }

  .container {
    span {
      line-height: 1.57;
    }

    > .image {
      height: 215px;
      width: fit-content;
      margin-bottom: 30px;
    }

    > .title {
      color: #333;
      font-size: 22px;
      margin-bottom: 20px;
      font-weight: bold;
    }

    > span {
      color: #333;
      font-size: 16px;
      margin-bottom: 20px;
    }

    > .btn-action {
      display: flex;
      margin-top: 40px;
      justify-content: flex-end;
      flex-wrap: wrap-reverse;

      > .delete-btn {
        max-width: 120px;
        width: 100%;
        border: 1px solid #e01b32;
        color: #e01b32;
        padding: 12px 0;

        &:hover {
          background-color: #e01b32;
          color: white;
        }

        @media (max-width: ${theme('breakpoints.mobile')}px) {
          max-width: none;
          margin-bottom: 20px;
        }
      }

      > .cancel-btn {
        border: none;
        color: #c1c3ca;

        &:hover {
          background-color: transparent;
        }
        @media (max-width: ${theme('breakpoints.mobile')}px) {
          width: 100%;
        }
      }
    }
  }
`;

export { StyledDeleteTicketModal as DeleteTicketModal };
