import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { Outlet } from 'react-router-dom';
import { AccountSideBar } from './components/AccountSideBar';

function AccountLayout({ className }) {
  return (
    <div className={className}>
      <AccountSideBar />
      <Outlet />
    </div>
  );
}

const StyledAccountLayout = styled(AccountLayout)`
  display: grid;
  grid-template-columns: 290px 1fr;

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    grid-template-columns: auto;
  }
`;

const MemoAccountLayout = React.memo(StyledAccountLayout);

export { MemoAccountLayout as AccountLayout };
