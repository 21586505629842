import React, { useMemo } from 'react';
import styled from 'styled-components';
import pick from 'ramda/es/pick';
import * as yup from 'yup';
import { theme } from 'styled-tools';

import { TextFieldView } from '../../features/authentication/components/TextField';
import {
  SearchIcon,
  PlusIcon,
  CirclePlusIcon,
  TrashIcon,
  CloseFillIcon,
} from '../Icons';
import { Avatar } from '../Avatar';
import { listToMapBy } from '../../lib/utils';
import { LoadingIndicator } from '../LoadingIndicator';
import { Badge } from '../ListBoard';

const emailValidator = yup.string().required().email();

function MemberManagement({
  className,
  onSearch,
  search,
  members,
  value,
  onChange,
  loading,
}) {
  const valueById = useMemo(() => listToMapBy('id', value), [value]);
  const selectedMembers = value;
  const availableMembers = members
    .filter(member => !valueById[member.id])
    .filter(member => member.email.search(search) > -1);
  const guestEmail = search;
  const showAddGuestBadge =
    emailValidator.isValidSync(search) &&
    !selectedMembers.some(member => member.email === search) &&
    !availableMembers.some(member => member.email === search);
  function handleAddGuest() {
    onSearch({ target: { value: '' } });
    onChange(
      value.concat({
        email: guestEmail,
        id: guestEmail,
        name: guestEmail,
        initial: guestEmail.substr(0, 2),
        roleId: 'guest', // TODO: refactor this
      })
    );
  }

  return (
    <div className={className}>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <div className="selection-area">
          <div className="left-section">
            <h3 className="title">Add members</h3>
            <div className="search">
              <TextFieldView
                id="search"
                name="search"
                label="Search with name or email"
                SuffixIcon={SearchIcon}
                onChange={onSearch}
                value={search}
              />
            </div>
            <div className="members">
              {showAddGuestBadge && (
                <Badge color="#999" onClick={handleAddGuest}>
                  <PlusIcon size={12} /> {guestEmail}
                </Badge>
              )}
              {availableMembers?.map(member => (
                <div
                  key={member.id}
                  className="member-item"
                  onClick={() => onChange(value.concat(member))}
                >
                  <Avatar {...pick(['name', 'initial', 'avatarUrl'], member)} />
                  <span className="email">
                    <h5>{member.name}</h5>
                    <h5 style={{ color: '#9fa3ae' }}>{member.email}</h5>
                  </span>
                  <span className="role">
                    {member.roleId.charAt(0).toUpperCase() +
                      member.roleId.slice(1)}
                  </span>
                  <CirclePlusIcon color="#22c759" size={24} />
                </div>
              ))}
            </div>
          </div>
          <div className="line" />
          <div className="right-section">
            <div className="clear-all">
              <div>Selected members ({selectedMembers.length})</div>
              {value.length > 1 && (
                <div className="clear" onClick={() => onChange([])}>
                  Clear all
                </div>
              )}
            </div>
            <div className="selected-member">
              {selectedMembers?.map(member => (
                <div key={member.id} className="member-item">
                  <Avatar {...pick(['name', 'initial', 'avatarUrl'], member)} />
                  <span className="email">
                    <h5>{member.name}</h5>
                    <h5 style={{ color: '#9fa3ae' }}>
                      {member.email} {member.me ? '(Me)' : null}
                    </h5>
                  </span>
                  <span className="role">
                    {(
                      member.roleId.charAt(0).toUpperCase() +
                      member.roleId.slice(1)
                    ).replaceAll('_', ' ')}
                  </span>
                  {!member.me ? (
                    <span
                      className="delete-btn"
                      onClick={() =>
                        onChange(value.filter(m => member.id !== m.id))
                      }
                    >
                      <CloseFillIcon color="#9fa3ae" size={24} />
                    </span>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const StyledMemberManagement = styled(MemberManagement)`
  ${Badge} {
    margin-bottom: 15px;
    cursor: pointer;
  }

  h5 {
    font-weight: normal;
  }

  .role {
    font-size: 14px;
  }

  .title {
    margin: 0 !important;
    margin-bottom: 10px !important;
    font-size: 14px;
  }

  .selection-area {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 70px;

    @media (max-width: ${theme('breakpoints.mobile')}px) {
      grid-gap: 20px;

      > .line {
        width: 100%;
        border-bottom: 1px dashed lightgrey;
      }
    }

    > .left-section {
      > .search {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        h3 {
          font-size: 14px;
          width: 150px;
        }

        ${TextFieldView} {
          flex: 1;
          margin: 0;
        }
      }

      > .members {
        height: 200px;
        overflow-y: scroll;

        > .member-item {
          display: grid;
          grid-template-columns: 30px 2fr 1fr 32px;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .member-item {
      align-items: center;
      padding: 8px 0;
      border-bottom: 1px dashed lightgrey;
      grid-gap: 8px;

      @media (max-width: ${theme('breakpoints.mobile')}px) {
        border-bottom: unset;
      }

      .email {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    > .right-section {
      h3 {
        font-size: 14px;
      }

      > .clear-all {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .clear {
          font-size: 14px;
          color: #9fa3ae;
          text-decoration: underline;
          cursor: pointer;
        }

        > .clear-btn {
          text-decoration: underline;

          &:hover {
            cursor: pointer;
          }
        }
      }

      > .selected-member {
        height: 200px;
        overflow-y: scroll;

        > .member-item {
          display: grid;
          grid-template-columns: 32px 1fr 90px 40px;
        }
      }

      .delete-btn {
        cursor: pointer;
        text-align: center;

        &:hover {
          ${TrashIcon} {
            color: ${theme('colors.error')};
          }
        }
      }
    }
  }

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    .selection-area {
      grid-template-columns: 100%;

      > .left-section {
        width: 100%;
        padding: 0;

        > .search {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          > h3 {
            margin: 10px 0;
          }

          > ${TextFieldView} {
            width: 100%;
          }
        }
      }

      > .right-section {
        width: 100%;
        padding: 0;
        > .clear-all {
          margin-bottom: 10px;
        }
      }
    }
  }
`;

export { StyledMemberManagement as MemberManagement };
