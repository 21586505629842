import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';

export const Breadcrumbs = React.memo(
  styled(function Breadcrumbs({ className, data }) {
    return (
      <div className={className}>
        {data.map(item => (
          <div className="item" key={item}>
            {item}
          </div>
        ))}
      </div>
    );
  })`
    > .item {
      position: relative;

      display: inline-block;
      padding: 8px;

      font-weight: bold;
      color: #c1c3ca;

      &:not(:last-child):after {
        position: absolute;
        top: 10px;
        right: -2px;

        content: '>';
        font-size: 12px;

        display: block;
      }

      &:last-child {
        color: ${theme('colors.primary')};
      }
    }
  `,
  (prev, next) => prev?.data?.join(',') === next?.data?.join(',')
);
