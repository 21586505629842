import { CompanySalariesPage } from './CompanySalariesPage';
import { withCompanySalariesPage } from './withCompanySalariesPage';
import { withPermission } from '../../../authentication/hocs/withPermission';

const ConnectedCompanySalariesPage = withPermission(
  withCompanySalariesPage(CompanySalariesPage),
  'company-employee-salary:read'
);

export { ConnectedCompanySalariesPage as CompanySalariesPage };
