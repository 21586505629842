import React from 'react';
import { Dialog } from '@reach/dialog';
import { prop } from 'styled-tools';
import styled from 'styled-components';

import { CloseFillIcon } from './Icons';

function Modal({
  className,
  children,
  onClose,
  isOpen,
  label = 'Modal',
  hideCloseButton,
}) {
  return (
    <Dialog className={className} isOpen={isOpen} aria-label={label}>
      {!hideCloseButton && (
        <button className="close" type="button" onClick={onClose}>
          <CloseFillIcon size={24} />
        </button>
      )}
      <div className="content">{children}</div>
    </Dialog>
  );
}

const StyledModal = styled(Modal)`
  ${props => props.width && `max-width: ${props.width};`}
  padding: ${prop('padding', '30px')};

  > .close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: unset;
    border: 0;

    svg {
      color: #c1c3ca;
      border-radius: 50%;
    }
  }
`;

export { StyledModal as Modal };
