import React, { useState, useContext } from 'react';
import hoist from 'hoist-non-react-statics';
import { useParams } from 'react-router-dom';
import { useAppClient } from '../../lib/AppProvider';
import { useFilter } from './useFilter';
import ProjectContext from '../../store/projectContext';

export function useProjectEpic({ projectId }) {
  const client = useAppClient();
  const { projectState, projectDispatch } = useContext(ProjectContext);
  const { epics } = projectState;

  return {
    data: epics,
    async create({ name, color }) {
      const result = await client.createEpic({ projectId, name, color });
      projectDispatch({
        type: 'CREATE_EPIC',
        epic: result,
      });
    },
    async edit({ epicId, name, color }) {
      await client.editEpic({ projectId, epicId, name, color });
      projectDispatch({
        type: 'UPDATE_EPIC',
        epic: { id: epicId, name, color },
      });
    },
    async delete({ epicId }) {
      await client.deleteEpic({ projectId, epicId });
      projectDispatch({
        type: 'REMOVE_EPIC',
        epicId,
      });
    },
  };
}

export function withEpicFilter(Component) {
  function WithEpicFilter({
    projectId: initProjectId,
    onOpenModal,
    onRefetch,
    ...props
  }) {
    const [isAddEpicModal, setIsAddEpicModal] = useState(false);
    const [isDeleteEpicModal, setIsDeleteEpicModal] = useState(false);
    const [selectedEpic, setSelectedEpic] = useState(null);
    const [epicMode, setEpicMode] = useState('');
    const { id = initProjectId } = useParams();

    const {
      data,
      create: createEpic,
      delete: deleteEpic,
      edit: editEpic,
    } = useProjectEpic({
      projectId: id,
    });

    const filter = useFilter({
      key: 'epic',
      data,
    });

    const onAddEpicModal = () => {
      if (onOpenModal) onOpenModal();
      setIsAddEpicModal(true);
      setEpicMode('create');
    };

    const onEditEpicModal = epicItem => () => {
      if (onOpenModal) onOpenModal();
      setIsAddEpicModal(true);
      setSelectedEpic(epicItem);
      setEpicMode('edit');
    };

    const onCloseEpicModal = () => {
      setIsAddEpicModal(false);
    };

    const onOpenDeleteEpicModal = epicItem => () => {
      setIsDeleteEpicModal(true);
      setSelectedEpic(epicItem);
    };

    const onCloseDeleteEpicModal = () => {
      setIsDeleteEpicModal(false);
    };

    const onSubmit = ({ epicName: name, color }, { resetForm }) => {
      if (epicMode === 'edit') {
        editEpic({ epicId: selectedEpic.id, name, color });
      }
      if (epicMode === 'create') {
        createEpic({ name, color });
      }
      resetForm();
      setIsAddEpicModal(false);

      if (onRefetch) {
        onRefetch();
      }
    };

    const handleKeyPress = (e, handleSubmit) => {
      if (e.key === 'Enter') {
        handleSubmit();
      }
    };

    // const onEdit = ({ epicName: name }, { resetForm }) => {
    //   editEpic({ projectId: id, name });
    //   resetForm();
    //   setIsAddEpicModal(false);
    // };

    const onDelete = () => {
      deleteEpic({ epicId: selectedEpic.id });
      setIsDeleteEpicModal(false);
    };

    const pageProps = {
      ...props,
      ...filter,
      projectId: id,
      data,
      isAddEpicModal,
      onAddEpicModal,
      onCloseEpicModal,
      onSubmit,
      isDeleteEpicModal,
      onOpenDeleteEpicModal,
      onCloseDeleteEpicModal,
      onDelete,
      selectedEpic,
      epicMode,
      onEditEpicModal,
      handleKeyPress,
    };

    return <Component {...pageProps} />;
  }

  hoist(WithEpicFilter, Component);

  WithEpicFilter.displayName = `withEpicFilter(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithEpicFilter;
}
