import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { theme } from 'styled-tools';
import { Logo } from '../../../../components/Logo';
import { AuthenticationLayout } from '../../components/AuthenticationLayout';
import { TextField } from '../../components/TextField';
import { Button } from '../../components/Button';
import { VerifyYourEmailModal } from '../../components/VerifyYourEmailModal';
import { DocumentTitle } from '../../../../components/DocumentTitle';

const initialValues = {
  firstName: '',
  lastName: '',
  companyName: '',
  email: '',
  password: '',
  confirmPassword: '',
};

const validationSchema = yup.object({
  firstName: yup.string().trim().required('First name is required'),
  lastName: yup.string().trim().required('Last name is required'),
  companyName: yup.string().trim().required('Company name is required'),
  email: yup.string().required('Email is required').email('Email is invalid'),
  password: yup
    .string()
    .matches(
      '^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$',
      'Password should contain at least one character, one number, minimum length 8 character.'
    )
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Confirm password should be matched.')
    .required('Please confirm your password'),
});

function RegistrationPage({
  className,
  onSubmit,
  error,
  onCloseModal,
  isShowModal,
}) {
  return (
    <AuthenticationLayout className={className}>
      <DocumentTitle title="Create a Toolings Account" showCredit={false} />
      <div className="container">
        <header className="header">
          <Logo />
        </header>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {() => (
            <>
              <Form className="form" noValidate>
                <h4 className="form-title">Register</h4>
                <TextField
                  id="firstName"
                  name="firstName"
                  label="First name"
                  required
                />
                <TextField
                  id="lastName"
                  name="lastName"
                  label="Last name"
                  required
                />
                <TextField
                  id="companyName"
                  name="companyName"
                  label="Company name"
                  required
                />
                <TextField
                  type="email"
                  id="email"
                  name="email"
                  label="Working email"
                  autoComplete="off"
                  required
                />
                <TextField
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="off"
                  label="Create password"
                  required
                  hint="Password should contain at least one character, one number, minimum length 8 character."
                />
                <TextField
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  autoComplete="off"
                  label="Confirm password"
                  required
                />
                <Button className="submit" type="submit">
                  Register
                </Button>
              </Form>
              <footer className="footer">
                <Link className="link" to="/auth/sign-in">
                  Login
                </Link>
              </footer>
            </>
          )}
        </Formik>
        {error && <div className="error">{error}</div>}
      </div>
      <VerifyYourEmailModal isOpen={isShowModal} onClose={onCloseModal} />
    </AuthenticationLayout>
  );
}

const StyledRegistrationPage = styled(RegistrationPage)`
  .form {
    margin-bottom: 16px;
  }

  .submit {
    display: block;
    width: 100%;
  }

  .error {
    padding: 30px 0;

    color: ${theme('colors.error')};
    text-align: center;
    font-weight: bold;
  }

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    .form {
      padding: 0 15px;
    }
  }
`;

export { StyledRegistrationPage as RegistrationPage };
