import React, { useRef, createContext, useContext } from 'react';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { DateTime } from 'luxon';
import Portal from '@reach/portal';
import { useTooltip, TooltipPopup } from '@reach/tooltip';
import { Button } from '../../authentication/components/Button';
import { withActivityLogs } from './withActivityLogs';
import { Avatar } from '../../../components/Avatar';
import GlobalContext from '../../../store/globalContext';
import { SendIcon, PaperClipIcon } from '../../../components/Icons';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { MentionsInput } from '../../../components/MentionsInput';
import { MentionsText } from '../../../components/MentionsText';
import { FileList } from '../pages/AllTicketPage/FileList';
import { PictureListView } from '../pages/AllTicketPage/PictureList';

const ActivityLogContext = createContext(null);

const centered = (triggerRect, tooltipRect) => {
  const triggerCenter = triggerRect.left + triggerRect.width / 2;
  const left = triggerCenter - tooltipRect.width / 2;
  const maxLeft = window.innerWidth - tooltipRect.width - 2;
  return {
    left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
    top: triggerRect.top - tooltipRect.height - 8,
  };
};

const initialValues = {
  comment: '',
  mentionIds: [],
  images: [],
};

const validationSchema = yup.object({
  comment: yup.string().required(),
});

const CommentForm = ({
  handleSubmit,
  initialValuesForm,
  idFileList = 'commentImages',
}) => {
  const { projectMembers } = useContext(ActivityLogContext);
  const imageInputRef = useRef(null);

  function handleUploadImages() {
    imageInputRef.current.click();
  }

  return (
    <Formik
      initialValues={initialValuesForm}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <MentionsInput
            name="comment"
            placeholder="Write a comment..."
            members={projectMembers}
            multiline
            rows={6}
            onChange={(comment, mentionIds) => {
              setFieldValue('comment', comment);
              setFieldValue('mentionIds', mentionIds);
            }}
          />
          <FileList
            ref={imageInputRef}
            id={idFileList}
            name="images"
            display="mix"
            style={{
              display: values.images.length ? 'flex' : 'none',
              marginBottom: 16,
            }}
          />
          <div className="actions">
            <div className="add-images-button" onClick={handleUploadImages}>
              <PaperClipIcon />
            </div>
            <div className="comment-actions">
              <Button className="submit" type="submit">
                <SendIcon />
                Send
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

function ActivityLogs({
  className,
  status,
  isFetching,
  canFetchMore,
  fetchMore,
  handleCreateComment,
  handleUpdateComment,
  showDetail,
  toggleShowDetail,
  data = [],
  profile,
  projectMembers,
  editCommentId,
  setEditCommentId,
  handleDeleteComment,
}) {
  const valuesContext = {
    editCommentId,
    setEditCommentId,
    projectMembers,
    handleUpdateComment,
    handleDeleteComment,
  };

  return (
    <ActivityLogContext.Provider value={valuesContext}>
      <div className={className}>
        {status === 'loading' ? (
          <LoadingIndicator />
        ) : (
          <>
            <div className="header">
              <span>Activity</span>
              <div className="detail" onClick={toggleShowDetail}>
                {showDetail ? 'Hide detail' : 'Show detail'}
              </div>
            </div>
            <div className="form">
              <Avatar
                avatarUrl={profile?.avatarUrl}
                name={profile?.name ?? '-'}
                initial={profile?.initial ?? '-'}
              />
              <CommentForm
                handleSubmit={handleCreateComment}
                initialValuesForm={initialValues}
                idFileList="fileListCreateForm"
              />
            </div>
            <div className="activity-comment-show">
              {data.map(item => (
                <ActivityLogsItem
                  key={item.id}
                  item={item}
                  projectMembers={projectMembers}
                />
              ))}
              {isFetching && <LoadingIndicator size="sm" />}
              {!isFetching && canFetchMore && (
                <Waypoint onEnter={() => fetchMore()} />
              )}
            </div>
          </>
        )}
      </div>
    </ActivityLogContext.Provider>
  );
}

const CommentLogsItem = ({ item, commentImages }) => {
  const {
    editCommentId,
    setEditCommentId,
    handleUpdateComment,
    handleDeleteComment,
  } = useContext(ActivityLogContext);
  const isEdit = editCommentId === item.loggable.id;
  const { globalState } = useContext(GlobalContext);
  const { profile } = globalState;
  const canEdit = profile?.id === item.loggable.whodunit?.id;

  const initialValuesBeforeUpdate = {
    comment: item.loggable.body,
    mentionIds: [],
    images: item.loggable.attachments.map(file => ({
      ...file,
      hash: file.url || '',
    })),
  };

  return (
    <section className="comment">
      <div className="left-section">
        <Avatar
          className="avatar"
          key={item.id}
          avatarUrl={item.loggable.whodunit?.avatarUrl}
          name={item.loggable.whodunit?.fullName}
          initial={
            item.loggable.whodunit
              ? `${item.loggable.whodunit?.firstName[0]}${item.loggable.whodunit?.lastName[0]}`
              : '-'
          }
        />
      </div>
      <div className="right-section">
        <div className="header">
          <span className="name">
            {item.loggable.whodunit?.fullName ?? '[deleted user]'}
          </span>
        </div>
        <div className="body comment-area">
          {!isEdit ? (
            <MentionsText>{item.loggable.body}</MentionsText>
          ) : (
            <CommentForm
              handleSubmit={handleUpdateComment}
              initialValuesForm={initialValuesBeforeUpdate}
              idFileList="fileListUpdateForm"
            />
          )}
          {commentImages.length && !isEdit ? (
            <PictureListView
              images={commentImages}
              style={{ padding: 0, marginTop: 10 }}
            />
          ) : null}
        </div>
        {canEdit &&
          (!isEdit ? (
            <div className="footer">
              <span
                className="edit"
                onClick={() => setEditCommentId(item.loggable.id)}
              >
                Edit
              </span>
              <span
                className="delete"
                onClick={() => handleDeleteComment(item.loggable.id)}
              >
                Delete
              </span>
              <span className="created-at">
                {DateTime.fromISO(item.createdAt).toFormat(
                  "dd/MM/yyyy 'at' HH:mm"
                )}
              </span>
            </div>
          ) : (
            <div className="footer">
              <span className="edit" onClick={() => setEditCommentId(null)}>
                Cancel
              </span>
            </div>
          ))}
      </div>
    </section>
  );
};

const ActivityLogsItem = styled(({ className, item }) => {
  const [trigger, tooltip] = useTooltip();
  const { isVisible, triggerRect } = tooltip;
  const commentImages = item.loggable.attachments;

  return (
    <>
      <div className={className}>
        {item.type === 'activity_log' ? (
          <div className="activity-log">
            <div className="left-section">
              <Avatar
                className="avatar"
                key={item.id}
                avatarUrl={item.loggable.whodunit?.avatarUrl}
                name={item.loggable.whodunit?.fullName}
                initial={`${item.loggable.whodunit?.firstName[0]}${item.loggable.whodunit?.lastName[0]}`}
              />
            </div>
            <div className="right-section">
              <div className="header">
                <span className="name">{item.loggable.whodunit?.fullName}</span>
                <span className="event">{item.loggable.event}</span>
                <span className="type">{item.loggable.trackableType}</span>
                <span className="usecase-name">{item.useCase.name}</span>
                <span className="for">for</span>
                <span className="usecase-gate">{item.useCase.gate}</span>
              </div>
              <div className="body">
                <span className="see-change" {...trigger}>
                  See change
                </span>
                <span className="created-at">
                  {DateTime.fromISO(item.createdAt).toFormat(
                    "dd/MM/yyyy 'at' HH:mm"
                  )}
                </span>
              </div>
            </div>
          </div>
        ) : item.type === 'comment' ? (
          <CommentLogsItem item={item} commentImages={commentImages} />
        ) : null}
      </div>
      {isVisible && (
        // The Triangle. We position it relative to the trigger, not the popup
        // so that collisions don't have a triangle pointing off to nowhere.
        // Using a Portal may seem a little extreme, but we can keep the
        // positioning logic simpler here instead of needing to consider
        // the popup's position relative to the trigger and collisions
        <Portal>
          <div
            style={{
              position: 'absolute',
              left: triggerRect && triggerRect.left - 6 + triggerRect.width / 2,
              top: triggerRect && triggerRect.top - window.scrollY - 8,
              width: 0,
              height: 0,
              borderLeft: '6px solid transparent',
              borderRight: '6px solid transparent',
              borderTop: '6px solid #eee',
              boxShadow: '0 10px 20px 0 rgba(0, 128, 42, 0.08)',
              zIndex: 2,
            }}
          />
        </Portal>
      )}
      <TooltipPopup
        {...tooltip}
        label={
          item.type === 'activity_log'
            ? item.loggable.revisions[0].description
            : ''
        }
        aria-label={
          item.type === 'activity_log'
            ? item.loggable.revisions[0].description
            : ''
        }
        style={{
          background: 'white',
          color: 'black',
          border: '1px solid #22c759',
          borderRadius: '6px',
          boxShadow: '0 10px 20px 0 rgba(0, 128, 42, 0.08)',
          padding: '0.5em 1em',
          zIndex: 999,
        }}
        position={centered}
      />
    </>
  );
})`
  margin-bottom: 30px;
  padding: 0;
  font-size: 12px;

  .activity-log {
    display: flex;
    align-items: center;

    > .right-section {
      margin-left: 10px;

      > .header {
        display: flex;
        align-items: center;
        margin-bottom: 6px;

        > .name {
          margin-right: 8px;
          font-weight: bold;
        }

        > .event {
          margin-right: 8px;
        }

        > .type {
          margin-right: 8px;
          color: ${theme('colors.primary')};
        }

        > .usecase-name {
          margin-right: 8px;
          color: ${theme('colors.primary')};
        }

        > .for {
          margin-right: 8px;
        }
      }

      > .body {
        > .see-change {
          margin-right: 8px;
          color: #9fa3ae;
          text-decoration: underline;

          &:hover {
            cursor: pointer;
          }
        }

        > .created-at {
          color: #c1c3ca;
        }
      }
    }
  }

  > .comment {
    display: flex;

    > .right-section {
      width: calc(100% - 42px);
      margin-left: auto;

      > .header {
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        height: 35px;

        > .created-at {
          color: #c1c3ca;
        }

        > .name {
          margin-right: 16px;
          font-weight: bold;
        }
      }

      > .body {
        margin-bottom: 6px;

        > .event {
          margin-right: 6px;
        }

        > .type {
          font-weight: bold;
          color: #333;
          margin-right: 6px;
        }
      }

      > .footer {
        > .see-change {
          color: ${theme('colors.primary')};
          text-decoration: underline;

          &:hover {
            background-color: ${theme('colors.primary')};
            color: white;
            cursor: pointer;
          }
        }

        > .edit,
        .delete {
          text-decoration: underline;
          color: #9fa3ae;
          margin-top: 12px;
          cursor: pointer;
        }

        > .delete {
          margin-left: 12px;
          margin-right: 12px;
        }

        > .created-at {
          color: #c1c3ca;
        }
      }

      > .comment-area {
        background-color: #f5f8f8;
        border-radius: 6px;
        padding: 12px;
        max-width: 100%;
        overflow-wrap: anywhere;
        white-space: pre-wrap;
      }
    }
  }
`;

const StyledActivityLogs = styled(ActivityLogs)`
  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-top: 2px solid #f5f5f5;

    > span {
      color: #3a3a3a;
      font-weight: bold;
      font-size: 14px;
    }

    > .detail {
      text-decoration: underline;
      color: #9fa3ae;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > .add-images-button {
      cursor: pointer;
    }

    > .comment-actions {
      margin-left: 15px;
    }
  }

  .form {
    display: flex;
    margin-bottom: 20px;

    > form {
      width: calc(100% - 42px);
      margin-left: auto;
    }
  }

  .activity-comment-show {
    max-height: 480px;
    overflow: scroll;
  }
`;

const ConnectStyledActivityLogs = withActivityLogs(StyledActivityLogs);

export { ConnectStyledActivityLogs as ActivityLogs };
