import React from 'react';
import styled from 'styled-components';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { theme } from 'styled-tools';
import { Modal } from '../Modal';
import { ColorPalette } from '../ColorPalette';
import { colorOptions } from '../ColorPalette/ColorPalette';

const validationSchema = yup.object({
  epicName: yup.string().required(),
});

function EpicCreateModal({
  className,
  isOpen,
  onClose,
  onSubmit,
  selectedEpic,
  epicMode,
  projectName,
  handleKeyPress,
  projectColor,
}) {
  const initialValues = {
    epicName: epicMode === 'edit' ? selectedEpic.name : '',
    color:
      epicMode === 'edit'
        ? selectedEpic?.color
        : projectColor || colorOptions[0],
  };

  return (
    <Modal className={className} isOpen={isOpen} onClose={onClose}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ handleSubmit }) => (
          <Form noValidate>
            <h2 style={{ fontSize: '20px' }}>
              {epicMode === 'edit' ? 'Edit Epic' : 'Create New Epic'}
            </h2>
            <div className="title">
              <h4 style={{ fontSize: '14px' }}>
                Project :{' '}
                <span style={{ color: '#22c759' }}>{projectName}</span>
              </h4>
            </div>
            <div className="content">
              <Field
                id="epicName"
                name="epicName"
                label="Epic Name"
                autocomplete="off"
              >
                {({ field }) => (
                  <div>
                    <p
                      style={{
                        color: '#c1c3ca',
                        fontSize: '12px',
                        marginBottom: '10px',
                      }}
                    >
                      Epic Name
                    </p>
                    <input
                      type="text"
                      style={{
                        width: '100%',
                        height: '40px',
                        border: '1px solid #dce0ea',
                        color: '#3a3a3a',
                        padding: '10px',
                        borderRadius: '6px',
                        fontSize: '13px',
                      }}
                      onKeyPress={e => handleKeyPress(e, handleSubmit)}
                      {...field}
                    />
                    <div className="color-palette">
                      <label>Color</label>
                      <ColorPalette
                        className="color"
                        name="color"
                        hasLabel={false}
                      />
                    </div>
                  </div>
                )}
              </Field>
            </div>
            <footer className="footer">
              <button
                type="button"
                style={{
                  backgroundColor: '#FFF',
                  border: 'none',
                  color: '#c1c3ca',
                  fontWeight: 'bold',
                  fontSize: '14px',
                }}
                onClick={onClose}
              >
                Cancel
              </button>

              <button
                type="submit"
                style={{
                  backgroundColor: '#22c759',
                  fontSize: '14px',
                  border: 'none',
                  width: '120px',
                  height: '40px',
                  color: '#FFF',
                  fontWeight: 'bold',
                  borderRadius: '6px',
                  marginLeft: '30px',
                }}
              >
                {epicMode === 'edit' ? 'Save' : 'Create'}
              </button>
            </footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

const StyledEpicCreateModal = styled(EpicCreateModal)`
  max-width: 420px;
  width: 100vw;
  position: relative;

  > .close {
    display: none;
  }

  .title {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 2px solid #eee;
    margin-top: 15px;

    > h2 {
      margin-bottom: 12px;
      font-size: 20px;
      font-weight: bold;
      margin-top: 15px;
    }

    > h4 {
      > span {
        color: ${theme('colors.primary')};
        font-weight: normal;
      }
    }
  }

  .color-palette {
    margin-top: 10px;

    > label {
      font-size: 12px;
      color: #c1c3ca;
    }

    .color {
      flex-flow: column;
      align-items: flex-start;
      row-gap: 10px;

      span {
        color: #c1c3ca;
        font-weight: normal;
      }

      .radio-color-group {
        margin: 0;

        .input-group {
          input,
          span {
            width: 18px;
            height: 18px;

            img {
              width: 12px;
              height: 12px;
            }
          }
        }

        button {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  .footer {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
  }
`;

export { StyledEpicCreateModal as EpicCreateModal };
