import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { KanbanBoard } from '../../../../components/KanbanBoard/KanbanBoard';
import { TextFieldView } from '../../../authentication/components/TextField';
import { Button, IconButton } from '../../../authentication/components/Button';
import {
  PlusIcon,
  SettingIcon,
  FilterIcon,
  CloseIcon,
} from '../../../../components/Icons';
import { DocumentPreview } from '../../components/DocumentPreview';
import { DocumentTitle } from '../../../../components/DocumentTitle';
import {
  EpicFilter,
  FilterGroup,
  BlockHeader,
  MemberFilter,
  SideBarFilter,
  SprintFilter,
  TagFilter,
} from '../../../../components/SideBarFilter';
import { Avatar } from '../../../../components/Avatar';
import { ProjectSettingModal } from '../../components/ProjectSettingModal';
import { TicketModal } from '../../../tickets/pages/AllTicketPage/TicketModal';
import { Breadcrumbs } from '../../../../components/Breadcrumbs';
import { useAppLayout } from '../../../../components/withAppLayout';
// import { CanlendarView } from '../../components/CalendarView';
import { ToggleViewButton } from '../../components/ToggleViewButton';
import { ExportTicketsModal } from '../../components/ExportTicketsModal';
import { TicketTable } from '../../components/TicketTabel';

function ProjectDetailPage({
  className,
  tickets,
  loading,
  query,
  onChangeQuery,
  project,
  reFetchTickets,
  onOpenSettingProjectModal,
  onCloseSettingProjectModal,
  isSettingProjectModal,
  onSelectTicket,
  isOpenTicketModal,
  onCloseTicketModal,
  selectedTicketId,
  selectedIdEachGroup,
  onUpdateStatus,
  onUpdateColumn,
  companyName,
  onDuplicate,
  onArchive,
  onArchiveColumn,
  onAddColumn,
  onAddTicketToColumn,
  handleOpenCreateTicket,
  queryParams,
  optionLoading,
}) {
  const navigate = useNavigate();
  const [isShowSidebarMobile, setIsShowSidebarMobile] = useState(false);
  const [isOpenExportTicketsModal, setIsOpenExportTicketsModal] = useState(
    false
  );
  const { isShowSidebar: isShowSidebarDesktop } = useAppLayout();
  const showCreateButton = false;
  const isListView = queryParams.status === 'list';

  return (
    <section className={className}>
      <DocumentTitle title={project.name} />
      <SideBarFilter
        className={`sidebar ${isShowSidebarDesktop ? 'desktop-show' : ''} ${
          isShowSidebarMobile ? 'mobile-show' : ''
        }`}
        showLogo
      >
        <div className="content">
          <BlockHeader>
            <div className="project-header">
              <div className="project-title-group">
                <Avatar
                  className="avatar"
                  noTooltip
                  name={project.name}
                  avatarUrl={project.logoUrl}
                  initial={project.name?.substr(0, 2).toUpperCase()}
                />
                <div className="project-title">{project.name}</div>
              </div>
              <IconButton onClick={onOpenSettingProjectModal}>
                <SettingIcon />
              </IconButton>
              <IconButton
                className="close-button"
                onClick={() => setIsShowSidebarMobile(false)}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <Button
              className="button"
              type="button"
              onClick={() => navigate(`./setup`)}
            >
              Setup Project
            </Button>
          </BlockHeader>
          <FilterGroup>
            <EpicFilter
              projectName={project?.name}
              projectColor={project?.color}
              onOpenModal={() => setIsShowSidebarMobile(false)}
              onRefetch={reFetchTickets}
              isLoading={optionLoading}
            />
            <SprintFilter
              projectName={project?.name}
              projectColor={project?.color}
              onOpenModal={() => setIsShowSidebarMobile(false)}
              onRefetch={reFetchTickets}
              isLoading={optionLoading}
            />
            <MemberFilter
              projectId={project?.id}
              projectName={project?.name}
              isLoading={optionLoading}
            />
            <TagFilter
              projectName={project?.name}
              color={project?.color}
              onOpenModal={() => setIsShowSidebarMobile(false)}
              reFetchTickets={reFetchTickets}
              isLoading={optionLoading}
            />
            <DocumentPreview
              projectId={project?.id}
              isLoading={optionLoading}
            />
          </FilterGroup>
        </div>
      </SideBarFilter>
      <div
        className={`content ${
          isShowSidebarDesktop ? 'show-sidebar-desktop' : ''
        }`}
      >
        <div className="header">
          <div className="bar">
            <Breadcrumbs data={[companyName, project?.name ?? '']} />
          </div>
          <div className="actions">
            <TextFieldView
              type="search"
              className="field"
              name="query"
              placeholder="Search by name"
              value={query}
              onChange={e => onChangeQuery(e.target.value)}
            />
            <div className="buttons">
              <IconButton
                className="filter-button"
                onClick={() => setIsShowSidebarMobile(true)}
              >
                <FilterIcon />
              </IconButton>
              <ToggleViewButton />
              <Button
                className="button"
                onClick={() => setIsOpenExportTicketsModal(true)}
              >
                Export Tickets
              </Button>
              {showCreateButton && (
                <Button
                  className="button"
                  type="button"
                  onClick={() => handleOpenCreateTicket()}
                >
                  <PlusIcon />
                  New Ticket
                </Button>
              )}
            </div>
          </div>
        </div>
        {isListView ? (
          <TicketTable
            className="board"
            color={project?.color}
            tickets={tickets.flatMap(column => column.data)}
            title="List View"
            onSelectTicket={onSelectTicket}
          />
        ) : (
          // TODO : May be use in the future
          // : isCalendarView ? (
          //   <CanlendarView tickets={tickets} onSelectTicket={onSelectTicket} />
          // )
          <div className="kanban">
            <KanbanBoard
              className="board"
              tickets={tickets}
              loading={loading}
              project={project}
              onSelectTicket={onSelectTicket}
              onUpdateStatus={onUpdateStatus}
              onUpdateColumn={onUpdateColumn}
              onAddColumn={onAddColumn}
              onAddTicketToColumn={onAddTicketToColumn}
              onArchiveColumn={onArchiveColumn}
            />
          </div>
        )}
        <ProjectSettingModal
          isOpen={isSettingProjectModal}
          onClose={onCloseSettingProjectModal}
          projectId={project?.id}
          reFetchTickets={reFetchTickets}
          reFetch
        />
      </div>

      <TicketModal
        isOpen={isOpenTicketModal}
        onClose={onCloseTicketModal}
        ticketId={selectedTicketId}
        idEachGroup={selectedIdEachGroup}
        projectId={project.id}
        projectName={project.name}
        onDuplicate={onDuplicate}
        onArchive={onArchive}
        onRefetch={reFetchTickets}
      />
      {isOpenExportTicketsModal ? (
        <ExportTicketsModal
          projectId={project?.id}
          ticketCount={tickets.flatMap(column => column.data).length}
          onClose={() => setIsOpenExportTicketsModal(false)}
        />
      ) : null}
    </section>
  );
}

// TODO: refactor this to be used with AllTicketPage
const StyledProjectDetailPage = styled(ProjectDetailPage)`
  display: flex;
  flex-flow: row nowrap;

  > .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;

    overflow-y: auto;
    transition: all 0.2s ease-in-out;

    border-right: 1px solid #eee;
    height: 100vh;
    width: 0;

    background: white;

    &.desktop-show {
      width: ${theme('sidebarWidth')}px;
      flex: 0 0 ${theme('sidebarWidth')}px;
      z-index: 1;
    }

    > .content {
      width: ${theme('sidebarWidth')}px;

      > ${BlockHeader} {
        > .button {
          width: 100%;
          font-size: 14px;
        }

        > .project-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;

          > .project-title-group {
            display: flex;
            align-items: center;

            > .project-title {
              margin-left: 10px;
            }
          }

          ${IconButton} {
            margin-left: 10px;
            font-size: 28px;

            &.close-button {
              margin-left: auto;
              display: none;

              @media (max-width: ${theme('breakpoints.mobile')}px) {
                display: block;
              }
            }
          }

          > .avatar {
            height: 32px;
            margin-right: 10px;
          }
        }
      }
    }

    @media (max-width: ${theme('breakpoints.mobile')}px) {
      width: 100% !important;
      display: none;

      &.mobile-show {
        display: block;
      }

      > .content {
        width: 100% !important;
      }
    }
  }

  > .content {
    background: ${theme('colors.background')};
    flex-grow: 1;
    transition: all 0.2s ease-in-out;
    margin-top: 96px;
    padding-top: 40px;

    &.width-100 {
      width: 100%;
      overflow: auto;
    }

    @media (max-width: ${theme('breakpoints.tablet')}px) {
      margin-top: 145px;
    }

    @media (max-width: ${theme('breakpoints.mobile')}px) {
      padding-left: 0 !important;
    }

    &.show-sidebar-desktop {
      padding-left: ${theme('sidebarWidth')}px;

      > .header {
        width: calc(100vw - 50px - ${theme('sidebarWidth')}px);
      }
    }

    > .header {
      background: ${theme('colors.background')};
      transition: all 0.2s ease-in-out;

      position: fixed;
      top: 0;
      padding-bottom: 20px;

      width: calc(100vw - 50px);

      @media (max-width: ${theme('breakpoints.mobile')}px) {
        width: 100% !important;
        top: 50px;
      }
    }

    > .header > .bar {
      display: flex;
      justify-content: space-between;
      padding: 10px 30px;

      > ${Breadcrumbs} {
        position: relative;
        left: -8px;
      }
    }

    > .header > .actions {
      display: flex;
      align-items: center;

      padding: 0 30px;

      @media (max-width: ${theme('breakpoints.tablet')}px) {
        display: block;
      }

      > .field {
        margin-bottom: 0;
        margin-right: 15px;

        width: 100%;
        max-width: 400px;

        @media (max-width: ${theme('breakpoints.tablet')}px) {
          margin-right: 0;
        }
      }

      > .buttons {
        display: flex;
        align-items: center;
        margin-left: auto;

        > .button {
          margin-left: 15px;
        }

        > .filter-button {
          color: ${theme('colors.primary')};
          margin-right: 15px;
          display: none;

          @media (max-width: ${theme('breakpoints.mobile')}px) {
            display: block;
          }
        }

        @media (max-width: ${theme('breakpoints.tablet')}px) {
          margin-top: 15px;
          margin-left: 0;
        }
      }
    }

    > .kanban > .board,
    > .board {
      padding: 0 30px 30px;
    }

    > .kanban {
      overflow: hidden;

      display: flex;
      flex-flow: column nowrap;

      height: calc(100vh - 136px);
      padding-bottom: 20px;

      > .board {
        flex-grow: 1;

        padding-top: 0;
      }

      @media (max-width: ${theme('breakpoints.tablet')}px) {
        height: calc(100vh - 185px);
      }

      @media (max-width: ${theme('breakpoints.mobile')}px) {
        height: calc(100vh - 235px);
      }
    }
  }
`;

export { StyledProjectDetailPage as ProjectDetailPage };
