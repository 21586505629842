import React from 'react';
import hoist from 'hoist-non-react-statics';
import { useQueryParams } from '../../../../lib/hooks';

export function withNoCompanies(Component) {
  function WithNoCompanies(props) {
    const [queryParams, setQueryParams] = useQueryParams();

    const onCloseSettingProjectModal = () => {
      setQueryParams({ modal: null });
    };

    const pageProps = {
      isSettingCompanyModal: Boolean(queryParams.modal === 'create-company'),
      onCloseSettingProjectModal,
      setQueryParams,
    };

    return <Component {...props} {...pageProps} />;
  }

  hoist(WithNoCompanies, Component);

  WithNoCompanies.displayName = `withNoCompanies(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithNoCompanies;
}
