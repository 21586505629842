import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { useField } from 'formik';
import { ifProp } from 'styled-tools';
import {
  EditIcon,
  UploadImageIcon,
  CancelFileIcon,
} from '../../../../components/Icons';
import { filehash } from '../../../../lib/utils';
import { ClearButton } from '../../../authentication/components/Button';

export const SingleImageUploader = React.memo(styled(
  function SingleImageUploader({
    className,
    id = 'single-image-uploader',
    name,
    previewUrl,
    disabled,
    onRemove,
    ...props
  }) {
    return (
      <div className={className}>
        <label htmlFor={id} className="container">
          {previewUrl ? (
            <img className="preview" src={previewUrl} alt="upload" />
          ) : disabled ? (
            <div className="placeholder">No Image</div>
          ) : (
            <div className="placeholder">
              <UploadImageIcon size={52} />
              <h4>Upload Image</h4>
            </div>
          )}
        </label>
        <div className="overlay">
          <EditIcon size={30} />
        </div>
        <ClearButton
          className="remove"
          onClick={e => {
            e.stopPropagation();
            onRemove();
          }}
        >
          <CancelFileIcon />
        </ClearButton>

        <input
          type="file"
          id={id}
          hidden
          name={name}
          accept="image/*"
          disabled={disabled}
          {...props}
        />
      </div>
    );
  }
)`
  width: 240px;
  height: 240px;
  overflow: hidden;
  position: relative;
  border-radius: 6px;
  background: white;

  > .container,
  > .overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  > .container {
    cursor: ${ifProp('disabled', 'default', 'pointer')};
    z-index: 0;

    > .placeholder {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      border: solid 1px #22c759;

      height: 100%;
      h4 {
        margin-top: 10px;
        color: #22c759;
      }
    }
  }

  > .overlay {
    z-index: 1;

    display: none;
    place-items: center;

    color: #eee;

    background: rgba(0, 0, 0, 0.3);
    pointer-events: none;
  }

  &:hover {
    > .overlay {
      display: ${ifProp('previewUrl', 'grid', 'none')};
    }
  }

  > .remove {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 0;

    color: white;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.7;
    text-decoration: underline;

    svg {
      width: 14px;
      height: 14px;
    }

    &:hover {
      z-index: 1;
      background: unset;
    }
  }

  > .container > .preview {
    width: 100%;
    height: 100%;
  }
`);

export const FormikSingleImageUploader = hoistNonReactStatics(
  function FormikSingleImageUploader({
    onChange,
    onBlur,
    previewUrl,
    setFieldValue,
    ...props
  }) {
    const [input, , { setValue }] = useField(props.name);
    const [url, setUrl] = useState(previewUrl);

    const handleChange = useCallback(
      e => {
        const file = e.target.files.item(0);

        if (filehash(file) === filehash(input.value)) {
          return;
        }
        if (setFieldValue) {
          setFieldValue('hasImageDelete', false);
        }
        setValue(file);

        if (onChange) {
          onChange(e);
        }
      },
      [onChange, setValue, input.value, setFieldValue]
    );

    const handleRemove = useCallback(() => {
      if (setFieldValue) {
        setFieldValue('hasImageDelete', true);
      }
      setValue(null);
      setUrl(null);
    }, [setValue, setFieldValue]);

    useEffect(() => {
      if (!input.value) {
        return () => {};
      }

      const objectUrl = URL.createObjectURL(input.value);

      setUrl(objectUrl);

      return () => URL.revokeObjectURL(objectUrl);
    }, [input.value]);

    return (
      <SingleImageUploader
        {...props}
        {...input}
        value=""
        onChange={handleChange}
        onRemove={handleRemove}
        previewUrl={url}
      />
    );
  },
  SingleImageUploader
);
