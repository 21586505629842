import React, { useMemo } from 'react';
import hoist from 'hoist-non-react-statics';
import { useNavigate } from 'react-router-dom';
import { useCurrentCompany } from '../../../projects/useCurrentCompany';
import { successToast } from '../../../../lib/toast';
import { usePermissionGuard } from '../../../authentication/hocs/withPermission';
import { useAppClient } from '../../../../lib/AppProvider';

export function withSettingCompanyPage(Component) {
  function WithSettingCompanyPage(props) {
    const { data: currentCompany, update } = useCurrentCompany();
    const disabled = !usePermissionGuard('company:write');
    const client = useAppClient();
    const navigate = useNavigate();

    async function onSubmit(form) {
      if (disabled) {
        return;
      }

      await update(form);

      successToast({ message: 'The company has been updated.' });
    }

    async function deleteCompany() {
      await client.deleteCompany(currentCompany.id);
      navigate('/');
      successToast({ message: 'The company has been deleted.' });
    }

    const initialValues = useMemo(
      () => ({
        name: currentCompany.name ?? '',
        description: currentCompany.description ?? '',
        hasImageDelete: false,
      }),
      [currentCompany]
    );

    const pageProps = {
      onSubmit,
      initialValues,
      previewUrl: currentCompany.logoUrl,
      disabled,
      deleteCompany,
    };
    return <Component {...props} {...pageProps} />;
  }

  hoist(WithSettingCompanyPage, Component);

  WithSettingCompanyPage.displayName = `withSettingCompanyPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithSettingCompanyPage;
}
