import { useReducer } from 'react';

const reducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_SEARCH':
      return {
        ...state,
        searchEmployee: !state.searchEmployee,
      };
    case 'ADD_PROFILE':
      return {
        ...state,
        profile: action.data,
      };
    case 'UPDATE_PROFILE':
      return {
        ...state,
        profile: { ...state.profile, ...action.form },
      };
    default: {
      return state;
    }
  }
};

const useGlobalState = () => {
  const [globalState, globalDispatch] = useReducer(reducer, {
    profile: null,
    searchEmployee: false,
  });

  return { globalState, globalDispatch };
};

export default useGlobalState;
