import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { AvatarList } from '../../../../components/AvatarList';
import {
  WorkIcon,
  StarIcon,
  StarFilledIcon,
} from '../../../../components/Icons';

function ProjectCard({
  className,
  members,
  project,
  isStarred = false,
  handleStarredProject,
  handleUnStarredProject,
  handleCardClicked,
}) {
  return (
    <div className={className} onClick={handleCardClicked}>
      <div className="content">
        <div className="title">
          <div className="project-logo">
            {project.logoUrl ? (
              <img src={project.logoUrl} alt={project.name} />
            ) : (
              <WorkIcon />
            )}
          </div>
          {project.isFavorite || isStarred ? (
            <span onClick={handleUnStarredProject} className="star-icon">
              <StarFilledIcon />
            </span>
          ) : (
            <span onClick={handleStarredProject} className="star-icon">
              <StarIcon />
            </span>
          )}
        </div>
        <h4 className="project-name">{project.name}</h4>
      </div>
      <div className="footer">
        <AvatarList
          className="assignees"
          data={members}
          userCount={project?.userCount}
          compact
          hidePlaceholder
        />
      </div>
    </div>
  );
}

const StyledProjectCard = styled(ProjectCard)`
  height: 150px;
  padding: 15px 15px 20px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 5px 10px 0 rgba(58, 58, 58, 0.05);
  display: flex;
  flex-direction: column;
  align-items: space-between;

  > .content {
    > .title {
      display: flex;
      justify-content: space-between;

      > .project-logo {
        width: 30px;
        height: 30px;
        border-radius: 6px;
        display: flex;
        align-items: center;

        > svg,
        img {
          border-radius: 6px;
          width: 100%;
          height: 100%;
          border: solid 1px #dce0ea;
        }

        > ${WorkIcon} {
          background: ${theme('colors.primary')};
          color: white;
          padding: 5px;
        }
      }

      > .star-icon {
        color: #ebbd34;
      }
    }

    > .project-name {
      color: #333;
      margin-top: 15px;
    }
  }

  > .footer {
    margin-top: auto;
    display: flex;
  }
`;

export { StyledProjectCard as ProjectCard };
