import { useCallback, useMemo, useState, useEffect } from 'react';
import { useQueryParams, useDebounce } from '../../lib/hooks';

export function useFilter({ key, data }) {
  const [queryParams, setQueryParams] = useQueryParams();
  const queryListKey = `${key}Ids`;
  const ids = queryParams[queryListKey];
  const [query, onChangeQ] = useState(queryParams[key] ?? '');
  const memoQuery = useDebounce(query, 500);

  useEffect(() => {
    setQueryParams({ [key]: memoQuery });
    /* eslint-disable-next-line */
  }, [key, memoQuery]);

  const selectedIds = useMemo(() => (ids?.split(',') ?? []).filter(Boolean), [
    ids,
  ]);

  const onSelect = useCallback(
    id => {
      const isChecked = selectedIds.includes(id);

      if (isChecked) {
        setQueryParams({
          [queryListKey]: selectedIds.filter(i => i !== id).join(','),
        });
        return;
      }

      setQueryParams({
        [queryListKey]: selectedIds.concat(id).join(','),
      });
    },
    [selectedIds, setQueryParams, queryListKey]
  );

  const onSelectAll = useCallback(() => {
    if (data.length === selectedIds.length) {
      setQueryParams({ [queryListKey]: '' });
      return;
    }

    setQueryParams({ [queryListKey]: data.map(({ id }) => id).join(',') });
  }, [data, selectedIds, setQueryParams, queryListKey]);

  return {
    data,
    selectedIds,
    onSelect,
    onSelectAll,
    onSearch: onChangeQ,
    query,
    memoQuery,
  };
}
