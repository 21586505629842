import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { theme } from 'styled-tools';
import { Modal } from '../../../../components/Modal';
import {
  OutlineButton,
  Button,
} from '../../../authentication/components/Button';
import { TextField } from '../../../authentication/components/TextField';
import { FormikSingleImageUploader as SingleImageUploader } from '../../../settings/pages/SettingCompanyPage/SingleImageUploader';
import { EditableText } from '../../../settings/pages/SettingCompanyPage/EditableText';
import { MemberManagement } from '../../../../components/MemberManagement';
import { colorOptions } from '../../../../components/ColorPalette/ColorPalette';
import { LoadingIndicator } from '../../../../components/LoadingIndicator';
import { DeleteModal } from '../../../../components/SideBarFilter/DeleteModal';

function CompanySettingModal({
  className,
  isOpen,
  onClose,
  projectId,
  selectedProject,
  initialValues = {
    name: '',
    description: '',
    logoFile: null,
    color: colorOptions[0],
    logoUrl: null,
  },
  onSubmit,
  onDelete,
  submitText = 'Create',
  loading,
  isShowDeleteModal,
  onCloseDeleteModal,
}) {
  if (loading) {
    return (
      <Modal className={className} isOpen={isOpen} onClose={onClose}>
        <LoadingIndicator />
      </Modal>
    );
  }

  return (
    <>
      <Modal className={className} isOpen={isOpen} onClose={onClose}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ values, isSubmitting, setFieldValue, submitForm }) => (
            <div>
              <div className="title">
                <div className="company-wrapper">
                  <h1>Create Company</h1>
                </div>
                <div className="actions">
                  <OutlineButton
                    className="button cancel"
                    type="button"
                    onClick={onClose}
                  >
                    Cancel
                  </OutlineButton>
                  <Button
                    className="button"
                    type="submit"
                    onClick={() => submitForm()}
                    disabled={isSubmitting || !values.name}
                  >
                    {submitText}
                  </Button>
                </div>
              </div>
              <div className="contents">
                <div className="logo-uploader">
                  <SingleImageUploader
                    className="image-uploader"
                    name="logoFile"
                    previewUrl={values.logoUrl}
                  />
                </div>
                <div className="field-group">
                  <EditableText name="name" placeholder="Enter Company Name" />
                  <TextField
                    id="description"
                    name="description"
                    label="Description and purpose"
                    rows={8}
                    multiline
                  />
                </div>
              </div>
              <MemberManagement
                value={values.members}
                onChange={members => setFieldValue('members', members)}
                projectId={projectId}
              />
            </div>
          )}
        </Formik>
      </Modal>
      <DeleteModal
        isOpen={isShowDeleteModal}
        onClose={onCloseDeleteModal}
        onDelete={onDelete}
        deleteText={`Delete Project "${selectedProject?.name}" ?`}
      />
    </>
  );
}

const StyledCompanySettingModal = styled(CompanySettingModal)`
  width: 1100px;

  .close {
    display: none;
  }

  ${LoadingIndicator} {
    min-height: 600px;
  }

  .content {
    width: 100%;
  }

  .title {
    display: grid;
    grid-template-areas: 'company actions';
    margin-top: 20px;
    margin-bottom: 20px;

    > .company-wrapper {
      grid-area: company;

      h1 {
        font-size: 18px;
        font-weight: bold;

        span {
          font-weight: normal;
        }
      }
    }

    > .actions {
      grid-area: actions;
      display: flex;
      justify-content: flex-end;
      align-self: center;

      .cancel {
        border: solid 1px #c2c3ca;
        color: #c2c3ca;
      }

      .cancel:hover {
        background-color: #c2c3ca;
        color: white;
      }

      > ${Button} {
        &.delete {
          color: ${theme('colors.error')};
          border-color: ${theme('colors.error')};
        }

        &.delete:hover {
          background: ${theme('colors.error')};
          color: white;
        }

        &:not(:last-child) {
          margin-right: 8px;
        }

        &:disabled {
          background: #adadad;
          cursor: not-allowed;
          border: none;
        }
      }
    }

    .company-name {
      color: ${theme('colors.primary')};
      margin-left: 14px;
    }
  }

  .contents {
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-gap: 30px;
  }

  .contents > .field-group {
    > ${EditableText} {
      margin-bottom: 16px;
    }
  }

  .add-members {
    .title {
      color: #999999;
      font-size: 12px;
    }

    .selection-area {
      display: flex;
      justify-content: space-between;

      .company-wrapper {
        flex: 0.5;
      }

      .right-section {
        flex: 0.5;
      }
    }
  }

  .logo-uploader {
    width: 250px;
    height: 250px;
    background: white;

    > .image-uploader {
      border-radius: 6px;
      box-shadow: 0 0 20px 0 rgba(0, 128, 42, 0.08);
    }
  }

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    .title {
      grid-template-areas:
        'actions actions'
        'company company';

      > .actions {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        > ${Button} {
          margin-bottom: 10px;

          &:not(:last-child) {
            margin-right: 0;
          }
        }
      }
    }

    .contents {
      grid-template-columns: auto;

      > .logo-uploader {
        margin: auto;
      }
    }
  }
`;

export { StyledCompanySettingModal as CompanySettingModal };
