import { useQuery } from 'react-query';
import { useAppClient } from 'lib/AppProvider';

export function useProjectListOption({ userId }) {
  const client = useAppClient();
  const { data } = useQuery(
    ['projectList', userId],
    () => client.getAllProjectTickets({ userId }),
    { refetchOnWindowFocus: false }
  );

  return {
    projectList: data,
  };
}

export function useTicketAction() {
  const client = useAppClient();

  return {
    async duplicate(id, { projectId }) {
      await client.duplicateTicket(id, { projectId });
    },

    async archive(id, { projectId, deleteType }) {
      await client.archiveTicket(id, { projectId, deleteType });
    },

    async changeDueDate({
      projectId,
      ticketId,
      date,
      start,
      end,
      allDay,
      estimateTime,
      estimateTimeMinute,
      hasSubtask,
    }) {
      await client.changeDueDate({
        projectId,
        ticketId,
        date,
        start,
        end,
        allDay,
        estimateTime,
        estimateTimeMinute,
        hasSubtask,
      });
    },
  };
}
