import React from 'react';
import classnames from 'classnames';
import { ArrowUpIcon } from '../Icons';
import { Popover } from '../../features/authentication/components/TextField';

export function Select({
  className,
  label,
  placeholder,
  options,
  onChange,
  value,
  error,
  hint,
  touched,
  ...props
}) {
  const displayPlaceholder = placeholder ?? label;
  const selectedOption = options.find(option => option.value === value);
  const selectedLabel = selectedOption?.label ?? displayPlaceholder;
  const selectedLabelCss = selectedOption ? '' : 'placeholder';
  const selectValue =
    value === null || typeof value === 'undefined' ? '' : value;
  const showError = touched && error;
  const showHint = false;

  function handleChange(event) {
    const e = {
      name: event.target.name,
      value:
        props.inputMode === 'numeric' || props.inputMode === 'decimal'
          ? parseFloat(event.target.value)
          : event.target.value,
    };

    if (onChange) {
      onChange(e);
    }
  }

  return (
    <div className={className}>
      {label && <div className="label">{label}</div>}
      <div className="input-group">
        <select
          {...props}
          disabled={props.disabled}
          value={selectValue}
          onChange={handleChange}
        >
          <option disabled value="">
            {displayPlaceholder}
          </option>
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div
          className={classnames({
            field: true,
            error,
            selected: value,
            disabled: props.disabled,
          })}
        >
          <div className={selectedLabelCss}>{selectedLabel}</div>
        </div>
        {!props.disabled && <ArrowUpIcon width={18} height={18} />}
        {showHint && (
          <Popover className="popover">
            <div className="hint">
              <div className="arrow" /> {hint}
            </div>
          </Popover>
        )}
        {showError && (
          <Popover className="popover" error>
            <div className="hint">
              <div className="arrow" /> {error}
            </div>
          </Popover>
        )}
      </div>
    </div>
  );
}
