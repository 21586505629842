import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';

function Radio({
  className,
  label,
  checked,
  value,
  onClick,
  onChange,
  disabled,
  ...props
}) {
  return (
    <span className={className} onClick={() => !disabled && onClick(value)}>
      <input
        type="radio"
        checked={checked}
        onChange={e => onChange(e)}
        value={value}
        disabled={disabled}
        {...props}
      />
      <div className={`check-mark ${checked ? 'checked' : ''}`} />
      <div className="label">{label}</div>
    </span>
  );
}

const StyledRadio = styled(Radio)`
  display: block;
  position: relative;
  padding-left: 15px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  > input:checked ~ .check-mark:after {
    display: block;
  }

  > .check-mark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: solid 1px #dce0ea;

    &.checked {
      border: solid 1px ${theme('colors.primary')};
    }
  }

  > .check-mark:after {
    content: '';
    position: absolute;
    display: none;

    transform: translateY(-50%);
    top: 50%;
    left: 1.5px;
    width: 12.5px;
    height: 12.5px;
    border-radius: 50%;
    border: solid 1px ${theme('colors.primary')};
    background: ${theme('colors.primary')};
  }

  > .label {
    cursor: pointer;
    font-size: 14px;
    color: #3a3a3a;
    margin-left: 14px;
    padding-top: 2px;

    > span {
      margin-right: 10px;
    }
  }
`;

export { StyledRadio as Radio };
