import React from 'react';
import { useField } from 'formik';
import { Select } from './Select';
import { SelectField } from './SelectField';

export function FormikSelect(props) {
  const [field, meta, helper] = useField(props.name);
  const error = meta.touched && meta.error;

  function handleChange(e) {
    helper.setValue(e.value);
  }

  return <Select {...props} {...field} onChange={handleChange} error={error} />;
}

export function FormikSelectField(props) {
  const [field, meta, helper] = useField(props.name);
  const error = meta.touched && meta.error;

  function handleChange(e) {
    helper.setValue(e.value);
  }

  return (
    <SelectField
      {...props}
      {...field}
      touched={meta.touched}
      onChange={handleChange}
      error={error}
    />
  );
}
