import React, { useState, useContext } from 'react';
import hoist from 'hoist-non-react-statics';
import { useParams } from 'react-router-dom';
import ProjectContext from '../../store/projectContext';
import { useAppClient } from '../../lib/AppProvider';
import { useFilter } from './useFilter';

export function useProjectTag({ projectId }) {
  const client = useAppClient();
  const { projectDispatch, projectState } = useContext(ProjectContext);
  const data = projectState.tags;

  return {
    data,
    async create({ name, color }) {
      const tag = await client.createProjectTag(projectId, { name, color });
      projectDispatch({ type: 'CREATE_TAG', data: tag });
    },
    async update({ tagId, name, color }) {
      await client.updateProjectTag(tagId, { name, projectId, color });
      projectDispatch({ type: 'UPDATE_TAG', tagId, tag: { name, color } });
    },
  };
}

export function withTagFilter(Component) {
  function WithTagFilter({ onOpenModal, reFetchTickets, ...props }) {
    const [isAddTagModal, setIsAddTagModal] = useState(false);
    const [tagMode, setTagMode] = useState('');
    const [selectedTag, setSelectedTag] = useState(null);
    const { id } = useParams();
    const { data, create: createTag, update: updateTag } = useProjectTag({
      projectId: id,
    });
    const filter = useFilter({ key: 'tag', data });

    const onAddTagModal = () => {
      if (onOpenModal) onOpenModal();
      setIsAddTagModal(true);
      setTagMode('create');
    };

    const onEditTagModal = tag => {
      if (onOpenModal) onOpenModal();
      setSelectedTag(tag);
      setIsAddTagModal(true);
      setTagMode('edit');
    };

    const onCloseTagModal = () => {
      setIsAddTagModal(false);
    };

    const onSubmit = async ({ tagName: name, color }, { resetForm }) => {
      if (tagMode === 'edit') {
        await updateTag({ tagId: selectedTag.id, name, color });
        await reFetchTickets();
      } else {
        createTag({ name, color });
      }
      resetForm();
      setIsAddTagModal(false);
    };

    const pageProps = {
      ...props,
      ...filter,
      data,
      isAddTagModal,
      onAddTagModal,
      onCloseTagModal,
      onSubmit,
      onEditTagModal,
      tagMode,
      selectedTag,
    };

    return <Component {...pageProps} />;
  }

  hoist(WithTagFilter, Component);

  WithTagFilter.displayName = `withTagFilter(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithTagFilter;
}
