import React, { useEffect } from 'react';
import hoist from 'hoist-non-react-statics';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthentication } from '../useAuthentication';

export function withAuthenticatedGuard(Component) {
  function WithAuthenticatedGuard(props) {
    const { isAuthenticated } = useAuthentication();
    const [searchParams] = useSearchParams();
    const { pathname, search } = useLocation();
    const navigate = useNavigate();
    const redirectTo =
      searchParams.get('redirectTo') ?? encodeURIComponent(pathname + search);

    useEffect(() => {
      if (isAuthenticated) {
        return;
      }

      navigate({
        pathname: '/auth/sign-in',
        search: `?redirectTo=${redirectTo}`,
      });
    }, [isAuthenticated, redirectTo, navigate, pathname]);

    return <Component {...props} />;
  }

  hoist(WithAuthenticatedGuard, Component);

  WithAuthenticatedGuard.type = 'AUTH_GUARD';
  WithAuthenticatedGuard.displayName = `withAuthenticatedGuard(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithAuthenticatedGuard;
}
