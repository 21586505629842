import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { Modal } from '../Modal';
import { OutlineButton } from '../../features/authentication/components/Button';

function DeleteModal({
  className,
  isOpen,
  onClose,
  onDelete,
  deleteText,
  description = 'Are you sure want to do that?',
}) {
  return (
    <Modal
      className={className}
      isOpen={isOpen}
      onClose={onClose}
      hideCloseButton
    >
      <div className="container">
        <div className="title">{deleteText}</div>
        <span>{description}</span>
        <div className="btn-action">
          <OutlineButton className="cancel-btn" onClick={onClose}>
            Cancel
          </OutlineButton>
          <OutlineButton className="delete-btn" onClick={onDelete}>
            Yes, Delete
          </OutlineButton>
        </div>
      </div>
    </Modal>
  );
}

const StyledDeleteModal = styled(DeleteModal)`
  max-width: 420px;
  width: 90vw;
  position: relative;
  top: 15vh;

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    padding: 20px;
  }

  > .content {
    height: unset;
  }
  > .close {
    display: none;
  }

  .close-btn {
    position: absolute;
    background-color: #999;
    color: white;
    right: 10px;
    top: 10px;
    padding: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 0;
  }

  .container {
    span {
      line-height: 1.57;
    }

    > .image {
      height: 215px;
      width: fit-content;
      margin-bottom: 30px;
    }

    > .title {
      color: #333;
      font-size: 22px;
      margin-bottom: 15px;
      font-weight: bold;
    }

    > span {
      color: #333;
      font-size: 16px;
      margin-bottom: 30px;
    }

    > .btn-action {
      display: flex;
      margin-top: 40px;
      justify-content: flex-end;
      flex-wrap: wrap-reverse;

      @media (max-width: ${theme('breakpoints.mobile')}px) {
        margin-top: 20px;
      }

      > .delete-btn {
        max-width: 120px;
        width: 100%;
        border: 1px solid #e01b32;
        color: #e01b32;
        padding: 12px 0;

        &:hover {
          background-color: #e01b32;
          color: white;
        }

        @media (max-width: ${theme('breakpoints.mobile')}px) {
          max-width: none;
          margin-bottom: 20px;
        }
      }

      > .cancel-btn {
        border: none;
        color: #c1c3ca;

        &:hover {
          background-color: transparent;
        }
        @media (max-width: ${theme('breakpoints.mobile')}px) {
          width: 100%;
        }
      }
    }

    @media (max-width: ${theme('breakpoints.mobile')}px) {
      > .title {
        font-size: 16px;
      }

      > span {
        font-size: 14px;
      }
    }
  }
`;

export { StyledDeleteModal as DeleteModal };
