import React, { useEffect, useState } from 'react';
import hoist from 'hoist-non-react-statics';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthentication } from '../../useAuthentication';

export function withSignInPage(Component) {
  function WithSignInPage(props) {
    const { signIn, isAuthenticated } = useAuthentication();
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const successAlert = searchParams.get('success');
    const errorAlert = searchParams.get('error');
    const resetPasswordAlert = searchParams.get('reset-password');
    const url = searchParams.get('redirectTo');
    const type = searchParams.get('type');

    useEffect(() => {
      if (!isAuthenticated || errorAlert) {
        return;
      }

      if (type !== 'auto') {
        return;
      }

      window.location.href = url ?? '/';
    }, [errorAlert, isAuthenticated, url, type]);

    async function onSignIn({ email, password }) {
      try {
        setError(null);
        await signIn({ email, password });

        navigate(url ?? '/', { replace: !!url });
      } catch (err) {
        if (!err.isAxiosError) {
          throw err;
        }

        setError(err.response?.data?.errors[0]?.detail);
      }
    }

    const pageProps = {
      error,
      onSignIn,
      successAlert,
      errorAlert,
      resetPasswordAlert,
      ...props,
    };

    return <Component {...pageProps} />;
  }

  hoist(WithSignInPage, Component);

  WithSignInPage.displayName = `withSignInPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithSignInPage;
}
