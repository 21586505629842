import React, { useMemo, useContext } from 'react';
import hoist from 'hoist-non-react-statics';
import GlobalContext from '../../../store/globalContext';
import { rules } from '../../../lib/permissions';
import { CompanyRedirect } from '../../../components/CompanyRedirect';

export function withPermission(Component, allow) {
  function WithPermission(props) {
    const hasPermission = usePermissionGuard(allow);

    if (hasPermission) {
      return <Component {...props} />;
    }
    return <CompanyRedirect />;
  }

  hoist(WithPermission, Component);

  WithPermission.type = 'AUTH_GUARD';
  WithPermission.displayName = `withPermission(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithPermission;
}

export function usePermissionGuard(allow) {
  const { globalState } = useContext(GlobalContext);
  const data = globalState.profile;
  const roleId = data?.roleId;

  return useMemo(() => {
    if (roleId) {
      const allowList = allow.split(',');

      const currentRule = rules[roleId] ?? [];

      return allowList.some(item => currentRule.includes(item));
    }
    return true;
  }, [roleId, allow]);
}

export const PermissionGuard = React.memo(({ children, allow, ...props }) => {
  const hasPermission = usePermissionGuard(allow);

  if (!hasPermission) {
    return null;
  }

  return React.cloneElement(children, props);
});
