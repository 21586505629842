import React, { useState } from 'react';
import styled from 'styled-components';
import { prop, theme } from 'styled-tools';
import { DateTime } from 'luxon';
import { Avatar } from '../../../components/Avatar';
import { DeleteModal } from '../../../components/SideBarFilter/DeleteModal';
import {
  TrashIcon,
  Pencil,
  EllipseVerticalIcon,
} from '../../../components/Icons';
import { IconButton } from '../../authentication/components/Button';
import { SalaryEditor } from './SalaryEditor';

// move this to Badge.js

export const Badge = styled.div`
  display: inline-block;
  border-radius: 999px;
  border: 0;
  padding: 2px 8px;

  color: white;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.7;

  background: ${prop('color', theme('colors.primary'))};
`;

const SalaryListItemHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 180px 90px 140px 120px 50px;
  padding: 20px;
  gap: 20px;
  background-color: #fff;
  color: #767676;
  font-size: 12px;

  > .col {
    display: flex;
    align-items: center;
    color: #9fa3ae;

    &.date {
      cursor: pointer;
      > svg {
        margin-left: 5px;
      }
    }

    &.salary {
      justify-content: end;
    }
  }

  > .summary {
    padding-left: 0;

    > ${Badge} {
      margin-right: 10px;
    }
  }
`;

const SalaryListItem = styled(
  React.forwardRef(function SalaryListItem(
    {
      salary,
      color,
      onDeleteSalary,
      setSelectSalaryId,
      setEditSalaryId,
      lastItem,
      isOpenMenu,
      onCloseMenu,
      ...props
    },
    ref
  ) {
    const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);

    function onOpenDeleteModal() {
      setIsShowDeleteModal(true);
    }

    function onCloseDeleteModal() {
      setIsShowDeleteModal(false);
    }

    const { user } = salary;

    return (
      <>
        <div {...props} ref={ref}>
          <div className="col account">
            <Avatar
              initial={user?.firstName?.substr(0, 2)}
              noTooltip
              avatarUrl={user?.avatarUrl}
            />
            <div className="name">
              <span>{user.fullName}</span>
              {user.nickName && <p>{user.nickName}</p>}
            </div>
          </div>
          <div className="col position">{salary.position}</div>
          <div className="col level">{salary.level}</div>
          <div className="col date">
            {DateTime.fromISO(salary.startDate).toFormat('d MMM yyyy')}
          </div>
          <div className="col salary">
            {salary.salary ? Intl.NumberFormat().format(salary.salary) : '-'}
          </div>
          <div className="col menu">
            <IconButton
              className="menu-icon"
              onClick={() => setSelectSalaryId(salary.id)}
            >
              <EllipseVerticalIcon color={isOpenMenu ? color : null} />
            </IconButton>
            {isOpenMenu && (
              <div className={`salary-menu ${lastItem ? 'last-item' : ''}`}>
                <div
                  onClick={() => {
                    setEditSalaryId(salary.id);
                    setSelectSalaryId();
                  }}
                >
                  <Pencil />
                  <span>Edit</span>
                </div>
                <div onClick={onOpenDeleteModal}>
                  <TrashIcon />
                  <span>Delete</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <DeleteModal
          isOpen={isShowDeleteModal}
          onClose={onCloseDeleteModal}
          onDelete={() => onDeleteSalary(salary.id)}
          deleteText="Delete Employee?"
          description="Do you really want to delete this employee? This process cannot be undone."
        />
      </>
    );
  })
)`
  display: grid;
  grid-template-columns: 1fr 180px 90px 140px 120px 50px;
  padding: 15px 20px;
  gap: 20px;

  color: #999;
  font-size: 12px;
  font-weight: bold;

  background: #f5f8f8;

  &.odd-row {
    background: white;
    font-size: 14px;
  }

  &.even {
    font-size: 14px;
  }

  > .col {
    color: #3a3a3a;
    display: flex;
    align-items: center;
    font-weight: normal;

    &.account {
      > .name {
        margin-left: 10px;

        > .span {
          font-size: 14px;
          font-weight: normal;
          color: #3a3a3a;
        }

        > p {
          margin-top: 5px;
          font-size: 12px;
          color: #9fa3ae;
        }
      }
    }

    &.salary {
      justify-content: end;
    }

    &.menu {
      position: relative;
    }
    > .salary-menu {
      position: absolute;
      width: 100px;
      height: 80px;
      padding: 10px;
      background-color: white;
      border-radius: 5px;
      z-index: 1;
      top: 100%;
      right: 50%;

      &.last-item {
        top: -120%;
        right: 70%;
      }

      > div {
        padding: 5px;
        display: flex;
        cursor: pointer;

        > svg {
          width: 15px;
        }
        > span {
          margin-left: 3px;
          margin-top: 3px;
        }
      }
    }
  }

  > .name {
    color: #333;
    font-size: 14px;
    font-weight: normal;
  }

  > .menu {
    margin-left: auto;
  }
`;

export const SalaryListBoard = React.memo(
  styled(function SalaryListBoard({
    className,
    color,
    companyId,
    onDeleteSalary,
    salaries = [],
    editSalary,
    setEditSalary,
    reloadSalaries,
  }) {
    const [editSalaryId, setEditSalaryId] = useState();
    const [selectSalaryId, setSelectSalaryId] = useState();

    function showSalaryOptions(id) {
      const salaryId = id !== selectSalaryId ? id : null;
      setSelectSalaryId(salaryId);
    }

    return (
      <div className={className}>
        <div className="table-wrapper">
          <div className="table">
            <SalaryListItemHeader>
              <div className="col account">Toolings Account</div>
              <div className="col position">Position</div>
              <div className="col level">Level</div>
              <div className="col date">Start Date</div>
              <div className="col salary">Salary (THB)</div>
              <div className="col menu" />
            </SalaryListItemHeader>
            {editSalary && (
              <SalaryEditor
                className="odd-row"
                color={color}
                companyId={companyId}
                setEditSalary={setEditSalary}
                reloadSalaries={reloadSalaries}
              />
            )}
            {salaries.map((salary, index) =>
              salary.id === editSalaryId ? (
                <SalaryEditor
                  className={index % 2 !== 0 ? 'odd-row' : 'even'}
                  key={salary.id}
                  salary={salary}
                  companyId={companyId}
                  color={color}
                  reloadSalaries={reloadSalaries}
                  setEditSalaryId={setEditSalaryId}
                />
              ) : (
                <SalaryListItem
                  className={index % 2 !== 0 ? 'odd-row' : 'even'}
                  key={salary.id}
                  salary={salary}
                  color={color}
                  lastItem={index + 1 === salaries.length}
                  isOpenMenu={selectSalaryId === salary.id}
                  onCloseMenu={() => setSelectSalaryId()}
                  setSelectSalaryId={showSalaryOptions}
                  setEditSalaryId={setEditSalaryId}
                  onDeleteSalary={onDeleteSalary}
                />
              )
            )}
          </div>
        </div>
      </div>
    );
  })`
    > .title {
      font-size: 20px;
    }

    > .table-wrapper {
      width: calc(100vw - 90px - ${theme('sidebarWidth')}px);
      overflow: auto;
      border-radius: 6px;
      box-shadow: 0 5px 10px 0 rgba(58, 58, 58, 0.05);
      background-color: #fff;

      > .table {
        min-width: max-content;
        min-height: 100vh;
      }
    }

    @media (max-width: ${theme('breakpoints.mobile')}px) {
      .table-wrapper {
        width: 100vw;
      }
    }
  `
);
