import React from 'react';
import hoist from 'hoist-non-react-statics';
import { useNavigate } from 'react-router-dom';
import { useCurrentCompany } from '../../useCurrentCompany';
import { useProjectAction } from '../../useProjectSearch';
import { colorOptions } from '../../../../components/ColorPalette/ColorPalette';
import { successToast } from '../../../../lib/toast';

export function withMobileProjectCreatePage(Component) {
  function WithMobileProjectCreatePage({ ...props }) {
    const { data: company } = useCurrentCompany();
    const { create } = useProjectAction();
    const navigate = useNavigate();

    const initialValues = {
      name: '',
      description: '',
      logoFile: null,
      color: colorOptions[0],
      logoUrl: null,
    };

    function onCancel() {
      navigate(-1);
    }

    const pageProps = {
      companyName: company?.name,
      initialValues,
      async onSubmit({ name, logoFile: logo, description, color, members }) {
        await create({ name, logo, description, color, members });
        successToast({ message: 'The project has been created.' });
        navigate(-1);
      },
      onCancel,
      ...props,
    };

    return <Component {...pageProps} />;
  }
  hoist(WithMobileProjectCreatePage, Component);

  WithMobileProjectCreatePage.displayName = `WithMobileProjectCreatePage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithMobileProjectCreatePage;
}
