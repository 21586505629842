import React, { useState, useCallback, useContext } from 'react';
import hoist from 'hoist-non-react-statics';
import { useQueryCache, useInfiniteQuery } from 'react-query';
import GlobalContext from '../../../store/globalContext';
import { useQueryParams } from '../../../lib/hooks';
import { useAppClient } from '../../../lib/AppProvider';
import ProjectContext from '../../../store/projectContext';

const limit = 10;

export function withActivityLogs(Component) {
  function WithActivityLogs({ projectId, ticketId, ...props }) {
    const { globalState } = useContext(GlobalContext);
    const { projectState } = useContext(ProjectContext);
    const queryCache = useQueryCache();
    const [showDetail, setShowDetail] = useState(false);
    const [editCommentId, setEditCommentId] = useState(null);

    const client = useAppClient();
    const [queryParams] = useQueryParams();
    const { members } = projectState;
    const { profile } = globalState;

    const { data, status, ...infiniteQuery } = useInfiniteQuery(
      ['activityLogs', projectId, ticketId, showDetail ? 'all' : 'comment'],
      async (_key, _projectId, _ticketId, type, nextId = null) => {
        if (!projectId || !ticketId) return [];
        const activityLogs = await client.listActivityLogsAndComments({
          id: projectId,
          ticketId,
          onlyComment: type === 'comment',
          limit,
          after: nextId,
        });
        return activityLogs.data;
      },
      {
        getFetchMore: lastGroup => lastGroup[limit - 1]?.id,
        refetchOnWindowFocus: false,
      }
    );

    const handleCreateComment = async (form, { resetForm }) => {
      await client.createComment({
        projectId,
        ticketId: queryParams.selectedTicketId,
        ...form,
      });
      await queryCache.refetchQueries(['activityLogs', projectId, ticketId]);
      resetForm();
    };

    const handleUpdateComment = async form => {
      await client.updateComment({
        projectId,
        commentId: editCommentId,
        comment: form.comment,
        images: form.images,
      });
      await queryCache.refetchQueries(['activityLogs', projectId, ticketId]);
      await setEditCommentId(null);
    };

    const handleDeleteComment = async commentId => {
      await client.deleteComment({ projectId, commentId });
      await queryCache.refetchQueries(['activityLogs', projectId, ticketId]);
    };

    const toggleShowDetail = useCallback(() => setShowDetail(c => !c), []);

    const pageProps = {
      ...infiniteQuery,
      data: data?.flat(1) || [],
      status: projectId && ticketId ? status : 'loading',
      showDetail,
      profile,
      projectMembers: members || [],
      handleCreateComment,
      handleUpdateComment,
      handleDeleteComment,
      toggleShowDetail,
      editCommentId,
      setEditCommentId,
    };

    return <Component {...props} {...pageProps} />;
  }
  hoist(WithActivityLogs, Component);

  WithActivityLogs.displayName = `WithActivityLogs(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithActivityLogs;
}
