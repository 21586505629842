import React, { useCallback } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import styled from 'styled-components';
import { MentionsInput as ReactMentions, Mention } from 'react-mentions';
import { useField } from 'formik';
import { ifProp, switchProp, theme } from 'styled-tools';

export function getDisplayUserMention(name) {
  return `@${(name.match(/\((.*?)\)/) || [])[1] || name.split(' ')[0]}`;
}

const MentionsInput = React.forwardRef(function MentionsInput(
  {
    className,
    required,
    error,
    touched,
    label,
    multiline,
    onChange,
    rows = 5,
    members = [],
    ...props
  },
  ref
) {
  const showError = touched && error;

  function displayTransform(_id, display) {
    return getDisplayUserMention(display);
  }

  function handleChange(_event, value) {
    if (onChange) {
      const mentionIds = [...value.matchAll(/@\[(.*?)\]\((.*?)\)/g)]
        .map(match => parseInt(match[2], 10))
        .filter((v, i, a) => a.indexOf(v) === i);
      onChange(value, mentionIds);
    }
  }

  return (
    <Container className={className} error={showError} multiline rows={rows}>
      {label && (
        <label htmlFor={props.id} className="label">
          {label}
        </label>
      )}
      <div className="input-group">
        <ReactMentions
          {...props}
          className="mentions"
          inputRef={ref}
          singleLine={!multiline}
          appendSpaceOnAdd
          onChange={handleChange}
        >
          <Mention
            data={members.map(({ id, name, nickName }) => ({
              id,
              display: nickName ? `${name} (${nickName})` : name,
            }))}
            displayTransform={displayTransform}
          />
        </ReactMentions>
        {showError && (
          <Popover className="popover" error>
            <div className="hint">
              <div className="arrow" /> {error}
            </div>
          </Popover>
        )}
        {required && <span className="required-indicator">*</span>}
      </div>
    </Container>
  );
});

export const Popover = styled.div`
  z-index: 1000;

  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(calc(100% + 30px));

  color: ${ifProp('error', theme('colors.error'), '#22a7c7')};
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;

  > .hint {
    content: ' ';

    position: relative;
    display: block;

    border-radius: 6px;
    padding: 10px;
    width: 280px;

    background: white;
    box-shadow: 0 0 5px rgba(0, 128, 42, 0.08);
  }

  > .hint > .arrow {
    position: absolute;
    top: calc(50% - 10px);
    left: -10px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid white;
    width: 0;
    height: 0;
  }

  > .hint {
    box-shadow: 0 0 5px rgba(0, 128, 42, 0.2);
  }
`;

const Container = styled.div`
  margin-bottom: 16px;

  > ${Popover} {
    position: absolute;
    top: 50%;
    left: calc(100% + 24px);

    transform: translateY(-50%);
  }

  > .label {
    display: block;
    font-size: 12px;

    margin-bottom: 8px;

    color: #999999;
  }

  > .input-group {
    position: relative;
  }

  > .input-group > .required-indicator {
    z-index: 1;

    position: absolute;
    top: calc(50% - 6px);
    right: -10px;

    width: 6px;
    height: 6px;

    color: ${theme('colors.error')};
  }

  > .input-group > .prefix {
    position: absolute;
    top: 10px;
    left: 16px;
    color: ${theme('colors.primary')};
  }

  > .input-group > .suffix {
    position: absolute;
    top: 10px;
    right: 16px;
    color: ${ifProp('error', theme('colors.error'), theme('colors.primary'))};
  }
`;

const StyledMentionsInput = styled(MentionsInput)`
  font-size: ${switchProp('size', { sm: '12px' }, 'inherit')};

  .mentions {
    width: 100%;
    display: ${ifProp('multiline', 'block', 'inline-block')};

    .mentions__control {
      background-color: #ffffff;
    }

    .mentions__highlighter {
      padding: 10px;
      box-sizing: border-box;
      overflow: ${ifProp('multiline', 'hidden', 'unset')};
      height: ${props =>
        props.multiline
          ? `calc(${props.rows}em + ${props.rows * 5}px)`
          : 'unset'};
    }

    .mentions__input {
      padding: 10px;
      border: 1px solid ${ifProp('error', theme('colors.error'), '#dce0ea')};
      border-radius: 6px;
      overflow: ${ifProp('multiline', 'auto', 'unset')};
      height: ${props =>
        props.multiline
          ? `calc(${props.rows}em + ${props.rows * 5}px)`
          : 'unset'};

      &:focus,
      &:active {
        border-radius: 6px;
        outline-color: ${theme('colors.primary')};
      }

      &:disabled {
        background: #efefef;
      }

      &::placeholder {
        color: #c1c3ca;
      }
    }

    .mentions__suggestions__list {
      background-color: '#ffffff';
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 6px;
      position: relative;
      top: 5px;
    }

    .mentions__suggestions__item {
      padding: 5px 15px;

      &:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      }

      &:hover {
        background-color: ${theme('colors.primary')}10;
      }
    }
  }
`;

const FormikMentionsInput = React.forwardRef(function (
  { onChange, ...props },
  ref
) {
  const [field, { error, touched }, helper] = useField(props.name);

  const handleChange = useCallback(
    (value, mentionIds) => {
      if (onChange) {
        onChange(value, mentionIds);
      } else {
        helper.setValue(value);
      }
    },
    [onChange, helper]
  );

  return (
    <StyledMentionsInput
      {...props}
      {...field}
      error={error}
      touched={touched}
      ref={ref}
      onChange={handleChange}
    />
  );
});

hoistNonReactStatics(FormikMentionsInput, StyledMentionsInput);

export {
  FormikMentionsInput as MentionsInput,
  StyledMentionsInput as MentionsInputView,
};
