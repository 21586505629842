import React, { useState } from 'react';
import hoist from 'hoist-non-react-statics';
import { useAuthentication } from '../../useAuthentication';

export function withRegistrationPage(Component) {
  function WithRegistrationPage(props) {
    const { register } = useAuthentication();
    const [isShowModal, setIsShowModal] = useState(false);
    const [error, setError] = useState(null);

    async function onSubmit(
      { email, password, firstName, lastName, companyName },
      { resetForm }
    ) {
      try {
        await register({ email, password, firstName, lastName, companyName });
        resetForm();
        setIsShowModal(true);
      } catch (err) {
        if (!err.isAxiosError) {
          throw err;
        }

        setError(err.response?.data?.errors[0]?.detail);
      }
    }

    function onCloseModal() {
      setIsShowModal(false);
    }

    return (
      <Component
        {...props}
        error={error}
        onSubmit={onSubmit}
        isShowModal={isShowModal}
        onCloseModal={onCloseModal}
      />
    );
  }

  hoist(WithRegistrationPage, Component);

  WithRegistrationPage.displayName = `withRegistrationPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithRegistrationPage;
}
