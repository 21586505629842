import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import React, { useMemo } from 'react';
import { ifProp, theme } from 'styled-tools';
import styled from 'styled-components';
import { useQueryCache } from 'react-query';
import { DraggableColumn } from './Column';
import { AddListCard } from './AddListCard';

const Loading = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);

  display: grid;
  place-items: center;

  border-radius: 6px;
  width: 100%;
  height: 100%;

  background: #ffffff77;

  &:after {
    content: ' ';
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid ${theme('colors.primary')};
    border-color: ${theme('colors.primary')} transparent
      ${theme('colors.primary')} transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

class InnerColumn extends React.PureComponent {
  render() {
    const { index, column } = this.props;
    return (
      <DraggableColumn
        key={`column-${column.droppableId}`}
        index={index}
        {...column}
      />
    );
  }
}

function KanbanBoard({
  tickets,
  className,
  loading,
  project,
  onSelectTicket,
  onUpdateStatus,
  onUpdateColumn,
  onAddColumn,
  onAddTicketToColumn,
  onArchiveColumn,
}) {
  const queryCache = useQueryCache();

  function onDragEnd(event) {
    function findColumnIndex(list, droppableId) {
      return list.findIndex(item => item.id === droppableId);
    }
    function roundToTwo(num) {
      return Math.round(num * 100) / 100;
    }
    switch (event.type) {
      case 'TICKET': {
        const { source, destination } = event;

        if (
          !destination ||
          (source.index === destination.index &&
            source.droppableId === destination.droppableId)
        ) {
          break;
        }
        const cloneTickets = Array.from(tickets);

        if (source.droppableId === destination.droppableId) {
          const columnIndex = findColumnIndex(cloneTickets, source.droppableId);
          const ticketData = cloneTickets[columnIndex].data;
          const sourceTicket = ticketData[source.index];

          const upperOrder =
            source.index < destination.index
              ? parseFloat(ticketData[destination.index]?.order)
              : parseFloat(ticketData[destination.index - 1]?.order);
          const underOrder =
            source.index < destination.index
              ? parseFloat(ticketData[destination.index + 1]?.order)
              : parseFloat(ticketData[destination.index]?.order);

          const newOrder = roundToTwo(
            Number.isNaN(underOrder) || Number.isNaN(upperOrder)
              ? source.index < destination.index
                ? upperOrder + 1
                : underOrder - 1
              : (upperOrder + underOrder) / 2
          );

          const reorder = () => {
            const result = Array.from(ticketData);
            const [removed] = result.splice(source.index, 1);
            result.splice(destination.index, 0, {
              ...removed,
              order: newOrder,
            });
            cloneTickets[columnIndex] = {
              ...cloneTickets[columnIndex],
              data: result,
            };

            return cloneTickets;
          };

          onUpdateStatus(
            sourceTicket,
            destination.droppableId,
            newOrder,
            reorder()
          );
        } else {
          const sourceColumnIndex = findColumnIndex(
            cloneTickets,
            source.droppableId
          );
          const destinationColumnIndex = findColumnIndex(
            cloneTickets,
            destination.droppableId
          );
          const sourceTicketData = cloneTickets[sourceColumnIndex].data;
          const destinationTicketData =
            cloneTickets[destinationColumnIndex].data;
          const sourceTicket = sourceTicketData[source.index];

          const upperOrder = parseFloat(
            destinationTicketData[destination.index - 1]?.order
          );
          const underOrder = parseFloat(
            destinationTicketData[destination.index]?.order
          );
          const newOrder = roundToTwo(
            destinationTicketData.length === 0
              ? 1
              : Number.isNaN(upperOrder)
              ? underOrder - 1
              : Number.isNaN(underOrder)
              ? upperOrder + 1
              : (upperOrder + underOrder) / 2
          );

          const reorder = () => {
            const sourceClone = Array.from(sourceTicketData);
            const destClone = Array.from(destinationTicketData);
            const [removed] = sourceClone.splice(source.index, 1);
            destClone.splice(destination.index, 0, {
              ...removed,
              order: newOrder,
            });
            cloneTickets[sourceColumnIndex] = {
              ...cloneTickets[sourceColumnIndex],
              data: sourceClone,
            };
            cloneTickets[destinationColumnIndex] = {
              ...cloneTickets[destinationColumnIndex],
              data: destClone,
            };

            return cloneTickets;
          };

          onUpdateStatus(
            sourceTicket,
            destination.droppableId,
            newOrder,
            reorder()
          );
        }

        break;
      }
      case 'COLUMN': {
        const { source, destination } = event;
        const column = tickets[source.index];
        if (
          destination.index + 1 <= tickets.length &&
          source.index !== destination.index
        ) {
          onUpdateColumn(column, { order: event.destination.index + 1 });
        }
        break;
      }
      default: {
        break;
      }
    }
  }
  const columns = useMemo(
    () =>
      tickets
        // TODO: refactor this
        .map(column => ({
          project,
          droppableId: column.id,
          title: column.name,
          data: column.data,
          numberOfTickets: column.data.length,
          totalEstimateMinutes: column.data.reduce(
            (sum, { userEstimate }) => sum + userEstimate,
            0
          ),
          onSelectTicket,
          async onUpdateName(name) {
            await onUpdateColumn(column, { name });
            await queryCache.refetchQueries([
              'project',
              'detail',
              project?.id,
              'column-options',
            ]);
          },
          onAddTicket() {
            onAddTicketToColumn(column.id);
          },
          onArchive() {
            onArchiveColumn(column.id);
          },
        })),
    [
      queryCache,
      tickets,
      project,
      onSelectTicket,
      onUpdateColumn,
      onArchiveColumn,
      onAddTicketToColumn,
    ]
  );

  if (loading) {
    return (
      <div className={className}>
        <Loading />
      </div>
    );
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="board"
        type="COLUMN"
        direction="horizontal"
        isDragDisabled
      >
        {provided => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={className}
          >
            {columns.map((column, index) => (
              <InnerColumn index={index} key={index} column={column} />
            ))}
            <AddListCard
              className="add-column"
              onAdd={onAddColumn}
              index={columns.length}
            />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

const StyledKanbanBoard = styled(KanbanBoard)`
  overflow: ${ifProp('loading', 'hidden', 'auto hidden')};

  position: relative;

  display: flex;
  flex-flow: row nowrap;

  > .add-column {
    margin-top: 10px;
  }
`;

const MemoKanbanBoard = React.memo(StyledKanbanBoard);

export { MemoKanbanBoard as KanbanBoard };
