import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { getDisplayUserMention } from './MentionsInput';

const MentionsText = ({ className, children = '', ...props }) => {
  function displayTransform(text) {
    return text.replace(
      /@\[(.*?)\]\((.*?)\)/g,
      (_mention, name) => `<span>${getDisplayUserMention(name)}</span>`
    );
  }

  return (
    <span
      {...props}
      className={className}
      dangerouslySetInnerHTML={{ __html: displayTransform(children) }}
    />
  );
};
const StyledMentionsText = styled(MentionsText)`
  > span {
    color: ${theme('colors.primary')};
  }
`;

export { StyledMentionsText as MentionsText };
