import React from 'react';
import { SettingCompanyPage } from './pages/SettingCompanyPage';
import { MemberListPage } from './pages/MemberListPage';
import { SettingLayout } from './SettingLayout';

export const SettingRoutes = {
  path: '/settings',
  element: <SettingLayout />,
  children: [
    { path: 'general', element: <SettingCompanyPage /> },
    { path: 'members', element: <MemberListPage /> },
  ],
};
