import React from 'react';
import styled from 'styled-components';
import pick from 'ramda/es/pick';
import { theme } from 'styled-tools';
import { AddUserIcon, CheckIcon } from './Icons';
import { Avatar } from './Avatar';

export const AvatarList = styled(function AvatarList({
  className,
  data,
  compact,
  hidePlaceholder,
  onSelect,
  userCount,
}) {
  return (
    <div className={className}>
      {data
        .slice(0, compact ? 2 : data.length + 1)
        .map(item => (
          <div className="avatar-block" key={item.id}>
            <Avatar
              className="avatar"
              {...pick(['avatarUrl', 'name', 'initial'], item)}
              onClick={() => onSelect && onSelect(item.id)}
            />
            {onSelect && <CheckIcon className="check" size={16} />}
          </div>
        ))
        .concat(
          compact && userCount && userCount > 2 ? (
            <div className="remaining" key={`r${userCount}`}>
              +{userCount - 2}
            </div>
          ) : compact && data.length > 2 ? (
            <div className="remaining" key={`r${data.length}`}>
              +{data.length - 2}
            </div>
          ) : !hidePlaceholder ? (
            <div className="placeholder" key="p">
              <AddUserIcon />
            </div>
          ) : null
        )}
    </div>
  );
})`
  display: flex;
  padding-left: 10px;

  > .avatar-block,
  > .remaining,
  > .placeholder {
    position: relative;
    flex: 0 0 32px;
    margin-left: -10px;

    width: 32px;
    height: 32px;

    @media (max-width: ${theme('breakpoints.mobile')}px) {
      margin-right: 10px;
      margin-left: 0;
    }
  }

  > .avatar-block {
    > .check {
      position: absolute;
      top: 16px;
      right: 0;
      padding: 2px;

      color: white;

      background: ${theme('colors.primary')};
      border-radius: 50%;
      pointer-events: none;
    }
  }

  > .remaining {
    font-size: 12px;
    font-weight: bold;
  }

  > .remaining {
    border-radius: 16px;

    color: white;
    font-weight: bold;
    text-align: center;
    line-height: 32px;

    background: ${theme('colors.primary')};
  }

  > .placeholder {
    border-radius: 50%;
    padding: 0;
    width: 32px;
    height: 32px;

    color: white;
    line-height: 32px;
    text-align: center;

    background: ${theme('colors.primary')};

    > ${AddUserIcon} {
      margin-right: 0;
    }
  }
`;
