import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { theme } from 'styled-tools';
import { Modal } from '../../../../components/Modal';
import {
  Button,
  OutlineButton,
} from '../../../authentication/components/Button';
import { TextField } from '../../../authentication/components/TextField';
import { FormikSingleImageUploader as SingleImageUploader } from '../../../settings/pages/SettingCompanyPage/SingleImageUploader';
import { EditableText } from '../../../settings/pages/SettingCompanyPage/EditableText';
import { ColorPalette } from '../../../../components/ColorPalette';
import { MemberManagement } from '../../../../components/MemberManagement';
import { colorOptions } from '../../../../components/ColorPalette/ColorPalette';
import { LoadingIndicator } from '../../../../components/LoadingIndicator';
import { DeleteModal } from '../../../../components/SideBarFilter/DeleteModal';

function ProjectSettingModal({
  className,
  isOpen,
  onClose,
  projectId,
  selectedProject,
  initialValues = {
    name: '',
    description: '',
    logoFile: null,
    color: colorOptions[0],
    logoUrl: null,
  },
  onSubmit,
  onDelete,
  companyName,
  submitText = projectId ? 'Save' : 'Create',
  loading,
  onOpenDeleteModal,
  isShowDeleteModal,
  onCloseDeleteModal,
  headerText = projectId ? 'Project Setting' : 'Create new project',
}) {
  if (loading) {
    return (
      <Modal className={className} isOpen={isOpen} onClose={onClose}>
        <LoadingIndicator />
      </Modal>
    );
  }

  return (
    <>
      <Modal className={className} isOpen={isOpen} onClose={onClose}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ values, isSubmitting, setFieldValue, submitForm }) => (
            <div>
              <div className="header">
                <div className="company-wrapper">
                  <EditableText name="name" placeholder="Enter project name" />
                  <h2>
                    Company :<span className="company-name">{companyName}</span>
                  </h2>
                  <h2>
                    <ColorPalette name="color" />
                  </h2>
                </div>
              </div>
              <hr />
              <div className="contents">
                <div className="logo-uploader">
                  <SingleImageUploader
                    className="image-uploader"
                    name="logoFile"
                    previewUrl={values.logoUrl}
                    setFieldValue={setFieldValue}
                  />
                </div>
                <div className="field-group">
                  <TextField
                    style={{ height: '180px' }}
                    id="description"
                    name="description"
                    label="Description and purpose"
                    rows={8}
                    multiline
                    oldLabel
                  />
                </div>
              </div>

              <MemberManagement
                value={values.members}
                onChange={members => setFieldValue('members', members)}
                projectId={projectId}
              />
              <div className="title">
                {projectId && (
                  <div>
                    <span
                      type="button"
                      className="delete"
                      onClick={onOpenDeleteModal}
                    >
                      Delete this project
                    </span>
                  </div>
                )}
                <div className="actions">
                  <OutlineButton
                    className="button cancel"
                    type="button"
                    onClick={onClose}
                  >
                    Cancel
                  </OutlineButton>
                  <Button
                    className="button"
                    type="submit"
                    onClick={() => submitForm()}
                    disabled={isSubmitting || !values.name}
                  >
                    {submitText}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </Modal>
      <DeleteModal
        isOpen={isShowDeleteModal}
        onClose={onCloseDeleteModal}
        onDelete={onDelete}
        deleteText={`Delete Project "${selectedProject?.name}" ?`}
      />
    </>
  );
}

const StyledProjectSettingModal = styled(ProjectSettingModal)`
  width: 1100px;

  hr {
    background-color: #eaecf2;
    height: 1px;
    border: 0;
    margin-bottom: 20px;
  }

  .close {
    display: none;
  }

  ${LoadingIndicator} {
    min-height: 600px;
  }

  .content {
    width: 100%;
  }

  .header {
    margin-top: 0 !important;
    margin-bottom: 20px;
  }

  .title,
  .header {
    display: grid;
    grid-template-areas: 'company actions';
    margin-top: 30px;

    > .company-wrapper {
      grid-area: company;
      margin-top: -5px;
      h1 {
        font-size: 20px;
        font-weight: normal;
        color: #3a3a3a;
        margin-bottom: 10px;
        span {
          font-weight: normal;
        }
      }
      h2 {
        font-size: 12px;
        font-weight: normal;
        span {
          font-weight: normal;
        }
      }
    }
    > .close-popup {
      > .close-popup-botton {
        position: absolute;
        top: 10px;
        right: 15px;
        border: 0;
        background-color: white;
        border-radius: 50%;
        width: 28px;
        height: 28px;
        color: white;
      }
    }
    > .actions {
      grid-area: actions;
      display: flex;
      justify-content: flex-end;
      align-self: center;

      .button {
        width: 120px;
      }

      .cancel {
        border: solid 1px #c2c3ca;
        color: #c2c3ca;
      }

      .cancel:hover {
        background-color: #c2c3ca;
        color: white;
      }

      > ${Button} {
        &.delete {
          color: #c1c3ca;
          border: none;
        }

        &.delete:hover {
          background: ${theme('colors.error')};
          color: white;
        }

        &:not(:last-child) {
          margin-right: 8px;
        }

        &:disabled {
          background: #adadad;
          cursor: not-allowed;
          border: none;
        }
      }
    }

    .company-name {
      color: ${theme('colors.primary')};
      margin-left: 14px;
    }
  }

  .contents {
    display: grid;
    grid-template-columns: 215px 1fr;
  }

  .contents > .field-group {
    margin-top: 5px;
    > ${EditableText} {
      margin-bottom: 16px;
    }

    .text-field {
      height: 122px;
    }
  }

  .add-members {
    .title {
      color: #999999;
      font-size: 12px;
    }

    .selection-area {
      display: flex;
      justify-content: space-between;

      .company-wrapper {
        flex: 0.5;
      }

      .right-section {
        flex: 0.5;
      }
    }
  }

  .logo-uploader {
    width: 250px;
    height: 250px;
    background: white;
    margin-top: -15px;

    > .image-uploader {
      width: 200px;
      height: 200px;
      margin: 20px 20px 30px 0;
      padding: 70px 54px 69px;
      border-radius: 6px;
      border: solid 1px #22c759;
      background-color: #ffffff;

      .remove {
        color: #9fa3ae;
      }
    }
  }

  .delete {
    color: red;
    font-size: 16px;
    cursor: pointer;
    padding: 10px;
    text-decoration: underline;
    font-weight: bold;
  }

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    .title {
      grid-template-areas:
        'actions actions'
        'company company';

      > .actions {
        display: flex;
        flex-direction: column;

        > ${Button} {
          &:not(:last-child) {
            margin-right: 0;
          }
        }
      }
    }

    .contents {
      grid-template-columns: auto;

      > .logo-uploader {
        margin: auto;
      }
    }
  }
`;

export { StyledProjectSettingModal as ProjectSettingModal };
