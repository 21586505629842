import React, { useMemo } from 'react';
import styled from 'styled-components';
import { prop, theme } from 'styled-tools';
import { useSortBy, useTable } from 'react-table';
import { AlarmIcon, FlagIcon } from '../../../components/Icons';
import { AvatarList } from '../../../components/AvatarList';
import { Select } from '../../../components/Select/Select';
import { LoadingIndicator } from '../../../components/LoadingIndicator';

const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0 20px;
  width: 100%;

  thead {
    th {
      color: #999;
      font-size: 12px;
      text-align: left;
    }
  }

  th:first-child,
  td:first-child {
    width: 76px;
  }

  th {
    padding: 16px 8px 4px;
    min-width: 50px;
    white-space: nowrap;
  }

  td {
    padding: 16px 8px;
    min-width: 50px;
    white-space: nowrap;
  }

  th:nth-last-child(2),
  td:nth-last-child(2) {
    min-width: 110px;
  }

  tbody {
    tr {
      > .empty {
        text-align: center;
      }
      > td {
        color: #999;
        font-size: 12px;
        font-weight: bold;

        background: white;
        cursor: pointer;

        &:first-child {
          border-radius: 6px 0 0 6px;
          padding-left: 16px;
        }

        &:last-child {
          border-radius: 0 6px 6px 0;
          padding-right: 16px;
        }

        ${Select} {
          .input-group > .field {
            padding: 5px 0;
          }
        }

        > .col {
          display: flex;
          align-items: center;

          padding: 0 20px;

          border-left: 1px solid #ddd;
          line-height: 32px;
        }

        > .name {
          color: #333;
          font-size: 14px;
          font-weight: normal;

          border-left: 0px solid #ddd;
        }

        > .date-point {
          font-weight: bold;

          > .point {
            margin-left: 10px;
            padding-left: 10px;
          }
        }

        > .sprints {
          > .more {
            flex: 0 0 20px;

            margin-top: -2px;
            margin-left: 5px;

            border-radius: 10px;
            width: 20px;
            height: 20px;

            color: white;
            text-align: center;
            line-height: 20px;
          }
        }

        > .tags {
          display: flex;
          flex-direction: column;
          line-height: 32px;
          row-gap: 6px;
        }

        > .members {
          justify-content: start;
        }
      }
    }
  }
`;

// move this to Badge.js
export const Badge = styled.div`
  padding: 2px 8px;

  border-radius: 8px;
  border: 0;

  color: white;
  font-size: 12px;
  font-weight: bold;

  background: ${prop('color', theme('colors.primary'))};
`;

export const TicketTable = styled(function TicketTable({
  tickets = [],
  className,
  loading,
  title,
  color,
  onSelectTicket,
}) {
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'name',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Due date | Estimation Date',
        accessor: 'dueDate',
      },
      {
        Header: 'Sprints',
        accessor: 'sprints',
      },
      {
        Header: 'Tags',
        accessor: 'tags',
      },
      {
        Header: 'Members',
        accessor: 'members',
      },
    ],
    []
  );

  const data = useMemo(() => {
    return tickets.map(ticket => ({
      id: ticket.id,
      groupId: ticket.idEachGroup,
      name: <div className="col name">{ticket.name}</div>,
      status: <div className="col status">{ticket.statusname}</div>,
      dueDate: (
        <div className="col date-point">
          {ticket.dueDate && (
            <div className="due-date">
              <AlarmIcon color={ticket?.epicColor || color} />
              {ticket.dueDate.toFormat('d MMM')}
              {' |'}
            </div>
          )}
          {(!!ticket.point || !!ticket.pointMinute) && (
            <div className="point">
              {ticket.point.toLocaleString()} hr(s){' '}
              {ticket.pointMinute.toLocaleString()} min(s)
            </div>
          )}
        </div>
      ),
      sprints: (
        <div className="col sprints">
          {ticket.storySprints?.slice(0, 1).map(sprint => (
            <div key={sprint.id} className="sprint">
              <FlagIcon color={sprint.color || color} />
              {sprint.name}
            </div>
          ))}
          {ticket.storySprints?.length > 1 && (
            <div className="more" style={{ background: color }}>
              +{(ticket.storySprints?.length - 1).toLocaleString()}
            </div>
          )}
        </div>
      ),
      tags: (
        <div className="col tags">
          {ticket.tags?.map(tag => (
            <Badge key={tag?.id} color={tag?.color}>
              {tag?.name}
            </Badge>
          ))}
        </div>
      ),
      members: (
        <div className="col members">
          <AvatarList
            data={ticket.assignees}
            userCount={ticket.userCount}
            compact
            hidePlaceholder
          />
        </div>
      ),
    }));
  }, [tickets, color]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: data ?? [],
    },
    useSortBy
  );

  return (
    <div className={className}>
      {loading && <LoadingIndicator />}
      <h4 className="title">{title}</h4>
      <div className="table-wrapper">
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {!loading && tickets.length === 0 && (
              <tr>
                <td className="empty" colSpan={7}>
                  <h3>No Data</h3>
                </td>
              </tr>
            )}
            {rows.map(row => {
              prepareRow(row);

              return (
                <tr
                  className="table-row"
                  {...row.getRowProps({
                    onClick: () => {
                      onSelectTicket(row.original.id, row.original.groupId);
                    },
                  })}
                >
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
})`
  position: relative;
  width: 100%;
  > .table-wrapper {
    @media (max-width: ${theme('breakpoints.mobile')}px) {
      overflow-x: auto;
      width: calc(100vw - 60px);
      margin: auto;

      ${Table} {
        min-width: 700px;
      }
    }
  }

  > ${LoadingIndicator} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;
