import React, { useState } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { ifProp, prop, theme, withProp } from 'styled-tools';
import { Menu, MenuButton, MenuPopover } from '@reach/menu-button';
import { Field, Formik, Form } from 'formik';
import {
  ArrowUpIcon,
  SaveIcon,
  Pencil,
  TrashIcon,
  EllipseVerticalIcon,
  CancelFileIcon,
  CloseFillIcon,
} from '../../../components/Icons';
import { IconButton } from '../../authentication/components/Button';
import { DeleteModal } from '../../../components/SideBarFilter/DeleteModal';
import { useSearchExpenseProjects } from '../useCompanyExpense';
import { errorToast } from '../../../lib/toast';

const initialValues = {
  name: '',
};

const validationSchema = yup.object({
  name: yup.string().required('Project name is required'),
});

const ProjectEditor = styled(function ProjectEditor({
  className,
  name,
  onSubmit,
  onCancel,
}) {
  return (
    <Formik initialValues={{ name }} onSubmit={onSubmit}>
      {() => {
        return (
          <Form noValidate className={className}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '2px',
                alignItems: 'center',
              }}
            >
              <Field id="name" name="name" label="New Type" autocomplete="off">
                {({ field }) => (
                  <div>
                    <input
                      type="text"
                      placeholder="Enter Type"
                      style={{
                        width: '150px',
                        height: '36px',
                        border: '1px solid #dce0ea',
                        color: '#3a3a3a',
                        padding: '10px',
                        borderRadius: '8px',
                        fontSize: '13px',
                      }}
                      {...field}
                    />
                  </div>
                )}
              </Field>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button
                  style={{
                    cursor: 'pointer',
                    backgroundColor: '#fff',
                    border: 'none',
                  }}
                  type="submit"
                >
                  <SaveIcon color="#22c759" size={20} />
                </button>
                <div onClick={onCancel} style={{ cursor: 'pointer' }}>
                  <CancelFileIcon size={20} color="#9fa3ae" />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
})``;

const Project = styled.div`
  display: inline-block;
  padding: 0 10px;

  color: '#fff';
  font-size: 12px;
  line-height: 1.7;
  font-weight: bold;
`;

const ProjectItem = styled(function ProjectItem({
  className,
  id,
  name,
  selected,
  onSelect,
  onRemove,
  onSubmit,
  color,
  currentOpenId,
  showProjectOptions,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);

  function onOpenDeleteModal() {
    setIsShowDeleteModal(true);
  }

  function onCloseDeleteModal() {
    setIsShowDeleteModal(false);
  }

  if (isEditing) {
    return (
      <ProjectEditor
        name={name}
        onSubmit={values => {
          setIsEditing(false);
          onSubmit(values);
        }}
        onCancel={() => setIsEditing(false)}
      />
    );
  }

  return (
    <div className={className}>
      <Project selected={selected} onClick={onSelect} color={color}>
        {name}
      </Project>
      <div className="actions">
        <IconButton
          className="menu-icon"
          onClick={() => showProjectOptions(id)}
        >
          <EllipseVerticalIcon color={currentOpenId ? color : null} />
        </IconButton>
        {currentOpenId && (
          <div className="project-menu">
            <div
              onClick={() => {
                setIsEditing(true);
                showProjectOptions();
              }}
            >
              <Pencil size={16} />
              <span style={{ paddingLeft: '5px' }}>Edit</span>
            </div>
            <div onClick={onOpenDeleteModal}>
              <TrashIcon size={16} />
              <span style={{ paddingLeft: '5px' }}>Delete</span>
            </div>
          </div>
        )}
      </div>
      <DeleteModal
        isOpen={isShowDeleteModal}
        onClose={onCloseDeleteModal}
        onDelete={onRemove}
        deleteText="Delete Project?"
        description="Do you really want to delete this project? This process cannot be undone."
      />
    </div>
  );
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  > .actions {
    > .project-menu {
      position: absolute;
      width: 100px;
      height: 80px;
      padding: 10px;
      background-color: white;
      border-radius: 5px;
      z-index: 10;
      top: 30%;
      left: 101%;

      > div {
        padding: 5px;
        display: flex;
        cursor: pointer;

        > svg {
          margin-top: 3px;
        }

        > span {
          font-size: 12px;
          margin-left: 3px;
          margin-top: 3px;
        }
      }
    }
  }

  > .actions > ${IconButton} {
    width: 24px;
    height: 24px;
  }
`;

const Popover = styled(MenuPopover)`
  position: relative;
  z-index: 1;
  margin-top: 10px;
  border-radius: 6px;
  border: 1px solid #ddd;
  width: ${ifProp(
    'width',
    withProp(prop('width'), w => `${w}px`),
    'auto'
  )};

  background: white;
  box-shadow: ${theme('shadow')};

  > hr {
    margin: 15px 0;

    border: 0 solid #ddd;
    border-top-width: 1px;
  }

  > .list > .placeholder {
    color: #3a3a3a;
    font-size: 12px;
    text-align: center;
  }

  .relative {
    position: relative;
  }

  .selectable-list {
    overflow-y: scroll;
    max-height: 110px;
    min-width: 200px;

    ${ProjectItem}:hover {
      background-color: rgba(34, 199, 89, 0.05);
    }
    ${Project} {
      cursor: pointer;
    }
  }
`;

export const ProjectSelect = styled(function ProjectSelect({
  className,
  onChange,
  value = null,
  companyId,
}) {
  const [show, setShow] = useState(false);
  const [currentOpenId, setCurrentOpenId] = useState();
  function showProjectOptions(id) {
    const typeId = id !== currentOpenId ? id : null;
    setCurrentOpenId(typeId);
  }

  const {
    expenseProjects,
    createProject,
    updateProject,
    destroyProject,
    revalidate,
  } = useSearchExpenseProjects(companyId);

  async function handleCreate(input) {
    const response = await createProject({
      name: input.name,
    });
    setShow(false);
    if (response?.errors) {
      errorToast({
        message: response?.errors?.detail,
      });
    } else {
      onChange(response.id);
      await revalidate();
    }
  }

  async function updateExpenseProject(id, { name }) {
    const response = await updateProject(id, { name });
    if (response?.errors) {
      errorToast({
        message: response?.errors?.map(err => err.detail)?.join(','),
      });
    } else {
      await revalidate();
    }
  }

  async function removeProject(id) {
    const response = await destroyProject(id);
    if (response?.errors) {
      errorToast({
        message: response?.errors?.map(err => err.detail)?.join(','),
      });
    } else {
      await revalidate();
    }
  }

  return (
    <Menu>
      <div className={className}>
        <MenuButton className="field-group">
          {!value && <div className="placeholder">Select Project</div>}
          {value && (
            <div className="placeholder">
              {expenseProjects.find(type => type.id === value)?.name}
            </div>
          )}
          <ArrowUpIcon />
        </MenuButton>
      </div>
      <Popover>
        <div className="relative">
          <div className="list selectable-list">
            {expenseProjects.length === 0 && (
              <div className="placeholder">No type found.</div>
            )}
            {expenseProjects.map(({ id, name }) => (
              <ProjectItem
                key={id}
                id={id}
                name={name}
                color="#22c759"
                currentOpenId={currentOpenId === id}
                showProjectOptions={showProjectOptions}
                onSubmit={form => updateExpenseProject(id, form)}
                onSelect={() => onChange(id)}
                onRemove={() => removeProject(id)}
              />
            ))}
          </div>
        </div>
        <hr />

        <div className={`${className} new-type-form`}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={e => handleCreate(e)}
          >
            {({ submitForm }) => (
              <>
                {show ? (
                  <div className="form">
                    <Field
                      id="name"
                      name="name"
                      label="New Type"
                      autocomplete="off"
                    >
                      {({ field }) => (
                        <div>
                          <input
                            type="text"
                            placeholder="Enter Type"
                            style={{
                              width: '150px',
                              height: '36px',
                              border: '1px solid #dce0ea',
                              color: '#3a3a3a',
                              padding: '10px',
                              borderRadius: '8px',
                              fontSize: '13px',
                            }}
                            {...field}
                          />
                        </div>
                      )}
                    </Field>
                    <div className="actions">
                      <IconButton
                        type="submit"
                        className="add-btn"
                        onClick={submitForm}
                      >
                        <SaveIcon color="#22c759" />
                      </IconButton>
                      <IconButton
                        className="cancel-btn"
                        onClick={() => setShow(false)}
                      >
                        <CloseFillIcon size={20} color="#9fa3ae" />
                      </IconButton>
                    </div>
                  </div>
                ) : (
                  <span className="add-tag-btn" onClick={() => setShow(true)}>
                    + &nbsp;Add project
                  </span>
                )}
              </>
            )}
          </Formik>
        </div>
      </Popover>
    </Menu>
  );
})`
  margin: 0;

  border: 0;
  padding: 0;
  width: 100%;

  background: transparent;

  &.new-type-form {
    padding: 0 10px 10px 10px;

    > .form {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > input {
        margin-right: 5px;
      }
    }
  }

  .add-tag-btn {
    color: ${theme('colors.primary')};
    cursor: pointer;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .cancel-btn {
      color: ${theme('colors.primary')};
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }

  > .label {
    margin-bottom: 5px;

    color: #999;
    font-size: 12px;
    line-height: 1.7;
  }

  > .field-group {
    text-align: left;

    position: relative;

    border-radius: 6px;
    border: 1px solid ${ifProp('error', theme('colors.error'), '#cccccc')};
    width: 100%;
    padding: 10px 0px 10px ${ifProp('hasPrefix', '50px', '10px')};

    background: #ffffff;

    > .placeholder {
      color: #999;
    }

    > .tag-list {
      overflow-x: auto;
      margin-bottom: -10px;
      padding-bottom: 10px;

      white-space: nowrap;
    }

    > .tag-list > ${Project} {
      margin-right: 10px;
    }

    > ${ArrowUpIcon} {
      width: 14px;
      height: 14px;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%) rotateZ(180deg);
      color: ${theme('colors.primary')};
    }

    &:focus,
    &:active {
      outline-color: ${theme('colors.primary')};
    }
  }
`;
