const colors = {
  primary: '#22c759',
  error: '#c72235',
  warning: '#e8bf13',
  background: '#f5f8f8',
  black: '#333',
  secondary: '#3a3a3a',
  grey: '#9fa3ae',
};

const breakpoints = {
  mobile: 720,
  tablet: 1023,
};

export const theme = {
  colors,
  sidebarWidth: 290,
  shadow: '0 10px 20px 0 rgba(0, 128, 42, 0.08)',
  breakpoints,
};
