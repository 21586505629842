import React, { useState } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import styled from 'styled-components';
import { useField } from 'formik';
import { ifProp, switchProp, theme } from 'styled-tools';
import { AlertIcon } from '../../../components/Icons';

/**
 *
 * @param {string} [className]
 * @param {boolean} [required]
 * @param {React.FC} [prefixIcon]
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const TextField = React.forwardRef(function TextField(
  {
    className,
    required,
    PrefixIcon,
    SuffixIcon: Suffix,
    hint,
    onFocus,
    onBlur,
    error,
    touched,
    label,
    multiline,
    suffixMsg,
    ...props
  },
  ref
) {
  const [isFocus, setIsFocus] = useState(false);
  const showError = !(hint && isFocus) && touched && error;
  const showHint = isFocus && hint;

  const SuffixIcon = showError ? AlertIcon : Suffix;

  const hasPrefix = !!PrefixIcon;
  const hasSuffix = !!SuffixIcon;
  const type = multiline ? 'textarea' : 'input';

  function handleFocus(e) {
    setIsFocus(true);
    if (onFocus) {
      onFocus(e);
    }
  }

  function handleBlur(e) {
    setIsFocus(false);
    if (onBlur) {
      onBlur(e);
    }
  }

  return (
    <Container className={className} error={showError}>
      {label &&
        (props.oldLabel ? (
          <label htmlFor={props.id} className="label oldLabel">
            {label}
          </label>
        ) : (
          <label
            htmlFor={props.id}
            className={`label${isFocus || props.value ? ' active' : ''}${
              showError ? ' has-error' : ''
            }${isFocus ? ' focus' : ''}`}
          >
            {label}
          </label>
        ))}
      <div className="input-group">
        {PrefixIcon && <PrefixIcon className="prefix" />}
        <Input
          as={type}
          {...props}
          hasPrefix={hasPrefix}
          hasSuffix={hasSuffix}
          hasMultiline={multiline}
          error={showError}
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={ref}
          autoComplete="off"
          isFocus={isFocus}
        />
        {suffixMsg && <div className="message-suffix">{suffixMsg}</div>}
        {showHint && <div className="message hint">{hint}</div>}
        {showError && <div className="message error">{error}</div>}
        {required && <span className="required-indicator">*</span>}
        {SuffixIcon && <SuffixIcon className="suffix" />}
      </div>
    </Container>
  );
});

const Input = styled.input`
  border-radius: 6px;
  border: 1px solid
    ${ifProp(
      'error',
      theme('colors.error'),
      ifProp('isFocus', theme('colors.primary'), '#dce0ea')
    )};
  width: 100%;
  padding: 10px ${ifProp('hasSuffix', '50px', '10px')} 10px
    ${ifProp('hasPrefix', '50px', '10px')};

  background: #ffffff;
  font-size: 14px;
  resize: vertical;

  &::placeholder {
    color: #c1c3ca;
  }

  &:focus,
  &:active {
    outline-color: ${theme('colors.primary')};
    border: 1px solid theme('colors.primary');
  }

  &:focus + .popover {
    display: block;
  }

  &:disabled {
    background: #c1c3ca;
  }

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    padding: 14px ${ifProp('hasSuffix', '50px', '10px')} 14px
      ${ifProp('hasPrefix', '50px', '10px')};
    height: ${ifProp('hasMultiline', 'unset', '40px')};
  }
`;

export const Popover = styled.div`
  z-index: 1000;

  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(calc(100% + 30px));

  color: ${ifProp('error', theme('colors.error'), '#22a7c7')};
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;

  > .hint {
    content: ' ';

    position: relative;
    display: block;

    border-radius: 6px;
    padding: 10px;
    width: 280px;

    background: white;
    box-shadow: 0 0 5px rgba(0, 128, 42, 0.08);
  }

  > .hint > .arrow {
    position: absolute;
    top: calc(50% - 10px);
    left: -10px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid white;
    width: 0;
    height: 0;
  }

  > .hint {
    box-shadow: 0 0 5px rgba(0, 128, 42, 0.2);
  }
`;

const Container = styled.div`
  margin-bottom: 16px;

  &.no-margin {
    margin: 0px;
  }

  > ${Popover} {
    position: absolute;
    top: 50%;
    left: calc(100% + 24px);

    transform: translateY(-50%);
  }

  > .label {
    display: block;
    margin-bottom: 8px;
    padding: 0 2px;
    color: #c1c3ca;
    z-index: 1;
    position: absolute;
    font-size: 1rem;
    top: 10px;
    left: 10px;
    transition: all 0.2s;
    background: white;
    pointer-events: none;

    &.active {
      top: -4px;
      left: 8px;
      font-size: 0.75rem;
    }

    &.focus {
      color: ${theme('colors.primary')};
    }

    &.has-error {
      color: ${theme('colors.error')};
    }

    &.oldLabel {
      position: unset;
      font-size: 12px;
    }
  }

  > .input-group {
    position: relative;

    .message-suffix {
      position: absolute;
      right: 0;
      margin-right: 20px;
      top: 10px;
      font-size: 14px;
      color: #9fa3ae;
    }
  }

  > .input-group > .required-indicator {
    z-index: 1;

    position: absolute;
    top: 16px;
    right: -10px;

    width: 6px;
    height: 6px;

    color: ${theme('colors.error')};
  }

  > .input-group > .prefix {
    position: absolute;
    top: 10px;
    left: 16px;
    color: ${theme('colors.primary')};
  }

  > .input-group > .suffix {
    position: absolute;
    top: 10px;
    right: 16px;
    color: ${ifProp('error', theme('colors.error'), theme('colors.primary'))};
  }

  > .input-group > .message {
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    padding: 10px 0 0 0;
    text-align: left;
    &.hint {
      color: #22a7c7;
    }

    &.error {
      color: ${theme('colors.error')};
    }
  }

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    > .label {
      font-size: 14px;
      top: 14px;

      &.active {
        font-size: 10px;
      }
    }

    > .input-group > .required-indicator {
      top: 18px;
    }

    > .input-group > .message-suffix {
      top: 14px;
    }

    > .input-group > .prefix {
      top: 14px;
    }

    > .input-group > .suffix {
      top: 14px;
    }
  }
`;

const StyledTextField = styled(TextField)`
  font-size: ${switchProp('size', { sm: '12px' }, 'inherit')};
  position: relative;
`;

const FormikTextField = React.forwardRef(function (
  { onBlur, forceError, ...props },
  ref
) {
  const [field, { error, touched }] = useField(props);

  return (
    <StyledTextField
      {...props}
      {...field}
      error={error}
      touched={touched}
      ref={ref}
      onBlur={e => {
        field.onBlur(e);
        if (onBlur) {
          onBlur(e);
        }
      }}
    />
  );
});

hoistNonReactStatics(FormikTextField, StyledTextField);

export { FormikTextField as TextField, StyledTextField as TextFieldView };
