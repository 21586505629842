import React, { useRef } from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';

import { ColorRadio } from './ColorRadio';
import { CirclePlusBorderIcon } from '../Icons';
import { IconButton } from '../../features/authentication/components/Button';

export const colorOptions = [
  '#1ccc56',
  '#e8bf13',
  '#f77719',
  '#e01b32',
  '#d621dd',
  '#7b1ad9',
  '#1d3adb',
  '#187ce0',
  '#1ab8de',
  '#1bd3be',
  '#69d818',
];
export const ColorPalette = styled(function ColorPalette({
  className,
  value,
  onCustomChange,
  hasLabel = true,
  ...props
}) {
  const inputRef = useRef(null);

  return (
    <div className={className}>
      {hasLabel && <span>Color : </span>}
      <div className="radio-color-group">
        {colorOptions.map(color => (
          <ColorRadio
            key={color}
            value={color}
            checked={value === color}
            name="color-picker"
            {...props}
          />
        ))}
        {value && !colorOptions.includes(value) && (
          <ColorRadio
            key={value}
            value={value}
            checked
            name="color-picker"
            {...props}
          />
        )}
        <IconButton
          className="cancel-btn"
          onClick={e => {
            e.preventDefault();
            inputRef.current.click();
          }}
        >
          <CirclePlusBorderIcon size={15} color="#ddd" />
        </IconButton>
        <input
          ref={inputRef}
          type="color"
          name="color-picker"
          onChange={onCustomChange}
        />
      </div>
    </div>
  );
})`
  display: flex;
  align-items: center;
  padding: 12px 0 0;

  input[type='color'] {
    visibility: hidden;
  }

  > span {
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;
  }

  > .radio-color-group {
    display: flex;
    margin: 0 12px;

    > ${IconButton} {
      border: 1px solid;
      border-radius: 30px;
      width: 25px;
      height: 25px;
    }
  }

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    flex-direction: column;

    > .radio-color-group {
      margin: 6px 0;
      flex-wrap: wrap;

      > ${ColorRadio} {
        span {
          width: 18px;
          height: 18px;
          margin-bottom: 4px;

          > img {
            width: 14px;
          }
        }
      }
    }
  }
`;
