import React, { useState } from 'react';
import hoist from 'hoist-non-react-statics';
import { useAuthentication } from '../../useAuthentication';

export function withForgotPasswordPage(Component) {
  function WithForgotPasswordPage(props) {
    const { forgotPassword } = useAuthentication();
    const [isShowModal, setIsShowModal] = useState(false);

    async function onSubmit({ email }) {
      await forgotPassword({ email });
      setIsShowModal(true);
    }

    function onCloseModal() {
      setIsShowModal(false);
    }

    return (
      <Component
        {...props}
        onSubmit={onSubmit}
        isShowModal={isShowModal}
        onCloseModal={onCloseModal}
      />
    );
  }

  hoist(WithForgotPasswordPage, Component);

  WithForgotPasswordPage.displayName = `withForgotPasswordPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithForgotPasswordPage;
}
