import React from 'react';
import { MobileProjectEditor } from '../MobileProjectEditor';
import { DeleteModal } from '../../../../components/SideBarFilter/DeleteModal';

export function MobileProjectsEditPage({
  className,
  initialValues,
  onSubmit,
  companyName,
  onOpenDeleteModal,
  isShowDeleteModal,
  onCloseDeleteModal,
  project,
  onDelete,
}) {
  return (
    <>
      <section className={className}>
        <MobileProjectEditor
          initialValues={initialValues}
          onSubmit={onSubmit}
          companyName={companyName}
          onCancel={onOpenDeleteModal}
          isEdit
        />
      </section>
      <DeleteModal
        isOpen={isShowDeleteModal}
        onClose={onCloseDeleteModal}
        onDelete={onDelete}
        deleteText={`Delete Project "${project?.name}" ?`}
      />
    </>
  );
}
