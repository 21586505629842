import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { theme } from 'styled-tools';
import { Button } from '../../../authentication/components/Button';
import { Breadcrumbs } from '../../../../components/Breadcrumbs';
import { useQueryParams } from '../../../../lib/hooks';
import { LoadingIndicator } from '../../../../components/LoadingIndicator';
import { TextFieldView } from '../../../authentication/components/TextField';
import { DocumentListBoard } from '../../../../components/DocumentListBoard';
import { DeleteModal } from '../../../../components/SideBarFilter/DeleteModal';
import { DocumentModal } from '../DocumentDetailPage/DocumentModal';

function DocumentListPage({
  className,
  documents,
  defaultDocuments,
  onDeleteDocument,
  isOpenDocumentModal,
  isShowDeleteModal,
  onCloseDocumentModal,
  onCloseDeleteModal,
  onOpenDeleteModal,
  selectedDocumentId,
  project,
  loading,
  companyName,
  selectedDocumentType,
}) {
  const navigate = useNavigate();
  const [, setQueryParams] = useQueryParams();

  return (
    <section className={className}>
      <div className="content">
        <div className="header">
          <div className="bar" onClick={() => navigate('./../')}>
            <Breadcrumbs
              className="breadcrumb"
              data={[companyName, project?.name ?? '']}
            />
          </div>
          <span>Documents</span>
        </div>
        {/* <div className="actions">
          <TextFieldView
            type="search"
            className="field"
            name="query"
            placeholder="Search"
            value={query}
            onChange={e => onChangeQuery(e.target.value)}
          />
        </div> */}
        {loading ? (
          <LoadingIndicator
            className={`${className} loading`}
            color="primary"
          />
        ) : (
          <>
            <div className="header-sub">
              <span>Specific Documents</span>
            </div>
            <DocumentListBoard
              className="board"
              color={project?.color}
              documents={defaultDocuments ?? []}
              defaultDocument
              editDefault
              onOpenDeleteModal={onOpenDeleteModal}
            />
            <div className="header-sub">
              <span>Other Documents</span>
              <Button
                className="button"
                type="button"
                onClick={() => setQueryParams({ modal: 'create-document' })}
              >
                New Document
              </Button>
            </div>
            <DocumentListBoard
              className="board"
              color={project?.color}
              documents={documents ?? []}
              onOpenDeleteModal={onOpenDeleteModal}
            />
          </>
        )}
      </div>
      <DocumentModal
        isOpen={isOpenDocumentModal}
        onClose={onCloseDocumentModal}
        documentId={selectedDocumentId}
        projectId={project?.id}
        projectName={project?.name}
        defaultDocument={
          selectedDocumentType && selectedDocumentType !== 'regular'
        }
        documentType={selectedDocumentType}
      />
      <DeleteModal
        isOpen={isShowDeleteModal}
        onClose={onCloseDeleteModal}
        onDelete={onDeleteDocument}
        deleteText="Delete Document ?"
        description="Do you really want to delete these document? This process cannot be undone."
      />
    </section>
  );
}

const StyledDocumentListPage = styled(DocumentListPage)`
  > .content {
    display: flex;
    flex-direction: column;
    width: calc(100vw - 50px);

    > .actions {
      display: flex;
      justify-content: space-between;

      > .field {
        width: 40%;
      }
      > .button {
        height: 40px;
      }
    }

    > .header-sub {
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-size: 20px;
        font-weight: bold;
      }
    }

    > .header {
      display: grid;
      background: white;
      padding: 20px;
      grid-template-columns: 1fr;
      grid-column-gap: 20px;

      > .bar {
        cursor: pointer;
        margin-bottom: 12px;

        .breadcrumb {
          font-size: 12px;
          position: relative;
          left: -8px;
        }
      }

      span {
        font-size: 20px;
        font-weight: bold;
      }

      > h1 {
        grid-area: title;
      }

      > ${TextFieldView} {
        grid-area: search;
        width: 300px;
      }

      > ${Button} {
        grid-area: button;
        height: 42px;
        margin-left: auto;
        margin-top: 20px;
      }
    }

    > .title {
      margin-top: 20px;
    }

    > .grid {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 20px;
      width: 100%;
      margin: 20px auto;
    }

    > .pagination {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .new-project-btn-mobile {
    display: none;
  }

  &.loading {
    height: 100vh;
    margin-top: 20px;
  }

  @media (max-width: ${theme('breakpoints.tablet')}px) {
    > .content {
      > .header {
        grid-row-gap: 10px;
        grid-column-gap: 0;
        display: none;
      }

      > .grid {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    padding-bottom: 160px;
    padding-top: 60px;

    &.no-project {
      padding-bottom: 0;
    }

    > .content {
      padding: 20px;
      width: 100%;

      > .actions {
        > .field {
          width: 50%;
        }
      }

      > .header {
        grid-template-areas:
          'title'
          'button'
          'search';
        grid-template-columns: auto;

        > ${TextFieldView} {
          width: 100%;
        }

        > ${Button} {
          margin-left: unset;
        }
      }

      > .grid {
        grid-template-columns: auto;
      }
    }
    > .new-project-btn-mobile {
      display: flex;
      position: fixed;
      bottom: 60px;
      left: 0;
      width: 100%;
      height: 86px;
      background: white;
      align-items: center;
      justify-content: center;
      padding: 20px 15px;

      > .button {
        width: 100%;
        height: 46px;
      }
    }
  }

  .no-projects {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 60px);
    padding: 0 15px;
    color: #9fa3ae;

    > .label {
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 10px;
    }

    > .description {
      font-size: 0.857rem;
      margin-bottom: 10px;
    }

    > div {
      text-align: center;
    }

    > ${Button} {
      height: 46px;
      font-size: 0.875rem;
    }
  }
`;

export { StyledDocumentListPage as DocumentListPage };
