import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { apiUrl } from '../../../../lib/config';
import { useAppClient } from '../../../../lib/AppProvider';
import { Pagination } from '../../../../components/Pagination';
import { DocumentTitle } from '../../../../components/DocumentTitle';
import {
  Button,
  OutlineButton,
} from '../../../authentication/components/Button';
import { ExportIcon, SearchIcon } from '../../../../components/Icons';
import { useQueryParams } from '../../../../lib/hooks';
import { useAppLayout } from '../../../../components/withAppLayout';
import { LoadingIndicator } from '../../../../components/LoadingIndicator';
import { TextFieldView } from '../../../authentication/components/TextField';
import { SelectField as Select } from '../../../../components/Select/SelectField';
import { DatePickerRange } from '../../../../components/DatePickerRange';
import { ExpenseListBoard } from '../../components/ExpenseListBoard';
import { CompanySidebar } from '../../../../components/CompanySidebar';

export const CompanyExpensesPage = styled(
  ({
    className,
    loading,
    expenseProjects = [],
    expenseTypes = [],
    totalExpense,
    expenses = [],
    company,
    deleteExpense,
    onChangeQuery,
    reloadAllExpenses,
    totalItems,
    currentPage,
    pageSize,
    query,
  }) => {
    const [editExpense, setEditExpense] = useState(false);
    const client = useAppClient();
    const [queryParams, setQueryParams] = useQueryParams();
    const { isShowSidebar: isShowSidebarDesktop } = useAppLayout();
    const [exportPath, setExportPath] = useState('');
    const { startDate, endDate } = queryParams;
    const projectOptions = [
      { label: 'All', value: '' },
      ...expenseProjects.map(project => ({
        label: project.name,
        value: project.id,
      })),
    ];
    const typeOptions = [
      { label: 'All', value: '' },
      ...expenseTypes.map(type => ({ label: type.name, value: type.id })),
    ];
    const teamOptions = [
      { label: 'All', value: '' },
      { label: 'All Team', value: 'all_team' },
      { label: 'Business Development', value: 'business_development' },
      { label: 'Project Management', value: 'project_management' },
      { label: 'Finance', value: 'finance' },
      { label: 'HR', value: 'hr' },
      { label: 'Legal', value: 'legal' },
      { label: 'Marketing', value: 'marketing' },
      { label: 'Development', value: 'development' },
      { label: 'UX/UI Designer', value: 'ux/ui_designer' },
      { label: 'Admin', value: 'admin' },
    ];

    function setFilterParams(params) {
      setQueryParams({ ...params, page: 1 });
    }

    function handleDateRangeChange(dates) {
      const { start, end } = dates;
      if (start !== 'undefined' && end !== 'undefined') {
        setFilterParams({ startDate: start, endDate: end });
        reloadAllExpenses();
      }
    }

    useEffect(() => {
      let path = `${apiUrl}/companies/${company?.id}/expenses/export.csv?param_token=${client.accessToken}`;

      if (query) {
        path += `&query=${query}`;
      }

      if (queryParams?.team) {
        path += `&team=${queryParams?.team}`;
      }

      if (queryParams?.expenseProjectId) {
        path += `&expense_project_id=${queryParams?.expenseProjectId}`;
      }

      if (queryParams?.sortDate) {
        path += `&sort_date=${queryParams?.sortDate}`;
      }

      if (queryParams?.expenseTypeId) {
        path += `&expense_type_id=${queryParams?.expenseTypeId}`;
      }

      if (queryParams?.startDate) {
        path += `&start_date=${queryParams?.startDate}`;
      }

      if (queryParams?.endDate) {
        path += `&end_date=${queryParams?.endDate}`;
      }

      setExportPath(path);
    }, [company, client, query, queryParams]);

    return (
      <section className={className}>
        <DocumentTitle title="Company Expenses" />
        <CompanySidebar className={className} company={company} />
        <div
          className={`content ${
            isShowSidebarDesktop ? 'show-sidebar-desktop' : ''
          }`}
        >
          <div className="header">
            <h2 style={{ fontSize: '20px', color: '#3a3a3a' }}>
              Company Expense
            </h2>
            <div className="actions">
              <OutlineButton
                className="button export"
                as="a"
                href={exportPath}
                target="__blank"
              >
                <ExportIcon />
                Export
              </OutlineButton>
              <Button
                className="button"
                type="button"
                onClick={() => setEditExpense(true)}
              >
                New Expense
              </Button>
            </div>
          </div>

          <div className="filter">
            <DatePickerRange
              className="date-picker"
              label="Date Range"
              valueStart={startDate}
              valueEnd={endDate}
              onChange={handleDateRangeChange}
              dateFormat="dd MMMM yyyy"
              isClearable
            />
            <Select
              className="select-filter"
              options={projectOptions}
              label="Project"
              value={queryParams.expenseProjectId ?? null}
              placeholder="All"
              onChange={e => setFilterParams({ expenseProjectId: e.value })}
              oldLabel
            />
            <Select
              options={typeOptions}
              className="select-filter"
              label="Type"
              value={queryParams.expenseTypeId ?? null}
              placeholder="All"
              onChange={e => setFilterParams({ expenseTypeId: e.value })}
              oldLabel
            />
            <Select
              options={teamOptions}
              className="select-filter"
              label="Job Title"
              value={queryParams.team ?? null}
              placeholder="All"
              onChange={e => setFilterParams({ team: e.value })}
              oldLabel
            />

            <button
              style={{
                width: '100%',
                color: '#f75519',
                border: '1px solid #f75519',
                borderRadius: '6px',
                marginTop: '22px',
                height: '38px',
                backgroundColor: '#FFF',
                fontSize: '14px',
                fontWeight: 'bold',
              }}
              className="clear-filter-button"
              type="button"
              onClick={() =>
                setFilterParams({
                  team: null,
                  expenseProjectId: null,
                  expenseTypeId: null,
                  useCaseId: null,
                  startDate: null,
                  endDate: null,
                })
              }
            >
              Clear Filter
            </button>
          </div>
          <section className="expense-table">
            <div className="query-row">
              <div className="total-cost">
                <span>Total Cost :</span>
                <div className="amount">
                  {Intl.NumberFormat().format(totalExpense)}
                  <span>THB</span>
                </div>
              </div>
              <div className="div-search-icon">
                <SearchIcon className="search-icon" />
                <input
                  className="input-search"
                  value={query}
                  placeholder="Search by Description"
                  onChange={e => onChangeQuery(e.target.value)}
                />
              </div>
            </div>
            {loading ? (
              <LoadingIndicator className="loading" color="primary" />
            ) : (
              <>
                <ExpenseListBoard
                  className="board"
                  color="#22c759"
                  companyId={company?.id}
                  expenses={expenses ?? []}
                  editExpense={editExpense}
                  onSort={sort => setQueryParams({ sortDate: sort })}
                  sortDate={queryParams?.sortDate ?? 'desc'}
                  setEditExpense={setEditExpense}
                  onDeleteExpense={deleteExpense}
                  reloadExpenses={reloadAllExpenses}
                />
              </>
            )}
          </section>
          <div className="pagination">
            <Pagination
              currentPage={currentPage}
              totalItems={totalItems}
              pageSize={pageSize}
              maxPages={5}
            />
          </div>
        </div>
      </section>
    );
  }
)`
  .flex {
    display: flex;
  }

  > .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    flex-grow: 1;
    transition: all 0.2s ease-in-out;
    background-color: #f5f8f8;
    padding-bottom: 30px;

    &.width-100 {
      width: 100vw;
      overflow: auto;
    }

    > .header {
      width: 100%;
      background-color: white;
      padding: 30px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > .actions {
        display: flex;
        gap: 20px;
      }

      > h1 {
        grid-area: title;
      }

      > ${TextFieldView} {
        grid-area: search;
        width: 300px;
      }

      > ${Button} {
        grid-area: button;
        height: 42px;
        margin-left: auto;
        &.export {
          grid-area: export;
        }
      }
    }

    @media (max-width: ${theme('breakpoints.mobile')}px) {
      padding-left: 20px !important;
    }

    &.show-sidebar-desktop {
      padding-left: calc(${theme('sidebarWidth')}px);
    }

    > .expense-table {
      width: calc(100vw - 110px - ${theme('sidebarWidth')}px);
      border-radius: 5px;
      background: #fff;
      margin: 0 20px 20px 20px;
      padding-top: 15px;
      border-radius: 8px;
      box-shadow: rgba(17, 17, 26, 0.01) 0px 0px 16px,
        rgba(17, 17, 26, 0.01) 0px 8px 32px;

      > .query-row {
        padding-bottom: 15px;
        margin: 0 20px;
        display: flex;
        border-bottom: 1px solid #dce0ea;

        > .div-search-icon {
          position: relative;
          padding-left: 1rem;
          width: 30%;
          > .search-icon {
            width: 32px;
            height: 32px;
            margin-right: 5px;
            position: absolute;
            right: 0.938px;
            box-sizing: border-box;
            top: 50%;
            transform: translateY(-50%);
          }
          > .input-search {
            width: 100%;
            height: 2.75rem;
            border-radius: 6px;
            border: 1px solid #dce0ea;
            padding-left: 1rem;
          }
        }

        > .total-cost {
          display: flex;
          width: 70%;
          padding-left: 15px;
          padding-right: 15px;
          padding-top: 12px;
          padding-bottom: 12px;
          height: 45px;
          border-radius: 8px;
          background-color: #f5f8f8;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;

          > .amount {
            font-weight: bold;
            margin-top: 5px;
            color: #22c759;
            font-size: 20px;
            display: flex;
            gap: 10px;
          }

          span {
            color: #c1c3ca;
            font-size: 14px;
            font-weight: normal;
            margin-top: 2px;
          }
        }

        > ${TextFieldView} {
          margin-left: 5px;
          margin-top: 2px;
          width: 40%;
        }
      }
    }

    > .filter {
      width: calc(100vw - 110px - ${theme('sidebarWidth')}px);
      margin: 10px 20px 0 20px;
      padding: 20px 0;
      display: grid;
      grid-template-columns: 4fr 2fr 2fr 2fr 2fr;
      grid-column-gap: 20px;

      > h1 {
        grid-area: title;
      }

      > ${TextFieldView} {
        padding-top: 22px;
        width: 20%;
      }
    }

    > .select-filter {
      width: 179px;
    }

    > .title {
      margin-top: 30px;
    }

    > .grid {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 20px;
      width: 100%;
      margin: 20px auto;
    }

    > .pagination {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .new-project-btn-mobile {
    display: none;
  }

  &.loading {
    height: 100vh;
    margin-top: 20px;
  }

  @media (max-width: ${theme('breakpoints.tablet')}px) {
    > .content {
      > .expense-table {
        width: 100%;
      }
      > .grid {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    padding-bottom: 160px;
    padding-top: 60px;

    > .content {
      width: 100%;
      padding-right: 20px;

      > .expense-table {
        width: 100%;
        overflow: auto;
        margin: 0;

        > .query-row {
          width: max-content;

          > .total-cost {
            width: 480px;
          }

          > .div-search-icon {
            width: 320px;
          }
        }
      }

      > .header {
        position: fixed;
        bottom: 55px;
        left: 0;
        z-index: 5;

        > h2 {
          display: none;
        }

        > .actions {
          width: 100%;

          > .button {
            width: 100%;
            justify-content: center;
            display: flex;
            align-items: center;
            white-space: nowrap;
            padding: 14px 20px;
          }
        }
      }

      > .filter {
        width: 100%;
        margin: 0px;
        padding: 20px 0;
        display: grid;
        grid-template-columns: unset;
        grid-auto-rows: 75px;

        > .date-picker {
          grid-column: span 2;
        }

        > h1 {
          grid-area: title;
        }

        > ${TextFieldView} {
          padding-top: 22px;
          width: 20%;
        }
      }

      > .grid {
        grid-template-columns: auto;
      }
    }
    > .new-project-btn-mobile {
      display: flex;
      position: fixed;
      bottom: 60px;
      left: 0;
      width: 100%;
      height: 86px;
      background: white;
      align-items: center;
      justify-content: center;
      padding: 20px 15px;

      > .button {
        width: 100%;
        height: 46px;
      }
    }
  }
`;
