import React from 'react';
import styled from 'styled-components';
import { prop, theme, ifProp } from 'styled-tools';
import { DateTime } from 'luxon';
import {
  Menu,
  MenuButton,
  MenuItem as ReachMenuItem,
  MenuList as ReachMenuList,
} from '@reach/menu-button';
import '@reach/menu-button/styles.css';
import { useQueryParams } from '../lib/hooks';
import { titleCase } from './TextTransform';
import {
  TrashIcon,
  Pencil,
  PreviewIcon,
  ExclamationIcon,
  EllipseVerticalIcon,
} from './Icons';
import { IconButton } from '../features/authentication/components/Button';
import { Avatar } from './Avatar';

// move this to Badge.js

export const MenuList = styled(ReachMenuList)`
  border: 0;
  min-width: 80px;
  border-radius: 6px;
  padding: 6px 0;
  box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.15);
`;

export const MenuItem = styled(ReachMenuItem).attrs(() => ({
  middle: null,
}))`
  padding: 5px 10px;
  width: 100px;
  margin: ${ifProp('middle', 'margin: 10px 0', '')}
  color: ${theme('colors.secondary')};

  &:hover {
    color: ${theme('colors.secondary')};
    background: #efefef;
  }

  svg {
    margin-right: 5px;
    width: 16px;
    height: 16px;
    color: ${theme('colors.secondary')};
  }
`;

export const Badge = styled.div`
  display: inline-block;
  border-radius: 999px;
  border: 0;
  padding: 2px 8px;
  color: white;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.7;
  background: ${prop('color', theme('colors.primary'))};
`;

const DocumentListItemHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 190px 250px 280px 80px;
  align-items: stretch;
  padding: 20px 0;
  color: #9fa3ae;
  font-size: 12px;

  > .col {
    display: flex;
    align-items: center;
    padding: 0 20px;
  }

  .col.document-name {
    padding: 0;
  }

  > .summary {
    padding-left: 0;

    > ${Badge} {
      margin-right: 10px;
    }
  }
`;

const DocumentListItem = styled(
  React.forwardRef(function DocumentListItem(
    {
      documentType = 'regular',
      defaultDocument = false,
      editable = true,
      document,
      color,
      onOpenDeleteModal,
      ...props
    },
    ref
  ) {
    const [, setQueryParams] = useQueryParams();
    return (
      <div {...props} ref={ref}>
        {document && (
          <div
            className={`col document-name ${
              defaultDocument ? 'default-document-available' : ''
            }`}
          >
            {defaultDocument && (
              <div className="col document-type-title">
                {titleCase(documentType)}
              </div>
            )}
            <div className="wrapper-text">
              {document?.name}
              <div className="file-type">
                {document?.fileUrl?.includes('.pdf') ? 'PDF' : 'IMG'}
              </div>
            </div>
          </div>
        )}
        {!document && (
          <div className="col document-name default-document">
            <ExclamationIcon color="red" />
            {titleCase(documentType)}
          </div>
        )}
        <div className={`col category ${!document ? 'border-none' : ''}`}>
          {document?.category && titleCase(document.category)}
        </div>
        <div className={`col by ${!document ? 'border-none' : ''}`}>
          {document?.creatorFullName}
        </div>
        <div className="col date">
          {document?.updatedAt && (
            <>
              <div className="avatar">
                <Avatar
                  name={document?.lastEditorFullName}
                  initial={`${document?.lastEditorFullName
                    .split(' ')[0]
                    .substr(0, 1)}${document?.lastEditorFullName
                    .split(' ')[1]
                    .substr(0, 1)}`}
                  noTooltip
                />
              </div>
              <div className="wrapper">
                <div className="due-date">
                  {DateTime.fromISO(document.updatedAt).toFormat('d MMM yyyy')}
                </div>
                <div>By {document?.lastEditorFullName ?? '-'}</div>
              </div>
            </>
          )}
        </div>
        <div className="col menu">
          <Menu>
            <MenuButton as={IconButton} className="menu">
              <EllipseVerticalIcon />
            </MenuButton>
            <MenuList>
              {document && (
                <MenuItem onSelect={() => {}}>
                  <a
                    href={document.fileUrl}
                    className="preview-link"
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <PreviewIcon color={color} />
                    View
                  </a>
                </MenuItem>
              )}
              {editable && (
                <MenuItem
                  onSelect={() =>
                    setQueryParams({
                      modal: 'create-document',
                      selectedDocumentId: document?.id ? document.id : null,
                      selectedDocumentType: documentType,
                    })
                  }
                >
                  <Pencil color={color} /> Edit
                </MenuItem>
              )}
              {document && editable && (
                <MenuItem
                  onSelect={() =>
                    onOpenDeleteModal(document?.id, defaultDocument)
                  }
                >
                  <TrashIcon color={color} /> Delete
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        </div>
      </div>
    );
  })
)`
  display: grid;
  grid-template-columns: 1fr 190px 250px 280px 80px;
  align-items: stretch;
  height: 90px;

  border-radius: 6px;
  padding: 20px 0;

  color: #999;
  font-size: 12px;
  font-weight: bold;

  background: white;
  box-shadow: 0 5px 10px 0 rgba(58, 58, 58, 0.05);

  .border-none {
    border-right: 0 !important;
  }

  .preview-link {
    text-decoration: none;
    color: #3a3a3a;
  }

  .wrapper-text {
    display: flex;
    align-items: center;
    font-size: 14px;
    column-gap: 5px;

    .file-type {
      color: #22c759;
      width: 40px;
      height: 20px;
      padding: 5px 10px;
      border-radius: 10px;
      background-color: #dff0e2;
      font-size: 10px;
    }
  }

  > .col {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: normal;
    color: #3a3a3a;
    padding: 0 20px;

    > .menu {
      color: #9fa3ae;

      &[aria-expanded='true'],
      &:active {
        color: ${theme('colors.primary')};
      }

      &:hover {
        background: unset;
      }
    }

    &.default-document-available {
      display: block;
      color: #3a3a3a;
      font-size: 14px;
      font-weight: normal;

      > .document-type-title {
        color: #9fa3ae;
        font-size: 10px;
        font-weight: bold;
        margin-bottom: 12px;
      }
    }

    &.default-document {
      font-size: 14px;
      border-right: 0 !important;
      color: #f75519;
      font-weight: normal;
      column-gap: 10px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    &.date {
      display: block;
      align-items: center;
      display: flex;
      column-gap: 8px;

      .due-date {
        margin-bottom: 5px;
      }
    }

    &:not(:last-child) {
      border-right: 1px solid #dce0ea;
    }
  }

  > .name {
    color: #333;
    font-size: 14px;
    font-weight: normal;
  }

  > .date-point {
    font-weight: bold;

    > .point {
      margin-left: 10px;

      border-left: 1px solid #ddd;
      padding-left: 10px;
    }
  }

  > .sprints {
    > .more {
      flex: 0 0 20px;

      margin-top: -2px;
      margin-left: 5px;

      border-radius: 10px;
      width: 20px;
      height: 20px;

      color: white;
      text-align: center;
      line-height: 20px;
    }
  }

  > .tags {
    > .btn {
      border: 0;
      border-radius: 6px;
      padding: 0;
      width: 24px;
      height: 24px;

      color: white;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      line-height: 24px;

      background: #e6e6e6;
    }
  }

  > .col.assignees {
    justify-content: flex-end;
    border-right: 0;
  }

  > .menu {
    padding: 0 10px;
    justify-content: space-evenly;
    > .preview-link {
      cursor: pointer;
    }
  }
`;

export const DocumentListBoard = React.memo(
  styled(function DocumentListBoard({
    className,
    color,
    onOpenDeleteModal,
    documents = [],
    defaultDocument = false,
    editable = true,
  }) {
    const defaultDocumentName = [
      'master_service_agreement',
      'scope_of_work',
      'requirement_document',
      'test_case',
      'user_manual',
    ];

    return (
      <div className={className}>
        <div className="table-wrapper">
          <div className="table">
            <DocumentListItemHeader>
              <div className="col document-name">Document Name</div>
              <div className="col category">Category</div>
              <div className="col by">Create by</div>
              <div className="col date">Last Modified</div>
              <div className="col menu" />
            </DocumentListItemHeader>

            {!defaultDocument &&
              documents.map(document => (
                <DocumentListItem
                  key={document.id}
                  document={document}
                  color={color}
                  onOpenDeleteModal={onOpenDeleteModal}
                />
              ))}

            {defaultDocument &&
              defaultDocumentName.map(name => (
                <DocumentListItem
                  key={name}
                  documentType={name}
                  document={documents.find(
                    document => document.documentType === name
                  )}
                  color={color}
                  onOpenDeleteModal={onOpenDeleteModal}
                  defaultDocument={defaultDocument}
                  editable={editable}
                  className={`${
                    documents.find(document => document.documentType === name)
                      ? ''
                      : 'content-list'
                  }`}
                />
              ))}
          </div>
        </div>
      </div>
    );
  })`
    > .title {
      font-size: 20px;
    }

    > .table-wrapper {
      width: 100%;
      overflow: auto;

      > .table {
        min-width: 850px;
        padding-top: 0 !important;
        padding: 20px;

        ${DocumentListItem} {
          margin-bottom: 20px;
        }

        .content-list {
          background-color: #f6efec;
        }
      }
    }
  `
);
