import styled from 'styled-components';
import { MenuPopover as Popover, MenuItem as Item } from '@reach/menu-button';
import { ifProp, withProp, prop, theme } from 'styled-tools';

export const MenuPopover = styled(Popover)`
  margin-top: 10px;

  border-radius: 6px;
  padding: 20px;
  width: ${ifProp(
    'width',
    withProp(prop('width'), w => `${w}px`),
    'auto'
  )};

  background: white;
  box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.15);
`;

export const MenuItem = styled(Item)`
  margin: 0 -20px;

  border: 0;
  padding: 5px 10px;

  font-size: 12px;

  &:first-child {
    margin-top: -10px;
  }

  &:last-child {
    margin-bottom: -10px;
  }

  &:hover {
    background: white;
    color: ${theme('colors.primary')};
  }
`;
