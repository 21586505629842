import React from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { theme } from 'styled-tools';
import { Modal } from '../Modal';
import {
  Button,
  OutlineButton,
} from '../../features/authentication/components/Button';
import { TextField } from '../../features/authentication/components/TextField';
import { ColorPalette } from '../ColorPalette';

const validationSchema = yup.object({
  tagName: yup.string().required(),
});

function TagCreateModal({
  className,
  isOpen,
  onClose,
  onSubmit,
  tagMode,
  projectName,
  selectedTag,
}) {
  const editMode = tagMode === 'edit';
  const initialValues = {
    tagName: editMode ? selectedTag?.name : '',
    color: editMode ? selectedTag?.color : '',
  };

  return (
    <Modal className={className} isOpen={isOpen} onClose={onClose}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {() => (
          <Form noValidate>
            <div className="title">
              <h1>{tagMode === 'edit' ? 'Edit Tag' : 'Create New Tag'}</h1>
              <h4>
                Project : <span className="project-name">{projectName}</span>
              </h4>
            </div>
            <div className="content">
              <TextField id="tagName" name="tagName" label="Tag Name" />
            </div>
            <div className="label">Color</div>
            <ColorPalette name="color" hasLabel={false} />
            <footer className="footer">
              <OutlineButton onClick={onClose}>Cancel</OutlineButton>
              <Button type="submit">{editMode ? 'Save' : 'Create'}</Button>
            </footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

const StyledTagCreateModal = styled(TagCreateModal)`
  max-width: 500px;
  width: 90vw;

  .title {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 2px solid #eee;

    > h1 {
      margin-bottom: 12px;
    }

    > h4 {
      > span {
        color: ${theme('colors.primary')};
        font-weight: normal;
      }
    }
  }

  .content {
    margin-bottom: 20px;
  }

  .label {
    font-size: 12px;
    color: #999999;
  }

  .radio-color-group {
    margin: 0;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    ${Button} {
      margin-left: 12px;
    }

    ${OutlineButton}.red-outline {
      border: 1px solid #e01b32;
      color: #e01b32;

      &:hover {
        background-color: #e01b32;
        color: white;
      }
    }
  }
`;

export { StyledTagCreateModal as TagCreateModal };
