import React from 'react';
import styled from 'styled-components';
import { theme, ifProp } from 'styled-tools';
import OutsideClickHandler from 'react-outside-click-handler';
import { ArrowUpIcon, TrashIcon } from '../../../../components/Icons';

function SelectCustom({
  className,
  placeHolder,
  children,
  selectedItem,
  handleSelect,
  handleDelete,
  handleOpen,
  handleClose,
  isShow,
  data,
  label,
}) {
  return (
    <OutsideClickHandler onOutsideClick={handleClose}>
      <div className={className}>
        <p className="label">{label}</p>
        <div
          className={`default-wrapper ${
            !selectedItem || selectedItem.length === 0 ? 'placeholder' : ''
          }`.trim()}
          onClick={handleOpen}
        >
          {Array.isArray(selectedItem)
            ? selectedItem.length
              ? selectedItem.map(item => (
                  <div key={item.id} className="tag-item">
                    {item.name} {dateFormat(item.range)}
                    <span onClick={() => handleDelete(item)}>
                      <TrashIcon size={16} />
                    </span>
                  </div>
                ))
              : placeHolder
            : selectedItem
            ? selectedItem.name
            : placeHolder}
          <ArrowUpIcon />
        </div>
        {isShow ? (
          <div className="options-wrapper">
            <ul className="select-options">
              {data.map(item => (
                <OptionItem
                  key={item.id}
                  className="option-item"
                  selected={
                    Array.isArray(selectedItem)
                      ? selectedItem.find(slItem => item.id === slItem.id)
                      : item.id === selectedItem?.id
                  }
                  onClick={() => handleSelect(item)}
                >
                  {item.name} {item.range ? dateFormat(item.range) : null}
                </OptionItem>
              ))}
            </ul>
            {children}
          </div>
        ) : null}
      </div>
    </OutsideClickHandler>
  );
}

function dateFormat(dateObj) {
  return `(${dateObj.start.c.day}/${dateObj.start.c.month}/${dateObj.start.c.year} - ${dateObj.end.c.day}/${dateObj.end.c.month}/${dateObj.end.c.year})`;
}

const OptionItem = styled.li`
  padding-bottom: 10px;
  color: ${ifProp('selected', theme('colors.primary'), '#333')};

  &:hover {
    cursor: pointer;
    color: ${theme('colors.primary')};
  }
`;

const StyledSelectCustom = styled(SelectCustom)`
  position: relative;

  .label {
    margin-bottom: 5px;

    color: #9fa3ae;
    font-size: 12px;
    line-height: 1.7;
  }

  .default-wrapper {
    border-radius: 6px;
    border: 1px solid ${ifProp('error', theme('colors.error'), '#c1c3ca')};
    width: 100%;
    padding: 10px 60px 10px ${ifProp('hasPrefix', '50px', '10px')};

    background: #ffffff;

    position: relative;
    margin-bottom: 20px;
    user-select: none;
    display: flex;
    flex-wrap: wrap;

    &.placeholder {
      color: #9fa3ae;
    }

    &:hover {
      cursor: pointer;
    }

    > .tag-item {
      width: fit-content;
      height: 90%;
      padding: 5px 10px;
      background-color: #333;
      border-radius: 6px;
      color: white;

      &:hover {
        color: #999;
      }

      > svg {
        margin-left: 12px;
      }
    }
    > .tag-item + .tag-item {
      margin-top: 6px;
    }

    > ${ArrowUpIcon} {
      width: 10px;
      height: 10px;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%) rotateZ(180deg);
      color: ${theme('colors.secondary')};
    }
  }

  .options-wrapper {
    background: white;
    box-shadow: 0 10px 20px 0 rgba(0, 128, 42, 0.08);
    border-radius: 6px;
    padding: 20px;
    position: absolute;
    z-index: 2;
    margin-top: -20px;
    left: 0px;
    width: 100%;

    .select-options {
      margin: 0 0 10px;
      padding: 0;
      list-style: none;
      border-bottom: 1px solid #eee;
      max-height: 150px;
      overflow-y: auto;
    }
  }
`;

export { StyledSelectCustom as SelectCustom };
