import React from 'react';
import { useSearchParams } from 'react-router-dom';
import hoist from 'hoist-non-react-statics';
import { useCurrentCompany } from '../../../projects/useCurrentCompany';
import { useQueryParams } from '../../../../lib/hooks';
import { successToast } from '../../../../lib/toast';
import {
  useSearchExpenses,
  useSearchExpenseTypes,
  useSearchExpenseProjects,
} from '../../useCompanyExpense';

export function withCompanyExpensesPage(Component) {
  function WithCompanyExpensesPage(props) {
    const [searchParams] = useSearchParams();
    const [queryParams] = useQueryParams();
    const currentPage = searchParams.get('page') || 1;
    const query = searchParams.get('query') ?? '';
    const limit = 20;
    const { data: company } = useCurrentCompany();

    const {
      totalExpense,
      expenses,
      query: q,
      onChangeQuery,
      pagination,
      loading,
      revalidate: reloadAllExpenses,
      destroy,
    } = useSearchExpenses(company.id, {
      query,
      limit,
      page: currentPage,
      endDate: queryParams.endDate ?? '',
      startDate: queryParams.startDate ?? '',
      expenseTypeId: queryParams.expenseTypeId ?? '',
      expenseProjectId: queryParams.expenseProjectId ?? '',
      sortDate: queryParams.sortDate ?? '',
      team: queryParams.team ?? '',
    });
    const { expenseTypes } = useSearchExpenseTypes(company.id);
    const { expenseProjects } = useSearchExpenseProjects(company.id);

    const pageProps = {
      expenseTypes,
      expenseProjects,
      expenses,
      totalExpense,
      loading,
      reloadAllExpenses,
      totalItems: pagination?.total,
      currentPage,
      pageSize: limit,
      query: q,
      company,
      onChangeQuery,
      async deleteExpense(expenseId) {
        await destroy(expenseId);
        successToast({ message: 'The expense has been deleted.' });
      },
    };

    return <Component {...props} {...pageProps} />;
  }

  hoist(WithCompanyExpensesPage, Component);

  WithCompanyExpensesPage.displayName = `withCompanyExpensesPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithCompanyExpensesPage;
}
