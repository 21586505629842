import React from 'react';
import { useSearchParams } from 'react-router-dom';
import hoist from 'hoist-non-react-statics';
import { useCurrentCompany } from '../../../projects/useCurrentCompany';
import { successToast } from '../../../../lib/toast';
import { useSearchSalaries } from '../../useCompanySalary';

export function withCompanySalariesPage(Component) {
  function WithCompanySalariesPage(props) {
    const [searchParams] = useSearchParams();
    const query = searchParams.get('query') ?? '';
    const { data: company } = useCurrentCompany();

    const {
      salaries,
      query: q,
      onChangeQuery,
      loading,
      revalidate: reloadAllSalaries,
      destroy,
    } = useSearchSalaries(company.id, {
      query,
    });

    const pageProps = {
      loading,
      salaries,
      reloadAllSalaries,
      query: q,
      company,
      onChangeQuery,
      async deleteSalary(salaryId) {
        await destroy(salaryId);
        successToast({ message: 'The employee salary has been deleted.' });
      },
    };

    return <Component {...props} {...pageProps} />;
  }

  hoist(WithCompanySalariesPage, Component);

  WithCompanySalariesPage.displayName = `withCompanySalariesPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithCompanySalariesPage;
}
