import React from 'react';
import styled from 'styled-components';

import { AuthenticationLayout } from '../../../authentication/components/AuthenticationLayout';
import { Button } from '../../../authentication/components/Button';
import NoCompaniesImage from '../../../../assets/images/no-companies.png';
import { CompanySettingModal } from '../../../projects/components/CompanySettingModal';

export const NoCompaniesPage = styled(
  ({
    className,
    isSettingCompanyModal,
    onCloseSettingProjectModal,
    setQueryParams,
  }) => {
    return (
      <AuthenticationLayout className={className}>
        <div className="wrapper-content">
          <img
            alt="cover-no-companies"
            src={NoCompaniesImage}
            className="no-company-image"
          />
          <div className="title">No company</div>
          <div className="description">
            You can also create a company by pressing a button. Build a company
            below.
          </div>
          <Button
            className="btn-create"
            onClick={() => setQueryParams({ modal: 'create-company' })}
          >
            Create new company
          </Button>
        </div>
        {isSettingCompanyModal && (
          <CompanySettingModal
            isOpen={isSettingCompanyModal}
            onClose={onCloseSettingProjectModal}
          />
        )}
      </AuthenticationLayout>
    );
  }
)`
  display: flex;
  align-items: center;
  justify-content: center;

  > .wrapper-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 360px;

    > .no-company-image {
      width: 200px;
    }

    > .title {
      font-weight: bold;
      font-size: 1.875rem;
      color: #3a3a3a;
      margin-bottom: 20px;
      text-align: center;
    }

    > .description {
      font-size: 0.75rem;
      color: #3a3a3a;
      text-align: center;
      margin-bottom: 30px;
    }

    > .field {
      width: 100%;
    }

    > .btn-create {
      width: 100%;
    }
  }
`;
