import React, { useEffect } from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { useSearchParams } from 'react-router-dom';
import { useSortBy, useTable } from 'react-table';

import { Pagination } from '../../../../components/Pagination';
import { Select } from '../../../../components/Select/Select';
import { Avatar } from '../../../../components/Avatar';
import { TrashIcon } from '../../../../components/Icons';
import { roleOptions } from '../../../../lib/config';
import { LoadingIndicator } from '../../../../components/LoadingIndicator';
import { usePermissionGuard } from '../../../authentication/hocs/withPermission';

const columns = [
  {
    Header: '',
    accessor: 'avatar',
  },
  {
    Header: 'Full Name',
    accessor: 'name',
  },
  {
    Header: 'Nickname',
    accessor: 'nickName',
  },
  // {
  //   Header: 'Job Title',
  //   accessor: 'jobTitle',
  // },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Role',
    accessor: 'role',
  },
  {
    Header: '',
    accessor: 'action',
  },
];

const mapSortBy = {
  name: 'full_name',
  nickName: 'nick_name',
  email: 'email',
  role: 'role',
};

const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0 20px;
  width: 100%;

  thead {
    th {
      color: #999;
      font-size: 12px;
      text-align: left;
    }
  }

  th:first-child,
  td:first-child {
    width: 76px;
  }

  th {
    padding: 16px 8px 4px;
    min-width: 50px;
    white-space: nowrap;
  }

  td {
    padding: 16px 8px;
    min-width: 50px;
    white-space: nowrap;
  }

  th:nth-last-child(2),
  td:nth-last-child(2) {
    min-width: 110px;
  }

  tbody {
    tr {
      > .empty {
        text-align: center;
      }
      > td {
        background: white;

        &:first-child {
          border-radius: 6px 0 0 6px;
          padding-left: 16px;
        }

        &:last-child {
          border-radius: 0 6px 6px 0;
          padding-right: 16px;
        }

        ${Select} {
          .input-group > .field {
            padding: 5px 0;
          }
        }

        .action {
          text-align: right;

          > button {
            margin-right: 4px;
          }
        }
      }
    }
  }
`;

export const MemberTable = styled(function MemberTable({
  members = [],
  totalItems,
  className,
  onRemove,
  onChangeRole,
  loading,
  onSortBy,
}) {
  const [searchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page'), 10) || 1;
  const allow = usePermissionGuard('member:write');
  const canUpdateSuperAdmin = usePermissionGuard('member-super-admin:write');
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      data:
        members.map(member => ({
          id: member.id,
          avatar: (
            <Avatar
              size="lg"
              name={member.name}
              initial={member.initial}
              avatarUrl={member.avatarUrl}
              noTooltip
            />
          ),
          name: member.name,
          nickName: member.nickName,
          // jobTitle: member.jobTitle,
          email: member.email,
          role: (
            <div className="role">
              <Select
                placeholder="Select Role"
                options={
                  member.roleId === 'super_admin' || canUpdateSuperAdmin
                    ? roleOptions
                    : roleOptions.filter(role => role.value !== 'super_admin')
                }
                value={member.roleId}
                onChange={e => onChangeRole(member.id, e.value)}
                disabled={
                  !allow ||
                  (!canUpdateSuperAdmin && member.roleId === 'super_admin')
                }
              />
            </div>
          ),
          action: (
            <div className="action">
              {allow && (
                <button
                  type="button"
                  className="remove"
                  onClick={() => onRemove(member.id)}
                >
                  <TrashIcon />
                </button>
              )}
            </div>
          ),
        })) ?? [],
      columns,
      manualSortBy: true,
    },
    useSortBy
  );

  useEffect(() => {
    const { id = null, desc = null } = sortBy[0] ?? {};
    onSortBy(mapSortBy[id] ?? '', desc ? 'desc' : 'asc');
  }, [onSortBy, sortBy]);

  return (
    <div className={className}>
      {loading && <LoadingIndicator />}
      <div className="table-wrapper">
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {!loading && members.length === 0 && (
              <tr>
                <td className="empty" colSpan={7}>
                  <h3>No Data</h3>
                </td>
              </tr>
            )}
            {rows.map(row => {
              prepareRow(row);

              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <div className="pagination">
        <Pagination
          pageSize={10}
          totalItems={totalItems}
          maxPages={5}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
})`
  position: relative;
  width: 100%;
  > .table-wrapper {
    @media (max-width: ${theme('breakpoints.mobile')}px) {
      overflow-x: auto;
      width: calc(100vw - 60px);
      margin: auto;

      ${Table} {
        min-width: 700px;
      }
    }
  }

  > ${LoadingIndicator} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .pagination {
    display: flex;
    justify-content: center;
  }

  .action > .remove {
    border-radius: 6px;
    border: 0;
    padding: 8px;

    color: #777;

    background: #ddd;
  }
`;
