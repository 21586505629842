import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import hoist from 'hoist-non-react-statics';
import { useAppClient } from '../../../../lib/AppProvider';

export function withAcceptInvitationPage(Component) {
  function WithAcceptInvitationPage(props) {
    const [error, setError] = useState(null);

    const client = useAppClient();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    const token = searchParams.get('t');
    const status = searchParams.get('status');
    const companyId = searchParams.get('company_id');
    const url = searchParams.get('redirectTo');

    useEffect(() => {
      if (status !== 'active') {
        return;
      }

      client
        .acceptInvitation({ companyId, token, id })
        .then(() => {
          navigate(
            url ??
              `/auth/sign-in?type=auto&redirectTo=/company/${companyId}&success=You have accepted the invitation. Please Log in to continue.`,
            {
              replace: !!url,
            }
          );
        })
        .catch(() => {
          navigate(url ?? '/auth/sign-in?error=Something went wrong.', {
            replace: !!url,
          });
        });
    }, [url, status, navigate, companyId, client, token, id]);

    async function onSubmit({ password, firstName, lastName }) {
      try {
        await client.setupAccount({
          id,
          token,
          firstName,
          lastName,
          password,
        });
        await client.acceptInvitation({ companyId, token, id });

        navigate(
          url ??
            '/auth/sign-in?success=Welcome to Toolings. Please Log in to continue.',
          {
            replace: !!url,
          }
        );
      } catch (err) {
        if (!err.isAxiosError) {
          throw err;
        }

        setError(err.response?.data?.errors[0]?.detail);
      }
    }

    return <Component {...props} error={error} onSubmit={onSubmit} />;
  }

  hoist(WithAcceptInvitationPage, Component);

  WithAcceptInvitationPage.displayName = `withAcceptInvitationPage(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithAcceptInvitationPage;
}
