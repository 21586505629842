import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { FieldArray, Formik } from 'formik';
import * as yup from 'yup';
import { DateTime } from 'luxon';
import { useQuery } from 'react-query';
import { theme } from 'styled-tools';
import * as clipboard from 'clipboard-polyfill/text';
import { Modal } from 'components/Modal';
import { EditableText } from 'features/settings/pages/SettingCompanyPage/EditableText';
import { TextField } from 'features/authentication/components/TextField';
import {
  Button,
  OutlineButton,
} from 'features/authentication/components/Button';
import { SelectField as Select } from 'components/Select';
import { DatePicker } from 'components/DatePicker';
import {
  transformTicket,
  useTicketAction,
  useProjectColumnOptions,
} from 'features/projects/useTicketSearch';
import {
  TrashIcon,
  PlusIcon,
  LinkHorizontalIcon,
  DuplicateIcon,
  CloseFillIcon,
} from 'components/Icons';
import { useQueryParams } from 'lib/hooks';
import {
  formatDate,
  compareTwoArrayOfObjects,
  formatDateTime,
} from 'lib/utils';
import { AvatarList } from 'components/AvatarList';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { DatePickerTime } from 'components/DatePickerTime';
import { successToast } from 'lib/toast';
import { useAppClient } from 'lib/AppProvider';
import { FileAllList } from './FileAllList';
import { MemberSelect, Subtask, SubtaskEditor } from './Subtask';
import { EpicSelect } from '../../components/EpicSelect';
import { SprintSelect } from '../../components/SprintSelect';
import { ActivityLogs } from '../../components/ActivityLogs';
import { Tooltip } from '../../../../components/Tooltip';
import { TagSelect } from './TagSelect';
import { DropFileArea } from './DropFileArea';
import { ConfirmModal } from './ConfirmModal';
import { RepeatTicket } from '../../components/RepeatTicket';
import { DeleteTicketModal } from './DeleteTicketModal';
import { SelectAction } from '../../components/SelectAction';

const validationSchema = yup.object({
  name: yup.string().required(),
  status: yup.number().required(),
  pointMinute: yup
    .number()
    .required()
    .min(0, 'Minimum atleast 0')
    .max(59, 'Allowed maximum is 59'),
  startAt: yup.string().when('recurrence', {
    is: value => value !== 'not_repeat',
    then: schema => schema.required(),
  }),
  endAt: yup.string().when('recurrence', {
    is: value => value !== 'not_repeat',
    then: schema => schema.required(),
  }),
});

export const TicketModal = React.memo(
  styled(function TicketModal({
    className,
    isOpen,
    onClose,
    projectId,
    projectName,
    ticketId,
    idEachGroup,
    onDuplicate,
    onArchive,
    onRefetch,
  }) {
    const formRef = useRef();
    const client = useAppClient();
    const [queryParams] = useQueryParams();
    const isEdit = typeof ticketId !== 'undefined';
    const showCopy = isEdit;
    const showDuplicate = isEdit;
    const showDelete = isEdit;
    const showActivityLogs = isEdit;
    const [dragging, setDragging] = useState(false);
    const { data: ticket } = useQuery(
      ['ticket', ticketId, isOpen],
      () => client.getTicket(ticketId).then(transformTicket),
      {
        enabled: !!ticketId,
        refetchOnWindowFocus: false,
      }
    );

    const [isAddingSubtask, setIsAddingSubtask] = useState(false);
    const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
    const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
    const [form, setForm] = useState(null);
    const projectColumnOptions = useProjectColumnOptions({
      projectId,
    });

    const { create, update } = useTicketAction();

    const initialValues = useMemo(
      () => ({
        name: ticket?.name ?? '',
        description: ticket?.description ?? '',
        status: ticket?.status ?? queryParams.selectedColumnId,
        dueDate: queryParams.defaultDate
          ? DateTime.fromISO(queryParams.defaultDate)
          : null ?? ticket?.dueDate ?? null,
        point: ticket?.point ?? 0,
        pointMinute: ticket?.pointMinute ?? 0,
        projectId,
        epicId: ticket?.epic?.id,
        sprintIds: ticket?.sprints?.map(sprint => sprint.id) ?? [],
        images:
          ticket?.images?.map(({ id, url }) => ({
            id,
            url,
            hash: url,
          })) ?? [],
        assignees: ticket?.assignees ?? [],
        subtasks:
          ticket?.subtasks?.map(subtask => ({
            id: subtask.id,
            title: subtask.title,
            estimation: subtask.estimation,
            estimationTimeMinute: subtask.estimationTimeMinute,
            assignees: subtask.assignees,
            isDone: subtask.isDone,
          })) ?? [],
        tags: ticket?.tags ?? [],
        startDate: ticket?.startDate
          ? DateTime.fromISO(
              new Date(formatDate(new Date(ticket?.startDate))).toISOString()
            )
          : null,
        endDate: ticket?.endDate
          ? DateTime.fromISO(
              new Date(formatDate(new Date(ticket?.endDate))).toISOString()
            )
          : null,
        recurrence: ticket?.recurrence ?? 'not_repeat',
        recurrenceId: ticket?.recurrenceId ?? null,
        startAt: ticket?.startAt
          ? DateTime.fromISO(
              new Date(formatDate(new Date(ticket?.startAt))).toISOString()
            )
          : undefined,
        endAt: ticket?.endAt
          ? DateTime.fromISO(
              new Date(formatDate(new Date(ticket?.endAt))).toISOString()
            )
          : undefined,
      }),
      [ticket, projectId, queryParams.selectedColumnId, queryParams.defaultDate]
    );

    async function updateTicket(recurrenceType, isUpdatedRecurrence, data) {
      const obj = data || form;

      const isUpdatedImage = compareTwoArrayOfObjects(
        obj.images,
        initialValues.images
      );

      const isUpdatedSubtask = compareTwoArrayOfObjects(
        obj.subtasks,
        initialValues.subtasks
      );

      const isUpdatedRecurrenceStatus =
        initialValues?.recurrence !== obj?.recurrence;

      const isUpdateRecurrenceStartAt =
        new Date(initialValues?.startAt).getTime() !==
        new Date(obj?.startAt).getTime();

      const isUpdateRecurrenceEndAt =
        new Date(initialValues?.endAt).getTime() !==
        new Date(obj?.endAt).getTime();

      const isUpdatedName = initialValues.name !== obj.name;
      const isUpdatedStatus = initialValues.status !== obj.status;
      const isUpdatedDescription =
        initialValues.description !== obj.description;
      const isUpdatedPoint = initialValues.point !== obj.point;
      const isUpdatedPointMinute =
        initialValues.pointMinute !== obj.pointMinute;
      const isUpdatedEpicId = initialValues.epicId !== obj.epicId;
      const isUpdatedStartDate =
        new Date(initialValues?.startDate).getTime() !==
        new Date(obj?.startDate).getTime();
      const isUpdatedEndDate =
        new Date(initialValues?.endDate).getTime() !==
        new Date(obj?.endDate).getTime();
      const isUpdatedSprintIds =
        JSON.stringify(initialValues.sprintIds) !==
        JSON.stringify(obj.sprintIds);
      const isUpdatedTagIds =
        JSON.stringify(initialValues.tags) !== JSON.stringify(obj.tags);
      const isUpdatedAssigneeIds =
        JSON.stringify(initialValues.assignees) !==
        JSON.stringify(obj.assignees);

      await update(ticketId, {
        ...obj,
        isUpdateRecurrenceStartAt,
        isUpdateRecurrenceEndAt,
        isUpdatedName,
        isUpdatedStatus,
        isUpdatedDescription,
        isUpdatedPoint,
        isUpdatedPointMinute,
        isUpdatedEpicId,
        isUpdatedStartDate,
        isUpdatedEndDate,
        isUpdatedSprintIds,
        isUpdatedTagIds,
        isUpdatedAssigneeIds,
        isUpdatedRecurrenceStatus,
        isUpdatedRecurrence,
        isUpdatedImage: !isUpdatedImage,
        isUpdatedSubtask: !isUpdatedSubtask,
        recurrenceType,
        order: ticket?.status === obj?.status ? ticket.order : 1,
      });
      successToast({ message: 'Ticket has been updated.' });
      onClose();
      onRefetch();
    }

    async function handleSubmit(params) {
      const { recurrenceId } = params;

      if (!ticketId) {
        await create(params);
        successToast({ message: 'Ticket has been created.' });
        onClose();
        onRefetch();
        return;
      }

      if (recurrenceId) {
        onOpenConfirmModal();
        setForm(params);
      } else {
        await updateTicket('this', false, params);
      }
    }

    function copyLink() {
      clipboard.writeText(window.location.href);
      successToast({
        message: 'The ticket link has been successfully copied.',
      });
    }

    function onDragEnter() {
      if (!dragging) {
        setDragging(true);
      }
    }

    function onDragLeave() {
      if (dragging) {
        setDragging(false);
      }
    }

    function onOpenDeleteModal() {
      setIsShowDeleteModal(true);
    }

    function onCloseDeleteModal() {
      setIsShowDeleteModal(false);
    }

    function onDeleteTicket(deleteType) {
      setIsShowDeleteModal(false);
      onArchive(ticketId, deleteType, projectId);
      onRefetch();
    }

    function onOpenConfirmModal() {
      setIsShowConfirmModal(true);
    }

    function onCloseConfirmModal() {
      setIsShowConfirmModal(false);
    }

    function onConfirmUpdateTicket(confirm, isUpdatedRecurrence) {
      setIsShowConfirmModal(false);
      updateTicket(confirm, isUpdatedRecurrence);
    }

    // prevent drop file open new tab browser
    useEffect(() => {
      window.addEventListener(
        'dragover',
        e => {
          e.preventDefault();
        },
        false
      );
      window.addEventListener(
        'drop',
        e => {
          e.preventDefault();
        },
        false
      );

      return () => {
        window.removeEventListener('dragover', null);
        window.removeEventListener('drop', null);
      };
    }, []);

    return (
      <>
        {(!isEdit || ticket) && (
          <Modal
            className="ticket-modal"
            isOpen={isOpen}
            onClose={onClose}
            width="910px"
            padding="30px"
            hideCloseButton
          >
            <div className={className} onDragEnter={onDragEnter}>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                innerRef={formRef}
                onSubmit={handleSubmit}
              >
                {({ values, isSubmitting, setFieldValue, submitForm }) => {
                  const FlagFields = () => (
                    <>
                      <EpicSelect
                        projectId={values.projectId}
                        value={values.epicId}
                        onChange={epicId =>
                          setFieldValue(
                            'epicId',
                            epicId === values.epicId ? '' : epicId
                          )
                        }
                      />
                      <SprintSelect
                        projectId={values.projectId}
                        value={values.sprintIds}
                        onChange={sprintIds =>
                          setFieldValue('sprintIds', sprintIds)
                        }
                      />
                      <TagSelect
                        projectId={values.projectId}
                        value={values.tags}
                        onChange={tags => setFieldValue('tags', tags)}
                      />
                    </>
                  );
                  return (
                    <div className="form">
                      <div className="header is-mobile">
                        <div className="detail">
                          <div className="name-wrapper">
                            {idEachGroup ? (
                              <strong className="ticket-number">
                                #{idEachGroup}
                              </strong>
                            ) : null}
                            <EditableText
                              className="name"
                              name="name"
                              placeholder="Enter Ticket Name"
                            />
                          </div>
                          <div className="project-name">
                            Create ticket in : <span>{projectName}</span>
                          </div>
                        </div>
                        <div>
                          <div className="actions is-hidden-mobile">
                            <OutlineButton
                              className="button cancel"
                              type="button"
                              onClick={onClose}
                            >
                              Cancel
                            </OutlineButton>
                            <Button
                              type="submit"
                              onClick={submitForm}
                              disabled={isSubmitting}
                            >
                              {isSubmitting ? (
                                <LoadingIndicator
                                  className="loading"
                                  size="sm"
                                />
                              ) : (
                                'Save'
                              )}
                            </Button>
                          </div>
                          <div className="wrapper-ticket-action is-hidden-mobile">
                            {showCopy && (
                              <Tooltip text="Copy link">
                                <div className="btn-action" onClick={copyLink}>
                                  <LinkHorizontalIcon size={20} />
                                </div>
                              </Tooltip>
                            )}
                            {showDuplicate && (
                              <Tooltip text="Duplicate">
                                <div
                                  className="btn-action"
                                  onClick={() =>
                                    onDuplicate(ticketId, projectId)
                                  }
                                >
                                  <DuplicateIcon size={20} />
                                </div>
                              </Tooltip>
                            )}
                            {showDelete && (
                              <Tooltip text="Delete">
                                <div
                                  className="btn-action"
                                  onClick={onOpenDeleteModal}
                                >
                                  <TrashIcon size={20} />
                                </div>
                              </Tooltip>
                            )}
                          </div>
                        </div>
                        <div className="action-group">
                          <div className="is-hidden-desktop" onClick={onClose}>
                            <CloseFillIcon size={24} />
                          </div>
                          {isEdit && (
                            <div className="is-hidden-desktop">
                              <SelectAction
                                onDuplicate={onDuplicate}
                                onDelete={onOpenDeleteModal}
                                onCopyLink={copyLink}
                                projectId={projectId}
                                ticketId={ticketId}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="sprint-group is-mobile ">
                        <div className="select-group flag-fields is-hidden-desktop">
                          <FlagFields />
                        </div>
                      </div>
                      <div className="section member-list is-mobile">
                        <h4 className="title">Members</h4>
                        <div className="member-item">
                          {(values.subtasks ?? []).length > 0 &&
                          !(values.subtasks ?? []).every(
                            subtask => subtask.removed
                          ) &&
                          (values.subtasks ?? []).some(
                            subtask => (subtask.assignees ?? []).length > 0
                          ) ? (
                            <>
                              <AvatarList
                                className="assignees"
                                data={values.assignees}
                                compact
                                hidePlaceholder
                              />
                            </>
                          ) : (
                            <MemberSelect
                              projectId={projectId}
                              value={values.assignees}
                              compact
                              onChange={assignees =>
                                setFieldValue('assignees', assignees)
                              }
                            />
                          )}
                        </div>
                      </div>
                      <div className="detail">
                        <div className="group">
                          <div className="section row">
                            <Select
                              noShadow
                              name="status"
                              options={projectColumnOptions}
                              label="Card status"
                              placeholder="Status"
                              size="sm"
                            />
                            <TextField
                              size="sm"
                              type="number"
                              name="point"
                              label="Estimate Time (Hour)"
                              min="0"
                              onBlur={e =>
                                setFieldValue(
                                  'point',
                                  Math.max(0, e.target.value)
                                )
                              }
                              disabled={
                                values.subtasks.length > 0 &&
                                values.subtasks.some(
                                  st =>
                                    !st.removed &&
                                    (st.estimation + st.estimationTimeMinute >
                                      0 ||
                                      st.assignees.length > 0)
                                )
                              }
                              oldLabel
                            />
                            <TextField
                              size="sm"
                              type="number"
                              name="pointMinute"
                              label="Estimate Time (Minutes)"
                              min="0"
                              max="59"
                              onBlur={e =>
                                setFieldValue(
                                  'pointMinute',
                                  Math.max(0, e.target.value)
                                )
                              }
                              disabled={
                                values.subtasks.length > 0 &&
                                values.subtasks.some(
                                  st =>
                                    !st.removed &&
                                    (st.estimation + st.estimationTimeMinute >
                                      0 ||
                                      st.assignees.length > 0)
                                )
                              }
                              oldLabel
                            />
                          </div>
                          <div className="section row-date">
                            <div>
                              <DatePicker
                                label="Due Date"
                                value={values.dueDate}
                                onChange={dueDate => {
                                  setFieldValue('dueDate', dueDate);
                                }}
                                size="sm"
                                isClearable
                                hiddenArrowIcon
                                showDateIcon
                                name="dueDate"
                                disabled={
                                  (!isEdit &&
                                    values.recurrence !== 'not_repeat') ||
                                  (isEdit &&
                                    values.recurrence !== 'not_repeat' &&
                                    (initialValues.recurrence !==
                                      values.recurrence ||
                                      !values.startAt ||
                                      formatDateTime(initialValues.startAt) !==
                                        formatDateTime(values.startAt) ||
                                      !values.endAt ||
                                      formatDateTime(initialValues.endAt) !==
                                        formatDateTime(values.endAt)))
                                }
                              />
                            </div>
                            <div>
                              <DatePickerTime
                                label="Start time"
                                value={values.startDate}
                                onChange={startDate => {
                                  setFieldValue('startDate', startDate);
                                  if (
                                    (!values.endDate && startDate) ||
                                    startDate > values.endDate
                                  ) {
                                    setFieldValue(
                                      'endDate',
                                      startDate.plus({ hours: 1 })
                                    );
                                  }
                                  if (!startDate) {
                                    setFieldValue('endDate', undefined);
                                  }
                                }}
                                size="sm"
                                isClearable
                              />
                            </div>
                            <div>
                              <DatePickerTime
                                label="End time"
                                value={values.endDate}
                                onChange={endDate => {
                                  setFieldValue('endDate', endDate);

                                  if (
                                    (!values.startDate && endDate) ||
                                    endDate < values.startDate
                                  ) {
                                    setFieldValue(
                                      'startDate',
                                      endDate.minus({ hours: 1 })
                                    );
                                  }
                                  if (!endDate) {
                                    setFieldValue('startDate', undefined);
                                  }
                                }}
                                size="sm"
                                isClearable
                              />
                            </div>
                          </div>
                          <div className="row-date">
                            <RepeatTicket
                              className="repeat-group"
                              initialValues={initialValues}
                              isEdit={isEdit}
                              values={values}
                              setFieldValue={setFieldValue}
                            />
                            {values.recurrence !== 'not_repeat' && (
                              <>
                                <DatePicker
                                  label="Start Date"
                                  value={values.startAt}
                                  onChange={dueDate => {
                                    setFieldValue('startAt', dueDate);
                                  }}
                                  size="sm"
                                  isClearable
                                  hiddenArrowIcon
                                  showDateIcon
                                  name="startAt"
                                  maxDate={
                                    values?.endAt
                                      ? new Date(
                                          values.endAt.minus({
                                            days: 1,
                                          })
                                        )
                                      : undefined
                                  }
                                  disabled={
                                    isEdit &&
                                    initialValues.recurrence !== 'not_repeat' &&
                                    values?.recurrence !== 'not_repeat' &&
                                    (!values.dueDate ||
                                      formatDateTime(initialValues?.dueDate) !==
                                        formatDateTime(values?.dueDate))
                                  }
                                />
                                <DatePicker
                                  label="End Date"
                                  value={values.endAt}
                                  onChange={dueDate => {
                                    setFieldValue('endAt', dueDate);
                                  }}
                                  minDate={
                                    values?.startAt
                                      ? new Date(
                                          values.startAt.plus({
                                            days: 1,
                                          })
                                        )
                                      : undefined
                                  }
                                  size="sm"
                                  isClearable
                                  hiddenArrowIcon
                                  showDateIcon
                                  name="endAt"
                                  disabled={
                                    isEdit &&
                                    initialValues.recurrence !== 'not_repeat' &&
                                    values?.recurrence !== 'not_repeat' &&
                                    (!values.dueDate ||
                                      formatDateTime(initialValues?.dueDate) !==
                                        formatDateTime(values?.dueDate))
                                  }
                                />
                              </>
                            )}
                          </div>
                          <div className="section is-mobile">
                            <h4 className="title">Image, Video and File</h4>
                            <FileAllList name="images" />
                          </div>
                          <div className="section is-mobile">
                            <TextField
                              name="description"
                              label="Description and purpose"
                              placeholder="Write a description and purpose…"
                              multiline
                              rows={6}
                              oldLabel
                            />
                          </div>
                          <div className="section is-mobile">
                            <h4 className="title">Subtasks</h4>
                            <div className="subtask-list">
                              <FieldArray
                                name="subtasks"
                                render={({ replace, remove }) => {
                                  return values.subtasks.map((subtask, i) =>
                                    subtask.removed ? null : (
                                      <Subtask
                                        projectId={values.projectId}
                                        className="subtask"
                                        key={
                                          subtask.removed
                                            ? `removed-${subtask.id}`
                                            : subtask.id
                                            ? subtask.id
                                            : `new-${i}`
                                        }
                                        subtask={subtask}
                                        onSubmit={value => {
                                          replace(i, value);
                                          const showValues = values.subtasks.filter(
                                            val => !val.removed
                                          );
                                          if (
                                            showValues.some(
                                              val => val.assignees.length > 0
                                            ) ||
                                            value.assignees.length > 0
                                          ) {
                                            let allSubtaskAssignees = [];
                                            values.subtasks.forEach(
                                              (val, index) => {
                                                if (
                                                  !val.removed &&
                                                  index !== i
                                                ) {
                                                  allSubtaskAssignees = [
                                                    ...allSubtaskAssignees,
                                                    ...val.assignees,
                                                  ];
                                                }
                                              }
                                            );

                                            allSubtaskAssignees = [
                                              ...allSubtaskAssignees,
                                              ...value.assignees,
                                            ];

                                            let uniqueCurrentAssignees = [];
                                            allSubtaskAssignees.forEach(
                                              assignee => {
                                                if (
                                                  !uniqueCurrentAssignees
                                                    .map(a => a.id)
                                                    .includes(assignee.id)
                                                ) {
                                                  uniqueCurrentAssignees = [
                                                    ...uniqueCurrentAssignees,
                                                    assignee,
                                                  ];
                                                }
                                              }
                                            );

                                            setFieldValue(
                                              'assignees',
                                              uniqueCurrentAssignees
                                            );
                                          }
                                          if (
                                            showValues.some(
                                              val =>
                                                val.estimation +
                                                  val.estimationTimeMinute >
                                                  0 || val.assignees.length > 0
                                            ) ||
                                            value.estimation +
                                              value.estimationTimeMinute >
                                              0 ||
                                            value.assignees.length > 0
                                          ) {
                                            const timeMinutes =
                                              showValues.reduce(
                                                (total, val, index) => {
                                                  return index !== i
                                                    ? total +
                                                        Number(
                                                          val.estimationTimeMinute
                                                        )
                                                    : total;
                                                },
                                                0
                                              ) +
                                              Number(
                                                value.estimationTimeMinute
                                              );

                                            const timeHours =
                                              showValues.reduce(
                                                (total, val, index) => {
                                                  return index !== i
                                                    ? total +
                                                        Number(val.estimation)
                                                    : total;
                                                },
                                                0
                                              ) +
                                              Number(value.estimation) +
                                              Math.floor(timeMinutes / 60);
                                            setFieldValue('point', timeHours);

                                            setFieldValue(
                                              'pointMinute',
                                              timeMinutes % 60
                                            );
                                          }
                                        }}
                                        onDelete={() => {
                                          const showValues = values.subtasks.filter(
                                            val => !val.removed
                                          );
                                          let currentAssignees =
                                            values.assignees;
                                          subtask.assignees.forEach(
                                            assignee => {
                                              if (
                                                showValues.filter(val =>
                                                  val.assignees.includes(
                                                    assignee
                                                  )
                                                ).length === 1
                                              ) {
                                                currentAssignees = currentAssignees.filter(
                                                  currentAssignee =>
                                                    currentAssignee !== assignee
                                                );
                                              }
                                            }
                                          );

                                          setFieldValue(
                                            'assignees',
                                            currentAssignees
                                          );

                                          const remainingTimeMinutes =
                                            values.point * 60 +
                                            values.pointMinute -
                                            (subtask.estimation * 60 +
                                              subtask.estimationTimeMinute);
                                          setFieldValue(
                                            'point',
                                            Math.max(
                                              0,
                                              Math.floor(
                                                remainingTimeMinutes / 60
                                              )
                                            )
                                          );

                                          setFieldValue(
                                            'pointMinute',
                                            Math.max(
                                              0,
                                              remainingTimeMinutes % 60
                                            )
                                          );

                                          return subtask.id
                                            ? replace(i, {
                                                ...subtask,
                                                removed: true,
                                              })
                                            : remove(i);
                                        }}
                                      />
                                    )
                                  );
                                }}
                              />
                              {isAddingSubtask && (
                                <SubtaskEditor
                                  className="subtask"
                                  projectId={values.projectId}
                                  onCancel={() => setIsAddingSubtask(false)}
                                  onSubmit={value => {
                                    const newValue = values.subtasks.concat(
                                      value
                                    );
                                    const showValues = newValue.filter(
                                      val => !val.removed
                                    );
                                    setFieldValue('subtasks', newValue);

                                    if (
                                      showValues.some(
                                        val => val.assignees.length > 0
                                      )
                                    ) {
                                      let allSubtaskAssignees = [];
                                      showValues.forEach(val => {
                                        allSubtaskAssignees = [
                                          ...allSubtaskAssignees,
                                          ...val.assignees,
                                        ];
                                      });
                                      let uniqueCurrentAssignees = [];
                                      allSubtaskAssignees.forEach(assignee => {
                                        if (
                                          !uniqueCurrentAssignees
                                            .map(a => a.id)
                                            .includes(assignee.id)
                                        ) {
                                          uniqueCurrentAssignees = [
                                            ...uniqueCurrentAssignees,
                                            assignee,
                                          ];
                                        }
                                      });
                                      setFieldValue(
                                        'assignees',
                                        uniqueCurrentAssignees
                                      );
                                    }
                                    if (
                                      showValues.some(
                                        val =>
                                          val.estimation +
                                            val.estimationTimeMinute >
                                            0 || val.assignees.length > 0
                                      )
                                    ) {
                                      const timeMinutes = showValues.reduce(
                                        (total, val) => {
                                          return (
                                            total + val.estimationTimeMinute
                                          );
                                        },
                                        0
                                      );

                                      const timeHours =
                                        showValues.reduce((total, val) => {
                                          return total + val.estimation;
                                        }, 0) + Math.floor(timeMinutes / 60);
                                      setFieldValue('point', timeHours);

                                      setFieldValue(
                                        'pointMinute',
                                        timeMinutes % 60
                                      );
                                    }

                                    setIsAddingSubtask(false);
                                  }}
                                />
                              )}
                            </div>
                            <OutlineButton
                              className="subtask-btn"
                              onClick={() => setIsAddingSubtask(true)}
                            >
                              <div>
                                <PlusIcon />
                              </div>
                              Add a new subtask
                            </OutlineButton>
                          </div>
                          <div className="section is-hidden-desktop">
                            <div className="activity-form">
                              {showActivityLogs ? (
                                <ActivityLogs
                                  projectId={projectId}
                                  ticketId={ticketId}
                                />
                              ) : null}
                            </div>
                          </div>
                          <div className="section is-hidden-desktop">
                            <div className="actions">
                              <OutlineButton
                                className="button cancel"
                                type="button"
                                onClick={onClose}
                              >
                                Cancel
                              </OutlineButton>
                              <Button
                                type="submit"
                                onClick={submitForm}
                                disabled={isSubmitting}
                              >
                                {isSubmitting ? (
                                  <LoadingIndicator
                                    className="loading"
                                    size="sm"
                                  />
                                ) : (
                                  'Save'
                                )}
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="sidebar">
                          <div className="select-group flag-fields">
                            <FlagFields />
                          </div>
                        </div>
                      </div>
                      <DropFileArea
                        dragging={dragging}
                        onDragLeave={onDragLeave}
                        setDragging={setDragging}
                      />
                    </div>
                  );
                }}
              </Formik>
              <div className="activity-form is-hidden-mobile">
                {showActivityLogs ? (
                  <ActivityLogs projectId={projectId} ticketId={ticketId} />
                ) : null}
              </div>
            </div>
          </Modal>
        )}
        <DeleteTicketModal
          isOpen={isShowDeleteModal}
          isDeleteRepeat={ticket?.recurrenceId}
          onClose={onCloseDeleteModal}
          onDelete={onDeleteTicket}
          deleteText="Delete this ticket?"
        />
        <ConfirmModal
          isOpen={isShowConfirmModal}
          isRepeat={ticket?.recurrenceId}
          initialValues={initialValues}
          updatedValues={formRef?.current?.values}
          onClose={onCloseConfirmModal}
          onConfirm={onConfirmUpdateTicket}
          message="Edit Ticket ?"
        />
      </>
    );
  })`
    position: relative;

    .is-hidden-desktop {
      display: none;
    }

    .loading {
      background: unset;

      &:after {
        margin: unset;
      }
    }

    @media (max-width: ${theme('breakpoints.mobile')}px) {
      .is-hidden-mobile {
        display: none !important;
      }

      .is-hidden-desktop {
        display: unset !important;
      }

      .repeat-group .row-date {
        margin-bottom: 10px;
      }

      .section.is-hidden-desktop {
        > .actions {
          background: white;
          justify-content: space-between;
          padding: 0 15px;
          padding-top: 30px;

          button {
            width: 100%;
          }
        }

        > .activity-form {
          grid-template-columns: 100%;
          grid-gap: unset;
          margin-top: 10px;
          margin-bottom: 10px;
          padding: 0 20px;
          background: white;

          .form {
            background: white;
          }

          .activity-log {
            align-items: start;

            .right-section .header {
              flex-wrap: wrap;
              line-height: 1.5;
            }

            .see-change {
              font-size: 14px;
            }
          }
        }
      }
    }

    .react-datepicker-popper {
      z-index: 2;
    }

    .section.recurrence {
      width: 280px;
    }

    .form {
      @media (max-width: ${theme('breakpoints.mobile')}px) {
        .action-group {
          display: flex;
          justify-content: space-between;
        }

        > .detail {
          padding: unset !important;
        }
      }

      > .detail > .sidebar {
        > .select-group {
          margin-bottom: 10px;
        }

        > .btn {
          border-color: #c6d5d5;
          width: 100%;

          color: #333;
          font-size: 14px;
          font-weight: bold;

          box-shadow: none;
          background: #f5f8f8;

          margin-bottom: 10px;
        }

        @media (max-width: ${theme('breakpoints.mobile')}px) {
          grid-row: 1;

          > .flag-fields {
            display: none;
          }
        }
      }

      @media (max-width: ${theme('breakpoints.mobile')}px) {
        .is-mobile {
          padding: 15px;
          margin-bottom: 10px !important;
          background: white;
        }

        background: #f5f8f8;
      }

      > .header {
        display: flex;
        justify-content: space-between;

        border-bottom: 2px solid #f5f5f5;
        padding-bottom: 20px;

        @media (max-width: ${theme('breakpoints.mobile')}px) {
          flex-direction: column-reverse;
        }
      }

      > .header > .detail {
        flex: 1;
        margin-right: 20px;
      }

      > .header > .detail > .name-wrapper {
        display: flex;
        margin-bottom: 15px;
        > .ticket-number {
          font-size: 20px;
          color: ${theme('colors.primary')};
          margin: auto 8px auto 0;
        }

        @media (max-width: ${theme('breakpoints.mobile')}px) {
          margin-top: 30px;
        }
      }

      > .header ${EditableText} {
        flex: 1;
        > .text {
          font-size: 20px;

          > .error {
            font-size: 12px;
          }

          &.error {
            color: ${theme('colors.error')};
          }
        }
      }

      .project-name {
        font-size: 12px;

        @media (max-width: ${theme('breakpoints.mobile')}px) {
          font-size: 14px;
        }
      }

      > .header .project-name > span {
        color: ${theme('colors.primary')};
      }

      > .header ${Button} {
        position: relative;
        top: -5px;

        @media (max-width: ${theme('breakpoints.mobile')}px) {
          float: right;
        }
      }

      > .section.member-list {
        margin-top: 16px;

        @media (max-width: ${theme('breakpoints.mobile')}px) {
          margin-top: unset;

          .member-item button div {
            padding-left: 0;
          }
        }

        > .title {
          display: block;

          margin-bottom: 8px;

          color: #c1c3ca;
          font-size: 12px;
          font-weight: normal;
        }
      }

      > .detail {
        > div > .select-group {
          border-radius: 6px;
          padding: 20px;
          background: #f5f8f8;
        }

        > .group {
          width: 100%;

          @media (max-width: ${theme('breakpoints.mobile')}px) {
            .repeat-group {
              background: white;
              padding-left: 15px;
              padding-right: 15px;
            }
          }

          > .section {
            margin-bottom: 20px;

            @media (max-width: ${theme('breakpoints.mobile')}px) {
              margin-bottom: unset;
            }

            > .title {
              display: block;

              margin-bottom: 8px;

              color: #c1c3ca;
              font-size: 12px;
              font-weight: normal;
            }

            .field.selected {
              padding-right: 32px;

              > div {
                overflow: hidden;
              }

              @media (max-width: ${theme('breakpoints.mobile')}px) {
                min-height: 40px;
                font-size: 14px;
              }
            }
          }

          .custom-repeat {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            font-size: 12px;
            color: #c1c3ca;

            > .row {
              display: flex;
              justify-content: space-between;
              column-gap: 12px;
            }

            > .row .week {
              width: 100%;
            }

            span {
              color: #3a3a3a;
            }

            .card-list {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              grid-gap: 10px;
              margin-top: 16px;
              font-size: 14px;

              .card {
                padding-top: 12px;
                padding-bottom: 12px;
                border-radius: 6px;
                border: solid 1px #dce0ea;
                text-align: center;
                color: #3a3a3a;
                cursor: pointer;
              }

              .card.active {
                border: solid 1px #22c759;
                color: #22c759;
              }
            }

            .column-list {
              display: grid;
              grid-template-columns: 1fr;
              row-gap: 20px;
              margin-top: 16px;

              .date-picker {
                width: 210px;
              }

              .custom-group {
                display: flex;
                align-items: center;
                column-gap: 20px;

                .label {
                  width: 30px;
                }

                .occurrence {
                  width: 210px;
                  margin-bottom: unset;
                }
              }
            }
          }

          > .row-date {
            display: grid;
            grid-template-columns: 280px repeat(2, 1fr);
            grid-gap: 10px;

            .date-type {
              margin-top: 20px;
            }

            > .group {
              position: relative;
              width: 100%;
              display: flex;
              align-items: center;

              .date-picker {
                width: 100%;
                position: absolute;
              }

              > div {
                position: absolute;
                right: 0;
              }

              .icon-close {
                cursor: pointer;
                background-color: ${theme('colors.primary')};
                color: #fff;
                border-radius: 50%;
                height: 20px;
                width: 20px;
                padding: 6px;
                font-size: 12px;
                line-height: 1;
                text-align: center;
                display: table-cell;
                vertical-align: middle;
                margin-top: 18px;
                margin-right: 2rem;

                &:hover {
                  ::after {
                    background: black;
                  }
                }
              }

              @media (max-width: ${theme('breakpoints.tablet')}px) {
                margin-top: 10px;
                margin-bottom: 20px;
              }
            }

            @media (max-width: ${theme('breakpoints.tablet')}px) {
              grid-template-columns: 100%;
              grid-gap: 10px;
            }

            @media (max-width: ${theme('breakpoints.mobile')}px) {
              background: white;
              padding: 15px;
              padding-top: 0;
              grid-template-columns: repeat(2, 1fr) !important;
              grid-gap: 10px !important;

              div:first-child {
                grid-column: span 2;
              }

              > .flag-fields {
                display: block;
              }
            }
          }

          > .row {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 10px;

            @media (max-width: ${theme('breakpoints.mobile')}px) {
              background: white;
              padding: 15px;
              padding-bottom: 0;
              grid-template-columns: repeat(2, 1fr) !important;
              grid-gap: 10px !important;

              div:first-child {
                grid-column: span 2;
              }
            }

            > .flag-fields {
              display: none;
              margin-top: 16px;
            }

            @media (max-width: ${theme('breakpoints.tablet')}px) {
              grid-template-columns: 100%;
              grid-gap: unset;
            }

            @media (max-width: ${theme('breakpoints.mobile')}px) {
              > .flag-fields {
                display: block;
              }
            }
          }
        }
      }

      > .on-drag-enter {
        position: absolute;
        top: -30px;
        left: -30px;
        width: calc(100% + 60px);
        height: calc(100% + 60px);
        border-radius: 6px;
        z-index: -1;
        background: white;
        padding: 30px;

        > .area-drop {
          border: 10px dashed ${theme('colors.primary')};
          height: 100%;
          border-radius: 6px;
          font-size: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          color: ${theme('colors.primary')};
        }

        &.dragging {
          z-index: 1;
        }
      }
    }

    .form > .detail,
    > .activity-form {
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 250px);
      grid-gap: 30px;
      padding: 20px 0;

      @media (max-width: ${theme('breakpoints.mobile')}px) {
        grid-template-columns: 100%;
        grid-gap: unset;
        margin-top: 10px;
        padding: 0 20px;

        .form {
          background: white;
        }

        .activity-log {
          align-items: start;

          .right-section .header {
            flex-wrap: wrap;
            line-height: 1.5;
          }

          .see-change {
            font-size: 14px;
          }
        }
      }
    }

    .subtask-list {
      margin-bottom: 20px;

      > .subtask {
        margin-bottom: 10px;

        @media (max-width: ${theme('breakpoints.mobile')}px) {
          grid-template-areas: 'title . menu' 'estimation assignees .';

          grid-template-columns: repeat(2, 1fr) 30px;
          padding: 10px;

          .field-group {
            grid-template-columns: repeat(2, 1fr);

            .text-field:first-child {
              grid-column: span 2;
            }

            .actions-mobile {
              display: flex;
              justify-content: end;
            }

            .member {
              border-left: unset;

              button {
                margin-left: unset;
              }

              .avatar-block {
                margin-right: unset;
                margin-left: -10px;
              }

              .placeholder {
                margin-left: -10px;
              }
            }
          }

          .title {
            grid-area: title;
            grid-column: span 2;

            span:first-child {
              margin-left: unset;
            }
          }

          .estimation {
            grid-area: estimation;

            svg {
              color: #9fa3ae;
            }
          }

          .assignees {
            grid-area: assignees;
            justify-content: flex-start;
            padding-left: 20px;

            .avatar-block {
              margin-right: unset;
              margin-left: -10px;
            }
          }

          .menu {
            grid-area: menu;
          }
        }
      }
    }

    .subtask-btn {
      width: 100%;
      font-size: 14px;
      font-weight: normal;

      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 10px;

      > div {
        background: #22c759;
        border-radius: 50%;
        text-align: center;

        svg {
          color: white;
          width: 12px;
          height: 12px;
          margin: 2px;
        }
      }
    }

    .wrapper-ticket-action {
      display: flex;
      justify-content: end;
      margin-top: 15px;

      .btn-action {
        width: 32px;
        height: 32px;
        margin: 0 0 0 10px;
        padding: 6px;
        border-radius: 6px;
        border: solid 1px #c6d5d5;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .actions {
      grid-area: actions;
      display: flex;
      justify-content: flex-end;
      align-self: center;

      .cancel {
        border: solid 1px #c2c3ca;
        color: #c2c3ca;
      }

      .cancel:hover {
        background-color: #c2c3ca;
        color: white;
      }

      > ${Button} {
        &.delete {
          color: #c1c3ca;
          border: none;
        }

        &.delete:hover {
          background: ${theme('colors.error')};
          color: white;
        }

        &:not(:last-child) {
          margin-right: 8px;
        }

        &:disabled {
          background: #adadad;
          cursor: not-allowed;
          border: none;
        }
      }
    }
  `
);
