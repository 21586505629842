import { useQueryCache, useInfiniteQuery } from 'react-query';
import { useAppClient } from '../../lib/AppProvider';
import { transformMember } from './useProfile';

const limit = 10;
let unreadCount = 0;

export function useNotificationList(status) {
  const client = useAppClient();
  const queryCache = useQueryCache();

  const { data, ...infiniteQuery } = useInfiniteQuery(
    ['notifications', status],
    async (_key, filter, nextId = null) => {
      const notifications = await client.listNotification({
        filter,
        limit,
        after: nextId,
      });
      unreadCount = notifications.meta.unread;
      return notifications.data;
    },
    {
      getFetchMore: lastGroup => lastGroup[limit - 1]?.id,
      refetchOnWindowFocus: false,
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
    }
  );

  const refetch = async () => {
    await queryCache.refetchQueries(['notifications']);
  };

  return {
    ...infiniteQuery,
    unreadCount,
    data: (data?.flat(1) || []).map(({ actor, ...noti }) => ({
      ...noti,
      actor: transformMember(actor ?? {}),
      title: getNotificationTitle(noti),
      subTitle: getNotificationSubTitle(noti),
      to: getNotificationDetailPath(noti),
      read: () => {
        if (noti.status !== 'unread') return;
        client.readNotifications({ ids: [noti.id] }).then(() => refetch());
      },
    })),
    readAll: () => client.readAllNotifications().then(() => refetch()),
    refetch,
  };
}

export const getNotificationTitle = noti => {
  switch (noti.notiType) {
    case 'UseCase': {
      return `${noti.event} project "${noti.useCase?.name}"`;
    }
    case 'Story': {
      return `${noti.event} ticket "${noti.parent?.name}" in project "${noti.useCase?.name}"`;
    }
    case 'Comment': {
      return `has left a comment on ticket "${noti.parent?.story?.name}" in project "${noti.useCase?.name}"`;
    }
    default: {
      return '';
    }
  }
};

export const getNotificationSubTitle = noti => {
  switch (noti.notiType) {
    case 'Comment': {
      return noti.parent?.body;
    }
    default: {
      return '';
    }
  }
};

export const getNotificationDetailPath = noti => {
  switch (noti.notiType) {
    case 'UseCase': {
      return `./projects/${noti.useCase?.id}`;
    }
    case 'Story': {
      return `./projects/${noti.useCase?.id}?selectedTicketId=${noti.parent?.id}`;
    }
    case 'Comment': {
      return `./projects/${noti.useCase?.id}?selectedTicketId=${noti.parent?.story?.id}`;
    }
    default: {
      return './';
    }
  }
};
