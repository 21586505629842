import * as React from 'react';

export function Logo() {
  return (
    <svg viewBox="0 0 2000 650">
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          y1="0.999"
          x2="0.5"
          y2="0.014"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#00d04f" />
          <stop offset="1" stopColor="#00964f" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="0"
          y1="0.5"
          x2="1"
          y2="0.5"
          xlinkHref="#linear-gradient"
        />
      </defs>
      <g transform="translate(5268 17615)">
        <rect
          width="2000"
          height="650"
          transform="translate(-5268 -17615)"
          fill="none"
        />
        <g transform="translate(-5429.181 -17752.766)">
          <path
            d="M438.508,489.118a101.236,101.236,0,0,1-39.839-39.833Q384.2,423.519,384.2,389.825q0-33.676,14.87-59.454A103.341,103.341,0,0,1,439.7,290.532q25.766-14.063,57.47-14.073t57.485,14.073a103.4,103.4,0,0,1,40.62,39.838q14.862,25.774,14.865,59.454,0,33.7-15.261,59.46a105.639,105.639,0,0,1-41.221,39.833q-25.963,14.079-58.071,14.068Q463.869,503.186,438.508,489.118Zm85.42-41.618q13.271-7.332,21.2-22t7.923-35.674q0-31.3-16.449-48.158-16.446-16.842-40.234-16.85-23.77,0-39.828,16.85t-16.058,48.158q0,31.324,15.662,48.163,15.646,16.85,39.432,16.844A57.7,57.7,0,0,0,523.928,447.5Z"
            transform="translate(150.671 67.146)"
            fill="#414042"
          />
          <path
            d="M488.166,489.118a101.27,101.27,0,0,1-39.839-39.833q-14.466-25.765-14.469-59.46,0-33.676,14.87-59.454a103.34,103.34,0,0,1,40.625-39.838q25.765-14.063,57.475-14.073t57.48,14.073a103.36,103.36,0,0,1,40.62,39.838q14.871,25.774,14.865,59.454,0,33.7-15.261,59.46a105.664,105.664,0,0,1-41.216,39.833q-25.971,14.079-58.071,14.068Q513.525,503.186,488.166,489.118ZM573.586,447.5q13.27-7.332,21.21-22,7.918-14.656,7.918-35.674,0-31.3-16.448-48.158-16.446-16.842-40.229-16.85T506.2,341.667q-16.058,16.858-16.058,48.158,0,31.324,15.662,48.163,15.646,16.85,39.437,16.844A57.7,57.7,0,0,0,573.586,447.5Z"
            transform="translate(363.144 67.146)"
            fill="#414042"
          />
          <path
            d="M560.039,284.975V488.887a7.84,7.84,0,0,1-7.839,7.839H512.382a7.84,7.84,0,0,1-7.839-7.839V284.975a7.84,7.84,0,0,1,7.839-7.839H552.2A7.84,7.84,0,0,1,560.039,284.975Z"
            transform="translate(665.586 70.043)"
            fill="#414042"
          />
          <path
            d="M710.526,301.307Q734.7,326.1,734.7,370.479V491.463a7.84,7.84,0,0,1-7.839,7.839H687.047a7.84,7.84,0,0,1-7.839-7.839V378.012q0-26.169-13.076-40.235T630.453,323.7q-22.986,0-36.265,14.073t-13.276,40.235V491.463a7.84,7.84,0,0,1-7.839,7.839H533.256a7.84,7.84,0,0,1-7.839-7.839V287.551a7.84,7.84,0,0,1,7.839-7.839h39.818a7.84,7.84,0,0,1,7.839,7.839v19.51a76.592,76.592,0,0,1,28.336-22.392,87.7,87.7,0,0,1,37.854-8.135Q686.352,276.534,710.526,301.307Z"
            transform="translate(754.901 67.467)"
            fill="#414042"
          />
          <path
            d="M668.939,493.478q-21.4-9.708-33.89-26.357a67.378,67.378,0,0,1-12.495-27.576,7.863,7.863,0,0,1,7.76-9.285h40.709a7.86,7.86,0,0,1,7.454,5.58,31.111,31.111,0,0,0,11.27,15.424q10.9,8.322,27.154,8.325,15.844,0,24.773-6.34T750.59,437q0-10.7-10.9-16.053-10.911-5.353-34.681-11.7-24.594-5.939-40.235-12.284a69.749,69.749,0,0,1-26.953-19.42q-11.3-13.089-11.3-35.283a56.965,56.965,0,0,1,10.5-33.293q10.5-15.06,30.126-23.786,19.621-8.71,46.173-8.72,39.25,0,62.632,19.626,20.1,16.858,24.683,43.829A7.853,7.853,0,0,1,792.868,349H755.12a7.9,7.9,0,0,1-7.564-5.944,28.006,28.006,0,0,0-9.85-14.865q-9.708-7.728-25.961-7.728-16.787,0-24.958,6.894a18.821,18.821,0,0,0-6.3,15.593q.705,10.008,11.049,15.361,11.093,5.756,34.486,11.7Q749.8,375.955,765.26,382.3a69.441,69.441,0,0,1,26.753,19.616Q803.312,415.208,803.7,437a58.193,58.193,0,0,1-10.5,34.09q-10.508,15.068-30.126,23.585T717.3,503.186Q690.336,503.186,668.939,493.478Z"
            transform="translate(1169.9 67.146)"
            fill="#414042"
          />
          <path
            d="M406.144,277.893l-39.865,27.983a7.807,7.807,0,0,0-3.326,6.392V492.552a7.068,7.068,0,0,0,7.068,7.068H411.38a7.068,7.068,0,0,0,7.068-7.068V284.285A7.814,7.814,0,0,0,406.144,277.893Z"
            transform="translate(59.761 67.148)"
            fill="url(#linear-gradient)"
          />
          <path
            d="M551.937,305.433v-33.4c0-3.157-2.9-5.712-6.466-5.712H355.453c-3.574,0-6.467,2.555-6.467,5.712v33.4c0,3.152,2.893,5.706,6.467,5.706H545.471C549.039,311.139,551.937,308.585,551.937,305.433Z"
            transform="translate(0 23.777)"
            fill="url(#linear-gradient-2)"
          />
          <path
            d="M560.039,269.624V307.4c0,4.893-3.511,8.863-7.839,8.863H512.382c-4.329,0-7.839-3.969-7.839-8.863V269.624c0-4.893,3.51-8.858,7.839-8.858H552.2C556.528,260.766,560.039,264.73,560.039,269.624Z"
            transform="translate(665.586)"
            fill="#414042"
          />
          <path
            d="M526.859,263.564l-39.87,27.983a7.82,7.82,0,0,0-3.32,6.4V553.862a7.065,7.065,0,0,0,7.068,7.068H532.09a7.065,7.065,0,0,0,7.068-7.068v-283.9A7.81,7.81,0,0,0,526.859,263.564Z"
            transform="translate(576.268 5.838)"
            fill="#414042"
          />
          <path
            d="M792.95,280.033H752.736a7.84,7.84,0,0,0-7.839,7.839v23.474q-10.7-15.464-29.329-25.169-18.639-9.715-43.211-9.718-28.149,0-50.934,13.878t-36.07,39.638q-13.279,25.766-13.281,59.059,0,33.7,13.281,59.655t36.27,40.235a94.306,94.306,0,0,0,50.734,14.263q24.174,0,43.011-10.3,18.821-10.294,29.529-25.76v34.085c0,19.558-5.025,24.821-15.065,34.465S706.706,550.14,690.6,550.14q-20.619,0-35.082-8.916a43.425,43.425,0,0,1-14.084-13.471c-3.257-5.078-9.85-6.751-14.791-3.294l-27.2,19.04a10.66,10.66,0,0,0-2.318,15.118,84.077,84.077,0,0,0,18.349,18.08q29.93,21.8,76.705,21.8,34.871,0,59.454-14.073t36.861-38.049c8.193-15.989,12.294-24.821,12.294-45.17V287.872A7.84,7.84,0,0,0,792.95,280.033ZM736.969,424.511a55.982,55.982,0,0,1-21.4,22.2,57.308,57.308,0,0,1-28.938,7.728,54.275,54.275,0,0,1-28.341-7.929,58.429,58.429,0,0,1-21.406-22.793q-8.132-14.862-8.129-34.681t8.129-34.285a57.081,57.081,0,0,1,78.685-21.8,55.947,55.947,0,0,1,21.4,22.2q7.926,14.474,7.929,34.681Q744.9,410.048,736.969,424.511Z"
            transform="translate(954.525 67.146)"
            fill="#414042"
          />
        </g>
      </g>
    </svg>
  );
}
