import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { theme } from 'styled-tools';

import { Modal } from '../../../components/Modal';
import CheckYourEmailImage from '../../../assets/images/check-your-email.svg';
import { Button } from './Button';

function CheckYourEmailModal({ className, isOpen, onClose }) {
  return (
    <Modal className={className} isOpen={isOpen} onClose={onClose}>
      <img
        src={CheckYourEmailImage}
        alt="Check your email!"
        className="image"
      />
      <h4>Check your email!</h4>
      <p>
        We just sent you an email
        <br />
        Please follow the instructions in the email
        <br />
        to access your account.
      </p>
      <Button as={Link} className="submit" type="button" to="/auth/sign-in">
        Go To Login Page
      </Button>
    </Modal>
  );
}

const StyledCheckYourEmailModal = styled(CheckYourEmailModal)`
  text-align: center;
  min-width: 554px;

  h4 {
    font-size: 20px;
    margin: 30px 0;
  }

  p {
    margin-bottom: 50px;
  }

  .submit {
    display: block;
    margin: 0 45px;
  }

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    min-width: calc(100% - 40px);

    .submit {
      margin: 0;
    }
  }
`;

export { StyledCheckYourEmailModal as CheckYourEmailModal };
