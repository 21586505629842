import React from 'react';
import styled from 'styled-components';

import { Pagination } from '../../../../../../components/Pagination';
import { LoadingIndicator } from '../../../../../../components/LoadingIndicator';
import { ProjectCard } from '../../../../components/ProjectCard';
import { useQueryParams } from '../../../../../../lib/hooks';
import { Button } from '../../../../../authentication/components/Button';

function Project({
  starredProjects,
  reloadAllProjects,
  loading,
  currentPage,
  totalItems,
  pageSize,
  projects,
}) {
  const [, setQueryParams] = useQueryParams();

  return (
    <>
      {loading ? (
        <LoadingIndicator className="loading-project" color="primary" />
      ) : projects.length > 0 ? (
        <>
          {starredProjects && (
            <>
              <h2 className="title">Starred Projects</h2>
              <div className="grid">
                {starredProjects.map(project => (
                  <ProjectCard
                    key={project.id}
                    project={project}
                    isStarred
                    reloadAllProjects={reloadAllProjects}
                  />
                ))}
              </div>
            </>
          )}
          <h2 className="title">All Projects</h2>
          <div className="grid">
            {projects.map(project => (
              <ProjectCard
                key={project.id}
                project={project}
                reloadAllProjects={reloadAllProjects}
              />
            ))}
          </div>
          <div className="pagination">
            <Pagination
              currentPage={currentPage}
              totalItems={totalItems}
              pageSize={pageSize}
              maxPages={5}
            />
          </div>
        </>
      ) : (
        <div className="no-project">
          <div className="no-projects">
            <div className="label">You not belong in any project yet.</div>
            <div className="description">
              Project not found or you do not have access to view it.
            </div>
            <Button
              className="button"
              type="button"
              onClick={() => setQueryParams({ modal: 'create-project' })}
            >
              Create new project
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

const StyledProject = styled(Project)`
  .title {
    margin-top: 20px;
    padding: 0px 20px;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;
    width: 100%;
    margin: 20px auto;
    padding: 0px 20px;

    > ${ProjectCard}:hover {
      cursor: pointer;
    }
  }

  .pagination {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export { StyledProject as Project };
