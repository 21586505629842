import useSWR from 'swr';
import { useAppClient } from '../../lib/AppProvider';
import { useCurrentCompany } from '../projects/useCurrentCompany';

export function useMemberSearch({
  status = 'active',
  page = 1,
  query = '',
  limit = 1000,
  order,
  sort,
} = {}) {
  const { data: company } = useCurrentCompany();
  const { kick, changeRole } = useMemberAction();
  const client = useAppClient();
  const result = useSWR(
    `members?status=${status}&page=${page}&query=${query}&limit=${limit}&order=${order}&sort=${sort}`,
    () =>
      client
        .listCompanyMembers({
          companyId: company.id,
          status,
          query,
          per_page: limit,
          page,
          order,
          sort,
        })
        .then(list => {
          return {
            meta: list.meta,
            data: list.data.map(member => ({
              id: member.id,
              name: member.fullName ?? 'N/A',
              avatarUrl: member.avatarUrl,
              initial:
                member.firstName && member.lastName
                  ? `${member.firstName.substr(0, 1)}${member.lastName.substr(
                      0,
                      1
                    )}`
                  : 'NA',
              nickName: member.nickName ?? '',
              email: member.email,
              roleId: member.roleInSelectedCompany ?? 'member',
            })),
          };
        }),
    { revalidateOnFocus: false }
  );

  return {
    ...result,
    async kick(id) {
      await result.mutate(
        members => ({
          ...members,
          data: members.data.filter(member => member.id !== id),
        }),
        false
      );

      await kick(id);

      result.revalidate();
    },
    async changeRole(id, roleId) {
      await result.mutate(
        members => ({
          ...members,
          data: members.data.map(member =>
            member.id === id ? { ...member, roleId } : member
          ),
        }),
        false
      );

      await changeRole(id, roleId);

      result.revalidate();
    },
  };
}

export function useMemberAction() {
  const client = useAppClient();
  const { data: company } = useCurrentCompany();

  return {
    async invite({ email, roleId }) {
      return client.inviteMember({
        companyId: company.id,
        email,
        roleId,
      });
    },
    async kick(id) {
      return client.removeMember({ companyId: company.id, memberId: id });
    },
    async changeRole(id, roleId) {
      return client.changeRoleMember({
        companyId: company.id,
        memberId: id,
        roleId,
      });
    },
  };
}

export function useFindMember() {
  const client = useAppClient();

  return {
    find({ email, companyId }) {
      return client
        .findUserByEmail({
          email,
          companyId,
        })
        .then(res => res.data)
        .catch(error => ({
          error: error.response?.data?.msg ?? error.message ?? error,
        }));
    },
  };
}
