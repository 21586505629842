import React, { useState, useContext } from 'react';
import hoist from 'hoist-non-react-statics';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useAppClient } from '../../lib/AppProvider';
import { useFilter } from './useFilter';
import { successToast, errorToast } from '../../lib/toast';
import ProjectContext from '../../store/projectContext';

export function useProjectSprint({ projectId }) {
  const client = useAppClient();
  const { projectState, projectDispatch } = useContext(ProjectContext);

  const { sprints } = projectState;

  return {
    data: sprints,
    async create({ name, goal, range, color }) {
      const now = DateTime.local().startOf('day');

      const response = await client.createSprint({
        projectId,
        name,
        goal,
        range,
        color,
      });
      if (response?.errors) {
        errorToast({
          message: response?.errors?.map(err => err.detail)?.join(','),
        });
      } else {
        projectDispatch({
          type: 'CREATE_SPRINT',
          sprint: {
            id: response.id,
            name: response.name,
            goal: response.goal,
            color: response.color,
            range: {
              start: DateTime.fromFormat(response.startDate, 'yyyy-MM-dd'),
              end: DateTime.fromFormat(response.endDate, 'yyyy-MM-dd'),
            },
            onSprint:
              DateTime.fromISO(response.startDate).startOf('day') <= now &&
              DateTime.fromISO(response.endDate).startOf('day') >= now,
          },
        });
        successToast({ message: 'The sprint has been created.' });
      }
    },
    async edit({ sprintId, name, goal, range, color }) {
      const response = await client.editSprint({
        projectId,
        sprintId,
        name,
        goal,
        range,
        color,
      });
      if (response?.errors) {
        errorToast({
          message: response?.errors?.map(err => err.detail)?.join(','),
        });
      } else {
        projectDispatch({
          type: 'UPDATE_SPRINT',
          sprint: { id: sprintId, name, goal, range, color },
        });
      }
    },
    async delete({ sprintId }) {
      await client.deleteSprint({ projectId, sprintId });
      projectDispatch({
        type: 'REMOVE_SPRINT',
        sprintId,
      });
    },
  };
}

export function withSprintFilter(Component) {
  function WithSprintFilter({
    sprintId: initId,
    onOpenModal,
    onRefetch,
    ...props
  }) {
    const [isAddSprintModal, setIsAddSprintModal] = useState(false);
    const [isDeleteSprintModal, setIsDeleteSprintModal] = useState(false);
    const [selectedSprint, setSelectedSprint] = useState(null);
    const [sprintMode, setSprintMode] = useState('');
    const { id = initId } = useParams();

    const {
      data,
      create: createSprint,
      delete: deleteSprint,
      edit: editSprint,
    } = useProjectSprint({
      projectId: id,
    });
    const filter = useFilter({ key: 'sprint', data });

    const onAddSprintModal = () => {
      if (onOpenModal) onOpenModal();
      setSelectedSprint(null);
      setIsAddSprintModal(true);
      setSprintMode('create');
    };

    const onEditSprintModal = sprintItem => () => {
      if (onOpenModal) onOpenModal();
      setIsAddSprintModal(true);
      setSelectedSprint(sprintItem);
      setSprintMode('edit');
    };

    const onCloseSprintModal = () => {
      setIsAddSprintModal(false);
    };

    const onOpenDeleteSprintModal = sprintItem => () => {
      setIsDeleteSprintModal(true);
      setSelectedSprint(sprintItem);
    };

    const onOpenDeleteInEditSprintModal = () => {
      setIsDeleteSprintModal(true);
      setIsAddSprintModal(false);
    };

    const onCloseDeleteSprintModal = () => {
      setIsDeleteSprintModal(false);
    };

    const onSubmit = (
      { sprintName: name, sprintGoal: goal, color, startDate, endDate },
      { resetForm }
    ) => {
      if (sprintMode === 'edit') {
        editSprint({
          sprintId: selectedSprint.id,
          name,
          goal,
          range: { start: startDate, end: endDate },
          color,
        });
      }
      if (sprintMode === 'create') {
        createSprint({
          name,
          goal,
          range: { start: startDate, end: endDate },
          color,
        });
      }
      resetForm();
      setIsAddSprintModal(false);
      if (onRefetch) {
        onRefetch();
      }
    };

    const onDelete = () => {
      deleteSprint({ sprintId: selectedSprint.id });
      setIsDeleteSprintModal(false);
    };

    const pageProps = {
      ...props,
      ...filter,
      data,
      selectedSprint,
      onAddSprintModal,
      onEditSprintModal,
      onCloseSprintModal,
      onOpenDeleteSprintModal,
      onOpenDeleteInEditSprintModal,
      onCloseDeleteSprintModal,
      onSubmit,
      onDelete,
      isAddSprintModal,
      isDeleteSprintModal,
      sprintMode,
    };

    return <Component {...pageProps} />;
  }

  hoist(WithSprintFilter, Component);

  WithSprintFilter.displayName = `withSprintFilter(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithSprintFilter;
}
