import { useReducer } from 'react';

const reducer = (state, action) => {
  switch (action.type) {
    case 'LIST_DOCUMENTS':
      return {
        ...state,
        documents: action.documents,
      };
    case 'UPDATE_DOCUMENT': {
      const { documents } = state;
      const index = documents.findIndex(x => x.id === action.document.id);
      const updatedDocument = { ...documents[index], ...action.document };
      const otherDocuments = documents.filter(
        document => document.id !== action.document.id
      );
      return {
        ...state,
        documents: [updatedDocument, ...otherDocuments],
      };
    }
    case 'CREATE_DOCUMENT': {
      return {
        ...state,
        documents: [action.document, ...state.documents],
      };
    }
    case 'REMOVE_DOCUMENT': {
      return {
        ...state,
        documents: state.documents.filter(
          document => document.id !== action.id
        ),
      };
    }
    case 'UPDATE_MEMBERS': {
      return {
        ...state,
        members: action.data,
      };
    }
    case 'SELECT_PROJECT':
      return {
        ...state,
        projectId: action.id,
        members: action.membersData,
        tags: action.tagsData,
        epics: action.epicData,
        sprints: action.sprintData,
      };
    case 'LIST_TICKETS':
      return {
        ...state,
        tickets: action.tickets,
      };
    case 'UPDATE_TICKET': {
      let { tickets } = state;
      tickets = tickets.map(column => {
        let data = [...column.data];
        const index = data.findIndex(x => x.id === action.ticket.id);
        if (index !== -1 && column.id === action.ticket.status) {
          const ticketIndex = data.findIndex(t => t.id === action.ticket.id);
          data[ticketIndex] = action.ticket;
        }

        if (index !== -1 && column.id !== action.ticket.status) {
          data = data.filter(t => t.id !== action.ticket.id);
        }

        if (index === -1 && column.id === action.ticket.status) {
          data = [action.ticket, ...data];
        }

        return { ...column, data };
      });

      return { ...state, tickets: [...tickets] };
    }
    case 'CREATE_TICKET': {
      const { tickets } = state;
      const columnIndex = tickets.findIndex(
        column => column.id === action.ticket.status
      );
      tickets[columnIndex] = {
        ...tickets[columnIndex],
        data: [action.ticket, ...tickets[columnIndex].data],
      };
      return {
        ...state,
        tickets: [...tickets],
      };
    }
    case 'REMOVE_TICKET': {
      const { tickets } = state;
      const archivedTicket = tickets
        .flatMap(column => column.data)
        .find(t => action.id === t.id);
      const columnIndex = tickets.findIndex(
        column => column.id === archivedTicket.status
      );
      tickets[columnIndex] = {
        ...tickets[columnIndex],
        data: tickets[columnIndex].data.filter(
          ticket => ticket.id !== archivedTicket.id
        ),
      };
      return {
        ...state,
        tickets: [...tickets],
      };
    }
    case 'UPDATE_COLUMN': {
      let { tickets } = state;
      const from = tickets.findIndex(column => action.column.id === column.id);
      const to = action.column.order - 1;

      if (from < to) {
        tickets = tickets
          .slice(0, from)
          .concat(tickets.slice(from + 1, to + 1))
          .concat({ ...tickets[from], name: action.column.name })
          .concat(tickets.slice(to + 1));
      } else {
        tickets = tickets
          .slice(0, to)
          .concat({ ...tickets[from], name: action.column.name })
          .concat(tickets.slice(to, from))
          .concat(tickets.slice(from + 1));
      }
      return { ...state, tickets: [...tickets] };
    }
    case 'CREATE_COLUMN': {
      return {
        ...state,
        tickets: [...state.tickets, action.column],
      };
    }
    case 'REMOVE_COLUMN': {
      return {
        ...state,
        tickets: state.tickets.filter(column => column.id !== action.id),
      };
    }
    case 'DROP_TICKET': {
      return { ...state, tickets: [...action.newData] };
    }
    case 'CREATE_TAG':
      return {
        ...state,
        tags: [...state.tags, action.data],
      };
    case 'UPDATE_TAG': {
      const { tags } = state;
      const targetTag = tags.find(tag => tag.id === action.tagId);
      const targetIndex = tags.indexOf(targetTag);
      tags[targetIndex] = { ...tags[targetIndex], ...action.tag };
      return {
        ...state,
        tags: [...tags],
      };
    }
    case 'REMOVE_TAG':
      return {
        ...state,
        tags: state.tags.filter(tag => tag.id !== action.tagId),
      };
    case 'All_TICKET': {
      return { ...state, all: action.all };
    }
    case 'ALL_REMOVE': {
      return {
        ...state,
        hasRemove: action.hasRemove,
      };
    }
    case 'ALL_DUPLICATE': {
      return {
        ...state,
        hasDuplicate: action.hasDuplicate,
      };
    }
    case 'CREATE_EPIC': {
      return {
        ...state,
        epics: [...state.epics, action.epic],
      };
    }
    case 'UPDATE_EPIC': {
      const { epics } = state;
      const targetEpic = epics.find(epic => epic.id === action.epic.id);
      const targetIndex = epics.indexOf(targetEpic);
      epics[targetIndex] = { ...epics[targetIndex], ...action.epic };
      return {
        ...state,
        epics: [...epics],
      };
    }
    case 'REMOVE_EPIC': {
      return {
        ...state,
        epics: state.epics.filter(epic => epic.id !== action.epicId),
      };
    }
    case 'CREATE_SPRINT': {
      return {
        ...state,
        sprints: [action.sprint, ...state.sprints],
      };
    }
    case 'UPDATE_SPRINT': {
      const { sprints } = state;
      const targetSprint = sprints.find(
        sprint => sprint.id === action.sprint.id
      );
      const targetIndex = sprints.indexOf(targetSprint);
      sprints[targetIndex] = { ...sprints[targetIndex], ...action.sprint };
      return {
        ...state,
        sprints: [...sprints],
      };
    }
    case 'REMOVE_SPRINT': {
      return {
        ...state,
        sprints: state.sprints.filter(sprint => sprint.id !== action.sprintId),
      };
    }
    case 'ADD_MEMBER': {
      return {
        ...state,
        members: action.membersData,
      };
    }
    case 'ADD_TAG': {
      return {
        ...state,
        tags: action.tagsData,
      };
    }
    case 'ADD_EPIC_SPRINT': {
      return {
        ...state,
        epics: action.epicData,
        sprints: action.sprintData,
      };
    }
    default: {
      return state;
    }
  }
};

const useProjectState = () => {
  const [projectState, projectDispatch] = useReducer(reducer, {
    projectId: null,
    members: [],
    tags: [],
    tickets: [],
    documents: [],
  });

  return { projectState, projectDispatch };
};

export default useProjectState;
