import { CompanyExpensesPage } from './CompanyExpensesPage';
import { withCompanyExpensesPage } from './withCompanyExpensesPage';
import { withPermission } from '../../../authentication/hocs/withPermission';

const ConnectedCompanyExpensesPage = withPermission(
  withCompanyExpensesPage(CompanyExpensesPage),
  'company-expense:read'
);

export { ConnectedCompanyExpensesPage as CompanyExpensesPage };
