import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as yup from 'yup';
import useSWR from 'swr';
import { theme } from 'styled-tools';
import { Modal } from '../../../../components/Modal';
import { EditableText } from '../../../settings/pages/SettingCompanyPage/EditableText';
import { TextField } from '../../../authentication/components/TextField';
import { Button, ClearButton } from '../../../authentication/components/Button';
import { SelectField as Select } from '../../../../components/Select';
import {
  useDocumentDefaultAction,
  useDocumentAction,
} from '../../useDocumentSearch';
import { successToast } from '../../../../lib/toast';
import { FileList } from '../../../tickets/pages/AllTicketPage/FileList';
import { useAppClient } from '../../../../lib/AppProvider';
import { titleCase } from '../../../../components/TextTransform';

const validationSchema = yup.object({
  name: yup.string().required(),
  category: yup.string().required(),
  file: yup.array().required().min(1),
});

export const DocumentModal = React.memo(
  styled(function DocumentModal({
    className,
    isOpen,
    onClose,
    defaultDocument = false,
    documentType = 'regular',
    projectId,
    projectName,
    documentId,
  }) {
    const { id } = useParams();
    const client = useAppClient();
    const categoryOptions = [
      { label: 'Development', value: 'development' },
      { label: 'UX/UI Designer', value: 'ux/ui_designer' },
      { label: 'Project Management', value: 'project_management' },
      { label: 'Marketing', value: 'marketing' },
      { label: 'HR', value: 'hr' },
      { label: 'Finance', value: 'finance' },
      { label: 'Legal', value: 'legal' },
      { label: '3rd Party', value: '3rd_party' },
    ];
    const { data: document } = useSWR(
      documentId && `documents/${documentId}`,
      () => client.getDocument(documentId, { projectId: id }),
      { revalidateOnFocus: false }
    );
    const { createAction, updateAction } = useDocumentAction();
    const {
      createDefaultDocument,
      updateDefaultDocument,
    } = useDocumentDefaultAction({ projectId: id });

    const initialValues = useMemo(
      () => ({
        name: document?.name ?? '',
        documentType,
        description: document?.description ?? '',
        category:
          categoryOptions.find(option => option.value === document?.category)
            ?.value ?? categoryOptions[0].value,
        file: document?.fileUrl
          ? [
              {
                id: 1,
                url: document.fileUrl,
                hash: document.fileUrl,
              },
            ]
          : [],
      }),
      [document, categoryOptions, documentType]
    );

    async function handleSubmit(params) {
      const paramsFormat = { ...params, file: params.file[0] };
      if (documentId) {
        if (defaultDocument) {
          await updateDefaultDocument(documentId, {
            projectId,
            ...paramsFormat,
          });
        } else {
          await updateAction(documentId, {
            projectId,
            ...paramsFormat,
          });
        }

        successToast({ message: 'Document has been updated.' });
        onClose();
        return;
      }

      if (defaultDocument) {
        await createDefaultDocument({ projectId, ...paramsFormat });
      } else {
        await createAction({ projectId, ...paramsFormat });
      }

      successToast({ message: 'Document has been created.' });
      onClose();
    }

    return (
      <Modal isOpen={isOpen} onClose={onClose} width="610px" padding="30px">
        <div className={className}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, submitForm }) => {
              return (
                <div className="form">
                  <div className="header">
                    <div className="detail">
                      <div className="name-wrapper">
                        <EditableText
                          className="name"
                          name="name"
                          placeholder="Enter Document Name"
                        />
                      </div>
                      <div className="project-name">
                        {defaultDocument && titleCase(documentType)} Document
                        for : <span>{projectName}</span>
                      </div>
                    </div>
                  </div>
                  <div className="detail">
                    <div className="group">
                      <div className="section row">
                        <Select
                          name="category"
                          options={categoryOptions}
                          label="Document Category"
                          placeholder="Category"
                          size="sm"
                        />
                      </div>
                      <div className="section">
                        <h4 className="title">File (PDF or Image only)</h4>
                        <FileList
                          name="file"
                          display="mix"
                          accept="application/pdf,image/*"
                          singleUpload
                        />
                      </div>
                      <div className="section">
                        <TextField
                          name="description"
                          label="Description"
                          placeholder="Write a description…"
                          multiline
                          rows={6}
                          oldLabel
                        />
                      </div>
                    </div>
                  </div>
                  <div className="button-group">
                    <ClearButton
                      type="reset"
                      className="cancel"
                      onClick={onClose}
                    >
                      Cancel
                    </ClearButton>
                    <Button
                      type="submit"
                      onClick={submitForm}
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                    <div className="wrapper-ticket-action">
                      <div />
                    </div>
                  </div>
                </div>
              );
            }}
          </Formik>
        </div>
      </Modal>
    );
  })`
    position: relative;

    .form {
      > .detail > .sidebar {
        > .select-group {
          margin-bottom: 10px;
        }

        > .btn {
          border-color: #c6d5d5;
          width: 100%;

          color: #333;
          font-size: 14px;
          font-weight: bold;

          box-shadow: none;
          background: #f5f8f8;

          margin-bottom: 10px;
        }

        @media (max-width: ${theme('breakpoints.mobile')}px) {
          grid-row: 1;

          > .flag-fields {
            display: none;
          }
        }
      }

      > .header {
        display: flex;
        justify-content: space-between;

        border-bottom: 2px solid #f5f5f5;
        padding-bottom: 20px;

        @media (max-width: ${theme('breakpoints.mobile')}px) {
          flex-direction: column-reverse;
        }
      }

      > .header > .detail {
        flex: 1;
        margin-right: 20px;
      }

      > .header > .detail > .name-wrapper {
        display: flex;
        margin-bottom: 10px;
        > .ticket-number {
          font-size: 20px;
          color: ${theme('colors.primary')};
          margin: auto 8px auto 0;
        }
      }

      > .header ${EditableText} {
        flex: 1;
        > .text {
          font-size: 20px;

          > .error {
            font-size: 12px;
          }

          &.error {
            color: ${theme('colors.error')};
          }
        }
      }

      > .detail > .group > .section {
        > .error-message {
          font-size: 12px;
          color: ${theme('colors.error')};
        }
      }

      > .header .project-name {
        font-size: 12px;
      }

      > .header .project-name > span {
        color: ${theme('colors.primary')};
        font-weight: bold;
      }

      > .header ${Button} {
        position: relative;
        top: -5px;

        @media (max-width: ${theme('breakpoints.mobile')}px) {
          float: right;
        }
      }

      > .detail {
        > div > .select-group {
          border-radius: 6px;
          padding: 20px;
          background: #f5f8f8;
        }

        > .group {
          width: 100%;

          > .section {
            margin-bottom: 30px;

            > .title {
              display: block;

              margin-bottom: 8px;

              color: #c1c3ca;
              font-size: 12px;
              font-weight: normal;
            }

            .field.selected {
              padding-right: 32px;

              > div {
                overflow: hidden;
              }
            }
          }

          > .row {
            display: grid;
            grid-template-columns: 180px 100px 230px;
            grid-gap: 10px;

            > .flag-fields {
              display: none;
              margin-top: 16px;
            }

            @media (max-width: ${theme('breakpoints.tablet')}px) {
              grid-template-columns: 100%;
            }

            @media (max-width: ${theme('breakpoints.mobile')}px) {
              > .flag-fields {
                display: block;
              }
            }
          }
        }
      }

      > .on-drag-enter {
        position: absolute;
        top: -30px;
        left: -30px;
        width: calc(100% + 60px);
        height: calc(100% + 60px);
        border-radius: 6px;
        z-index: -1;
        background: white;
        padding: 30px;

        > .area-drop {
          border: 10px dashed ${theme('colors.primary')};
          height: 100%;
          border-radius: 6px;
          font-size: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          color: ${theme('colors.primary')};
        }

        &.dragging {
          z-index: 1;
        }
      }

      .button-group {
        display: flex;
        justify-content: end;
        align-items: center;

        .cancel {
          font-size: 14px;
          font-weight: bold;
          color: #c1c3ca;
          cursor: pointer;
        }

        button {
          width: 105px;
          margin-left: 5px;
        }
      }
    }

    .form > .detail,
    > .activity-form {
      display: grid;
      grid-template-columns: 100%;
      grid-gap: 30px;

      padding: 20px 0;

      @media (max-width: ${theme('breakpoints.mobile')}px) {
        grid-template-columns: 100%;
      }
    }

    .subtask-list {
      margin-bottom: 20px;

      > .subtask {
        margin-bottom: 10px;
      }
    }

    .wrapper-ticket-action {
      display: flex;

      .btn-action {
        width: 32px;
        height: 32px;
        margin: 0 10px 0 0;
        padding: 6px;
        border-radius: 6px;
        border: solid 1px #c6d5d5;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  `
);
