import React from 'react';
import styled from 'styled-components';

export const SearchInput = styled(
  React.forwardRef(
    ({ className, inputClassName, onKeyDown, ...props }, ref) => {
      return (
        <div className={className}>
          <input
            ref={ref}
            className="input-search"
            type="search"
            onKeyDown={onKeyDown}
            {...props}
          />
        </div>
      );
    }
  )
)`
  position: relative;
  width: 100%;

  .input-search {
    border: unset;
    width: 100%;
    color: #3a3a3a;

    &::placeholder {
      color: #3a3a3a;
    }
  }

  input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }
`;
