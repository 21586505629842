import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from '@reach/accordion';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { ArrowUpIcon, CirclePlusIcon } from '../Icons';
import { TextFieldView } from '../../features/authentication/components/TextField';
import { PermissionGuard } from '../../features/authentication/hocs/withPermission';

function BaseFilter({
  className,
  title,
  query,
  onSearch,
  onSelectAll,
  children,
  onAdd,
  addPermission = '',
  noSearch = false,
  isLoading = false,
}) {
  return (
    <AccordionItem className={className}>
      <div className="header">
        <h5 className="title">{title}</h5>
        <div className="action">
          <PermissionGuard allow={addPermission}>
            <button type="button" className="add" onClick={onAdd}>
              <CirclePlusIcon color="#22c759" />
            </button>
          </PermissionGuard>
          <AccordionButton className="toggle">
            <ArrowUpIcon color="#3a3a3a" />
          </AccordionButton>
        </div>
      </div>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <AccordionPanel>
          <div className="form">
            {noSearch ? (
              <PermissionGuard allow={addPermission}>
                <div className="wrapper-empty-items">
                  <div>
                    Don’t have any {title.toLowerCase().slice(0, -1)} yet.
                  </div>
                  <div onClick={onAdd}>
                    Create new {title.toLowerCase().slice(0, -1)}
                  </div>
                </div>
              </PermissionGuard>
            ) : (
              <>
                <TextFieldView
                  type="search"
                  name="query"
                  placeholder={`Search ${title}`}
                  value={query}
                  onChange={e => onSearch(e.target.value)}
                />
                <div className="all" onClick={onSelectAll}>
                  Select all
                </div>
              </>
            )}
          </div>
          <div className="filter-list">{children}</div>
        </AccordionPanel>
      )}
    </AccordionItem>
  );
}

const StyledBaseFilter = styled(BaseFilter)`
  padding: 10px 20px;

  &::after {
    content: ' ';

    position: relative;
    bottom: -8px;

    display: block;

    border-bottom: 2px dashed #eee;
  }

  .form {
    padding: 10px 0;

    ${TextFieldView} {
      margin: 0;

      input {
        height: 32px;
        font-size: 0.875rem;
      }
    }

    ${TextFieldView} > input {
      box-shadow: none;
    }

    .all {
      font-size: 0.75rem;
      color: #9fa3ae;
      text-decoration: underline;
      margin-top: 10px;
      cursor: pointer;
    }
  }

  > .header {
    display: flex;
    align-items: center;
    margin-right: -8px;

    > * {
      font-size: 14px;
    }

    button {
      border: 0;

      background: transparent;

      cursor: pointer;
    }

    .action {
      margin-left: auto;

      .add {
        color: #ddd;
      }

      .add,
      .toggle {
        padding: 5px 8px;
      }

      .toggle {
        color: ${theme('colors.primary')};
      }
    }
  }

  [data-state='collapsed'] {
    .toggle {
      transform: rotateZ(180deg);
    }
  }

  .filter-list {
    padding-bottom: 10px;
  }

  .wrapper-empty-items {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 0.75rem;
    margin: 20px 0;

    > div:first-child {
      color: #9fa3ae;
    }

    > div:nth-child(2) {
      color: ${theme('colors.primary')};
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const MemoBaseFilter = React.memo(StyledBaseFilter);

export { MemoBaseFilter as BaseFilter };
