import React, { useRef, useState } from 'react';
import { Menu, MenuButton, MenuPopover, MenuItem } from '@reach/menu-button';
import { Waypoint } from 'react-waypoint';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { useNavigate } from 'react-router-dom';

import { Avatar } from './Avatar';
import {
  NotificationIcon,
  CancelIcon,
  ChevronLeft,
  AllCheckIcon,
} from './Icons';
import { LoadingIndicator } from './LoadingIndicator';
import { useNotificationList } from '../features/projects/useNotificationList';

const NotificationPopover = styled(MenuPopover)`
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.15);
  width: 350px;
  height: 100vh;
  padding-bottom: 40px;
  left: 50px;
  bottom: 0;

  > .close-btn {
    position: absolute;
    right: 20px;
    top: 20px;
    > ${CancelIcon} {
      width: 24px;
      height: 24px;
    }
  }

  > .mobile-close-btn {
    display: none;
    position: absolute;
    left: 20px;
    top: 18px;
    > ${ChevronLeft} {
      width: 24px;
      height: 24px;
    }
  }

  > h1 {
    font-size: 20px;
    font-weight: bold;
    padding: 20px;
  }

  > .actions {
    font-size: 12px;
    color: ${theme('colors.primary')};
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-bottom: 20px;

    > div {
      cursor: pointer;

      &.filter {
        font-weight: bold;
        text-decoration: underline;
      }

      &.divider {
        font-weight: bold;
        color: #dce0ea;
      }

      > svg {
        width: 1em;
        height: 1em;
      }
    }
  }

  > .noti-list {
    height: calc(100% - 65px);
    overflow-y: scroll;

    &.empty {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
    }

    > div {
      display: flex;
      position: relative;
      padding: 15px 20px;
      background-color: #ffffff;

      &:hover {
        color: currentColor;
        background: transparent;
      }

      &.unread {
        background-color: #f5f8f8;
      }

      > .content {
        font-size: 12px;
        margin-left: 10px;
        padding-right: 12px;
        white-space: pre-wrap;
        width: calc(100% - 44px);

        > .title {
          line-height: 1.5;
        }

        > .sub-title {
          margin-top: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        > .datetime {
          color: #999;
          margin-top: 10px;
        }
      }
    }
  }

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    left: 0;
    width: 100vw;
    bottom: 60px;
    height: calc(100vh - 60px);

    > h1 {
      padding-left: 60px;
      background-color: #3a3a3a;
      color: #ffffff;
    }

    > .noti-list {
      height: calc(100% - 75px);
      overflow-y: scroll;
    }

    > .close-btn {
      display: none;
    }

    > .mobile-close-btn {
      display: block;
    }

    > .actions {
      font-size: 14px;
      margin-top: 20px;
      justify-content: start;
      margin-left: 20px;
    }
  }
`;

function Notification({ className }) {
  const navigate = useNavigate();
  const [notiStatus, setNotiStatus] = useState('unread');
  const notifications = useNotificationList(notiStatus);
  const notiRef = useRef();

  const isNotiEmpty = notifications.data.length === 0;
  const notiUnreadMode = notiStatus === 'unread';
  const notiFilterText = notiUnreadMode ? 'View All' : 'Filter by Unread';
  const notiEmptyText = notiUnreadMode
    ? 'No unread notifications'
    : 'No notifications';

  const onSelectNotification = noti => {
    navigate(noti.to);
    noti.read();
  };

  const closePopover = () => {
    notiRef.current.hidden = true;
  };

  return (
    <div className={className}>
      <Menu>
        <MenuButton className="notification">
          {notifications.unreadCount > 0 && (
            <div className="unread-count">
              {notifications.unreadCount > 9 ? '9+' : notifications.unreadCount}
            </div>
          )}

          <NotificationIcon />
        </MenuButton>
        <NotificationPopover portal={false} ref={notiRef}>
          <div
            className="mobile-close-btn"
            onClick={e => {
              closePopover(e);
            }}
          >
            <ChevronLeft color="#ffffff" />
          </div>
          <h1>Notifications</h1>
          <div
            className="close-btn"
            onClick={e => {
              closePopover(e);
            }}
          >
            <CancelIcon color="#c1c3ca" />
          </div>
          <div className="actions">
            <div
              className="filter"
              onClick={() => setNotiStatus(notiUnreadMode ? '' : 'unread')}
            >
              {notiFilterText}
            </div>
            <div className="divider">|</div>
            <div onClick={() => notifications.readAll()}>
              <AllCheckIcon /> Mark all as read
            </div>
          </div>
          <div className={`noti-list ${isNotiEmpty ? 'empty' : ''}`}>
            {notifications.status === 'loading' ? (
              <LoadingIndicator />
            ) : isNotiEmpty ? (
              <div>{notiEmptyText}</div>
            ) : (
              <>
                {notifications.data.map(noti => (
                  <MenuItem
                    key={noti.id}
                    className={`${noti.status === 'unread' ? 'unread' : ''}`}
                    onSelect={() => onSelectNotification(noti)}
                  >
                    <Avatar
                      name={noti.actor.name}
                      initial={noti.actor.initial}
                      avatarUrl={noti.actor.avatarUrl}
                      noTooltip
                    />
                    <div className="content">
                      <div className="title">
                        <b>{noti.actor.name}</b> {noti.title}
                      </div>
                      {noti.subTitle ? (
                        <div className="sub-title">{noti.subTitle}</div>
                      ) : null}
                      <div className="datetime">
                        {DateTime.fromISO(noti.createdAt).toFormat(
                          "dd/MM/yyyy 'at' HH:mm"
                        )}
                      </div>
                    </div>
                  </MenuItem>
                ))}
                {notifications.isFetching && <LoadingIndicator size="sm" />}
                {!notifications.isFetching && notifications.canFetchMore && (
                  <Waypoint onEnter={() => notifications.fetchMore()} />
                )}
              </>
            )}
          </div>
        </NotificationPopover>
      </Menu>
    </div>
  );
}

const StyledNotification = styled(Notification)`
  > .notification {
    color: white;
    background: transparent;
    width: 100%;
    position: relative;
    border: none;
    padding: 15px 0 5px;

    &:focus {
      outline: none;
    }

    > .unread-count {
      font-size: 10px;
      font-weight: 500;
      line-height: 1;
      background-color: ${theme('colors.error')};
      border-radius: 50%;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 16px;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }

    > svg {
      width: 100%;
      height: 24px;
    }
  }

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    padding: 0 12px;

    > .notification {
      border: none;
      padding: 0;
      width: 16px;
      color: black;

      > .unread-count {
        bottom: 0;
        top: unset;
        right: -8px;
        color: white;
      }
    }
  }
`;

export { StyledNotification as Notification };
