import styled from 'styled-components';
import { toast } from 'react-toastify';
import React from 'react';
import { AlertIcon } from '../components/Icons';

const SuccessToast = styled.div`
  display: flex;
  align-items: strench;

  > .icon {
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #1ccc56;

    background: #1ccc5622;
  }

  > .text {
    padding: 20px;

    > .title {
      margin-bottom: 8px;

      color: #333;
      font-size: 14px;
      font-weight: bold;
    }

    > .message {
      color: #999;
      font-size: 12px;
    }
  }
`;

export function successToast({ title = 'Success', message }) {
  toast(
    <SuccessToast>
      <div className="icon">
        <AlertIcon size={32} />
      </div>
      <div className="text">
        <div className="title">{title}</div>
        <div className="message">{message}</div>
      </div>
    </SuccessToast>
  );
}

const ErrorToast = styled.div`
  display: flex;
  align-items: strench;

  > .icon {
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;

    color: white;

    background: red;
  }

  > .text {
    padding: 20px;

    > .title {
      margin-bottom: 8px;

      color: red;
      font-size: 14px;
      font-weight: bold;
    }

    > .message {
      color: red;
      font-size: 12px;
    }
  }
`;

export function errorToast({ title = 'Error', message }) {
  toast(
    <ErrorToast>
      <div className="icon">
        <AlertIcon size={32} />
      </div>
      <div className="text">
        <div className="title">{title}</div>
        <div className="message">{message}</div>
      </div>
    </ErrorToast>
  );
}

const WarningToast = styled.div`
  display: flex;
  align-items: strench;

  > .icon {
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;

    color: white;

    background: #e8bf13;
  }

  > .text {
    padding: 20px;

    > .title {
      margin-bottom: 8px;

      color: #e8bf13;
      font-size: 14px;
      font-weight: bold;
    }

    > .message {
      color: #e8bf13;
      font-size: 12px;
    }
  }
`;

export function warningToast({ title = 'Warning', message }) {
  toast(
    <WarningToast>
      <div className="icon">
        <AlertIcon size={32} />
      </div>
      <div className="text">
        <div className="title">{title}</div>
        <div className="message">{message}</div>
      </div>
    </WarningToast>
  );
}
