import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { DateTime } from 'luxon';
import { useQuery, useQueryCache } from 'react-query';
import * as clipboard from 'clipboard-polyfill/text';
import { useQueryParams, useDebounce } from 'lib/hooks';
import { formatDate, formatDateTime } from 'lib/utils';
import { successToast } from 'lib/toast';
import ProjectContext from 'store/projectContext';
import GlobalContext from 'store/globalContext';

import {
  useAllCalendarTickets,
  useProjectListOption,
  useTicketAction,
  // useAllProjectTickets,
} from 'features/projects/pages/ProjectListPage/components/Ticket/hook';
import { transformMember } from 'features/projects/useProfile';
import { useAppClient } from 'lib/AppProvider';

export function withTicket(Component) {
  function WithTicket({
    onArchive: onHandleArchive,
    onDuplicate: onHandleDuplicate,
    ...props
  }) {
    const [queryParams, setQueryParams] = useQueryParams();
    const queryCache = useQueryCache();
    const [projectId, setProjectId] = useState(queryParams.projectId || null);
    const [selectProjectId, setSelectedProjectId] = useState(null);
    const [ticketId, setTicketId] = useState(null);
    const [hasRecurrence, setHasRecurrence] = useState(false);
    const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [view, setView] = useState(queryParams.view ?? 'timeGridWeek');
    const { duplicate, archive, changeDueDate } = useTicketAction();
    const { projectState, projectDispatch } = useContext(ProjectContext);
    const memoQuery = useDebounce(projectId, 1000);
    const { globalState } = useContext(GlobalContext);
    const client = useAppClient();
    const { profile } = globalState;

    const { isLoading: memberLoading } = useQuery(
      [projectId, 'onSelectTicket', 'memberResponse'],
      () => client.listProjectMember({ projectId }),
      {
        onSuccess: res => {
          projectDispatch({
            type: 'ADD_MEMBER',
            membersData: res.data.map(transformMember),
          });

          return res.data.map(transformMember);
        },
        enabled: !!projectId,
        refetchOnWindowFocus: false,
      }
    );
    const { isLoading: tagLoading } = useQuery(
      [projectId, 'onSelectTicket', 'tagResponse'],
      () => client.listProjectTag(projectId),
      {
        onSuccess: res => {
          projectDispatch({
            type: 'ADD_TAG',
            tagsData: res.data,
          });
          return res.data;
        },
        enabled: !!projectId,
        refetchOnWindowFocus: false,
      }
    );
    const { isLoading: projectLoading } = useQuery(
      [projectId, 'onSelectTicket', 'projectResponse'],
      () => client.getProject(projectId),
      {
        onSuccess: res => {
          const now = DateTime.local().startOf('day');
          const epics =
            res?.epics.map(epic => ({
              id: epic.id,
              name: epic.name,
              color: epic.color,
            })) ?? [];
          const sprints = res?.sprints.map(sprint => ({
            id: sprint.id,
            name: sprint.name,
            goal: sprint.goal,
            color: sprint.color,
            range: {
              start: DateTime.fromFormat(sprint.startDate, 'yyyy-MM-dd'),
              end: DateTime.fromFormat(sprint.endDate, 'yyyy-MM-dd'),
            },
            onSprint:
              DateTime.fromISO(sprint.startDate).startOf('day') <= now &&
              DateTime.fromISO(sprint.endDate).startOf('day') >= now,
          }));
          projectDispatch({
            type: 'ADD_EPIC_SPRINT',
            epicData: epics,
            sprintData: sprints,
          });
          return res;
        },
        enabled: !!projectId,
        refetchOnWindowFocus: false,
      }
    );

    const {
      data: calendarTickets,
      loading: calendarLoading,
      refetchCalendar,
    } = useAllCalendarTickets({
      projectId,
      startDate: startDate ?? queryParams.start,
      endDate: endDate ?? queryParams.end,
      query: queryParams.q,
      projectIds: queryParams.projectIds,
      userId: queryParams.memberId,
      view,
    });

    const { projectList } = useProjectListOption({
      userId: queryParams.memberId,
    });

    // const {
    //   allProjectTickets: projects,
    //   loading: ticketLoading,
    //   refetchAllTicket,
    // } = useAllProjectTickets({
    //   query: queryParams.q,
    //   projectIds: queryParams.projectIds,
    // });
    //
    // const displayedProjects = useMemo(() => {
    //   return queryParams?.projectIds
    //     ? projects.filter(e => queryParams.projectIds.includes(e.id))
    //     : projects;
    //   // eslint-disable-next-line
    // }, [queryParams?.projectIds, projects]);
    //
    // const totalNumOfStories = displayedProjects.reduce(
    //   (sum, project) => sum + project?.stories?.length,
    //   0
    // );
    //
    //
    // const totalMinutes = displayedProjects.reduce((acc1, project) => {
    //   return (
    //     acc1 +
    //     project.stories?.reduce((acc2, story) => {
    //       return acc2 + story.userEstimate;
    //     }, 0)
    //   );
    // }, 0);

    const isMemberFilter = useMemo(
      () => queryParams?.memberId && profile?.id !== queryParams?.memberId,
      // eslint-disable-next-line
      [queryParams?.memberId, profile]
    );

    const projectOptions = projectList?.map(project => ({
      value: project.id,
      label: project.name,
    }));

    useEffect(() => {
      if (projectState.hasRemove) {
        onRefetchAfterRemove();
        projectDispatch({
          type: 'ALL_REMOVE',
          hasRemove: false,
        });
      }
      // eslint-disable-next-line
    }, [projectState.hasRemove]);

    useEffect(() => {
      if (projectState.hasDuplicate) {
        onRefetchAfterDuplicate();
        projectDispatch({
          type: 'ALL_DUPLICATE',
          hasDuplicate: false,
        });
      }
      // eslint-disable-next-line
    }, [projectState.hasDuplicate]);

    useEffect(() => {
      if ((startDate || endDate) && !queryParams.selectedTicketId) {
        setQueryParams({
          projectIds: Array.isArray(memoQuery)
            ? memoQuery?.join(',') || null
            : memoQuery || null,
        });
      }
      // eslint-disable-next-line
    }, [memoQuery]);

    function onSelectedEvent(isRecurrence) {
      setHasRecurrence(isRecurrence);
    }

    function onDelete(id, idProject) {
      setTicketId(id);
      setSelectedProjectId(idProject);
      setIsShowDeleteModal(true);
    }

    function onCloseDeleteModal() {
      setIsShowDeleteModal(false);
    }

    function onDeleteTicket(deleteType) {
      setIsShowDeleteModal(false);
      onArchive(ticketId, deleteType);
    }

    function onCopyLink(idTicket, idEachGroup, idProject) {
      clipboard.writeText(
        `${window.location.href}&selectedIdEachGroup=${idEachGroup}&selectedTicketId=${idTicket}&projectId=${idProject}`
      );
      successToast({
        message: 'The ticket link has been successfully copied.',
      });
    }

    async function onArchive(id, deleteType, idProject) {
      await archive(id, {
        projectId: idProject || selectProjectId,
        deleteType,
      });
      await onRefetchAfterRemove();
      await invalidateSidebar();
      document.dispatchEvent(new Event('resetSidebarToDefault'));
      successToast({ message: 'The ticket has been archived.' });
    }

    async function onDuplicate(idTicket, idProject) {
      await duplicate(idTicket, { projectId: idProject });
      await onRefetchAfterDuplicate();
      await invalidateSidebar();
      document.dispatchEvent(new Event('resetSidebarToDefault'));
      successToast({ message: 'The ticket has been duplicated.' });
    }

    const onChangeDueDate = useCallback(async changeInfo => {
      const { endStr, startStr, extendedProps, id, allDay } = changeInfo.event;

      const start = formatDate(new Date(startStr), 'yyyy-LL-dd HH:mm', 0);
      const startPlus = DateTime.fromISO(new Date(start).toISOString()).plus({
        hours: 1,
      });

      let estimateTime = 0;
      let estimateTimeMinute = 0;
      let selfEstimateTime = 0;
      let selfEstimateTimeMinute = 0;
      const members = extendedProps?.members ?? [];

      if (allDay) {
        estimateTime = 8 * members.length;
      } else {
        const startTime = DateTime.fromISO(startStr);
        const endTime = endStr ? DateTime.fromISO(endStr) : startPlus;

        const timeDiff = endTime
          .diff(startTime, ['hours', 'minutes'])
          .toObject();
        if (timeDiff.hours) {
          selfEstimateTime = timeDiff.hours;
          estimateTime = timeDiff.hours * members.length;
        }
        if (timeDiff.minutes) {
          selfEstimateTimeMinute = timeDiff.minutes;

          const minutes = timeDiff.minutes * members.length;
          estimateTimeMinute = minutes % 60;
          estimateTime += Math.trunc(minutes / 60);
        }
      }

      await changeDueDate({
        projectId: extendedProps.projectId,
        ticketId: id,
        date: startStr,
        start: startStr,
        end: endStr || startPlus,
        estimateTime,
        estimateTimeMinute,
        hasSubtask: extendedProps.hasSubtask,
        allDay,
      });
      await onRefetchAfterDuplicate();

      document.dispatchEvent(
        new CustomEvent('updateTicketAfterDrag', {
          detail: {
            id,
            date: startStr,
            estimateTime: selfEstimateTime,
            estimateTimeMinute: selfEstimateTimeMinute,
            hasSubtask: extendedProps.hasSubtask,
            allDay,
          },
        })
      );

      successToast({ message: 'The ticket has been updated.' });
      // eslint-disable-next-line
    }, []);

    async function refetch() {
      refetchCalendar();
    }

    async function invalidateSidebar() {
      queryCache.invalidateQueries(({ queryKey }) => {
        return queryKey.join('/').startsWith(`projectList`);
      });
    }

    async function onRefetchAfterDuplicate() {
      await refetch();
      onHandleDuplicate();
    }

    async function onRefetchAfterRemove() {
      await refetch();
      setQueryParams({
        selectedTicketId: null,
        selectedIdEachGroup: null,
        projectId: null,
      });
      onHandleArchive();
    }

    function onChangeView(selected) {
      setView(selected);
      setQueryParams({ view: selected, start: null, end: null });
    }

    function onChangeDateFilter(value) {
      setQueryParams({ startFilter: value ? formatDateTime(value) : null });
    }

    function onClose() {
      if (queryParams.selectedTicketId) {
        setQueryParams({
          selectedTicketId: null,
          selectedIdEachGroup: null,
          projectId: null,
        });

        invalidateSidebar();
        document.dispatchEvent(new Event('resetSidebarToDefault'));
      }
    }

    function onChangeProject(p) {
      setProjectId(p);
    }

    function onChangeDate(start, end) {
      const sd = formatDateTime(start);
      const ed = formatDateTime(end);
      setStartDate(sd);
      setEndDate(ed);
      setQueryParams({
        start: sd,
        end: ed,
      });
    }

    async function onSelectTicket(selectedTicketId, selectedIdEachGroup, id) {
      setProjectId(id);
      setQueryParams({
        selectedTicketId,
        selectedIdEachGroup,
        projectId: id,
      });
    }

    async function onResetFilterParams() {
      await setQueryParams({
        start: null,
        end: null,
        startFilter: null,
        endFilter: null,
        projectIds: null,
        memberId: null,
        view: null,
      });

      await refetch();
    }

    const newProps = {
      calendarLoading,
      events: calendarTickets,
      onSelectTicket,
      onClose,
      queryParams,
      projectId: projectId || queryParams.projectId,
      onChangeProject,
      onChangeDate,
      onChangeView,
      onCopyLink,
      onDelete,
      onDuplicate,
      isShowDeleteModal,
      onCloseDeleteModal,
      onDeleteTicket,
      onArchive,
      onSelectedEvent,
      onChangeDateFilter,
      onResetFilterParams,
      refetch,
      hasRecurrence,
      // ticketLoading,
      // displayedProjects,
      // totalNumOfStories,
      // totalMinutes,
      projectOptions,
      isMemberFilter,
      onChangeDueDate,
      memberLoading,
      tagLoading,
      projectLoading,
      ...props,
    };
    return <Component {...newProps} />;
  }

  return WithTicket;
}
