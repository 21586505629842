import useSWR from 'swr';
import { useContext } from 'react';
import GlobalContext from '../../store/globalContext';
import { useAppClient } from '../../lib/AppProvider';
import { useCurrentCompany } from './useCurrentCompany';

export function useProfile() {
  const client = useAppClient();
  const { update } = useProfileAction();
  const { data: currentCompany } = useCurrentCompany();
  const { globalState, globalDispatch } = useContext(GlobalContext);

  const { data, revalidate, mutate } = useSWR(
    ['profile', currentCompany?.id],
    () => client.getProfile().then(transformMember),
    { revalidateOnFocus: false }
  );

  return {
    data,
    revalidate,
    async update({
      firstName = '',
      lastName = '',
      nickName = '',
      avatarFile,
      hasImageDelete = false,
    }) {
      await mutate(
        profile => ({
          ...profile,
          firstName,
          lastName,
          nickName,
          avatarUrl: avatarFile
            ? URL.createObjectURL(avatarFile)
            : profile.avatarUrl,
          hasImageDelete,
        }),
        false
      );
      await update({
        firstName,
        lastName,
        nickName,
        avatarFile,
        hasImageDelete,
      });
      await revalidate();
      globalDispatch({
        type: 'UPDATE_PROFILE',
        form: {
          firstName,
          lastName,
          nickName,
          name: `${firstName ?? ''} ${lastName ?? ''}`,
          initial:
            firstName && lastName
              ? `${firstName.substr(0, 1)}${lastName.substr(0, 1)}`
              : 'NA',
          avatarUrl: avatarFile
            ? URL.createObjectURL(avatarFile)
            : globalState.profile?.avatarUrl,
        },
      });
    },
  };
}

export function useProfileAction() {
  const client = useAppClient();

  return {
    async update({
      firstName,
      lastName,
      nickName,
      avatarFile,
      hasImageDelete,
    }) {
      await client.updateAccount({
        firstName,
        lastName,
        nickName,
        avatarFile,
        hasImageDelete,
      });
    },
  };
}

export function transformMember(member) {
  return {
    id: member.id,
    name: member.fullName ?? 'N/A',
    firstName: member.firstName ?? '',
    lastName: member.lastName ?? '',
    avatarUrl: member.avatarUrl,
    initial:
      member.firstName && member.lastName
        ? `${member.firstName.substr(0, 1)}${member.lastName.substr(0, 1)}`
        : 'NA',
    nickName: member.nickName ?? '',
    email: member.email,
    roleId: member.roleInSelectedCompany ?? 'guest',
  };
}
