import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import GlobalContext from '../../../../store/globalContext';
import { DocumentTitle } from '../../../../components/DocumentTitle';
import {
  Button,
  OutlineButton,
} from '../../../authentication/components/Button';
import { SearchIcon } from '../../../../components/Icons';
import { useAppLayout } from '../../../../components/withAppLayout';
import { LoadingIndicator } from '../../../../components/LoadingIndicator';
import { TextFieldView } from '../../../authentication/components/TextField';
import { SalaryListBoard } from '../../components/SalaryListBoard';
import { CompanySidebar } from '../../../../components/CompanySidebar';

export const CompanySalariesPage = styled(
  ({
    className,
    loading,
    salaries = [],
    company,
    deleteSalary,
    onChangeQuery,
    reloadAllSalaries,
    query,
  }) => {
    const [editSalary, setEditSalary] = useState(false);
    const [showMobileSearch, setShowMobileSearch] = useState(false);
    const { isShowSidebar: isShowSidebarDesktop } = useAppLayout();
    const { globalState } = useContext(GlobalContext);

    useEffect(() => {
      if (globalState && globalState.searchEmployee) {
        setShowMobileSearch(true);
      } else {
        setShowMobileSearch(false);
      }
    }, [globalState]);

    return (
      <section className={className}>
        <DocumentTitle title="Employee Salaries" />
        <CompanySidebar className={className} company={company} />
        <div
          className={`content ${
            isShowSidebarDesktop ? 'show-sidebar-desktop' : ''
          }`}
        >
          <div className="header">
            <h1>Employee</h1>
            <div className="search">
              <TextFieldView
                className="search-input"
                type="search"
                value={query}
                placeholder="Search by nickname or full name"
                onChange={e => onChangeQuery(e.target.value)}
              />
              <SearchIcon size={24} />
            </div>
            <Button
              className="button"
              type="button"
              onClick={() => setEditSalary(true)}
            >
              New Employee
            </Button>
          </div>

          {showMobileSearch && (
            <div className="search-mobile">
              <TextFieldView
                className="search-input"
                type="search"
                value={query}
                placeholder="Search by nickname or full name"
                onChange={e => onChangeQuery(e.target.value)}
              />
            </div>
          )}
          <section className="salary-table">
            {loading ? (
              <LoadingIndicator className="loading" color="primary" />
            ) : (
              <>
                <SalaryListBoard
                  className="board"
                  color="#22c759"
                  companyId={company?.id}
                  salaries={salaries ?? []}
                  editSalary={editSalary}
                  setEditSalary={setEditSalary}
                  onDeleteSalary={deleteSalary}
                  reloadSalaries={reloadAllSalaries}
                />
              </>
            )}
          </section>
        </div>
      </section>
    );
  }
)`
  .flex {
    display: flex;
  }

  > .content {
    display: flex;
    flex-direction: column;
    width: calc(100vw - 50px);

    flex-grow: 1;
    transition: all 0.2s ease-in-out;

    &.width-100 {
      width: 100%;
      overflow: auto;
    }

    @media (max-width: ${theme('breakpoints.mobile')}px) {
      padding-left: 20px !important;
    }

    &.show-sidebar-desktop {
      padding-left: ${theme('sidebarWidth')}px;

      > .header {
        width: 100%;
      }
    }

    @media (max-width: ${theme('breakpoints.mobile')}px) {
      &.show-sidebar-desktop {
        overflow-x: scroll;
      }
    }


    > .salary-table {
      border-radius: 5px;
      margin: 20px;

      > .query-row {
        padding: 10px;
        display flex;

        > .total-cost {
          display: flex;
          width: 60%;
          padding: 10px;
          height: 45px;
          background-color: #f5f8f8;
          justify-content: space-between;
          font-size: 16px;
          font-weight: bold;

          > .amount {
            margin-top: 5px;
            color: #22c759;
          }

          span {
            margin-left: 5px;
            margin-top: 5px;
            color: #c1c3ca;
          }
        }

        > ${TextFieldView} {
          margin-left: 5px;
          margin-top: 2px;
          width: 40%;
        }
      }
    }

    @media (max-width: ${theme('breakpoints.mobile')}px) {
      > .salary-table {
        margin: 0px;
        width: 100vw;
      }
    }

    > .search-mobile {
      position: relative;
      width: 100%;
      padding-bottom: 20px;

      > .search-input {
        margin-bottom: 0;
      }
    }

    > .header {
      display: grid;
      grid-template-areas: 'title search button';
      grid-template-columns: max-content 1fr auto;
      grid-column-gap: 20px;
      align-items: center;
      background: white;
      padding: 20px;
      width: 100%;

      > h1 {
        grid-area: title;
        font-size: 20px;
      }

      > .search {
        position: relative;
        grid-area: search;
        width: 300px;

        > .search-input {
          margin-bottom: 0;
        }

        > svg {
          position: absolute;
          right: 10px;
          top: 8px;
        }
      }

      > ${Button} {
        grid-area: button;
        height: 42px;
        margin-left: auto;

        &.export {
          grid-area: export;
        }
      }
    }

    > .filter {
      margin-top: 10px;
      display: grid;
      grid-template-columns: auto auto auto auto auto;
      grid-column-gap: 20px;

      > h1 {
        grid-area: title;
      }

      > ${TextFieldView} {
        padding-top: 22px;
      }

      > ${OutlineButton} {
        margin-top: 22px;
        height: 38px;
      }
    }

    > .title {
      margin-top: 20px;
    }

    > .grid {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 20px;
      width: 100%;
      margin: 20px auto;
    }

    > .pagination {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .new-project-btn-mobile {
    display: none;
  }

  &.loading {
    height: 100vh;
    margin-top: 20px;
  }

  @media (max-width: ${theme('breakpoints.tablet')}px) {
    > .content {
      > .header {
        grid-template-areas:
          'title button'
          'search';
        grid-template-columns: auto auto;
        grid-row-gap: 10px;
        grid-column-gap: 0;
      }

      > .grid {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    padding-bottom: 160px;
    padding-top: 60px;

    &.no-project {
      padding-bottom: 0;
    }

    > .content {
      padding: 20px;
      width: 100%;

      > .header {
        grid-template-areas: unset;
        grid-template-columns: unset;
        position: fixed;
        bottom: 60px;
        left: 0;

        > .search {
          display: none;
        }

        > h1 {
          display: none;
        }
        > .button {
          width: 100%;
          grid-area: unset;
          height: 46px;
        }
      }

      > .grid {
        grid-template-columns: auto;
      }
    }
    > .new-project-btn-mobile {
      display: flex;
      position: fixed;
      bottom: 60px;
      left: 0;
      width: 100%;
      height: 86px;
      background: white;
      align-items: center;
      justify-content: center;
      padding: 20px 15px;

      > .button {
        width: 100%;
        height: 46px;
      }
    }
  }
`;
