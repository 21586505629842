import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DateTime, Duration } from 'luxon';

export function useQueryParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const query = useMemo(
    () =>
      Array.from(searchParams.keys()).reduce(
        (memo, key) => ({ ...memo, [key]: searchParams.get(key) }),
        {}
      ),
    [searchParams]
  );

  const setQuery = useCallback(
    newQuery => {
      Object.keys(newQuery).forEach(key => {
        if (Object.hasOwnProperty.call(newQuery, key)) {
          if (newQuery[key] === null || newQuery[key] === '') {
            searchParams.delete(key);
            return;
          }
          searchParams.set(key, newQuery[key]);
        }
      });

      setSearchParams(searchParams, { replace: true });
    },
    [searchParams, setSearchParams]
  );

  return [query, setQuery];
}

export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

export function useCountUp(startedTime) {
  const [duration, setDuration] = useState(Duration.fromObject({}));

  useEffect(() => {
    if (!startedTime || startedTime.invalid) {
      setDuration(Duration.fromObject({}));
      return;
    }

    setDuration(DateTime.utc().diff(startedTime));

    const id = setInterval(() => {
      setDuration(DateTime.utc().diff(startedTime));
    }, 1000);

    return () => clearInterval(id);
  }, [startedTime]);

  return duration;
}

export function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = event => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}

export const useMutiSelectDropDown = (initialDefaultOpen = false) => {
  const dropdownRef = useRef(null);
  const [isShowDropdown, setIsShowDropdown] = useState(initialDefaultOpen);

  const toggleDropdown = () => {
    setIsShowDropdown(!isShowDropdown);
  };
  useEffect(() => {
    const pageClickEvent = e => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsShowDropdown(false);
      }
    };
    if (isShowDropdown) {
      window.addEventListener('click', pageClickEvent);
    }
    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [isShowDropdown]);

  return { dropdownRef, isShowDropdown, toggleDropdown };
};

export const useDropdown = (initialDefaultOpen = false) => {
  const dropdownRef = useRef(null);
  const [isShowDropdown, setIsShowDropdown] = useState(initialDefaultOpen);

  const toggleDropdown = () => {
    setIsShowDropdown(!isShowDropdown);
  };

  useEffect(() => {
    const pageClickEvent = () => {
      if (dropdownRef.current !== null) {
        setIsShowDropdown(!isShowDropdown);
      }
    };
    if (isShowDropdown) {
      window.addEventListener('click', pageClickEvent);
    }
    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [isShowDropdown]);

  return { dropdownRef, isShowDropdown, toggleDropdown };
};
