import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { Modal } from '../../../../components/Modal';
import { Checkbox } from '../../../../components/Checkbox';
import { Button } from '../../../authentication/components/Button';

function ExportTicketsModal({
  className,
  ticketCount,
  exportPath,
  exportable,
  column_options,
  selectedColumns,
  isFiltering,
  searchKeyword,
  selectedSprints,
  selectedEpics,
  selectedTags,
  selectedMembers,
  onClose,
  onCheckColumn,
}) {
  return (
    <Modal className={className} onClose={onClose}>
      <h1 className="title">Export Tickets</h1>
      <div className="section">
        <div className="sub-title">Columns</div>
        <div className="column-list">
          {column_options.map(({ name, value }) => (
            <label key={value}>
              <Checkbox
                checked={selectedColumns.includes(value)}
                onChange={() => onCheckColumn(value)}
              />
              {name}
            </label>
          ))}
        </div>
      </div>
      {isFiltering ? (
        <div className="section">
          <div className="sub-title">Filtering</div>
          <div className="filter-list">
            {searchKeyword ? <li>Keyword: {searchKeyword}</li> : null}
            {selectedSprints.length ? (
              <li>
                Sprints: {selectedSprints.map(({ name }) => name).join(', ')}
              </li>
            ) : null}
            {selectedEpics.length ? (
              <li>Epics: {selectedEpics.map(({ name }) => name).join(', ')}</li>
            ) : null}
            {selectedTags.length ? (
              <li>Tags: {selectedTags.map(({ name }) => name).join(', ')}</li>
            ) : null}
            {selectedMembers.length ? (
              <li>
                Members: {selectedMembers.map(({ name }) => name).join(', ')}
              </li>
            ) : null}
          </div>
        </div>
      ) : null}
      <Button
        className={`button ${exportable ? '' : 'disabled'}`}
        as="a"
        href={exportPath}
        target="__blank"
        onClick={onClose}
      >
        Export {ticketCount} Tickets
      </Button>
    </Modal>
  );
}

const StyledExportTicketsModal = styled(ExportTicketsModal)`
  max-width: 600px;
  width: 90vw;

  .title {
    border-bottom: 2px solid #eee;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .section {
    margin-bottom: 20px;

    > .sub-title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 5px;
    }
  }

  .column-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    > label {
      cursor: pointer;

      > span {
        margin-right: 10px;
      }
    }

    @media (max-width: ${theme('breakpoints.mobile')}px) {
      grid-template-columns: 1fr;
    }
  }

  .filter-list {
    > li {
      margin-top: 7px;
    }
  }

  .button {
    display: block;
    text-align: center;
    margin-top: 40px;

    &.disabled {
      pointer-events: none;
      background-color: #adadad;
      border-color: #adadad;
      cursor: not-allowed;
    }
  }
`;

export { StyledExportTicketsModal as ExportTicketsModal };
