import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ifProp, switchProp } from 'styled-tools';

const Container = styled.div`
  position: fixed;
  top: ${ifProp('isShow', '0', '-30px')};
  left: 0;
  right: 0;

  padding: 7px;
  height: 30px;

  font-size: 14px;
  font-weight: bold;
  text-align: center;

  background: ${switchProp(
    'type',
    {
      success: 'rgba(104, 216, 24, 0.3)',
      error: 'rgba(222, 27, 50, 0.3)',
    },
    'transparent'
  )};

  transition: top 0.2s ease-in-out;
`;

/**
 *
 * @param {string} message
 * @param {string} [type]
 * @param {number} [duration]
 * @returns {JSX.Element}
 * @constructor
 */
export function FlashAlert({ message, duration, type }) {
  const { isShow } = useFlashAlert({ duration });

  return (
    <Container type={type} isShow={isShow}>
      {message}
    </Container>
  );
}

function useFlashAlert({ duration = 3 } = {}) {
  const [isShow, setIsShow] = useState(true);

  useEffect(() => {
    const id = setTimeout(() => {
      setIsShow(false);
    }, duration * 1000);

    return () => {
      clearTimeout(id);
    };
  }, [setIsShow, duration]);

  return {
    isShow,
  };
}
