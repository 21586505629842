import React, { useEffect } from 'react';
import { useRoutes, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { AppLayout } from './components/AppLayout';
import { withAuthenticatedGuard } from './features/authentication/hocs/withAuthenticatedGuard';
import { SettingRoutes } from './features/settings/SettingRoutes';
import { AccountRoutes } from './features/account/AccountRoutes';
import { AuthenticationRoutes } from './features/authentication/AuthenticationRoutes';
import { ProjectListPage } from './features/projects/pages/ProjectListPage';
import { ProjectSetupPage } from './features/projects/pages/ProjectSetupPage';
import { ProjectDetailPage } from './features/projects/pages/ProjectDetailPage';
import { DocumentListPage } from './features/documents/pages/DocumentListPage';
import { EpicAndSprintPage } from './features/tickets/pages/EpicAndSprintPage';
import { CurrentCompanyProvider } from './features/projects/useCurrentCompany';
import { withCurrentCompanyGuard } from './features/authentication/hocs/withCurrentCompanyGuard';
import { CompanySalariesPage } from './features/companies/pages/CompanySalariesPage';
import { CompanyExpensesPage } from './features/companies/pages/CompanyExpensesPage';
import { CompaniesListPage } from './features/companies/pages/CompaniesListPage';
import { NoCompaniesPage } from './features/companies/pages/NoCompaniesPage';
import ProjectStateProvider from './store/ProjectStateProvider';
import GlobalStateProvider from './store/GlobalStateProvider';
import { CompanyRedirect } from './components/CompanyRedirect';
import { MobileProjectCreatePage } from './features/projects/pages/MobileProjectCreatePage';
import { MobileProjectsEditPage } from './features/projects/pages/MobileProjectsEditPage';

const queryCache = new QueryCache();

const AuthenticatedAppLayout = withAuthenticatedGuard(
  withCurrentCompanyGuard(AppLayout)
);

function Redirect({ to }) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    navigate({ pathname: to, search: location.search });
  }, [to, location.search, navigate]);

  return null;
}

export default function Router() {
  const RouterComp = useRoutes([
    {
      path: 'app/set-up-account',
      element: <Redirect to="/auth/accept_invitation" />,
    },
    AuthenticationRoutes,
    AccountRoutes,
    {
      path: 'companies',
      element: (
        <ReactQueryCacheProvider queryCache={queryCache}>
          <GlobalStateProvider>
            <CurrentCompanyProvider>
              <AuthenticatedAppLayout>
                <Outlet />
              </AuthenticatedAppLayout>
            </CurrentCompanyProvider>
          </GlobalStateProvider>
        </ReactQueryCacheProvider>
      ),
      children: [{ path: '', element: <CompaniesListPage /> }],
    },
    {
      path: 'company/:companyId',
      element: (
        <ReactQueryCacheProvider queryCache={queryCache}>
          <GlobalStateProvider>
            <CurrentCompanyProvider>
              <AuthenticatedAppLayout>
                <Outlet />
              </AuthenticatedAppLayout>
            </CurrentCompanyProvider>
          </GlobalStateProvider>
        </ReactQueryCacheProvider>
      ),

      children: [
        { path: '/salaries', element: <CompanySalariesPage /> },
        { path: '/expenses', element: <CompanyExpensesPage /> },
        {
          path: '',
          element: (
            <ProjectStateProvider>
              <ProjectListPage />
            </ProjectStateProvider>
          ),
        },
        {
          path: '/projects/:id',
          element: (
            <ProjectStateProvider>
              <ProjectDetailPage />
            </ProjectStateProvider>
          ),
        },
        {
          path: '/projects/:id/documents',
          element: (
            <ProjectStateProvider>
              <DocumentListPage />
            </ProjectStateProvider>
          ),
        },
        {
          path: '/projects/:id/setup',
          element: (
            <ProjectStateProvider>
              <ProjectSetupPage />
            </ProjectStateProvider>
          ),
        },
        { path: '/epic-sprint', element: <EpicAndSprintPage /> },
        {
          path: '/project/create',
          element: (
            <ProjectStateProvider>
              <MobileProjectCreatePage />
            </ProjectStateProvider>
          ),
        },
        {
          path: '/project/:id',
          element: (
            <ProjectStateProvider>
              <MobileProjectsEditPage />
            </ProjectStateProvider>
          ),
        },
        SettingRoutes,
      ],
    },
    {
      path: 'no-companies',
      element: (
        <ReactQueryCacheProvider queryCache={queryCache}>
          <GlobalStateProvider>
            <AuthenticatedAppLayout>
              <NoCompaniesPage />
            </AuthenticatedAppLayout>
          </GlobalStateProvider>
        </ReactQueryCacheProvider>
      ),
    },
    {
      path: '*',
      element: <CompanyRedirect />,
    },
  ]);

  return (
    <React.Suspense fallback={<div>Module Lazy loading...</div>}>
      {RouterComp}
    </React.Suspense>
  );
}
