import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { theme } from 'styled-tools';

import { Logo } from '../../../../components/Logo';
import { TextField } from '../../components/TextField';
import { Button } from '../../components/Button';
import { AuthenticationLayout } from '../../components/AuthenticationLayout';
import { CheckYourEmailModal } from '../../components/CheckYourEmailModal';
import { DocumentTitle } from '../../../../components/DocumentTitle';

const initialValues = {
  email: '',
};

const validationSchema = yup.object({
  email: yup
    .string()
    .required('Email is required')
    .email('Email must be valid'),
});

function ForgotPasswordPage({
  className,
  onSubmit,
  onCloseModal,
  isShowModal,
}) {
  return (
    <AuthenticationLayout className={className}>
      <DocumentTitle title="Reset password to Toolings" showCredit={false} />
      <div className="container">
        <header className="header">
          <Logo />
        </header>
        <div className="form">
          <h4 className="title">Reset password to Toolings</h4>
          <p className="description">
            Please fill in your username and we will email you a link to reset
            your password.
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {() => (
              <Form className="forgot-form" noValidate>
                <TextField
                  className="email"
                  id="email"
                  type="email"
                  name="email"
                  label="Email"
                  autoComplete="off"
                />
                <Button className="submit" type="submit">
                  Send reset link
                </Button>
              </Form>
            )}
          </Formik>
        </div>
        <footer className="footer">
          <Link className="link" to="/auth/registration">
            Register
          </Link>
          <Link className="link" to="/auth/sign-in">
            Login
          </Link>
        </footer>
      </div>
      <CheckYourEmailModal isOpen={isShowModal} onClose={onCloseModal} />
    </AuthenticationLayout>
  );
}

const StyledForgotPasswordPage = styled(ForgotPasswordPage)`
  > .container {
    > .form {
      display: flex;
      flex-direction: column;

      > .title {
        font-size: 18px;
        margin: 0 0 10px;
      }

      > .description {
        font-size: 12px;
      }

      > .forgot-form {
        display: flex;
        flex-direction: column;
        margin: 12px 0;

        > .submit {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    > .container > .form {
      padding: 0 15px;

      > .title {
        font-size: 16px;
      }

      > .description {
        font-size: 14px;
      }

      > .forgot-form {
        margin: 20px 0;

        > .email {
          margin-bottom: 20px;
        }

        > .submit {
          font-size: 14px;
          min-height: 46px;
        }
      }
    }
  }
`;

export { StyledForgotPasswordPage as ForgotPasswordPage };
