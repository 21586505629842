import React from 'react';
import styled from 'styled-components';

function InternalError({ className }) {
  return (
    <section className={className}>
      <div className="container">
        <h1 className="title">Something went wrong</h1>
        <p className="description">
          Our team has been notified, Please wait patiently until the problem is
          fixed or <strong>Refresh the page</strong>.
        </p>
      </div>
    </section>
  );
}

const StyledInternalError = styled(InternalError)`
  display: grid;
  place-items: center;

  min-height: 100vh;

  text-align: center;
`;

export { StyledInternalError as InternalError };
