import React from 'react';
import { useField } from 'formik';
import { ColorPalette } from './ColorPalette';

function FormikColorPalette(props) {
  const [field, , helper] = useField(props.name);

  function handleChange(e) {
    helper.setValue(e.target.value);
  }

  function onCustomChange(e) {
    helper.setValue(e.target.value);
  }
  return (
    <ColorPalette
      value={field.value}
      onChange={handleChange}
      onCustomChange={onCustomChange}
      {...props}
    />
  );
}

export { FormikColorPalette as ColorPalette };
