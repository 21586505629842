import { useQuery } from 'react-query';
import { useAppClient } from 'lib/AppProvider';
import { formatDate } from 'lib/utils';

export function useAllCalendarTickets({
  view,
  projectIds,
  startDate,
  endDate,
  query,
  userId,
}) {
  const client = useAppClient();

  const { data: projectIdList } = useQuery(
    'projects',
    () =>
      client
        .getAllProjectTickets({ userId: '' })
        .then(project => project.map(({ id }) => id)),
    { refetchOnWindowFocus: false }
  );

  const { data, refetch, isLoading } = useQuery(
    [startDate, endDate, query, projectIds, userId, view],
    () =>
      client
        .getAllTicketsCalendar({
          projectIds,
          startDate,
          endDate,
          query,
          userId,
        })
        .then(transformTicketData),
    {
      enabled: !!startDate && !!endDate,
      refetchOnWindowFocus: false,
    }
  );

  return {
    loading: isLoading,
    data: transformEventTicket(data, view, projectIdList) ?? [],
    refetchCalendar: refetch,
  };
}

function transformTicketData(tickets) {
  return (
    tickets &&
    tickets.map(ticket => ({
      ...ticket,
      assignees: (ticket.storyOwners ?? []).map(owner => ({
        id: owner.id,
        name: owner.fullName,
        avatarUrl: owner.avatarUrl,
        initial: `${owner.firstName.substr(0, 1)}${owner.lastName.substr(
          0,
          1
        )}`,
      })),
    }))
  );
}

function transformEventTicket(events, view, projectIds) {
  return events?.map(ticket => ({
    id: ticket.id,
    title: `${
      view === 'dayGridMonth' && (ticket.startAt || ticket.endAt)
        ? `| ${ticket.name}`
        : ticket.name
    }`,
    date: ticket.forecastCompletionDate,
    start: ticket.startAt
      ? `${ticket.forecastCompletionDate} ${formatDate(
          new Date(ticket.startAt),
          'HH:mm'
        )}`
      : null,
    end: ticket.endAt
      ? `${ticket.forecastCompletionDate} ${formatDate(
          new Date(ticket.endAt),
          'HH:mm'
        )}`
      : null,
    color: ticket?.epicColor || ticket?.useCase?.color,
    allDay:
      view !== 'dayGridMonth'
        ? (ticket.startAt === null || ticket.endAt === null) &&
          view !== 'dayGridMonth'
        : ticket.startAt === null || ticket.endAt === null,
    display: view === 'dayGridMonth' ? 'list-item' : 'auto',
    extendedProps: {
      userCount: ticket.storyOwnerCount,
      hasSubtask: ticket.hasSubtasks,
      members: ticket.assignees,
      startDate: ticket.startAt
        ? formatDate(new Date(ticket.startAt), 'HH:mm')
        : null,
      endDate: ticket.endAt
        ? formatDate(new Date(ticket.endAt), 'HH:mm')
        : null,
      dueDate: ticket.forecastCompletionDate,
      idEachGroup: ticket.idEachGroup,
      projectId: ticket.useCase.id,
      hasRecurrence: ticket.hasRecurrence,
      hasEdit: !!projectIds && projectIds.includes(ticket.useCase.id),
    },
  }));
}
