import { Droppable } from 'react-beautiful-dnd';
import React from 'react';
import styled from 'styled-components';
import hoistNonReactStatics from 'hoist-non-react-statics/src';
import { DraggableTicket } from './Ticket';
import { PlusIcon } from '../Icons';

class InnerTicket extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.tickets === this.props.tickets) {
      return false;
    }

    return true;
  }

  render() {
    const self = this.props;
    return self.tickets?.map((ticket, index) => (
      <DraggableTicket
        onClick={() =>
          self.onSelectTicket
            ? self.onSelectTicket(
                ticket.id,
                ticket.idEachGroup,
                self.project.id
              )
            : null
        }
        key={`ticket-${ticket.id}`}
        index={index}
        color={ticket?.epicColor || self.project?.color || '#22c759'}
        projectId={self.project?.id}
        {...ticket}
      />
    ));
  }
}

const TicketList = React.forwardRef(function TicketList(
  { project, data, listId, onSelectTicket, placeholder, onAddTicket, ...props },
  ref
) {
  return (
    <div {...props} ref={ref}>
      {onAddTicket && (
        <div className="add-button" onClick={onAddTicket}>
          <PlusIcon /> Add Ticket
        </div>
      )}
      <InnerTicket
        tickets={data}
        onSelectTicket={onSelectTicket}
        project={project}
      />
      {placeholder}
    </div>
  );
});

const StyledTicketList = styled(TicketList)`
  > .add-button {
    background: #eee;
    text-align: center;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 6px;
    cursor: pointer;
  }
`;

const MemoTicketList = React.memo(StyledTicketList);

function DroppableTicketList({ listId, ...props }) {
  return (
    <Droppable droppableId={listId} type="TICKET">
      {provided => (
        <MemoTicketList
          {...provided.droppableProps}
          ref={provided.innerRef}
          placeholder={provided.placeholder}
          {...props}
        />
      )}
    </Droppable>
  );
}

hoistNonReactStatics(DroppableTicketList, StyledTicketList);

export { MemoTicketList as TicketList, DroppableTicketList };
