import React, { useEffect, useState, forwardRef } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { theme, ifProp, switchProp } from 'styled-tools';
import { DateTime } from 'luxon';
import { DatepickerIcon } from './Icons';

import 'react-datepicker/dist/react-datepicker.css';

const CustomDateInput = forwardRef(
  (
    { onClick, onBlur, startDate, dateFormat, endDate, isClearable, isOverdue },
    ref
  ) => {
    const start = startDate
      ? DateTime.fromJSDate(startDate).toFormat(dateFormat)
      : '';
    const end = endDate
      ? DateTime.fromJSDate(endDate).toFormat(dateFormat)
      : '';
    const date = `${start} - ${end}`;

    return (
      <>
        <div
          className="field"
          onClick={onClick}
          isClearable={isClearable}
          ref={ref}
          onBlur={onBlur}
        >
          {date !== ' - ' ? date : ''}
          {isOverdue && <div className="overdue">Overdue</div>}
          <DatepickerIcon className="icon" />
        </div>
      </>
    );
  }
);

export const DatePickerRange = React.memo(
  styled(function DatePickerRange({
    className,
    label,
    valueStart,
    valueEnd,
    onChange,
    showTimeSelect,
    dateFormat = 'yyyy-MM-dd',
    isClearable,
    isOverdue = false,
    ...props
  }) {
    const [startDate, setStartDate] = useState(
      valueStart ? new Date(valueStart) : null
    );

    const [endDate, setEndDate] = useState(
      valueEnd ? new Date(valueEnd) : null
    );
    const [isClose, setIsclose] = useState(false);

    const handleChange = dates => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
      setIsclose(startDate !== '' && endDate !== '');

      if (start && end) {
        onChange({
          start: DateTime.fromJSDate(start).toFormat('yyyy-MM-dd'),
          end: DateTime.fromJSDate(end).toFormat('yyyy-MM-dd'),
        });
      }

      if (start === null && end === null && valueStart && valueEnd) {
        onChange({ start: null, end: null });
      }
    };

    useEffect(() => {
      if (isClearable && valueStart === undefined && valueEnd === undefined) {
        setStartDate(valueStart);
        setEndDate(valueEnd);
      }
    }, [valueStart, valueEnd, isClearable]);

    return (
      <div className={className}>
        {label && (
          <label htmlFor={props.id} className="label">
            {label}
          </label>
        )}
        <div className="input-group">
          <DatePicker
            className="field"
            dateFormat={dateFormat}
            startDate={startDate}
            endDate={endDate}
            customInput={
              <CustomDateInput
                isClearable={isClearable}
                startDate={startDate}
                endDate={endDate}
                dateFormat={dateFormat}
                value={startDate}
                isOverdue={isOverdue}
              />
            }
            shouldCloseOnSelect={isClose}
            selectsRange
            onChange={handleChange}
            isClearable={isClearable}
          />
        </div>
      </div>
    );
  })`
    > .label {
      display: block;
      font-size: 12px;

      margin-bottom: 8px;

      color: #c1c3ca;
    }

    > .input-group {
      position: relative;

      .icon {
        color: red;
      }
      .popover {
        display: block;
      }

      .react-datepicker {
        display: flex;
      }

      .react-datepicker-wrapper {
        width: 100%;
      }

      .field {
        border-radius: 6px;
        border: 1px solid ${ifProp('error', theme('colors.error'), '#dce0ea')};
        width: 100%;
        padding: 10px ${ifProp('hasSuffix', '50px', '10px')} 10px
          ${ifProp('hasPrefix', '50px', '10px')};

        font-size: ${switchProp('size', { sm: '14px' }, 'inherit')};
        text-align: left;
        min-height: 40px;

        background: #ffffff;

        .overdue {
          font-size: 10px;
          background: red;
          width: fit-content;
          padding: 3px 10px;
          border-radius: 10px;
          color: white;
          font-weight: bold;
          margin-left: 5px;
        }

        &:focus,
        &:active {
          outline-color: ${theme('colors.primary')};
        }

        &:focus + .popover {
          display: block;
        }

        > .placeholder {
          color: #777;
        }
      }

      > select {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }

      > select:active + .field,
      > select:focus + .field {
        border-color: ${theme('colors.primary')};
      }

      svg {
        width: 14px;
        height: 14px;
        position: absolute;
        top: 13px;
        right: 15px;
        color: #000000;
      }
    }

    .react-datepicker__close-icon {
      right: 10px;

      ::after {
        background: ${theme('colors.primary')};
      }

      &:hover {
        ::after {
          background: black;
        }
      }
    }
  `
);
