import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthentication } from '../features/authentication/useAuthentication';
import { useCompanyList } from '../features/projects/useCompanyList';

export function CompanyRedirect() {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { isAuthenticated } = useAuthentication();
  const { data } = useCompanyList();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(`/auth/sign-in`, { replace: true });
      return;
    }

    if (!data) {
      return;
    }

    const company = data[0];

    if (company) {
      navigate(`/company/${company?.id}`, { replace: true });
    } else {
      navigate('no-companies');
    }
  }, [isAuthenticated, navigate, search, pathname, data]);

  return null;
}
