import { withSideBarFilter } from './withSideBarFilter';
import { SideBarFilter as PureSideBarFilter } from './SideBarFilter';
import { withProjectFilter } from './withProjectFilter';
import { ProjectFilter as PureProjectFilter } from './ProjectFilter';
import { withMemberFilter } from './withMemberFilter';
import { MemberFilter as PureMemberFilter } from './MemberFilter';
import { EpicFilter as PureEpicFilter } from './EpicFilter';
import { withEpicFilter } from './withEpicFilter';
import { SprintFilter as PureSprintFilter } from './SprintFilter';
import { withSprintFilter } from './withSprintFilter';
import { withTagFilter } from './withTagFilter';
import { TagFilter as PureTagFilter } from './TagFilter';

export { Header, BlockHeader, FilterGroup } from './SideBarFilter';
export const SideBarFilter = withSideBarFilter(PureSideBarFilter);
export const ProjectFilter = withProjectFilter(PureProjectFilter);
export const MemberFilter = withMemberFilter(PureMemberFilter);
export const EpicFilter = withEpicFilter(PureEpicFilter);
export const SprintFilter = withSprintFilter(PureSprintFilter);
export const TagFilter = withTagFilter(PureTagFilter);
