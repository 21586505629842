import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { EditIcon, WorkIcon } from '../Icons';
import { Avatar } from '../Avatar';
import { ProjectSettingModal } from '../../features/projects/components/ProjectSettingModal';

import { Indicator } from './Indicator';
import { BaseFilter } from './BaseFilter';
import { LoadingIndicator } from '../LoadingIndicator';
import { PermissionGuard } from '../../features/authentication/hocs/withPermission';

function ProjectFilter({
  className,
  projects,
  selectedIds,
  query,
  onSelect,
  onSearch,
  onSelectAll,
  onAdd,
  onCloseSettingProjectModal,
  isSettingProjectModal,
  selectedProjectId,
  onEdit,
  loading,
}) {
  const projectOptions = useMemo(
    () =>
      projects
        .map(project => {
          const isChecked = selectedIds.includes(project.id);

          return {
            rank: project.name.charCodeAt(0) + (isChecked ? 100 : 0),
            to: `./projects/${project.id}`,
            onSelect(e) {
              e.stopPropagation();

              onSelect(project.id);
            },
            avatar: {
              initial: project.name.substr(0, 2).toUpperCase(),
              name: project.name,
              avatarUrl: project.logoUrl,
            },
            isChecked,
            name: project.name,
            id: project.id,
            isShow:
              isChecked ||
              !query ||
              project.name.toLowerCase().startsWith(query.toLocaleLowerCase()),
          };
        })
        .sort((x, y) => y.rank - x.rank),
    [projects, selectedIds, query, onSelect]
  );

  return (
    <BaseFilter
      title="Projects"
      query={query}
      onSearch={onSearch}
      onSelectAll={onSelectAll}
      className={className}
      onAdd={onAdd}
      addPermission="project:write"
    >
      <>
        {!loading && <LoadingIndicator />}
        {projectOptions.map(project => (
          <Item
            key={project.id}
            onClick={project.onSelect}
            style={{ display: !project.isShow && 'none' }}
          >
            <Avatar {...project.avatar} />
            <h5 className="project">{project.name}</h5>
            <div className="actions">
              <Link
                to={project.to}
                type="button"
                className="btn"
                onClick={e => e.stopPropagation()}
              >
                <WorkIcon />
              </Link>
              <PermissionGuard allow="project:write">
                <button
                  type="button"
                  className="btn"
                  onClick={e => {
                    e.stopPropagation();

                    onEdit(project.id);
                  }}
                >
                  <EditIcon />
                </button>
              </PermissionGuard>
            </div>
            <Indicator checked={project.isChecked} onClick={project.onSelect} />
          </Item>
        ))}
        <ProjectSettingModal
          isOpen={isSettingProjectModal}
          onClose={onCloseSettingProjectModal}
          projectId={selectedProjectId}
        />
      </>
    </BaseFilter>
  );
}

const Item = styled.div`
  display: flex;
  align-items: center;

  padding: 10px 0;
  cursor: pointer;

  > .project {
    overflow: hidden;

    flex-grow: 1;
    flex-shrink: 1;

    padding: 0 10px;

    white-space: nowrap;
    text-overflow: ellipsis;
  }

  > .actions {
    display: none;
    flex-grow: 1;

    margin-left: auto;
    text-align: right;

    white-space: nowrap;

    > .btn {
      margin: 0 1px;

      border: 0;
      color: #ddd;

      background: transparent;

      cursor: pointer;

      &:hover {
        color: #aaa;
      }

      &:last-child {
        margin-right: 10px;
      }
    }
  }

  &:hover > .actions {
    display: block;
  }

  ${Indicator} {
    flex: 0 0 24px;
  }
`;

const StyledProjectFilter = styled(ProjectFilter)``;

const MemoProjectFilter = React.memo(StyledProjectFilter);

export { MemoProjectFilter as ProjectFilter };
