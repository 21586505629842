import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { theme } from 'styled-tools';

import { Modal } from '../../../components/Modal';
import CheckYourEmailImage from '../../../assets/images/check-your-email.svg';
import { Button } from './Button';

function VerifyYourEmailModal({ className, isOpen, onClose }) {
  return (
    <Modal className={className} isOpen={isOpen} onClose={onClose}>
      <img
        src={CheckYourEmailImage}
        alt="Check your email!"
        className="image"
      />
      <h4>You&apos;re almost there.</h4>
      <p>
        Please click on the verification link that has just been sent
        <br />
        to your email address to continue the registration process.
      </p>
      <Button as={Link} className="submit" type="button" to="/auth/sign-in">
        Go To Login
      </Button>
    </Modal>
  );
}

const StyledVerifyYourEmailModal = styled(VerifyYourEmailModal)`
  text-align: center;
  min-width: 554px;

  h4 {
    font-size: 20px;
    margin: 30px 0;
  }

  p {
    margin-bottom: 50px;
  }

  .submit {
    display: block;
    margin: 0 45px;
  }

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    min-width: calc(100% - 40px);
  }
`;

export { StyledVerifyYourEmailModal as VerifyYourEmailModal };
