import React, { useState } from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useMemberAction, useFindMember } from '../useMemberSearch';
import { Modal } from '../../../components/Modal';
import { TextField } from '../../authentication/components/TextField';
import { Button, OutlineButton } from '../../authentication/components/Button';
import { TrashIcon } from '../../../components/Icons';
import { useCurrentCompany } from '../../projects/useCurrentCompany';
import { SelectField } from '../../../components/Select';
import { Avatar } from '../../../components/Avatar';
import { Select } from '../../../components/Select/Select';
import { roleOptions } from '../../../lib/config';

import { successToast } from '../../../lib/toast';

const initialValues = {
  email: '',
  roleId: '',
  jobTitle: '',
};

const validationSchema = yup.object({
  email: yup
    .string()
    .required('Email is required')
    .email('Email must be valid'),
  roleId: yup.string().required('Role is required'),
});

const options = roleOptions;

function InviteNewUserModal({ className, isOpen, onClose, reload }) {
  const { data } = useCurrentCompany();
  const { invite } = useMemberAction();
  const { find } = useFindMember();
  const [list, setList] = useState([]);
  const [error, setError] = useState(false);
  const memberListTitle = `People to invite (${list.length.toLocaleString()})`;

  async function handleInvite() {
    await Promise.all(
      list.map(item => invite({ email: item.email, roleId: item.roleId }))
    );

    setList([]);
    onClose();
    reload();
    successToast({ message: 'The user has been invited.' });
  }

  async function handleSubmit(values, { resetForm }) {
    const result = await find({ email: values.email, companyId: data?.id });
    if (list.some(ml => ml.email === values.email)) {
      setError('This email is already added to invite list');
      return;
    }
    if (!result.error) {
      setError('This email is already in the company');
      return;
    }

    setError(false);
    setList(list.concat(values));
    resetForm();
  }

  return (
    <Modal
      className={className}
      isOpen={isOpen}
      onClose={() => {
        setList([]);
        setError(null);
        onClose();
      }}
    >
      <div className="container">
        <div className="info">
          <div className="header">
            <Avatar
              className="avatar"
              name={data?.name}
              avatarUrl={data?.logoUrl}
              initial={data?.initial}
              noTooltip
            />
            <div className="text">
              <h4 className="label">Invite new members to</h4>
              <p className="name">{data?.name}</p>
            </div>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form noValidate className="form">
                <TextField
                  id="email"
                  type="email"
                  name="email"
                  label="Email"
                  autoComplete="off"
                />
                <SelectField
                  id="roleId"
                  type="roleId"
                  name="roleId"
                  placeholder="Select Role"
                  options={options}
                />
                {/* <TextField
                  id="jobTitle"
                  type="jobTitle"
                  name="jobTitle"
                  placeholder="Job Title"
                  autoComplete="off"
                /> */}
                {error && <div className="error-msg">{error}</div>}
                <OutlineButton className="submit" type="submit">
                  Add Member
                </OutlineButton>
              </Form>
            )}
          </Formik>
        </div>
        <div className="member-list">
          <h5 className="title">{memberListTitle}</h5>
          <div className="list">
            {list.map(({ email, roleId }) => (
              <div className="item" key={email}>
                <Avatar
                  name={email}
                  initial={email.substr(0, 2).toUpperCase()}
                />
                <div className="email">{email}</div>
                <Select
                  className="role"
                  options={options}
                  placeholder="Select Role"
                  value={roleId}
                  onChange={({ value }) =>
                    setList(
                      list.map(item =>
                        item.email === email ? { ...item, roleId: value } : item
                      )
                    )
                  }
                />
                <button
                  type="button"
                  className="remove"
                  onClick={() =>
                    setList(list.filter(item => item.email !== email))
                  }
                >
                  <TrashIcon size={16} />
                </button>
              </div>
            ))}
          </div>
          <Button
            className="button"
            type="button"
            onClick={handleInvite}
            disabled={!list.length}
          >
            Invite User
          </Button>
        </div>
      </div>
    </Modal>
  );
}

const StyledInviteNewUserModal = styled(InviteNewUserModal)`
  .container {
    display: flex;
    flex-flow: row wrap;

    > .info {
      flex: 1 0 50%;
      padding-right: 50px;

      display: flex;
      flex-flow: column nowrap;

      > .header {
        display: flex;
        align-items: center;

        margin-bottom: 50px;

        > .text > .label {
          margin-bottom: 12px;

          font-size: 18px;
        }

        > .avatar {
          margin-right: 20px;

          > .image {
            width: 100px;
            height: 100px;
          }
        }

        > .avatar > .image.initial {
          border-radius: 6px;
          line-height: 100px;
          font-size: 40px;
          width: 100px;
          height: 100px;
        }
      }

      > .form {
        flex-grow: 1;

        display: flex;
        flex-flow: column nowrap;
      }

      .form > .error-msg {
        color: ${theme('colors.error')};
        text-align: center;
        margin-top: 12px;
      }

      > .form > .submit {
        margin-top: auto;
      }
    }

    > .member-list {
      flex: 1 0 50%;

      overflow-y: auto;
      display: flex;
      flex-flow: column nowrap;

      max-height: 100%;
      height: 380px;
      padding-left: 50px;

      > .title {
        margin-bottom: 20px;
      }

      > .list > .item {
        display: grid;
        grid-template-columns: 32px 1fr 100px 30px;
        grid-gap: 8px;
        align-items: center;

        margin-bottom: 16px;

        > .email {
          font-size: 14px;
          word-break: break-all;
        }

        > .role > .input-group > .field > div {
          font-size: 14px;
        }

        > .remove {
          border: 0;
          border-radius: 6px;

          padding: 4px;

          color: #adadad;

          background: #f4f7fd;
        }
      }

      > .button {
        margin-top: auto;

        &[disabled] {
          background-color: #adadad;
          border: none;
          cursor: not-allowed;
        }
      }
    }
  }

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    width: 90vw;

    .container {
      > .info {
        padding-right: 0px;
      }

      > .member-list {
        height: fit-content;
        margin-top: 12px;
        padding-left: 0px;

        > .list > .item > .email {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
`;

export { StyledInviteNewUserModal as InviteNewUserModal };
