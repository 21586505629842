import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { useMatch } from 'react-router-dom';
import GlobalContext from '../store/globalContext';
import { Avatar } from './Avatar';
import { useCurrentCompany } from '../features/projects/useCurrentCompany';
import { AddNewCompanyIcon, ChevronLeft, MagnifyingGlass } from './Icons';
import { useQueryParams } from '../lib/hooks';
import { MobileMenuModal } from './MobileMenuModal';

export const MobileHeaderLayout = styled(({ className }) => {
  const companyRoute = useMatch('company/:id');
  const companyExpensesRoute = useMatch('company/:id/expenses');
  const companySalariesRoute = useMatch('company/:id/salaries');
  const companiesRoute = useMatch('companies');
  // const isProjectRoute = useMatch('company/:id');
  // const isProjectCreateRoute = useMatch('company/:id/project/create');
  const [company, setCompany] = useState(undefined);
  const { data } = useCurrentCompany();
  const [queryParams, setQueryParams] = useQueryParams();
  const [isOpenMenuModal, setIsOpenMenuModal] = useState(false);
  const { globalDispatch } = useContext(GlobalContext);
  const onCloseMenuModal = () => {
    setIsOpenMenuModal(false);
  };
  useEffect(() => {
    if (data) {
      setCompany({
        id: data?.id,
        name: data?.name,
        logoUrl: data?.logoUrl,
        initial: data?.name.substr(0, 2).toUpperCase(),
        description: data?.description,
      });
    }
  }, [data]);

  useEffect(() => {
    if (queryParams && queryParams.mobileNav === 'true') {
      setIsOpenMenuModal(true);
    }
  }, [queryParams]);

  // function getTitleNav() {
  //   if (isProjectCreateRoute) {
  //     return 'Create new project';
  //   }
  //   return 'Projects';
  // }

  return (
    <div className={className}>
      {companySalariesRoute && (
        <>
          <div className="wrapper-header">
            <div className="label">
              <a
                className="back-icon"
                href={`/company/${companySalariesRoute?.params.id}`}
              >
                <ChevronLeft color="white" />
              </a>
              <div>Employee</div>
            </div>

            <div className="back-menu">
              <div
                className="search"
                onClick={() => {
                  globalDispatch({ type: 'TOGGLE_SEARCH' });
                }}
              >
                <MagnifyingGlass color="white" size="24" />
              </div>
              {company && (
                <Avatar
                  className="rectangle-avatar"
                  name={company.name}
                  initial={company.initial}
                  avatarUrl={company.logoUrl}
                  onClick={() => setIsOpenMenuModal(true)}
                  noTooltip
                />
              )}
            </div>
          </div>
          <MobileMenuModal
            isOpen={isOpenMenuModal}
            onClose={onCloseMenuModal}
            company={company}
          />
        </>
      )}
      {companyExpensesRoute && (
        <>
          <div className="wrapper-header">
            <div className="label">
              <a
                className="back-icon"
                href={`/company/${companyExpensesRoute?.params.id}`}
              >
                <ChevronLeft color="white" />
              </a>
              <div>Company Expense</div>
            </div>

            {company && (
              <Avatar
                className="rectangle-avatar"
                name={company.name}
                initial={company.initial}
                avatarUrl={company.logoUrl}
                onClick={() => setIsOpenMenuModal(true)}
                noTooltip
              />
            )}
          </div>
          <MobileMenuModal
            isOpen={isOpenMenuModal}
            onClose={onCloseMenuModal}
            company={company}
          />
        </>
      )}
      {companiesRoute && (
        <div className="wrapper-header">
          <div className="label">All Company</div>
          <div
            className="button-add-new-company"
            onClick={() => setQueryParams({ modal: 'create-company' })}
          >
            <AddNewCompanyIcon />
          </div>
        </div>
      )}
      {companyRoute && (
        <>
          <div className="wrapper-header">
            <div className="label">
              <a className="back-icon" href="/companies">
                <ChevronLeft color="white" />
              </a>
              <div>All Project</div>
            </div>
            {company && (
              <Avatar
                className="rectangle-avatar"
                name={company.name}
                initial={company.initial}
                avatarUrl={company.logoUrl}
                onClick={() => setIsOpenMenuModal(true)}
                noTooltip
              />
            )}
          </div>
          <MobileMenuModal
            isOpen={isOpenMenuModal}
            onClose={onCloseMenuModal}
            company={company}
          />
        </>
      )}
    </div>
  );
})`
  display: none;

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    display: flex;
    position: fixed;
    height: 60px;
    width: 100vw;
    background: #3a3a3a;
    color: white;
    padding: 0 15px;
    align-items: center;
    z-index: 10;

    > .wrapper-header {
      display: flex;
      justify-content: space-between;
      width: 100%;

      > .label {
        display: flex;
        align-items: center;
        font-size: 1rem;
        font-weight: bold;

        > .back-icon {
          margin-right: 10px;
        }
      }

      > .back-menu {
        display: flex;
        align-items: center;

        > .search {
          margin-right: 20px;
        }
      }

      > .button-add-new-company {
        width: 36px;
        height: 36px;
        padding: 8px;
        border-radius: 6px;
        border: dashed 1px #ffffff;
      }

      > .rectangle-avatar > .image {
        width: 36px;
        height: 36px;
        border-radius: 6px;
      }
    }
  }
`;
