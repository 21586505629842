import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import * as yup from 'yup';
import { Formik } from 'formik';
import { PermissionGuard } from 'features/authentication/hocs/withPermission';
import { SelectCustom } from './SelectCustom';
import { withEpicSelect } from './withEpicSelect';
import { TextField } from '../../authentication/components/TextField';
import { Button } from '../../authentication/components/Button';

const initialValues = {
  epicName: '',
};

const validationSchema = yup.object({
  epicName: yup.string().required('Epic name is required'),
});

function EpicSelect({
  className,
  onChange,
  value,
  data = [],
  onSubmit,
  onShow,
  onHide,
  show,
}) {
  return (
    <SelectCustom
      label="Epic"
      placeHolder="Select Epic"
      handleSelect={epic => onChange(epic.id)}
      selectedItem={data.find(item => item.id === value)}
      data={data}
    >
      <div className={className}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ submitForm }) => (
            <>
              {show ? (
                <div className="form">
                  <TextField id="epicName" name="epicName" label="New Epic" />
                  <footer className="footer">
                    <span className="cancel-btn" onClick={onHide}>
                      Cancel
                    </span>
                    <Button
                      type="submit"
                      className="add-btn"
                      onClick={submitForm}
                    >
                      + Add
                    </Button>
                  </footer>
                </div>
              ) : (
                <PermissionGuard allow="project-epic:write">
                  <span className="add-epic-btn" onClick={onShow}>
                    + Add new epic
                  </span>
                </PermissionGuard>
              )}
            </>
          )}
        </Formik>
      </div>
    </SelectCustom>
  );
}

const StyledEpicSelect = styled(EpicSelect)`
  .add-epic-btn {
    color: ${theme('colors.primary')};
    cursor: pointer;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .cancel-btn {
      color: ${theme('colors.primary')};
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }
`;

const ConnectEpicSelect = withEpicSelect(StyledEpicSelect);

export { ConnectEpicSelect as EpicSelect };
