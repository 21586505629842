import React from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import * as yup from 'yup';
import { theme } from 'styled-tools';
import { Modal } from '../Modal';
import {
  Button,
  OutlineButton,
} from '../../features/authentication/components/Button';
import { TextField } from '../../features/authentication/components/TextField';
import { DatePicker } from '../DatePicker';
import { ColorPalette } from '../ColorPalette';
import { colorOptions } from '../ColorPalette/ColorPalette';

const validationSchema = yup.object({
  sprintName: yup.string().required(),
  sprintGoal: yup.string(),
  startDate: yup.string().when('showStartDate', {
    is: true,
    then: schema => schema.test('is-start-error', value => !value),
  }),
  endDate: yup.string().when('showEndDate', {
    is: true,
    then: schema => schema.test('is-end-error', value => !value),
  }),
});

function SprintCreateModal({
  className,
  isOpen,
  onClose,
  onSubmit,
  selectedSprint,
  sprintMode,
  projectName,
  projectColor,
}) {
  const initialValues = {
    sprintName: sprintMode === 'edit' ? selectedSprint?.name : '',
    sprintGoal: sprintMode === 'edit' ? selectedSprint?.goal : '',
    color:
      sprintMode === 'edit'
        ? selectedSprint?.color
        : projectColor || colorOptions[0],
    startDate:
      sprintMode === 'edit' ? selectedSprint?.startDate : DateTime.utc(),
    endDate: sprintMode === 'edit' ? selectedSprint?.endDate : DateTime.utc(),
    showStartDate: false,
    showEndDate: false,
  };

  return (
    <Modal className={className} isOpen={isOpen} onClose={onClose}>
      <OutlineButton className="close-btn" onClick={onClose}>
        &#10006;
      </OutlineButton>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values, setFieldValue }) => (
          <Form noValidate>
            <div className="title">
              <h1>
                {sprintMode === 'edit' ? 'Edit Sprint' : 'Create New Sprint'}
              </h1>
              <h4>
                Project : <span className="project-name">{projectName}</span>
              </h4>
            </div>
            <div className="content">
              <label>Sprint Name</label>
              <TextField
                id="sprintName"
                name="sprintName"
                placeholder={
                  sprintMode === 'edit' ? 'Sprint Name' : 'New Sprint Name'
                }
              />
              <div className="color-palette">
                <label>Color</label>
                <ColorPalette className="color" name="color" hasLabel={false} />
              </div>
              <label>Sprint Goal</label>
              <TextField
                id="sprintGoal"
                name="sprintGoal"
                multiline
                rows={6}
                placeholder={
                  sprintMode === 'edit' ? 'Sprint Goal' : 'New Sprint Goal'
                }
              />
              <div className="date-picker">
                <DatePicker
                  name="startDate"
                  className="start-date-picker"
                  label="Start Date"
                  value={values.startDate}
                  onChange={dueDate => {
                    setFieldValue('showStartDate', dueDate > values.endDate);
                    setFieldValue('showEndDate', false);
                    setFieldValue('startDate', dueDate);
                  }}
                  dateFormat="dd/MM/yyyy"
                  showDateIcon
                  hiddenArrowIcon
                />
                <DatePicker
                  name="endDate"
                  className="end-date-picker"
                  label="End Date x"
                  value={values.endDate}
                  onChange={dueDate => {
                    setFieldValue('showEndDate', dueDate < values.startDate);
                    setFieldValue('showStartDate', false);
                    setFieldValue('endDate', dueDate);
                  }}
                  dateFormat="dd/MM/yyyy"
                  showDateIcon
                  hiddenArrowIcon
                />
              </div>
            </div>
            <footer className="footer">
              <OutlineButton onClick={onClose} className="cancel-btn">
                Cancel
              </OutlineButton>
              <Button type="submit">
                {sprintMode === 'edit' ? 'Save' : 'Create'}
              </Button>
            </footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

const StyledSprintCreateModal = styled(SprintCreateModal)`
  max-width: 500px;
  width: 90vw;
  position: relative;

  > .close {
    display: none;
  }

  .close-btn {
    position: absolute;
    background-color: #999;
    color: white;
    right: 25px;
    top: 25px;
    padding: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 0;
  }

  .title {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 2px solid #eee;

    > h1 {
      margin-bottom: 15px;
      font-size: 20px;
    }

    > h4 {
      font-size: 14px;
      > span {
        color: ${theme('colors.primary')};
        font-weight: normal;
      }
    }

    @media (max-width: ${theme('breakpoints.mobile')}px) {
      > h1 {
        font-size: 16px;
      }
    }
  }

  .content {
    > .color-palette {
      > label {
        font-size: 12px;
        color: #c1c3ca;
      }

      .color {
        flex-flow: column;
        align-items: flex-start;
        row-gap: 10px;

        span {
          color: #c1c3ca;
          font-weight: normal;
        }

        .radio-color-group {
          margin: 0;

          .input-group {
            input,
            span {
              width: 18px;
              height: 18px;

              img {
                width: 12px;
                height: 12px;
              }
            }
          }

          button {
            width: 18px;
            height: 18px;
          }
        }
      }
    }

    > label {
      font-size: 12px;
      color: #c1c3ca;
    }

    > ${TextField} {
      margin-top: 10px;
    }
    > .date-picker {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 10px;

      > .start-date-picker,
      .end-date-picker {
        flex-grow: 1;

        @media (max-width: ${theme('breakpoints.mobile')}px) {
          width: 100%;
        }
      }
    }
  }

  .footer {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap-reverse;

    ${Button} {
      margin-left: 12px;
      height: 46px;

      &.cancel-btn {
        border: none;
        color: #c1c3ca;

        &:hover {
          background-color: transparent;
        }
      }

      @media (max-width: ${theme('breakpoints.mobile')}px) {
        margin-left: 0px;
        margin-bottom: 20px;
        width: 100%;

        &.cancel-btn {
          margin-bottom: 0;
        }
      }
    }

    ${OutlineButton}.red-outline {
      border: 1px solid #e01b32;
      color: #e01b32;

      &:hover {
        background-color: #e01b32;
        color: white;
      }
    }
  }
`;

export { StyledSprintCreateModal as SprintCreateModal };
