import React, { useEffect } from 'react';
import hoist from 'hoist-non-react-statics';
import { useNavigate } from 'react-router-dom';
import { useAuthentication } from '../useAuthentication';
import { useQueryParams } from '../../../lib/hooks';

export function withUnauthenticationGuard(Component) {
  function WithUnauthenticationGuard(props) {
    const { isAuthenticated } = useAuthentication();
    const navigate = useNavigate();
    const [{ redirectTo = '/' }] = useQueryParams();
    const pageProps = {};

    useEffect(() => {
      if (!isAuthenticated) {
        return;
      }

      window.location.href = redirectTo;
    }, [isAuthenticated, navigate, redirectTo]);

    if (isAuthenticated) {
      return null;
    }

    return <Component {...props} {...pageProps} />;
  }

  hoist(WithUnauthenticationGuard, Component);

  WithUnauthenticationGuard.displayName = `withUnauthenticationGuard(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithUnauthenticationGuard;
}
